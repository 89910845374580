// React libs
import React, { FC, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Components
import LocalLoader from '../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import SlidingMenu from '../../../../Core/Components/UiKit/SlidingMenu/SlidingMenu';
import Typography from '../../../../Core/Components/UiKit/Typography/Typography';
// Hooks
import useExternalTerritories from '../../Data/Hooks/ExternalTerritories';
// Contexts
import MapConfigContext from '../../Data/Contexts/MapConfigContext';
// Type
import * as Types from './ExternalTerritoriesMenu.type';
import * as MapTypes from '../../Data/Models/Map.type';
// Utils
import { translateStringCenter } from '../../Utils/Map';

const ExternalTerritoriesMenu: FC<Types.IProps> = ({
  onTerritoryClick,
  defaultTerritory,
}) => {
  // Variables
  const { t } = useTranslation(['map']);

  // State
  const [isOpen, setIsOpen]: [boolean, Function] = useState(false);

  // Hooks
  const externalTerritories = useExternalTerritories();

  // Handlers
  const handlerTerritoryClick = (t: MapTypes.IExternalTerritory) => {
    setTimeout(() => setIsOpen(false), 300);
    onTerritoryClick(t);
  };
  const onMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  // Renders
  const getElement = (t: MapTypes.IExternalTerritory) => (
    <div
      key={t.id}
      className='flex flex-col flex-shrink-0 cursor-pointer mx-2'
      onClick={() => handlerTerritoryClick(t)}
    >
      <img
        className={`border p-1 max-w-40 rounded ${
          defaultTerritory?.id === t.id ||
          (!defaultTerritory && t.id === 'home')
            ? 'border-red-610'
            : 'border-transparent'
        }`}
        src={t.img}
        alt={t.label}
      />
      <Typography variant='h6' className='text-center'>
        {t.label}
      </Typography>
    </div>
  );
  return (
    <Fragment>
      <div className='hidden' data-testid='external-territories-menu' />
      <SlidingMenu
        header={t('map:externalTerritories.menu.header')}
        isOpen={isOpen}
        onToggle={onMenuToggle}
      >
        <MapConfigContext.Consumer>
          {({ mapConfig }) => {
            return externalTerritories.isLoading ? (
              <div className='flex h-full items-center justify-center w-full'>
                <LocalLoader
                  message={t('map:externalTerritories.menu.loading')}
                  color='secondary'
                />
              </div>
            ) : (
              <div className='flex h-full items-center'>
                {externalTerritories.data?.territories?.map(
                  (t: MapTypes.IExternalTerritory) => getElement(t)
                )}
                {getElement({
                  id: 'home',
                  img: '/data/images/territories/Home.png',
                  label: 'Accueil',
                  zoom: mapConfig?.mapConfigZoom || 0,
                  coords: {
                    lat: translateStringCenter(
                      mapConfig?.mapConfigCenter || ''
                    )[0],
                    lng: translateStringCenter(
                      mapConfig?.mapConfigCenter || ''
                    )[1],
                  },
                })}
              </div>
            );
          }}
        </MapConfigContext.Consumer>
      </SlidingMenu>
    </Fragment>
  );
};

ExternalTerritoriesMenu.propTypes = {
  defaultTerritory: PropTypes.any,
  onTerritoryClick: PropTypes.func.isRequired,
};

export default ExternalTerritoriesMenu;
