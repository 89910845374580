import React, { FC } from 'react';
// Components
import Button from './Button'
import FaIcon from '../Icon/FaIcon/FaIcon'
// Type
import * as Types from './ActiveButton.type'

const ActiveButton: FC<Types.Iprops> = ({
  className,
  handler,
  icon,
  iconClasses,
  iconType,
  isActive,
  label,
}) =>
  <Button
    className={`${isActive ? 'bg-active text-selection-inverse hover:bg-active-hover' : 'hover:bg-active-hover'} ${className}}`}
    color='secondary'
    data-testid='activeButton'
    onClick={handler}
    size='small'
    variant={isActive ? 'contained' : 'text'}
  >
    <FaIcon name={icon} className={`text-lg mr-1 ${iconClasses}`} type={iconType} />
    <div className='relative'>{label}</div>
  </Button>

export default ActiveButton
