// React libs
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { keyBy, sortBy } from 'lodash';
// Components
import LinksFilter from '../../../../Core/Components/UiKit/LinksFilter/LinksFilter'
// Services
import DatavizService from '../Services/DatavizService';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'
// Hooks
import useGenericData from '../../../../Core/Data/Hooks/GenericData';
import useLinkTypes from '../../../../Core/Data/Hooks/LinkTypes';
// utils
import { ifDef } from '../../../../Core/Utils/Misc'

const useData = (dateStart: string, dateEnd: string, filters: any) => {
  // Variables
  const resourceLinksEvolutionMetrics = useGenericData(() => DatavizService.getResourceLinksEvolutionMetrics(dateStart, dateEnd, filters))
  const linkTypes = useLinkTypes()

  const isLoading = resourceLinksEvolutionMetrics.isLoading || linkTypes.isLoading
  const refreshTime = useMemo(() => Math.max(resourceLinksEvolutionMetrics.refreshTime, linkTypes.refreshTime), [linkTypes.refreshTime, resourceLinksEvolutionMetrics.refreshTime])
  const linkTypesById = useMemo(() => keyBy(linkTypes.data, 'id'), [linkTypes.data])
  const legends = useMemo(() => {
    if (resourceLinksEvolutionMetrics.data == null || resourceLinksEvolutionMetrics.data.length === 0) {
      return
    }

    const [entry] = resourceLinksEvolutionMetrics.data
    return Object.keys(entry).filter(key => key !== 'date').map(id => linkTypesById[id]?.label)
  }, [linkTypesById, resourceLinksEvolutionMetrics.data])
  const { dates, series } = useMemo(() => {
    const dates: string[] = []
    const dataByLegend: { [key: string]: any } = {}
    sortBy(resourceLinksEvolutionMetrics.data, 'date').forEach(({ date, ...linkIds }) => {
      dates.push(moment(date).format('MMM YY'))

      Object.entries(linkIds).forEach(([linkId, value]) => {
        const linkLabel = linkTypesById[linkId]?.label
        if (dataByLegend[linkLabel] === undefined) {
          dataByLegend[linkLabel] = []
        }
        dataByLegend[linkLabel].push(value)
      })
    })
    return {
      dates,
      series: Object.entries(dataByLegend).map(([legend, data]) => ({
        legend,
        data
      }))
    }
  }, [linkTypesById, resourceLinksEvolutionMetrics.data])

  // Handlers
  const refresh = useCallback(() => {
    if (!isLoading) {
      resourceLinksEvolutionMetrics.refresh()
      linkTypes.refresh()
    }
  }, [isLoading, linkTypes, resourceLinksEvolutionMetrics])

  return {
    isLoading,
    refresh,
    refreshTime,
    data: {
      legends,
      dates,
      series
    },
  }
}

const useResourceLinksEvolutionMetrics = (dateStart: string, dateEnd: string): CoreTypes.IDatavizHook => {
  // Ref
  const toggleFilterVueRef = useRef({ toggleFilterVue: () => { } })

  // State
  const [filters, setFilters] = useState<any>()

  // Variables
  const data = useData(dateStart, dateEnd, filters)

  const stringFilters = useMemo(() => ifDef(filters, JSON.stringify), [filters])
  const areFiltersEnabled = filters !== undefined

  // Handlers
  const onFiltersClear = useCallback(() => {
    setFilters(undefined)
  }, [])
  const onFiltersChange = useCallback(filters => {
    setFilters(filters)
    toggleFilterVueRef.current.toggleFilterVue()
  }, [])

  // Effects
  useEffect(() => {
    data.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, stringFilters])

  return {
    ...data,
    filter: {
      Component: <LinksFilter filters={filters} onFiltersChange={onFiltersChange} />,
      onClear: onFiltersClear,
      areEnabled: areFiltersEnabled,
      toggleFilterVueRef
    },
  }
}

export default useResourceLinksEvolutionMetrics