export default class ProjectUrls {
  static path = process.env.REACT_APP_HOST;

  static get apiPath(): string {
    return `${ProjectUrls.path ?? ''}/api`;
  }

  // Tasks
  static getProjectTasks(id: string) {
    return `${ProjectUrls.apiPath}/kanban/${id}`
  }
  static updateProjectTasks(id: string) {
    return `${ProjectUrls.apiPath}/kanban/${id}`
  }
}
