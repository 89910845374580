// React libs
import React, { FC } from 'react';

interface IProps {
  className?: string;
  color?: string;
  icon?: string;
}

const PoiProjectMarker: FC<any> = ({ className, color, icon }: IProps) => (
  <svg
    width='54'
    height='50'
    viewBox='0 0 54 35'
    version='1.1'
    className={className}
  >
    <path
      d='M32.23 1.88C38.59 1.88 43.74 7.46 43.74 14.34C43.74 14.6 43.74 14.86 43.72 15.12C48.32 16.16 51.69 19.67 51.69 23.84C51.69 23.84 51.69 23.84 51.69 23.84C51.69 28.83 46.84 32.88 40.86 32.88C38.14 32.88 16.4 32.88 13.69 32.88C7.7 32.88 2.85 28.83 2.85 23.84C2.85 23.84 2.85 23.84 2.85 23.84C2.85 19.48 6.54 15.85 11.45 14.99C11.89 10.27 15.57 6.59 20.04 6.59C21.03 6.59 21.97 6.77 22.85 7.1C24.94 3.94 28.36 1.88 32.23 1.88Z'
      fill={color}
    ></path>
    <foreignObject className='node' x='0' y='0' width='100%' height='100%'>
      <i className={`fa fa-${icon}  icon-white`}></i>
    </foreignObject>
  </svg>
);

PoiProjectMarker.defaultProps = {
  className: '',
  color: 'currentColor',
  icon: '',
};

export default PoiProjectMarker;
