// React libs
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next';
// Components
import AppLayout from '../../../App/Components/Layout/AppLayout/AppLayout'
import PoisList from '../../../Core/Components/Form/PoisList/PoisList';
// Hooks
import usePois from '../../../Core/Data/Hooks/Pois';
// Types
import * as Types from './Projects.type'

const Projects: FC<Types.IProps> = () => {
  // State
  const [filters, updateFilters] = useState<any>({
    $and: [
      { isProject: true }
    ]
  })
  const [pagination, onPaginationChange] = useState({
    page: 0,
    itemsPerPage: 50
  })
  // Variables
  const { t } = useTranslation(['projects']);
  const projects = usePois({
    filter: filters,
    size: pagination.itemsPerPage,
    offset: pagination.itemsPerPage * pagination.page,
  })

  const onFiltersUpdate = (filters: any) => {
    updateFilters(filters)
    onPaginationChange(pagination => ({
      ...pagination,
      page: 0
    }))
  }

  return <AppLayout
    headerConf={{
      title: {
        label: t('projects:title'),
        icon: 'cubes'
      }
    }}
    isLoading={projects.isLoading}
    loadingMessages={[t('projects:loadingMessage')]}
    className='h-full w-full p-2 overflow-auto'
  >
    <PoisList
      isProject
      pois={projects.data ?? []}
      poiFilters={filters}
      updatePoiFilters={onFiltersUpdate}
      refreshPois={projects.refresh}
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      totalRows={projects.total}
    />
  </AppLayout>
}

export default Projects