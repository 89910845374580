// React libs
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// Components
import ExportButton from '../../../../../Core/Components/UiKit/Button/ExportButton/ExportButton';
import FormHeader from '../../../../../Core/Components/Form/FormHeader/FormHeader';
import FormTitle from '../../../../../Core/Components/Form/FormTitle/FormTitle';
import * as FormFields from '../../../../../Core/Components/Form/FormFields/FormFields';
// Types
import * as Types from './ResourceIdentityPreview.type'
// Utils
import useServiceErrorHandler from '../../../../../Core/Utils/useServiceErrorHandler';
import { formatProjectAddress, getPoiImageUrl } from '../../../../../Core/Utils/Misc';
// Services
import CommonService from '../../../../../Core/Data/Services/CommonService';

const ResourceIdentityPreview: FC<Types.IProps> = ({ poi, toggleEditionMode }) => {
  // State
  const [isExporting, setIsExporting] = useState<boolean>(false)
  // Variables
  const { t } = useTranslation(['resource']);
  const thematic = useMemo(() => poi.thematics && poi.thematics.map(({ thematic }: any) => thematic.label), [poi.thematics])
  const imageUrl = poi.image != null ? getPoiImageUrl(poi.image.id) : `${process.env.PUBLIC_URL}/data/images/noPhoto.png`
  const address = useMemo(() => formatProjectAddress(poi.address), [poi.address])
  const handleServiceError = useServiceErrorHandler()
  // Handlers
  const exportProject = useCallback(async () => {
    setIsExporting(true)
    try {
      const { data: { url } } = await CommonService.exportResourceInfo(poi.id)
      window.open(url, 'Download')
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsExporting(false)
    }
  }, [handleServiceError, poi.id])

  return <div className='h-full w-full p-1'>
    <div className='h-0.7/10 w-full px-2 pt-1'>
      <FormHeader
        title={t('resource:identity')}
        toggleEditionMode={toggleEditionMode}
        isEditionMode={false}
        actions={<ExportButton
          pending={isExporting}
          onClick={exportProject}
        />}
      />
    </div>
    <div className='h-9.3/10 w-full p-1 pt-2 overflow-auto'>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormFields.FormPreviewFieldValue label={t('resource:type')} value={poi.type?.label} />
          <FormFields.FormPreviewFieldValue label={t('resource:name')} value={poi.name} />
          <FormFields.FormPreviewFieldValue label={t('resource:thematic')} value={thematic} />
        </Grid>
        <Grid item xs={6} className='flex justify-end'>
          <img src={imageUrl} alt='preview' className='h-41' />
        </Grid>
        <Grid item xs={12}>
          <FormTitle title={t('resource:localization')} />
          <div>
            <FormFields.FormPreviewFieldValue label={t('resource:territory')} value={poi.territory?.label} />
            <FormFields.FormPreviewFieldValue label={t('resource:coordinates')} value={poi.geo?.coordinates} />
            <FormFields.FormPreviewFieldValue label={t('resource:address')} value={address} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <FormTitle title={t('resource:description')} />
          <div>
            <FormFields.FormPreviewFieldValue label={t('resource:comment')} value={poi.comment} />
            <FormFields.FormPreviewFieldValue label={t('resource:keywords')} value={poi.keywords} />
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
}

export default ResourceIdentityPreview