// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Hooks
import useGenericData, { IGetGenericData } from './GenericData';

export interface IGetAccessTypes extends IGetGenericData<CoreTypes.IAccessType> {
}

const useAccessTypes = (): IGetAccessTypes => {
  return useGenericData(() => CommonService.getAccessTypes())
}

export default useAccessTypes