// React libs
import React, { FC, useContext, useMemo } from 'react';
import { Divider } from '@material-ui/core';
// Components
import EditButton from '../Buttons/EditButton/EditButton';
import Typography from '@material-ui/core/Typography';
// Type
import * as Types from './FormHeader.type';
// Contexts
import PoiContext from '../../../Data/Contexts/PoiContext';
import UserContext from '../../../Data/Contexts/UserContext';
// Utils
import { hasPermissionsOnPoi } from '../../../Utils/User';


const FormHeader: FC<Types.IProps> = ({ toggleEditionMode, isEditionMode, title, actions }) => {
  // contexts
  const { poi } = useContext(PoiContext)
  const { user } = useContext(UserContext)
  // variable
  const canEdit = useMemo(() => poi == null || (user && hasPermissionsOnPoi(poi, user)), [poi, user])

  return <div data-testid='form-header'>
    <div className="flex w-full items-center justify-between">
      <div className='flex'>
        <Typography variant="h6">
          {title}
        </Typography>
        {canEdit && isEditionMode !== undefined &&
          <div className='ml-1'>
            <EditButton
              onClick={toggleEditionMode}
              isEditionMode={isEditionMode}
              size='medium'
            />
          </div>
        }
      </div>
      <div>
        {actions}
      </div>
    </div>
    <Divider variant="middle" className='mt-1' />
  </div>
}

export default FormHeader
