// React libs
import React, { FC, useCallback } from 'react';
import * as Yup from 'yup';
import { difference } from 'lodash'
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Components
import FormResourceIdentity from '../../../Components/Form/FormResourceIdentity'
// Types
import * as Types from './ResourceIdentityEdition.type'
import * as FormResourceIdentityTypes from '../../../Components/Form/FormResourceIdentity.type';
// Utils
import useConfirmMessageModal from '../../../../../Core/Utils/useConfirmMessageModal'
import useServiceErrorHandler from '../../../../../Core/Utils/useServiceErrorHandler';
import { getPoiImageUrl } from '../../../../../Core/Utils/Misc';
// Services
import CommonService from '../../../../../Core/Data/Services/CommonService'
// Common
import CoreCommon from '../../../../../Core/Resources/Common';

const ResourceIdentityEdition: FC<Types.IProps> = ({ poi, toggleEditionMode, refreshPoi }) => {
  // Variables
  const { t } = useTranslation(['common', 'resource']);
  const { enqueueSnackbar } = useSnackbar();
  const poiTypeChangeConfirmModal = useConfirmMessageModal(t('common:modales.confirm.poiTypeChangeConfirmation'))
  const handleServiceError = useServiceErrorHandler()
  const linkedImageUrls = poi.image != null ? [getPoiImageUrl(poi.image.id)] : undefined
  const defaultValues: FormResourceIdentityTypes.IFormProject = {
    type: poi.type?.id,
    name: poi.name,
    thematics: poi.thematics?.map(({ thematic }: any) => thematic.id) ?? [],
    territory: poi.territory?.id,
    geo: poi.geo?.coordinates,
    address: poi.address?.id,
    comment: poi.comment ?? '',
    keywords: poi.keywords ?? '',
  }
  const validationSchema = Yup.object({
    image: Yup.mixed(),
    type: Yup.string().required(t('common:errors.defaultRequiredField')),
    name: Yup.string().required(t('common:errors.defaultRequiredField')),
    thematics: Yup.array(Yup.string()),
    territory: Yup.string().nullable(),
    geo: Yup.array(Yup.number().required(t('common:errors.defaultRequiredField'))),
    address: Yup.string().required(t('common:errors.defaultRequiredField')),
    comment: Yup.string().nullable(),
    keywords: Yup.string().nullable(),
  })

  // Handlers
  const handleSubmitPoi = useCallback(async (values: FormResourceIdentityTypes.IFormProject, { setSubmitting, resetForm }: FormikHelpers<FormResourceIdentityTypes.IFormProject>) => {
    setSubmitting(true);
    const getValueIfChanged = (name: keyof FormResourceIdentityTypes.IFormProject) => {
      let value = values[name]
      if (value !== defaultValues[name]) {
        typeof value === 'string' && (value = value.trim())
        return value === '' || value === undefined ? null : value
      }
    }

    try {
      const serviceFns = []
      const updateProps: any = {
        id: poi.id,
        isProject: false,
        fkimage: undefined,
        fkpoiType: values.type,
        name: getValueIfChanged('name'),
        thematics: undefined,
        fkterritory: getValueIfChanged('territory'),
        geo: defaultValues.geo?.[0] !== values.geo?.[0] || defaultValues.geo?.[1] !== values.geo?.[1]
          ? {
            type: "Point",
            coordinates: values.geo?.map(pos => +pos)
          }
          : undefined,
        fkaddress: getValueIfChanged('address'),
        comment: values.comment !== defaultValues.comment ? values.comment : undefined,
        keywords: values.keywords !== defaultValues.keywords ? values.keywords : undefined,
      }

      if (values.image !== undefined) {
        const image = await CommonService.uploadImage({
          file: values.image
        })
        updateProps.fkimage = image.data.id

        if (poi.image !== undefined) {
          serviceFns.push(() => CommonService.deleteImage(poi.image.id))
        }
      }

      if (values.thematics != null) {
        const thematicsToAdd = difference(values.thematics, defaultValues.thematics ?? [])
        const thematicsToDel = difference(defaultValues.thematics, values.thematics)
        if (thematicsToAdd.length > 0 || thematicsToDel.length > 0) {
          const thematics: any = updateProps.thematics = []
          thematicsToAdd.forEach((id: string) => {
            thematics.push({
              thematic: {
                id
              }
            })
            serviceFns.push(() => CommonService.savePoiThematics({
              fkpoi: poi.id,
              fkthematic: id
            }))
          })
          thematicsToDel.forEach((id: string) => {
            thematics.push({
              thematic: {
                id,
                toDelete: true
              }
            })
            serviceFns.push(() => CommonService.deletePoiThematic(id, poi.id))
          })
        }
      }

      await CommonService.updatePoi(poi.id, updateProps)
      await Promise.all(serviceFns.map(fn => fn()))

      enqueueSnackbar(t('common:forms.validation.saveSucceeded'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'success',
      })
    } catch (error) {
      handleServiceError(error)
    } finally {
      setSubmitting(false);
      resetForm({ values: defaultValues });
      refreshPoi()
      toggleEditionMode()
    }
  }, [refreshPoi, defaultValues, enqueueSnackbar, handleServiceError, poi.id, poi.image, t, toggleEditionMode])

  const onPoiTypeChange = useCallback(() => poiTypeChangeConfirmModal.openModal().then(() => true, () => false), [poiTypeChangeConfirmModal])

  return <div className='h-full w-full'>
    <FormResourceIdentity
      defaultValues={defaultValues}
      onFormSubmit={handleSubmitPoi}
      validationSchema={validationSchema}
      miscFunctions={{ toggleEditionMode, onPoiTypeChange }}
      miscData={{ linkedImageUrls, isEditionMode: true }}
    />
    {poiTypeChangeConfirmModal.modal}
  </div>
}

export default ResourceIdentityEdition
