// React libs
import React, { FC } from 'react';
// Components
import Button from '../Button';
import FaIcon from '../../Icon/FaIcon/FaIcon';
import GenericIconButton from '../GenericIconButton/GenericIconButton';
import { Tooltip } from '@material-ui/core';
// Types
import * as Types from './FilterButton.type'

const FilterButton: FC<Types.IProps> = ({ onClick, isActive, isFiltersPanelOpened, tooltip, label, className }) => {
  const commonIconClassName = 'text-sm h-6 w-6 p-1 flex items-center justify-center'

  if (label === undefined) {
    return <span data-testid='FilterButton'>
      <GenericIconButton
        ariaLabel='filter button'
        onClick={onClick}
        icon='filter'
        tooltip={tooltip}
        iconClassName={isActive ? `bg-active rounded-full text-selection-inverse ${commonIconClassName}` : ''}
      />
    </span>
  }

  const button = <Button
    aria-label='filter button'
    variant='text'
    color='secondary'
    size='small'
    startIcon={<FaIcon name='filter' className={isActive ? `bg-white-80 rounded-full text-red-610 ${commonIconClassName}` : ''} />}
    onClick={onClick}
    className={`text-selection hover:bg-active-hover ${isFiltersPanelOpened ? 'bg-active text-selection-inverse' : ''} ${className ?? ''}`}
  >
    {label}
  </Button>

  return <span data-testid='FilterButton'>
    {tooltip !== undefined
      ? <Tooltip title={tooltip}>
        <span>
          {button}
        </span>
      </Tooltip>
      : button
    }
  </span>
}

export default FilterButton