// React libs
import React, {
  FC,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';
// Contexts
import MapContext from '../../Data/Contexts/MapContext';
// Components
import LocalLoader from '../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import MapLayer from '../../Components/Canvas/MapLayer/MapLayer';
import Typography from '../../../../Core/Components/UiKit/Typography/Typography';
import TabsFilter, {
  getNbActiveFilters,
} from '../../Components/SharedMap/TabsFilter';
// Types
import * as Types from './SharedMap.type';
import * as MapTypes from '../../Data/Models/Map.type';
// Services
import MapService from '../../Data/Services/MapService';

import FaIcon from '../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import RecapPopup from '../../Components/SharedMap/RecapPopup';
import { sportsPicto } from '../../../Resource/constants/sports.picto';
import { updateNbOfPois } from '../../Data/Hooks/updateNbOfPois';

const SharedMap: FC<Types.IProps> = ({ location }) => {
  // State
  const useEffectRunning = useRef<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mapShare, setMapShare] = useState<MapTypes.IMapSharesPois>();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [map, setMap] = useState<MapTypes.IMapSharesPois>();
  const [needPoisRefresh, setNeedPoisRefresh] = useState(true);
  const [initialValues, setInitialValues] = useState<
    MapTypes.IMapSharesPois['mapInfos']
  >({
    center: [0, 0],
    zoom: 0,
    layer: '',
  });
  const [filters, setFilters] = useState<Types.IFilters>({
    facilities: [],
    clubs: [],
    sports: [],
    sportsAge: undefined,
    id: undefined,
  });
  const [emptyMarkers, setEmptyMarkers] = useState<boolean>(true);
  const [recapPopup, setRecapPopup] = useState<Types.IMarker>();
  const [facilities, setFacilities] = useState<MapTypes.IPoiType[]>([]);
  const [clubs, setClubs] = useState<Types.IMarker[]>([]);
  const [sports, setSports] = useState<Types.ISport>({});

  // Variabless
  const { t } = useTranslation(['map']);
  const query = new URLSearchParams(location?.search);
  const id = query.get('embed');
  const showLegend = query.get('title') === 'true';
  const legend = query.get('legend');
  const isFiltersOn = query.get('filters') === 'true';
  const isRecapOn = query.get('recap') === 'true';

  const mapId = useMemo(() => _.uniqueId('shared-map'), []);

  //Effects
  useEffect(() => {
    if (useEffectRunning.current) {
      return;
    }
    useEffectRunning.current = true;
    MapService.getMapSharesPois(id ?? '')
      .then(
        ({ data }: { data: MapTypes.IMapSharesPois }) => {
          const markers: Types.IMarker[] = [];
          const poiTypes: { [id: string]: MapTypes.IPoiType } = {};

          data.markers.forEach((marker: Types.IMarker) => {
            markers.push({
              ...marker,
              fkpoiType: marker.type.id,
            });
            poiTypes[marker.type.id] = marker.type;
          });

          // links
          const links: MapTypes.IPoiLink[] = [];
          const linkTypes: { [id: string]: MapTypes.ILinkType } = {};

          data.links.forEach((link: MapTypes.IPoiLink) => {
            markers.forEach((marker: Types.IMarker, index) => {
              if (marker.id === link.poi.id) {
                const findLinkedPoi = markers.find(
                  (linkedMarker: Types.IMarker) =>
                    linkedMarker.id === link.fkpoiFrom
                );

                if (findLinkedPoi) {
                  markers[index] = {
                    ...marker,
                    links: marker.links
                      ? [
                          ...marker.links,
                          {
                            ...link,
                            poi: findLinkedPoi,
                          },
                        ]
                      : [
                          {
                            ...link,
                            poi: findLinkedPoi,
                          },
                        ],
                  };
                }
              }
            });
          });

          setInitialValues(data.mapInfos);
          const map = {
            ...data,
            mapInfos: {
              ...data.mapInfos,
              zoom: data.mapInfos.zoom,
              center:
                markers.length === 1
                  ? markers[0].geo.coordinates
                  : data.mapInfos.center,
            },
            markers,
            poiTypes: Object.values(poiTypes),
            links,
            linkTypes: Object.values(linkTypes),
          };

          setMapShare(map as MapTypes.IMapSharesPois);

          // Only on the first render
          setSports(prev => {
            if (_.isEmpty(prev)) {
              const sportsObject = data?.markers
                .filter(marker => marker.isProject)
                .reduce((acc: Types.ISport, marker: Types.IMarker) => {
                  marker.valueSlots.forEach((valueSlot: Types.IValueSlot) => {
                    if (valueSlot.valueDef.label === 'Activité') {
                      if (!acc[marker.type.label]) {
                        acc[marker.type.label] = {
                          activities: [],
                          style: { ...marker.type.style },
                        };
                      }
                      if (
                        !acc[marker.type.label].activities.some(
                          (activity: Types.IActivity) =>
                            activity.value === valueSlot.value
                        )
                      ) {
                        const s = valueSlot.valueDef.valueChoices.find(
                          (vc: MapTypes.IValueChoice) =>
                            vc.value === valueSlot.value
                        );
                        acc[marker.type.label].activities.push({
                          label: s?.label,
                          value: s?.value,
                          nbOfPois: 1,
                          picto:
                            sportsPicto.find(
                              picto => picto.slug === valueSlot.value
                            )?.picto ?? '',
                        });
                      } else {
                        const index = acc[
                          marker.type.label
                        ].activities.findIndex(
                          (activity: Types.IActivity) =>
                            activity.value === valueSlot.value
                        );
                        acc[marker.type.label].activities[index].nbOfPois += 1;
                      }
                    }
                  });
                  return acc;
                }, {});
              return sportsObject;
            }
            return prev;
          });

          // Only on the first render
          setFacilities(prevFacilities =>
            prevFacilities.length === 0
              ? Object.values(poiTypes)
                  .filter(
                    (poiType: MapTypes.IPoiType) =>
                      !poiType.isProject && poiType.id !== 'ASSOCIATION'
                  )
                  .sort((a, b) => a.label.localeCompare(b.label))
              : prevFacilities
          );

          // Only on the first render
          setClubs(prevClubs =>
            prevClubs.length === 0
              ? data?.markers
                  .filter(marker => marker.type?.id === 'ASSOCIATION')
                  .sort((a, b) => a.name.localeCompare(b.name))
              : prevClubs
          );
        },
        error => {
          console.error(error);
        }
      )
      .finally(() => {
        setIsLoading(false);
        setNeedPoisRefresh(true);
        useEffectRunning.current = false;
      });
  }, [id]);

  const toggleSidebar = useCallback(() => setIsSidebarOpen(prev => !prev), []);

  const contextValue = useMemo(
    () => ({
      map,
      setMap,
      setSearchedPoi: () => {},
      setCurrentZoom: () => {},
      setCurrentCenter: () => {},
    }),
    [map]
  );

  const resetFilters = useCallback(
    () =>
      setFilters({
        facilities: [],
        clubs: [],
        sports: [],
        sportsAge: undefined,
        id: undefined,
      }),
    [setFilters]
  );

  const renderFilters = useCallback(() => {
    if (!isFiltersOn) {
      return null;
    }

    const nbActiveFilters = getNbActiveFilters(filters);

    if (isSidebarOpen) {
      return (
        <TabsFilter
          showLegend={showLegend}
          facilities={facilities}
          resetFilters={resetFilters}
          clubs={clubs}
          sports={sports}
          filters={filters}
          setFilters={setFilters}
          toggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
        />
      );
    } else {
      return (
        <div
          className={`absolute left-0 top-${showLegend ? '12' : '4'} z-1500`}
        >
          <Tooltip title={t('map:sidebar.project.open') ?? ''}>
            <button
              onClick={toggleSidebar}
              className='w-8 h-12 bg-selection opacity-80 rounded-r-lg'
            >
              <FaIcon name='chevron-right' className='text-xl text-white' />
            </button>
          </Tooltip>
          {nbActiveFilters > 0 && (
            <span className='absolute bg-red-600 -right-2 -top-2 rounded-full text-xs w-4 h-4 flex items-center justify-center'>
              {nbActiveFilters}
            </span>
          )}
        </div>
      );
    }
  }, [
    clubs,
    facilities,
    filters,
    isFiltersOn,
    isSidebarOpen,
    resetFilters,
    showLegend,
    sports,
    t,
    toggleSidebar,
  ]);

  const markers = useMemo(() => {
    if (!mapShare) {
      return [];
    }
    if (!isFiltersOn) {
      return mapShare.markers;
    }

    if (emptyMarkers) {
      return [];
    }

    let markers = mapShare.markers;

    if (!_.isEmpty(filters.facilities) || !_.isEmpty(filters.clubs)) {
      markers = mapShare.markers.filter(
        marker =>
          filters.clubs.includes(marker.id) ||
          filters.facilities.includes(marker.type?.id ?? '')
      );
    }

    if (!_.isEmpty(filters.sports)) {
      const sportsMarkers = mapShare.markers.filter(marker =>
        marker.valueSlots.some(
          valueSlot =>
            valueSlot.valueDef.label === 'Activité' &&
            filters.sports.includes(valueSlot.value)
        )
      );
      if (!filters.sportsAge) {
        markers = sportsMarkers;
      } else {
        const filteredMarkers = sportsMarkers.filter(marker => {
          const minAgeValueSlot = marker.valueSlots.find(
            valueSlot => valueSlot.valueDef.label === 'Age min'
          );
          const maxAgeValueSlot = marker.valueSlots.find(
            valueSlot => valueSlot.valueDef.label === 'Age max'
          );
          if (
            minAgeValueSlot &&
            maxAgeValueSlot &&
            filters.sportsAge &&
            parseInt(filters.sportsAge) >= parseInt(minAgeValueSlot.value) &&
            parseInt(filters.sportsAge) <= parseInt(maxAgeValueSlot.value)
          ) {
            return true;
          }
          return false;
        });
        markers = filteredMarkers;
      }
    }

    if (!_.isEmpty(filters.id)) {
      const markerFromId = mapShare.markers.find(
        marker => marker.id === filters.id
      );
      const isMarkerWithIdExist = markers.find(
        marker => marker.id === markerFromId?.id
      );

      if (!isMarkerWithIdExist && markerFromId) {
        markers = [...markers, markerFromId];
      }
    }

    return markers;
  }, [
    emptyMarkers,
    filters.clubs,
    filters.facilities,
    filters.id,
    filters.sports,
    filters.sportsAge,
    isFiltersOn,
    mapShare,
  ]);

  useEffect(() => {
    setSports((prev: any) => {
      if (_.isEmpty(prev)) {
        return prev;
      } else {
        const sportMarkers = mapShare?.markers.filter(
          marker => marker.isProject
        );
        if (_.isEmpty(sportMarkers)) {
          return prev;
        }
        return updateNbOfPois(
          prev,
          sportMarkers as Types.IMarker[],
          parseInt(filters.sportsAge as string),
          filters.sportsAge ? true : false
        );
      }
    });
  }, [filters.sportsAge, mapShare]);

  useEffect(() => {
    const isFiltersActive =
      !_.isEmpty(filters.clubs) ||
      !_.isEmpty(filters.facilities) ||
      !_.isEmpty(filters.sports);

    if (isFiltersActive) {
      setEmptyMarkers(false);
    } else {
      setEmptyMarkers(true);
    }

    if (isFiltersActive) {
      setMapShare(prev => {
        if (_.isUndefined(prev)) {
          return prev;
        }
        const findMarkersFromFilterId = filters.id
          ? prev.markers.find(marker => marker.id === filters?.id)
          : undefined;

        return {
          ...prev,
          mapInfos: {
            center: findMarkersFromFilterId
              ? (findMarkersFromFilterId.geo.coordinates as [number, number])
              : [...initialValues.center],
            zoom: !_.isUndefined(findMarkersFromFilterId)
              ? 17
              : initialValues.zoom,
            layer: prev.mapInfos.layer,
          },
        };
      });
    }

    // We have to refresh the pois when the filters change
    setNeedPoisRefresh(true);
  }, [filters, initialValues, setNeedPoisRefresh]);

  return (
    <MapContext.Provider value={contextValue}>
      <div data-testid='shared-map' className='h-full'>
        {isLoading || !mapShare ? (
          <div className='flex h-full w-full items-center justify-center bg-map'>
            {isLoading ? (
              <LocalLoader message={t('map:shared.loading')} type='contained' />
            ) : (
              <Typography
                variant='h1'
                className='font-extrabold text-center m-auto'
              >
                {t('map:shared.noMapShared')}
              </Typography>
            )}
          </div>
        ) : (
          <div className='flex flex-1 overflow-hidden relative h-full w-full'>
            <MapLayer
              poisRefresh={{
                needPoisRefresh,
                setNeedPoisRefresh,
              }}
              isSharedMap
              setPopupActive={setRecapPopup}
              data={{
                pois: (markers as unknown) as MapTypes.IPoi[],
                poisLinks: mapShare.links,
                phaseTypes: mapShare.phaseTypes,
                poiTypes: mapShare.poiTypes,
                linkTypes: mapShare.linkTypes,
                category: mapShare.selection.category,
              }}
              containerId={mapId}
              center={mapShare.mapInfos.center}
              zoom={mapShare.mapInfos.zoom}
              layerUrl={mapShare.mapInfos.layer}
              minZoom={mapShare.mapInfos.zoom}
            />
            {renderFilters()}

            {isRecapOn && !_.isEmpty(recapPopup) ? (
              <RecapPopup
                showLegend={showLegend}
                clearPopup={() => setRecapPopup(undefined)}
                recapPopup={recapPopup}
                setFilters={setFilters}
                setMapShare={setMapShare}
                setRecapPopup={setRecapPopup}
              />
            ) : null}

            {showLegend && (
              <div className='absolute flex justify-between h-10 w-full bg-main-light mt-2 p-1 z-500'>
                <div className='flex items-center gap-2 flex-1'>
                  <img
                    src={`${process.env.PUBLIC_URL}/logo192.png`}
                    alt='logo'
                    height={35}
                    width={35}
                  />
                  <Typography variant='h4' className='capitalize-first'>
                    mia
                  </Typography>
                </div>
                <div className='flex items-center'>
                  <Typography
                    variant='h4'
                    className='font-extrabold capitalize-first'
                  >
                    {legend}
                  </Typography>
                </div>
                <div className='flex-1' />
              </div>
            )}
          </div>
        )}
      </div>
    </MapContext.Provider>
  );
};

export default SharedMap;
