// React libs
import React, { FC, useCallback, useState } from 'react';
import moment from 'moment';
import { Card, CardHeader, Modal, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
// Components
import BaseModale from '../Modales/BaseModale/BaseModale';
import ChartContent from './ChartContent/ChartContent';
import CloseIconButton from '../Button/CloseIconButton/CloseIconButton';
import FullScreenButton from '../Button/FullScreenButton/FullScreenButton';
import LocalLoader from '../Loader/LocalLoader/LocalLoader';
import RefreshButton from '../Button/RefreshButton/RefreshButton';
// Types
import * as Types from './ChartBuilder.type'

const ChartBuilder: FC<Types.IProps> = ({
  chart: Chart,
  hook,
  hookData,
  title
}) => {
  // Variables
  const { t } = useTranslation(['components', 'common']);

  // State
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
  const [dateStart, setDateStart] = useState<moment.Moment>(moment().subtract(1, 'years'))
  const [dateEnd, setDateEnd] = useState<moment.Moment>(moment())
  const [formattedDate, setFormattedDate] = useState<[string, string]>([dateStart.toISOString(), dateEnd.toISOString()])

  // Handlers
  const onDateStartChange = useCallback((date?: moment.Moment | null) => {
    if (!date) {
      return
    }

    setDateStart(date)
    if (date.isValid()) setFormattedDate(formattedDate => [date.toISOString(), formattedDate[1]])
  }, [])
  const onDateEndChange = useCallback((date?: moment.Moment | null) => {
    if (!date) {
      return
    }

    setDateEnd(date)
    if (date.isValid()) setFormattedDate(formattedDate => [formattedDate[0], date.toISOString()])
  }, [])

  // Derived data
  const data = hook.apply(undefined, hookData !== undefined ? [hookData] : formattedDate)
  const date = {
    dateStart,
    dateEnd,
    onDateStartChange,
    onDateEndChange,
    lastDataUpdate: moment(data.refreshTime)
  }

  // handlers
  const toggleModal = useCallback(() => setIsModalOpened(isModalOpened => !isModalOpened), [])

  return <Card data-testid='chart-builder' className='h-full w-full overflow-auto relative'>
    <CardHeader
      title={<Tooltip title={title}>
        <p className='truncate w-96'>{title}</p>
      </Tooltip>}
      className='h-1/10 w-full'
      titleTypographyProps={{
        variant: 'h6'
      }}
      action={
        <>
          <RefreshButton
            edge='end'
            size='small'
            disabled={data.isLoading}
            onClick={data.refresh}
            tooltip={t('components:chartBuilder.actions.refresh')}
          />
          <FullScreenButton
            edge='end'
            size='small'
            disabled={data.isLoading}
            onClick={toggleModal}
            tooltip={t('components:chartBuilder.actions.fullScreen')}
          />
        </>
      }
    />

    <div className='h-8.9/10 w-full'>
      {data.isLoading
        ? <div className='flex justify-center'>
          <LocalLoader
            message={t('common:loading.dataLoading')}
            color='secondary'
          />
        </div>
        : <ChartContent filter={data.filter} date={date} Chart={<Chart {...data.chart} size='medium' data={data.data} />} />
      }

      <Modal
        open={isModalOpened}
        onClose={toggleModal}
        aria-labelledby='chart-full-screen'
        aria-describedby='chart-full-screen'
      >
        <BaseModale
          content={<div className='w-full h-320'>
            <ChartContent filter={data.filter} date={date} Chart={<Chart {...data.chart} size='maxi' data={data.data} />} />
          </div>}
          title={<div className='flex justify-between'>
            <div>{title}</div>
            <div />
            <div className='float-right'>
              <CloseIconButton
                onClose={toggleModal}
              />
            </div>
          </div>}
          size='large'
        />
      </Modal>
    </div>

  </Card>
}

export default ChartBuilder