// Utils
import { createContext } from 'react';
// Types
import * as CoreTypes from '../Models/Core.type';

export interface IPoiContext {
  poi: CoreTypes.IPoi | undefined
}
const PoiContext = createContext<IPoiContext>({
  poi: undefined
});

export default PoiContext;
