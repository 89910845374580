// React libs
import { useState, useEffect, useCallback } from 'react';
// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Utils
import useServiceErrorHandler from '../../Utils/useServiceErrorHandler';

export interface IGetPersonsHook extends CoreTypes.IHook<CoreTypes.IPerson[]> {
  refresh: () => void
}

const usePersons = (filter?: any): IGetPersonsHook => {
  // Variables
  const handleServiceError = useServiceErrorHandler()

  // State
  const [data, setData] = useState<CoreTypes.IPerson[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [needRefresh, setNeedRefresh] = useState<boolean>(true);

  // Actions
  const refresh = useCallback(() => {
    setNeedRefresh(true)
  }, [])

  // Effects
  useEffect(() => {
    refresh()
  }, [refresh, filter])

  useEffect(() => {
    if (needRefresh && !isLoading) {
      setIsLoading(true)
      CommonService.getPersons(filter)
        .then((data: CoreTypes.IData<CoreTypes.IPerson>) => {
          setData(data.data)
        })
        .catch(handleServiceError)
        .finally(() => {
          setNeedRefresh(false)
          setIsLoading(false);
        })
    }
  }, [handleServiceError, isLoading, needRefresh, filter])

  return { data, isLoading, refresh }
}

export default usePersons