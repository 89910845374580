// React libs
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// Common
import CoreCommon from '../Resources/Common';

export default function useServiceErrorHandler() {
  // Variables
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();

  // Actions
  const handleServiceError = useCallback((e: any) => {
    console.error(e)
    enqueueSnackbar(
      e?.error?.message || t('common:errors.defaultMessage'),
      {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'error',
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return handleServiceError
}