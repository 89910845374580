// React libs
import React, { FC } from 'react';
import ReactECharts from 'echarts-for-react'
// Types
import * as Types from './BarChart.type'
import * as CoreTypes from '../../../../Data/Models/Core.type'
// Hooks
import useDatavizPalettes from '../../../../Data/Hooks/DatavizPalettes';
// Components
import LocalLoader from '../../Loader/LocalLoader/LocalLoader';

const OPTIONS_BY_SIZE: { [size in CoreTypes.datavizChartSizeType]: any } = {
  mini: {},
  medium: {
    globalStyle: {
      height: '25em',
      width: '50em'
    }
  },
  maxi: {
    globalStyle: {
      height: '45em',
      width: '110em'
    }
  }
}

interface IBarChartComponent extends Types.IProps {
  colorPalette: string[]
}

const BarChartComponent = ({ size = 'medium', data, colorPalette }: IBarChartComponent) => {
  // Variables
  const chartDynamicOption = OPTIONS_BY_SIZE[size]

  const option = {
    tooltip: {
      triggerOn: 'mousemove',
      formatter: data.formatter
    },
    toolbox: {
      feature: {
        saveAsImage: {},
        dataView: { show: true, readOnly: true }
      }
    },
    textStyle: {
      fontSize: 25
    },
    grid: {
      left: '3%',
      right: '3%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      show: false,
      type: 'value',
      max: 'dataMax'
    },
    yAxis: {
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: { fontSize: 20 },
      type: 'category',
      data: data.legends
    },
    series: [
      {
        type: 'bar',
        data: data.series.map((value, key) => ({
          value,
          itemStyle: {
            color: colorPalette[key] ?? colorPalette[key % colorPalette.length],
            borderRadius: 9
          }
        }))
      },
    ]
  }

  return <ReactECharts option={option} style={chartDynamicOption.globalStyle} />
}

const BarChart: FC<Types.IProps> = props => {
  // Variables
  const colorPalette = useDatavizPalettes('barChart').data
  const paletteNameByDataSize: any = {
    2: 'twoItems',
    3: 'threeItems',
    4: 'fourItems',
    5: 'fiveItems',
    6: 'sixItems',
    7: 'sevenItems'
  }
  return <div data-testid='BarChart' className='h-full w-full flex items-center justify-center'>
    {colorPalette === undefined
      ? <LocalLoader />
      : <BarChartComponent {...props} colorPalette={colorPalette[paletteNameByDataSize[props.data.series.length] ?? 'default'] ?? []} />
    }
  </div>
}

export default BarChart