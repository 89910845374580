const diacritics =
  'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
const diacriticsOut =
  'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

function removeDiacritics(str: string): string {
  const split = str.split('');
  split.forEach((letter, index) => {
    const i = diacritics.indexOf(letter);
    if (i !== -1) {
      split[index] = diacriticsOut[i];
    }
  });
  return split.join('').toLowerCase();
}

export default removeDiacritics;
