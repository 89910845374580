// React libs
import React, { FC } from 'react';
// Type
import * as Types from './Separator.type';

const Separator: FC<Types.IProps> = ({ className, type }) => {
  const getClasses = () => {
    switch (type) {
      case 'horizontal':
        return 'my-1 h-1px w-full';
      case 'vertical':
        return ' mx-1 w-1px';
    }
  };
  return (
    <div
      className={`bg-gray-400 ${getClasses()} ${className}`}
      data-testid='separator'
    />
  );
};

export default Separator;
