// Utils
import { createContext } from 'react';

export interface IMapLegendContext {
  needRefreshLegendPosition: boolean
  hiddenLegendMapId: string | undefined;
  setNeedRefreshLegendPosition: (needRefreshLegendPosition: boolean) => void
  updateHiddenLegendMapId: (id: string | undefined) => void;
}
const MapLegendContext = createContext<IMapLegendContext>({
  needRefreshLegendPosition: false,
  hiddenLegendMapId: undefined,
  setNeedRefreshLegendPosition: (needRefreshLegendPosition: boolean) => { },
  updateHiddenLegendMapId: (id: string | undefined) => { },
});

export default MapLegendContext;
