// React libs
import React, { FC, useMemo, useCallback } from 'react';
import { keyBy } from 'lodash'
// Components
import Autocomplete from '../../../../UiKit/Form/Autocomplete/Autocomplete';
// Types
import * as Types from './SelectPosition.type'
// Hooks
import usePositions from '../../../../../Data/Hooks/Positions';

const SelectPosition: FC<Types.IProps> = props => {
  // Variables
  const positions = usePositions()
  const positionsById = useMemo(() => keyBy(positions.data, 'id'), [positions.data])
  const options = useMemo(() => positions.data != null ? positions.data.map(position => ({
    label: position.name,
    value: position.id
  })) : [], [positions.data])

  // Handlers
  const onChange = useCallback((event, newValue) => {
    if (props.value !== newValue.trim()) {
      props.onChange(newValue.trim() === ''
        ? {
          ...positionsById[props.value],
          toDelete: true
        }
        : {
          ...positionsById[newValue],
          toUpdate: true
        })
    }
  }, [positionsById, props])

  return <div data-testid='SelectPosition'>
    <Autocomplete
      options={options}
      color='secondary'
      field={{ name: 'position', value: props.value }}
      form={{
        errors: [],
        touched: [],
        setFieldValue: onChange,
        setFieldTouched() { }
      }}
    />
  </div>
}

export default SelectPosition