// React libs
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// Components
import ResourceIdentityEdition from './ResourceIdentityEdition/ResourceIdentityEdition'
import ResourceIdentityPreview from './ResourceIdentityPreview/ResourceIdentityPreview';
// Types
import * as Types from './ResourceIdentity.type'
// Common
import Common from '../../../../App/Resources/Common'

const ResourceIdentity: FC<Types.IProps> = ({ poi, isEditionMode, refreshPoi }) => {
  // Variables
  const history = useHistory()

  // Handlers
  const toggleEditionMode = useCallback(() => {
    history.push(
      `/${Common.Routes.routeResource}/${poi.id}/${Common.Routes[isEditionMode ? 'routePreviewResource' : 'routeUpdateResource']}`
    )
  }, [history, isEditionMode, poi.id])

  return <div data-testid='resource-identity' className='h-full w-full'>
    {isEditionMode
      ? <ResourceIdentityEdition poi={poi} toggleEditionMode={toggleEditionMode} refreshPoi={refreshPoi} />
      : <ResourceIdentityPreview poi={poi} toggleEditionMode={toggleEditionMode} />
    }
  </div>
}

export default ResourceIdentity