import Nightingale from '../../../../Core/Components/UiKit/Charts/Nightingale/Nightingale';
import BarChart from '../../../../Core/Components/UiKit/Charts/BarChart/BarChart';
import LineChart from '../../../../Core/Components/UiKit/Charts/LineChart/LineChart';
import Timeline from '../../../../Core/Components/UiKit/Charts/Timeline/Timeline';

export default {
  Nightingale,
  BarChart,
  LineChart,
  Timeline
}