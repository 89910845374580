// React libs
import { useEffect, useState } from 'react';
// Services
import CommonService from '../Services/CommonService';
// Utils
import useServiceErrorHandler from '../../Utils/useServiceErrorHandler'

export interface IGetDatavizCompositionHook {
  data: any
  isLoading: boolean
}

const useDatavizComposition = (type: 'project' | 'dashboard'): IGetDatavizCompositionHook => {
  // Variables
  const handleServiceError = useServiceErrorHandler()

  // State
  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false)
  const [needRefresh, setNeedRefresh] = useState<boolean>(true)

  // Effect
  useEffect(() => {
    if (needRefresh && !isLoading) {
      setIsLoading(true)

      const promise = type === 'project'
        ? CommonService.getProjectDatavizComposition()
        : CommonService.getDashboardDatavizComposition()

      promise
        .then((data: any) => {
          setData(data)
        })
        .catch(handleServiceError)
        .finally(() => {
          setNeedRefresh(false)
          setIsLoading(false)
        })
    }
  }, [handleServiceError, isLoading, needRefresh, type])

  return {
    data,
    isLoading
  }
}

export default useDatavizComposition