// React libs
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import FaIcon from '../../Icon/FaIcon/FaIcon';
import Button from '../../Button/Button';
// Types
import * as Types from './SaveButton.type'

const SaveButton: FC<Types.IProps> = ({ isSubmitting }) => {
  // Variables
  const { t } = useTranslation(['common']);

  return <span data-testid='save-button'>
    <Button
      className='hover:bg-active-hover'
      type='submit'
      color='secondary'
      size='small'
      disabled={isSubmitting}
      startIcon={
        isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : <FaIcon name='plus-circle' className='text-lg' />
      }
    >
      {t('common:buttons.save')}
    </Button>
  </span>
}

export default SaveButton