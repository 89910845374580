// React libs
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Services
import MapService from '../Services/MapService';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
import * as MapTypes from '../Models/Map.type';
// Common
import CoreCommon from '../../../../Core/Resources/Common';

export interface IGetPhaseTypesHook extends CoreTypes.IHook<MapTypes.IPhaseType[] | undefined> { }

const usePhaseTypes = (): IGetPhaseTypesHook => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);

  // State
  const [data, setData] = useState<MapTypes.IPhaseType[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    setIsLoading(true)
    MapService.getPhaseTypes()
      .then((data: MapTypes.IData<MapTypes.IPhaseType>) => {
        setData(data.data)
        setIsLoading(false)
      })
      .catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
        setIsLoading(false);
      });
  }, [enqueueSnackbar, t])

  return { data, isLoading }
}

export default usePhaseTypes