// React libs
import { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Services
import AuthService from '../../../../App/Data/Services/AuthService';
import LocalStorage from '../../../../Core/Data/Services/Storage/LocalStorage';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
import * as MapTypes from '../Models/Map.type';
// Common
import CoreCommon from '../../../../Core/Resources/Common';

export interface IGetUserPref extends CoreTypes.IHook<MapTypes.IPref> {
  refresh: () => void
}

const useUserPref = (): IGetUserPref => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);
  const userId = LocalStorage.get(LocalStorage.keys.userId)

  // State
  const [data, setData] = useState<MapTypes.IPref | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [needRefresh, setNeedRefresh] = useState<boolean>(true);

  // Actions
  const refresh = useCallback(() => {
    setNeedRefresh(true)
  }, [])

  // Effects
  useEffect(() => {
    if (needRefresh && !isLoading) {
      setIsLoading(true)
      AuthService.getCurrentUser(userId)
        .then((data: any) => {
          setData(data.data.account.pref)
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
        }).finally(() => {
          setNeedRefresh(false)
          setIsLoading(false);
        })
    }
  }, [enqueueSnackbar, isLoading, needRefresh, t, userId])

  return { data, isLoading, refresh }
}

export default useUserPref