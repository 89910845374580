// React libs
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useContext,
  useCallback,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { pdf, Image, Text, View } from '@react-pdf/renderer';
import keyBy from 'lodash/keyBy';
import * as Yup from 'yup';
// Components
import CloseIconButton from '../../../../Core/Components/UiKit/Button/CloseIconButton/CloseIconButton';
import CreateLinkForm from '../../../../Core/Components/Form/CreateLink/CreateLinkForm';
import SwitchProjectConfirmModal from '../Modales/SwitchProject/SwitchProjectConfirmModal';
import FaIcon from '../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import PdfDocument from '../../../../Core/Components/UiKit/PdfDocument/PdfDocument';
import PageLoader from '../../../../Core/Components/UiKit/Loader/PageLoader/PageLoader';
import Typography from '../../../../Core/Components/UiKit/Typography/Typography';
import ConfirmModale from '../../../../Core/Components/UiKit/Modales/ConfirmModale/ConfirmModale';
import MapLegend from './MapLegend/MapLegend';
import MapLayer from './MapLayer/MapLayer';
// Context
import MapConfigContext from '../../Data/Contexts/MapConfigContext';
import PoisContext from '../../Data/Contexts/PoisContext';
import PoiFormPanelContext, {
  IPoiFormPanelContext,
} from '../../Data/Contexts/PoiFormPanelContext';
import PhaseTypeContext from '../../Data/Contexts/PhaseTypeContext';
import PoiTypeContext from '../../Data/Contexts/PoiTypesContext';
import LinkTypeContext from '../../Data/Contexts/LinkTypesContext';
import PoisLinksContext from '../../Data/Contexts/PoisLinksContext';
import UserContext from '../../../../Core/Data/Contexts/UserContext';
import MapLegendContext, {
  IMapLegendContext,
} from '../../Data/Contexts/MapLegendContext';
import MapFiltersContext from '../../Data/Contexts/MapFiltersContext';
import MapSidebarProjectContext, {
  IMapSidebarProjectContext,
  ISidebarProjectData,
} from '../../Data/Contexts/MapSidebarProjectContext';
// Services
import MapService from '../../Data/Services/MapService';
// Type
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
import * as Types from './MapCanvas.type';
import * as MapTypes from '../../Data/Models/Map.type';
// Utils
import useConfirmModal from '../../../../Core/Utils/useConfirmModal';
import { deletePoi } from '../../../../Core/Utils/ServiceHelpers';
import {
  handleMarkerForPoiCreation,
  translateStringCenter,
  creationMarkerId,
  formatMapFilters,
} from '../../Utils/Map';
import { isAdmin, isWriter } from '../../../../Core/Utils/User';
// Common
import Common from '../../../../App/Resources/Common';
import CoreCommon from '../../../../Core/Resources/Common';
// Css
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
// Misc
require('leaflet.markercluster/dist/leaflet.markercluster.js');

const MapCanvas = forwardRef(
  (
    {
      currentCenter,
      currentZoom,
      layer,
      onHomeClick,
      title,
      toggleMapSideBar,
    }: Types.IProps,
    ref
  ) => {
    // Variables
    const { t } = useTranslation(['map']);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const mapContainerId = 'map-canvas-container';
    const switchProjectModal = useConfirmModal();

    // Contexts
    const { mapConfig } = useContext(MapConfigContext);
    const {
      pois,
      updatePois,
      needPoisRefresh,
      setNeedPoisRefresh,
    } = useContext(PoisContext);
    const { phaseTypes } = useContext(PhaseTypeContext);
    const { poiTypes } = useContext(PoiTypeContext);
    const { linkTypes } = useContext(LinkTypeContext);
    const { poisLinks } = useContext(PoisLinksContext);
    const { user } = useContext(UserContext);
    const { mapFilters, updateMapFilters } = useContext(MapFiltersContext);
    const { hiddenLegendMapId }: IMapLegendContext = useContext(
      MapLegendContext
    );
    const {
      activePoiPanelType,
      setActivePoiPanelType,
      setPoiToEdit,
    }: IPoiFormPanelContext = useContext(PoiFormPanelContext);
    const sidebarProjectContext: IMapSidebarProjectContext = useContext(
      MapSidebarProjectContext
    );

    // Derived variables
    const mapFiltersCategory = useMemo(
      () => formatMapFilters(mapFilters).category,
      [mapFilters]
    );

    // State
    const [center, setCenter]: [
      [number, number] | undefined,
      Function
    ] = useState();
    const [map, setMap]: [any, Function] = useState<any>(undefined);
    const [zoom, setZoom] = useState<number | undefined>();
    const [layerUrl, setLayerUrl] = useState<string | undefined>();
    const [legendData, setLegendData]: [{ data: any[] }, Function] = useState<{
      data: any[];
    }>({ data: [] });
    const [isExporting, setIsExporting]: [
      'png' | 'pdf' | '',
      Function
    ] = useState('');

    const [isHidenWhenExport, setIsHidenWhenExport]: [
      boolean,
      Function
    ] = useState(false);
    const [markerDeleting, setMarkerDeleting]: [
      MapTypes.IPoi | undefined,
      Function
    ] = useState<MapTypes.IPoi | undefined>(undefined);

    // Derived data
    const legendDataByType: {
      [key: string]: MapTypes.ILayerMapImage;
    } = React.useMemo(() => keyBy(legendData.data, 'legendType'), [legendData]);

    // Handlers
    const onMapCreated = (map: any) => {
      map.on('click', mapClickedCallback);
      setMap(map);
    };
    const markerDoubleClickBinding = (p: MapTypes.IPoi) => {
      if (p.isProject) {
        history.push(
          `/${Common.Routes.routeProject}/${p.id}/${Common.Routes.routePreviewProject}`
        );
      } else {
        history.push(
          `/${Common.Routes.routeResource}/${p.id}/${Common.Routes.routePreviewResource}`
        );
      }
    };
    const onMarkerClick = useCallback(
      (p: MapTypes.IPoi, marker: any) => {
        const isLinkCreationMode =
          sidebarProjectContext.data.project !== undefined &&
          !sidebarProjectContext.data.ro;
        let { projectLinks = [] } = sidebarProjectContext.data;
        const linkPredicate = (poiLink: any) =>
          poiLink.fkpoiTo === p.id &&
          poiLink.fkpoiFrom === sidebarProjectContext.data.project?.id;
        let linkToCurrentProject: any = [...poisLinks, ...projectLinks].find(
          linkPredicate
        );
        const linkTypesOptions = linkTypes.map(linkType => ({
          label: linkType.label,
          value: linkType.id,
        }));

        if (!p.isProject && isLinkCreationMode) {
          const updateSidebarData = (data: ISidebarProjectData) => {
            sidebarProjectContext.setData(data);

            // the current marker is overridden on each MAP refresh
            map.eachLayer((layer: any) => {
              if (layer.Kid === marker.Kid) {
                marker = layer;
              }
            });
          };

          if (linkToCurrentProject !== undefined) {
            updateSidebarData({
              ...sidebarProjectContext.data,
              activeLink: linkToCurrentProject,
            });
            if (!linkToCurrentProject.isCreationPending) {
              marker.openPopup();
              return;
            }
          }

          if (linkToCurrentProject === undefined) {
            linkToCurrentProject = {
              comment: '',
              fkpoiTo: p.id,
              fkpoiFrom: sidebarProjectContext.data.project?.id ?? '',
              fklinkType: linkTypesOptions[0]?.value,
              createdAt: '',
              createdBy: '',
              lastModifyAt: '',
              lastModifyBy: '',
              toCreate: true,
              isCreationPending: true,
            };
            projectLinks = [...projectLinks, linkToCurrentProject];
            updateSidebarData({
              ...sidebarProjectContext.data,
              activeLink: linkToCurrentProject,
              projectLinks,
            });
          }

          const defaultValues = {
            linkType: linkTypesOptions[0]?.value ?? '',
            comment: '',
          };
          const validationSchema = Yup.object().shape({
            linkType: Yup.string().required(
              t('map:sidebar.project.errors.linkTypeRequired')
            ),
            comment: Yup.string(),
          });

          const div = document.createElement('div');

          const onSubmit = (value: any, actions: any) => {
            const newProjectLinks = [...projectLinks];
            linkToCurrentProject = {
              ...linkToCurrentProject,
              comment: value.comment,
              fklinkType: value.linkType,
              isCreationPending: undefined,
            };
            newProjectLinks.splice(
              newProjectLinks.findIndex(linkPredicate),
              1,
              linkToCurrentProject
            );
            updateSidebarData({
              ...sidebarProjectContext.data,
              activeLink: linkToCurrentProject,
              projectLinks: newProjectLinks,
            });
            actions.setSubmitting(false);
          };

          ReactDOM.render(
            <div>
              <div className='flex w-full justify-end'>
                <CloseIconButton
                  onClose={() => marker.closePopup()}
                  hideTooltip={true}
                />
              </div>
              <CreateLinkForm
                defaultValues={defaultValues}
                onFormSubmit={onSubmit}
                miscData={{ linkTypes: linkTypesOptions }}
                validationSchema={validationSchema}
              />
            </div>,
            div
          );
          marker.bindPopup(div, {
            closeButton: false,
            className: 'resource-menu min-w-60',
            offset: [
              0,
              -(marker.options.icon?.options.iconSize as any)?.y || 0,
            ],
          });
          marker.openPopup();
        }
      },
      [linkTypes, poisLinks, sidebarProjectContext, t, map]
    );
    const moreOptionsMenuId = 'more-options-menu';
    const getMarkerPopupContent = (p: MapTypes.IPoi) => {
      interface IMenuItem {
        id: string;
        action: (...args: any) => void;
        icon: string;
        tooltip: string;
      }

      const menuConf: IMenuItem[] = [];
      const moreOptionsMenuconf: IMenuItem[] = [];

      menuConf.push({
        id: 'info',
        action: (p: MapTypes.IPoi) => {
          if (p.isProject) {
            history.push(
              `/${Common.Routes.routeProject}/${p.id}/${Common.Routes.routePreviewProject}`
            );
          } else {
            history.push(
              `/${Common.Routes.routeResource}/${p.id}/${Common.Routes.routePreviewResource}`
            );
          }
        },
        icon: 'info',
        tooltip: t('map:marker.popup.info.tooltip'),
      });

      if (p.isProject) {
        const getAction = (
          project: MapTypes.IPoi,
          isEcosystemEnabled: boolean
        ) => {
          toggleMapSideBar(false);

          const currentProject = sidebarProjectContext.data.project;
          const updateSidebarProjectData = () => {
            sidebarProjectContext.setData({
              project,
              projectLinks: poisLinks.filter(
                poiLink => poiLink.fkpoiFrom === project.id
              ),
              isEcosystemEnabled,
              ro: isEcosystemEnabled,
            });
          };

          if (currentProject === undefined) {
            return updateSidebarProjectData();
          }

          if (currentProject.id === project.id) {
            return sidebarProjectContext.setData({
              ...sidebarProjectContext.data,
              needSidebarClose: true,
            });
          }

          switchProjectModal
            .openModal()
            .then(updateSidebarProjectData, () => {});
        };

        if (
          user &&
          (isAdmin(user) || (isWriter(user) && p.createdBy === user.login))
        ) {
          menuConf.push({
            id: 'link',
            action: (project: MapTypes.IPoi) => getAction(project, false),
            icon: 'link',
            tooltip: t('map:marker.popup.link.tooltip'),
          });
        }

        menuConf.push({
          id: 'ecosystem',
          action: (project: MapTypes.IPoi) => getAction(project, true),
          icon: 'sitemap',
          tooltip: t('map:marker.popup.ecosystem.tooltip'),
        });
      }

      if (
        user &&
        (isAdmin(user) || (isWriter(user) && p.createdBy === user.login))
      ) {
        moreOptionsMenuconf.push({
          id: 'move',
          action: (p: MapTypes.IPoi) => {
            setPoiToEdit(p);
            setActivePoiPanelType(p.isProject ? 'project' : 'resource');
          },
          icon: 'map-marker',
          tooltip: t('map:marker.popup.other.move.label'),
        });
        moreOptionsMenuconf.push({
          id: 'delete',
          action: (p: MapTypes.IPoi) => {
            setMarkerDeleting(p);
          },
          icon: 'trash',
          tooltip: t('map:marker.popup.other.delete.label'),
        });
      }

      const menuClasses =
        'bg-red-610 cursor-pointer flex h-12 items-center justify-center mx-1 rounded-full w-12';
      const container = document.createElement('div');
      container.setAttribute('class', 'flex flex-col items-center');
      const title = ReactDOMServer.renderToStaticMarkup(
        <Typography variant='subtitle1' className='text-center'>
          {p.name}
        </Typography>
      );
      container.innerHTML = title;
      const menu = document.createElement('div');
      menu.setAttribute(
        'class',
        'flex items-center justify-center mt-2 relative'
      );
      menuConf.forEach(c => {
        const element = document.createElement('div');
        element.setAttribute('class', menuClasses);
        element.addEventListener('click', () => c.action(p));
        const icon = ReactDOMServer.renderToStaticMarkup(
          <FaIcon name={c.icon} className='text-white text-xl' />
        );
        element.innerHTML = icon;
        const tooltip = document.createElement('div');
        tooltip.innerHTML = c.tooltip;
        tooltip.setAttribute(
          'class',
          'raw-tooltip absolute bg-selection-inverse flex hidden items-center justify-center top-13 px-4 py-2 rounded text-selection z-50'
        );
        element.addEventListener('mouseover', () => {
          tooltip.classList.remove('hidden');
        });

        element.addEventListener('mouseout', () => {
          tooltip.classList.add('hidden');
        });
        element.append(tooltip);
        menu.append(element);
      });

      if (moreOptionsMenuconf.length > 0) {
        const moreOptionsBtn = document.createElement('div');
        moreOptionsBtn.setAttribute('class', `moreOptionsBtn ${menuClasses}`);
        moreOptionsBtn.addEventListener('click', event => {
          const e = document.getElementById(moreOptionsMenuId);
          if (!e?.classList.contains('hidden')) {
            e?.classList.add('hidden');
          } else {
            e?.classList.remove('hidden');
          }
          event.stopPropagation();
        });
        const icon = ReactDOMServer.renderToStaticMarkup(
          <FaIcon name='ellipsis-v' className='text-white text-xl' />
        );
        moreOptionsBtn.innerHTML = icon;
        menu.append(moreOptionsBtn);

        const moreOptionsMenu = document.createElement('div');
        moreOptionsMenu.setAttribute('id', moreOptionsMenuId);
        moreOptionsMenu.setAttribute(
          'class',
          'absolute bg-selection-inverse hidden min-w-40 right-0 rounded shadow top-13 z-50'
        );
        moreOptionsMenuconf.forEach((m: IMenuItem) => {
          const element = document.createElement('div');
          element.setAttribute(
            'class',
            'cursor-pointer flex items-center px-2 py-1 hover:bg-active-hover'
          );
          element.addEventListener('click', () => m.action(p));
          const icon = document.createElement('div');
          icon.innerHTML = ReactDOMServer.renderToStaticMarkup(
            <FaIcon name={m.icon} className='mr-1 text-selection text-xl w-4' />
          );
          element.append(icon);
          const label = document.createElement('div');
          label.setAttribute('class', 'text-selection text-sm');
          label.innerHTML = m.tooltip;
          element.append(label);
          moreOptionsMenu.append(element);
        });
        menu.append(moreOptionsMenu);
      }

      container.append(menu);
      return container;
    };
    // close more options on window click
    useEffect(() => {
      const listener = (event: any) => {
        const needHide = !event.target.matches('.moreOptionsBtn');
        if (needHide) {
          const e = document.getElementById(moreOptionsMenuId);
          if (!e?.classList.contains('hidden')) {
            e?.classList.add('hidden');
          }
        }
      };
      window.addEventListener('click', listener);
      return () => {
        window.removeEventListener('click', listener);
      };
    });
    const handlerMarkerDeletingConfirmClose = (
      id: string | undefined,
      result: boolean
    ) => {
      setMarkerDeleting(undefined);
      if (result && id) {
        deleteMarker(id);
      }
    };
    const deleteMarker = async (id: string) => {
      const poiToDelete = pois.find((p: MapTypes.IPoi) => p.id === id);
      if (poiToDelete) {
        try {
          const poiDetailsData: {
            data: CoreTypes.IPoi;
          } = await MapService.getPoiDetails(poiToDelete.id);

          await deletePoi(poiDetailsData.data, poisLinks);

          enqueueSnackbar(
            t(
              `map:marker.popup.other.delete.${
                poiToDelete.isProject ? 'projectSuccess' : 'resourceSuccess'
              }`
            ),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'success',
            }
          );
          updateMapFilters({ ...mapFilters, needMapRefresh: true });
        } catch (e) {
          enqueueSnackbar(
            (e as { error?: { message?: string } })?.error?.message ??
              t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
        }
      }
    };

    // Callbacks
    const mapClickedCallback = useCallback(
      (event: any) => {
        if (activePoiPanelType !== undefined) {
          const position: [number, number] = [
            event.latlng.lat,
            event.latlng.lng,
          ];
          updatePois(
            handleMarkerForPoiCreation(
              [...pois],
              position,
              activePoiPanelType === 'project'
            )
          );
          setNeedPoisRefresh(true);
        }
      },
      [activePoiPanelType, pois, setNeedPoisRefresh, updatePois]
    );
    const homeClickedCallback = useCallback(() => {
      if (map) {
        map.setView(
          translateStringCenter(mapConfig?.mapConfigCenter || ''),
          mapConfig?.mapConfigZoom
        );
        onHomeClick();
      }
    }, [map, mapConfig, onHomeClick]);

    // Effects
    useImperativeHandle(ref, () => ({
      export(output: 'pdf' | 'png') {
        if (output === 'png') {
          exportAsPng();
        } else if (output === 'pdf') {
          exportAsPdf();
        }
      },
    }));
    useEffect(() => {
      // --> Layer
      let newLayerUrl;
      let newLegendData = {};
      if (layer) {
        newLayerUrl = layer?.url;
        newLegendData = layer?.images ? { data: layer.images } : {};
      } else {
        newLayerUrl = mapConfig?.layerMap?.url;
        newLegendData = mapConfig?.layerMap?.images
          ? { data: mapConfig?.layerMap?.images }
          : {};
      }
      setLayerUrl(newLayerUrl);
      setLegendData(newLegendData);
    }, [layer, mapConfig, setLayerUrl, setLegendData]);

    useEffect(() => {
      // --> Center
      let newCenter;
      let centerConfig = mapConfig?.mapConfigCenter;
      if (currentCenter) {
        newCenter = currentCenter;
      } else if (centerConfig && centerConfig !== '') {
        centerConfig = centerConfig.replace('[', '').replace(']', '');
        const centerArray = centerConfig
          .split(',')
          .map((v: string) => parseFloat(v));
        newCenter =
          centerArray && centerArray.length > 1
            ? [centerArray[0], centerArray[1]]
            : undefined;
      }
      setCenter(newCenter);

      // --> Zoom
      let newZoom;
      let zoomConfig = mapConfig?.mapConfigZoom;
      if (currentZoom !== undefined) {
        newZoom = currentZoom;
      } else if (zoomConfig !== undefined) {
        newZoom = zoomConfig;
      }
      setZoom(newZoom);
    }, [currentCenter, currentZoom, map, mapConfig]);
    useEffect(() => {
      if (activePoiPanelType !== undefined) {
        if (map) {
          map.off('click');
          map.on('click', (event: any) => mapClickedCallback(event));
        }
      } else {
        if (map) {
          map.off('click');
        }
      }
    }, [activePoiPanelType, map, mapClickedCallback]);
    // Misc
    const exportAsPng = () => {
      const container = document.getElementById(mapContainerId);
      if (container) {
        setIsExporting('png');
        domtoimage
          .toBlob(container, {
            height: container.offsetHeight,
            width: container.offsetWidth,
          })
          .then(blob => {
            window.saveAs(blob, `export_${moment().format('YYYYMMDDHHmmss')}`);
            setIsExporting('');
          })
          .catch(() => setIsExporting(''));
      }
    };
    const exportAsPdf = async () => {
      const container = document.getElementById(mapContainerId);
      if (container) {
        setIsHidenWhenExport(true);
        setIsExporting('pdf');
        try {
          const pages = [];
          const scale = window.devicePixelRatio;
          const containerUrl = await domtoimage.toPng(container, {
            height: container.offsetHeight * scale,
            width: container.offsetWidth * scale,
            style: {
              transform: `scale(${scale})`,
              transformOrigin: 'top left',
              height: `${container.offsetHeight}px`,
              width: `${container.offsetWidth}px`,
            },
          });
          pages.push({
            content: <Image src={containerUrl} />,
            title: `${t(
              'map:header.export.carte.title'
            )} - ${title}`.toLocaleUpperCase(),
          });

          if (
            (hiddenLegendMapId === undefined ||
              layer?.id !== hiddenLegendMapId) &&
            legendData.data?.length > 0
          ) {
            pages.push({
              content: ['analysis', 'method', 'knowMore']
                .map((type: string, key: number) => {
                  const legend = legendDataByType[type];
                  return (
                    legend && (
                      <View
                        key={key}
                        style={CoreCommon.PdfStyles.centerColumnItems}
                      >
                        <Text style={{ marginBottom: '2vh' }}>
                          {legend.legend}
                        </Text>
                        <Image src={`/api/images/${legend.image.id}/media`} />
                      </View>
                    )
                  );
                })
                .filter(_ => _ !== undefined),
              title: `${t(
                'map:header.export.legend.title'
              )} - ${title}`.toLocaleUpperCase(),
            });
          }

          const doc = (
            <PdfDocument
              pages={pages}
              footer={
                <Text style={{ fontSize: 10 }}>
                  {t('map:header.export.carte.source', {
                    source: window.location.hostname,
                  })}
                </Text>
              }
            />
          );

          const asPdf = pdf([] as any);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          saveAs(blob, `export_${moment().format('YYYYMMDDHHmmss')}.pdf`);
        } catch (error) {
          console.error(error);
        } finally {
          setIsExporting('');
          setIsHidenWhenExport(false);
        }
      }
    };

    useEffect(() => {
      setNeedPoisRefresh(true);
    }, [setNeedPoisRefresh, sidebarProjectContext]);

    /*
     QGA - 04/01/2021
     To have a zoom more precise, add these options :
          zoomSnap={0}
          zoomDelta={0.25}
      BUT the lib to generate png does not like zoom that are not integer...
      -> I tried to set the zoom to integer with Math.floor,
      but I got strange behaviours (centered in an Africa, or in the ocean)
      --> checked lat/lng inversion, zoom round (that are good) and add a timeout,
      but no one worked…
    */

    return (
      <div
        className='flex-1 h-full relative'
        data-testid='map-canvas'
        id='map-canvas'
      >
        {isExporting && (
          <PageLoader
            message={t(`map:loading.export.${isExporting as string}`)}
          />
        )}
        <MapLayer
          onHomeClick={homeClickedCallback}
          containerId={mapContainerId}
          center={center}
          zoom={zoom}
          minZoom={mapConfig?.mapConfigMinZoom}
          maxZoom={mapConfig?.mapConfigMaxZoom}
          maxBounds={
            mapConfig?.mapConfigBoundaries &&
            JSON.parse(
              mapConfig.mapConfigBoundaries
            ).filter((o: any, index: number) => [0, 2].includes(index))
          }
          data={{
            pois,
            poisLinks,
            phaseTypes,
            poiTypes,
            linkTypes,
            category: mapFiltersCategory,
          }}
          poisRefresh={{
            needPoisRefresh,
            setNeedPoisRefresh,
          }}
          onMarkerCreation={(marker, p) => {
            marker.on('dblclick', () => markerDoubleClickBinding(p));
            marker.on('click', () => onMarkerClick(p, marker));
            marker.bindPopup(getMarkerPopupContent(p), {
              closeButton: false,
              className: 'marker-menu',
              offset: [
                0,
                -(marker.options.icon?.options.iconSize as any)?.y || 0,
              ],
            });
          }}
          hideZoom={isHidenWhenExport}
          onMapCreated={onMapCreated}
          layerUrl={layerUrl}
        />
        <ConfirmModale
          id={markerDeleting?.id}
          message={t(
            `map:marker.popup.other.delete.confirm${
              markerDeleting?.isProject ? 'Project' : 'Resource'
            }`,
            {
              name: markerDeleting?.name,
            }
          )}
          isOpened={typeof markerDeleting !== 'undefined'}
          handleClose={handlerMarkerDeletingConfirmClose}
        />
        <SwitchProjectConfirmModal
          handleClose={switchProjectModal.onClose}
          isOpened={switchProjectModal.isOpened}
        />
        {legendData.data?.length > 0 && (
          <MapLegend
            isHidenWhenExport={isHidenWhenExport}
            layer={layer}
            legendDataByType={legendDataByType}
          />
        )}
      </div>
    );
  }
);

MapCanvas.propTypes = {
  currentCenter: PropTypes.any,
  currentZoom: PropTypes.number,
  layer: PropTypes.any,
  onHomeClick: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default MapCanvas;
