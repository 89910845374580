// React libs
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Services
import MapService from '../Services/MapService';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
import * as MapTypes from '../Models/Map.type';
// Common
import CoreCommon from '../../../../Core/Resources/Common';

export interface IGetCountriesHook
  extends CoreTypes.IHook<MapTypes.ICountry[]> {}

const useCountries = (): IGetCountriesHook => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);

  // State
  const [data, setData]: [MapTypes.ICountry[], Function] = useState([]);
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);

  // Effects
  useEffect(() => {
    const getCountries = () => {
      // Map config
      setIsLoading(true);
      MapService.getCountries()
        .then((data: MapTypes.ICountry[]) => {
          setData(data);
          setIsLoading(false);
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
          setIsLoading(false);
        });
    };
    getCountries();
  }, [enqueueSnackbar, t]);

  return { data, isLoading };
};

export default useCountries;
