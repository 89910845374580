// React libs
import moment from 'moment';
import { cloneDeep, sortBy, get } from 'lodash';

export function isLogEnabled() {
  return process.env.NODE_ENV === 'development';
}

export const urlToFile = async (
  url: string,
  filename: string,
  mimeType: string
) => {
  const result = await fetch(url);
  const blob = await result.blob();
  return new File([blob], filename, { type: mimeType });
};

export const momentToISOString = (date: moment.Moment) => date.millisecond(0).second(0).minute(0).hour(0).toISOString()

export const copyToClipboard = async (text: string) =>
  'clipboard' in navigator
    ? navigator.clipboard.writeText(text)
    : document.execCommand('copy', true, text)


export const formatProjectAddress = (address: any) => address != null
  ? [
    address.line1,
    address.line2,
    address.city,
    address.zip,
    address.country,
  ].filter(item => item != null).join(', ')
  : ''

export const getPoiImageUrl = (imageId: string) => `/api/images/${imageId}/media`

export const ifDef = (value: any, definedFn: Function, undefinedFn?: Function) => value != null
  ? definedFn(value)
  : undefinedFn != null ? undefinedFn(value) : value

export const removeArrayValue = (array: any[], predicate: any) => {
  array = cloneDeep(array)
  const index = typeof predicate === 'function' ? array.findIndex(predicate) : array.indexOf(predicate)
  index !== -1 && array.splice(index, 1)
  return array
}

export const sortArray = (array: any[], path?: string) => sortBy(array, element => {
  if (path !== undefined) {
    element = get(element, path)
  }

  if (typeof element !== 'string') {
    return element
  }

  return element.toLowerCase()
    .replace(/é|ê|è/g, 'e')
    .replace('à', 'a')
    .replace('ù', 'u')
})