// React libs
import { useEffect, useMemo } from 'react';
import { round } from 'lodash';
// Services
import DatavizService from '../Services/DatavizService';
// Hooks
import usePoiFilters from './PoiFilters'
import useGenericData from '../../../../Core/Data/Hooks/GenericData';
// Utils
import { ifDef } from '../../../../Core/Utils/Misc';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'

const useProjectTasksMetrics = (dateStart: string, dateEnd: string): CoreTypes.IDatavizHook => {
  // Variables
  const { filters, ...filtersProps } = usePoiFilters('project')
  const { data: metrics, ...props }: any = useGenericData(() => DatavizService.getProjectTasksMetrics(dateStart, dateEnd, filters).then(data => ({
    data
  })))
  const stringFilters = useMemo(() => ifDef(filters, JSON.stringify), [filters])

  const data: any = useMemo(() => {
    let total = 0
    let totalDone = 0
    if (!(metrics?.tasks?.rows.length > 0)) {
      return []
    }

    metrics.tasks.rows.forEach(({ children }: any) =>
      children?.forEach(({ status, children }: any) => {
        ifDef(children, () => {
          total += children.length

          if (status === 'done') {
            totalDone += children.length
          }
        })
      }))

    const percentDone = round(totalDone * 100 / total, 1)

    return [percentDone, 100 - percentDone]
  }, [metrics])

  useEffect(() => {
    props.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, stringFilters])

  return {
    ...props,
    data,
    filter: filtersProps,
    chart: {
      title: data[0] && `${data[0]} %`
    }
  }
}

export default useProjectTasksMetrics