// Utils
import { createContext } from 'react';
// Types
import * as MapTypes from '../Models/Map.type';

export interface IPoiTypeContext {
  poiTypes: MapTypes.IPoiType[];
  updatePoiTypes: (types: MapTypes.IPoiType[]) => void;
}
const PoiTypeContext = createContext<IPoiTypeContext>({
  poiTypes: [],
  updatePoiTypes: (types: MapTypes.IPoiType[]) => {},
});

export default PoiTypeContext;
