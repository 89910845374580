// React libs
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
// Components
import AppLayout from '../../../Components/Layout/AppLayout/AppLayout';
// Type
import * as Types from './ProfilUser.type';
import * as FormUser from '../../../Components/Form/FormType/Auth/UserProfilForm/UserPofilForm.type';
import UserProfilForm from '../../../Components/Form/FormType/Auth/UserProfilForm/UserProfilForm';
import * as FormAddress from '../../../Components/Form/FormType/Auth/AddressProfilForm/AddressProfilForm.type';
import AddressForm from '../../../Components/Form/FormType/Auth/AddressProfilForm/AddressProfilForm';
import * as FormPassword from '../../../Components/Form/FormType/Auth/PasswordProfilForm/PasswordProfilForm.type';
import UserPasswordForm from '../../../Components/Form/FormType/Auth/PasswordProfilForm/PasswordProfilForm';
//Services
import AuthService from '../../../Data/Services/AuthService';
import LocalStorage from '../../../../Core/Data/Services/Storage/LocalStorage';
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';

import { useSnackbar } from 'notistack';
import { FormikHelpers } from 'formik';
// Common
import CoreCommon from '../../../../Core/Resources/Common';
import { Divider } from '@material-ui/core';

const ProfilUser: FC<Types.IProps> = ({ match }) => {
  const { t } = useTranslation(['common', 'profil']);
  const [data, setData] = useState<any>()
  const [isRefresh, setIsRefresh] = useState<any>(true)

  const idUser = match.params.id ?? LocalStorage.get(LocalStorage.keys.userId)

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    AuthService.getCurrentUser(idUser).then(value => {
      setData(value)
      setIsRefresh(false)
    })
  }, [isRefresh, idUser]);

  const defaultValues: FormUser.IFormUser = {
    firstname: data?.data.firstname || '',
    name: data?.data.name || '',
    url: data?.data.url || '',
    mail: data?.data.mail || '',
    phone: data?.data.phone || '',
    mobile: data?.data.mobile || '',
    comment: data?.data.comment || '',
    fkaddress: data?.data.fkaddress || '',
    fkaccount: data?.data.fkaccount || '',
    password: ''
  };

  const defaultValuesAddress: FormAddress.IFormAdress = data?.data.address === undefined ? {} : {
    name: data.data.address.name || '',
    line1: data.data.address.line1 || '',
    line2: data.data.address.line2 || '',
    city: data.data.address.city || '',
    zip: data.data.address.zip || '',
    country: data.data.address.country || '',
    fkaddress: data.data.address.id || ''
  }

  const defaultValuesPassword: FormPassword.IFormPassword = {
    accountSubjectId: data?.data.account?.subjectId || '',
    accountOldPassword: "" || '',
    accountNewPassword: "" || '',
    accountConfirmPassword: "" || '',
    accountAccessType: data?.data.account?.accessType.id || '',
    accountComment: data?.data.account?.comment || '',
  }
  const handleSubmit = ({ password, ...values }: FormUser.IFormUser, { setSubmitting, resetForm }: FormikHelpers<FormUser.IFormUser>) => {
    setSubmitting(true);
    const user: any = values
    if (password !== '') {
      user.account = {
        op: password
      }
    }
    AuthService.setCurrentUser(idUser, user).then((data: FormUser.IFormUser) => {
      resetForm({ values: defaultValues });
      enqueueSnackbar(t('profil:Vos données ont bien été enregistrer'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'success',
      });
      setIsRefresh(true)
      setSubmitting(false);
    }).catch((e: CoreTypes.IWsException) => {
      enqueueSnackbar(
        e?.error?.message || t('common:Une erreur est survenue, veuillez ressayer dans un instant...'),
        {
          ...CoreCommon.Constantes.snackbarDefaultProps,
          variant: 'error',
        })
      setSubmitting(false);
      resetForm({ values: defaultValues });
    })
  };

  const handleSubmitAddress = (values: FormAddress.IFormAdress, { setSubmitting, resetForm }: FormikHelpers<FormAddress.IFormAdress>) => {
    setSubmitting(true);
    AuthService.setCurrentUser(idUser, { fkaddress: values.fkaddress }).then((data: FormUser.IFormUser) => {
      resetForm({ values: defaultValuesAddress });
      enqueueSnackbar(t('profil:Vos données ont bien été enregistrer'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'success',
      });
      setIsRefresh(true)
      setSubmitting(false);
    }).catch((e: CoreTypes.IWsException) => {
      enqueueSnackbar(
        e?.error?.message || t('common:Une erreur est survenue, veuillez ressayer dans un instant...'),
        {
          ...CoreCommon.Constantes.snackbarDefaultProps,
          variant: 'error',
        })
      setSubmitting(false);
      resetForm({ values: defaultValuesAddress });
    })

  };

  const handleSubmitPassword = (values: FormPassword.IFormPassword, { setSubmitting, resetForm }: FormikHelpers<FormPassword.IFormPassword>) => {
    setSubmitting(true);
    AuthService.setCurrentUser(idUser, {
      account: {
        comment: values.accountComment,
        fkaccessType: values.accountAccessType,
        op: values.accountOldPassword,
        p: values.accountNewPassword,
        pc: values.accountConfirmPassword,
        subjectId: values.accountSubjectId,
      }
    }).then((data: FormPassword.IFormPassword) => {
      resetForm({ values: defaultValuesPassword });
      enqueueSnackbar(t('profil:Vos données ont bien été enregistrer'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'success',
      });
      setIsRefresh(true)
      setSubmitting(false);
    }).catch((e: CoreTypes.IWsException) => {
      enqueueSnackbar(
        e?.error?.message || t('common:Une erreur est survenue, veuillez ressayer dans un instant...'),
        {
          ...CoreCommon.Constantes.snackbarDefaultProps,
          variant: 'error',
        })
      setSubmitting(false);
      resetForm({ values: defaultValuesPassword });
    })
  };


  const validationSchema = Yup.object({
    firstname: Yup.string(),
    name: Yup.string(),
    url: Yup.string().nullable(),
    mail: Yup.string().email(t('common:errors.nonValidEmail')).nullable(),
    phone: Yup.string().nullable(),
    mobile: Yup.string().nullable(),
    comment: Yup.string().nullable(),
    password: data?.data.account !== undefined ? Yup.string().required(t('common:errors.defaultRequiredField')) : Yup.string()
  });

  const validationSchemaAddress = Yup.object({
    name: Yup.string().nullable(),
    line1: Yup.string().nullable(),
    line2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    zip: Yup.string().nullable(),
    country: Yup.string().nullable(),
    fkaddress: Yup.string().nullable()
  });

  const validationSchemaPassword = Yup.object({
    accountSubjectId: Yup.string().nullable(),
    accountOldPassword: Yup.string().nullable(),
    accountNewPassword: Yup.string().required('Le nouveau password est requis'),
    accountConfirmPassword: Yup.string().oneOf([Yup.ref('accountNewPassword'), null], 'Les mots de passe ne sont pas identique'),
    accountAccessType: Yup.string().nullable(),
    accountCommentcomment: Yup.string().nullable(),
  });

  return <AppLayout
    headerConf={{
      title: {
        label: t('profile:title'),
        icon: 'address-card-o'
      }
    }}
    isLoading={data == null}
    className='p-1 overflow-auto'
  >
    {data &&
      <>
        <div className="flex w-full">
          <div className='flex flex-col w-6/12' >
            <UserProfilForm defaultValues={defaultValues} onFormSubmit={handleSubmit} validationSchema={validationSchema} miscData={{ hidePasswordField: data?.data.account === undefined }} />
          </div>
          <Divider orientation="vertical" flexItem />
          <div className='flex flex-col w-6/12 pl-1'>
            <AddressForm defaultValues={defaultValuesAddress} onFormSubmit={handleSubmitAddress} validationSchema={validationSchemaAddress} />
          </div>
        </div>
        <div>
          <UserPasswordForm defaultValues={defaultValuesPassword} onFormSubmit={handleSubmitPassword} validationSchema={validationSchemaPassword} />
        </div>
      </>
    }
  </AppLayout>
}
export default ProfilUser;