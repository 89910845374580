// React libs
import React, { FC } from 'react';
import { IconButton, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
// Components
import FaIcon from '../../Icon/FaIcon/FaIcon';
// Types
import * as Types from './ClearButton.type'

const ClearButton: FC<Types.IProps> = ({ className, onClear }) => {
  // Variables
  const { t } = useTranslation(['common']);

  return <span data-testid='ClearButton'>
    <IconButton
      onClick={onClear}
      aria-label='clear'
      className='text-selection'
    >
      <Tooltip title={t('common:forms.buttons.clear') ?? ''}>
        <span>
          <FaIcon name='times-circle' className={`text-2xl cursor-pointer ${className}`} />
        </span>
      </Tooltip>
    </IconButton>
  </span>
}

export default ClearButton