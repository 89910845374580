// Services
import { useEffect, useState } from 'react';
import CommonService from '../Services/CommonService';
// Utils
import useServiceErrorHandler from '../../Utils/useServiceErrorHandler'
import { ifDef } from '../../Utils/Misc';

export interface IGetDatavizPalettesHook {
  data: any
}

const useDatavizPalettes = (paletteName?: string): IGetDatavizPalettesHook => {
  // Variables
  const handleServiceError = useServiceErrorHandler()

  // State
  const [data, setData] = useState<any>()

  // Effect
  useEffect(() => {
    CommonService.getDatavizPalettes().then(
      (data: any) => {
        setData(ifDef(paletteName, (paletteName: string) => data[paletteName] ?? [], () => data))
      },
      handleServiceError
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    data
  }
}

export default useDatavizPalettes