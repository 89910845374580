// Types
import * as CoreTypes from '../Models/Core.type';
// Utils
import ServicesUtils from '../../Utils/Services';
import CommonUrls from './CommonUrls';

export default class CommonService {
  /**
   * IMAGES
   */
  static uploadImage(data: any) {
    return ServicesUtils.postFormData(CommonUrls.uploadImage(), data, false);
  }
  static deleteImage(id: string) {
    return ServicesUtils.delete(CommonUrls.deleteImage(id), false);
  }
  static deleteImageLegend(fklayerMapId: string, legendtype: string) {
    return ServicesUtils.delete(
      CommonUrls.deleteLayerMapImageLegend(fklayerMapId, legendtype),
      false
    );
  }
  /**
   *  POI
   */
  static getPois(params?: CoreTypes.IUrlQuery) {
    return ServicesUtils.get(CommonUrls.getPois(params), true);
  }
  static getPoi(id: string) {
    return ServicesUtils.get(CommonUrls.getPoi(id), true);
  }
  static updatePoi(id: string, data: any) {
    return ServicesUtils.put(CommonUrls.updatePoi(id), data, false);
  }
  static deletePoi(id: string) {
    return ServicesUtils.delete(CommonUrls.deletePoi(id), false);
  }
  static exportProjectInfo(id: string) {
    return ServicesUtils.get(CommonUrls.exportProjectInfo(id), true);
  }
  static exportResourceInfo(id: string) {
    return ServicesUtils.get(CommonUrls.exportResourceInfo(id), true);
  }
  static getPoiLinks(params?: CoreTypes.IUrlQuery) {
    return ServicesUtils.get(CommonUrls.getPoiLinks(params));
  }
  static addPoiLink(data: any) {
    return ServicesUtils.postJSON(CommonUrls.addPoiLink(), data, false);
  }
  static updatePoiLink(data: any) {
    return ServicesUtils.put(
      CommonUrls.updatePoiLink(data.fkpoiFrom, data.fkpoiTo),
      data,
      false
    );
  }
  static savePoiThematics(data: any) {
    return ServicesUtils.postJSON(CommonUrls.savePoiThematics(), data, false);
  }
  static deletePoiThematic(thematicId: string, poiId: string) {
    return ServicesUtils.delete(
      CommonUrls.deletePoiThematic(thematicId, poiId),
      false
    );
  }
  static savePoiAllowedPersons(data: any) {
    return ServicesUtils.postJSON(
      CommonUrls.savePoiAllowedPersons(),
      data,
      false
    );
  }
  static deletePoiAllowedPerson(poiId: string, allowedPerson: string) {
    return ServicesUtils.delete(
      CommonUrls.deletePoiAllowedPerson(poiId, allowedPerson),
      false
    );
  }
  static savePoiAssociatedPerson(data: any) {
    return ServicesUtils.postJSON(
      CommonUrls.savePoiAssociatedPerson(),
      data,
      false
    );
  }
  static updatePoiAssociatedPerson(poiId: string, personId: string, data: any) {
    return ServicesUtils.put(
      CommonUrls.updatePoiAssociatedPerson(poiId, personId),
      data,
      false
    );
  }
  static deletePoiAssociatedPerson(poiId: string, personId: string) {
    return ServicesUtils.delete(
      CommonUrls.deletePoiAssociatedPerson(poiId, personId),
      false
    );
  }
  static deletePoiLink(fkpoiFrom: string, fkpoiTo: string) {
    return ServicesUtils.delete(
      CommonUrls.deletePoiLink(fkpoiFrom, fkpoiTo),
      false
    );
  }
  static savePoiFile(id: string, data: any, folderId?: string) {
    return ServicesUtils.postFormData(
      CommonUrls.savePoiFile(id, folderId),
      data,
      false
    );
  }
  static updatePoiFile(id: string, fileId: string, data: any) {
    return ServicesUtils.put(CommonUrls.updatePoiFile(id, fileId), data, false);
  }
  static deletePoiFile(id: string, fileId: string) {
    return ServicesUtils.delete(CommonUrls.deletePoiFile(id, fileId), false);
  }
  static savePoiFolder(id: string, data: any) {
    return ServicesUtils.postJSON(CommonUrls.savePoiFolder(id), data, false);
  }
  static savePoiFolderByPath(id: string, data: any) {
    return ServicesUtils.postJSON(
      CommonUrls.savePoiFolderByPath(id),
      data,
      false
    );
  }
  static updatePoiFolder(id: string, folderId: string, data: any) {
    return ServicesUtils.put(
      CommonUrls.updatePoiFolder(id, folderId),
      data,
      false
    );
  }
  static deletePoiFolder(id: string, folderId: string) {
    return ServicesUtils.delete(
      CommonUrls.deletePoiFolder(id, folderId),
      false
    );
  }
  static getPoiFolders(id: string) {
    return ServicesUtils.get(CommonUrls.getPoiFolders(id));
  }

  /**
   *  VALUE SLOTS
   */
  static saveValueSlot(data: any) {
    return ServicesUtils.postJSON(CommonUrls.saveValueSlot(), data, false);
  }
  static updateValueSlot(id: string, data: any) {
    return ServicesUtils.put(CommonUrls.updateValueSlot(id), data, false);
  }
  static deleteValueSlot(id: string) {
    return ServicesUtils.delete(CommonUrls.deleteValueSlot(id), false);
  }

  /**
   *  POI Phases
   */
  static updatePoiPhase(id: string, data: any) {
    return ServicesUtils.put(CommonUrls.updatePoiPhase(id), data, false);
  }
  static savePoiPhase(data: any) {
    return ServicesUtils.postJSON(CommonUrls.savePoiPhase(), data, false);
  }
  static deletePoiPhase(id: string) {
    return ServicesUtils.delete(CommonUrls.deletePoiPhase(id), false);
  }

  /**
   *  Financing
   */
  static saveExpenseLine(data: any) {
    return ServicesUtils.postJSON(CommonUrls.saveExpenseLine(), data, false);
  }
  static updateExpenseLine(id: string, data: any) {
    return ServicesUtils.put(CommonUrls.updateExpenseLine(id), data, false);
  }
  static deleteExpenseLine(id: string) {
    return ServicesUtils.delete(CommonUrls.deleteExpenseLine(id), false);
  }
  static saveExpenseFund(data: any) {
    return ServicesUtils.postJSON(CommonUrls.saveExpenseFund(), data, false);
  }
  static updateExpenseFund(id: string, data: any) {
    return ServicesUtils.put(CommonUrls.updateExpenseFund(id), data, false);
  }
  static deleteExpenseFund(id: string) {
    return ServicesUtils.delete(CommonUrls.deleteExpenseFund(id), false);
  }
  static saveExpenseEngaged(data: any) {
    return ServicesUtils.postJSON(CommonUrls.saveExpenseEngaged(), data, false);
  }
  static updateExpenseEngaged(id: string, data: any) {
    return ServicesUtils.put(CommonUrls.updateExpenseEngaged(id), data, false);
  }
  static deleteExpenseEngaged(id: string) {
    return ServicesUtils.delete(CommonUrls.deleteExpenseEngaged(id), false);
  }

  // Data
  static getPhaseTypes() {
    return ServicesUtils.get(CommonUrls.getPhaseTypes(), false);
  }
  static getPoiTypes() {
    return ServicesUtils.get(CommonUrls.getPoiTypes(), false);
  }
  static getFundTypes() {
    return ServicesUtils.get(CommonUrls.getFundTypes(), false);
  }
  static getThematic() {
    return ServicesUtils.get(CommonUrls.getThematic(), false);
  }
  static getTerritories() {
    return ServicesUtils.get(CommonUrls.getTerritories(), false);
  }
  static getPhase() {
    return ServicesUtils.get(CommonUrls.getPhase(), false);
  }
  static getPersonsWithAccount() {
    return ServicesUtils.get(CommonUrls.getPersonsWithAccount(), false);
  }
  static getAddresses() {
    return ServicesUtils.get(CommonUrls.getAddress(), false);
  }
  static getPositions() {
    return ServicesUtils.get(CommonUrls.getPositions(), false);
  }
  static getAccessTypes() {
    return ServicesUtils.get(CommonUrls.getAccessTypes(), false);
  }
  static getPersons(filter?: any) {
    return ServicesUtils.get(CommonUrls.getPersons(filter), false);
  }
  static getLinkTypes() {
    return ServicesUtils.get(CommonUrls.getLinkTypes(), false);
  }

  // Persons
  static savePerson(data: any) {
    return ServicesUtils.postJSON(CommonUrls.savePerson(), data, false);
  }
  static deletePerson(id: string) {
    return ServicesUtils.delete(CommonUrls.deletePerson(id), false);
  }

  // Dataviz
  static getDatavizPalettes() {
    return ServicesUtils.get(CommonUrls.getDatavizPalettes());
  }
  static getDashboardDatavizComposition() {
    return ServicesUtils.get(CommonUrls.getDashboardDatavizComposition());
  }
  static getProjectDatavizComposition() {
    return ServicesUtils.get(CommonUrls.getProjectDatavizComposition());
  }
}
