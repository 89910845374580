// React libs
import React, { FC } from 'react';
import { Switch as MuiSwitch, IconButton, Typography } from '@material-ui/core';
// Components
import FaIcon from '../Icon/FaIcon/FaIcon';
// Types
import * as Types from './Switch.type'

const Switch: FC<Types.IProps> = ({
  checkedIconOptions,
  checkedLabel,
  isChecked,
  onChange,
  unCheckedIconOptions,
  unCheckedLabel
}) => {
  // Variables
  const switchClasses = {
    track: isChecked ? 'bg-active' : 'bg-green-500'
  }
  const checkedIconElement = <IconButton className='text-xs text-selection-inverse bg-green-500' classes={{
    root: 'w-1 h-1'
  }}>
    <FaIcon {...checkedIconOptions} />
  </IconButton>
  const unCheckedIconElement = <IconButton className='text-xs text-selection-inverse bg-active' classes={{
    root: 'w-1 h-1'
  }}>
    <FaIcon {...unCheckedIconOptions} />
  </IconButton>

  return <div data-testid='Switch' className='flex items-center'>
    <Typography component='span' className='font-bold'>{checkedLabel}</Typography>
    <MuiSwitch
      checked={isChecked}
      checkedIcon={unCheckedIconElement}
      classes={switchClasses}
      icon={checkedIconElement}
      onChange={onChange}
    />
    <Typography component='span' className='font-bold'>{unCheckedLabel}</Typography>
  </div>
}

export default Switch
