export default class DatavizUrls {
  static path = process.env.REACT_APP_HOST;

  static get apiPath(): string {
    return `${DatavizUrls.path ?? ''}/api`;
  }

  static _getUrl(path: string, dateStart: string, dateEnd: string, filter?: any, size?: number) {
    let params = `size=${size}&period=${encodeURIComponent(JSON.stringify({
      dateStart,
      dateEnd
    }))}`
    if (filter !== undefined) {
      params += `&filter=${encodeURIComponent(JSON.stringify(filter))}`
    }
    return `${DatavizUrls.apiPath}/${path}?${params}`
  }

  // Resources
  static getResourcesMetrics(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return DatavizUrls._getUrl('indicators/pois/resources/average', dateStart, dateEnd, filter, size)
  }

  static getResourceEvolutionMetrics(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return DatavizUrls._getUrl('indicators/pois/resources', dateStart, dateEnd, filter, size)
  }

  static getResourceLinksEvolutionMetrics(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return DatavizUrls._getUrl('indicators/pois/links', dateStart, dateEnd, filter, size)
  }

  // Projects
  static getProjectsMetrics(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return DatavizUrls._getUrl('indicators/pois/projects/average', dateStart, dateEnd, filter, size)
  }

  static getProjectEvolutionMetrics(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return DatavizUrls._getUrl('indicators/pois/projects', dateStart, dateEnd, filter, size)
  }

  static getProjectsMetricsBudget(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return DatavizUrls._getUrl('indicators/pois/projects/budget', dateStart, dateEnd, filter, size)
  }

  // Links
  static getLinkMetrics(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return DatavizUrls._getUrl('indicators/pois/links/average', dateStart, dateEnd, filter, size)
  }

  // Tasks
  static getProjectTasksMetrics(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return DatavizUrls._getUrl('gant', dateStart, dateEnd, filter, size)
  }
}
