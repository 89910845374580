import _ from 'lodash';
import { IMarker, ISport } from '../../Pages/SharedMap/SharedMap.type';

export function updateNbOfPois(
  sports: ISport,
  markers: IMarker[],
  sportAge: number,
  isFilterAgeOn?: boolean
): ISport {
  const updatedSports = _.cloneDeep(sports);

  Object.values(updatedSports).forEach(sport => {
    sport.activities.forEach(activity => {
      activity.nbOfPois = 0;
    });
  });

  markers.forEach(marker => {
    const sportType = marker.type.label;
    let ageMin = 0;
    let ageMax = 0;
    marker.valueSlots.forEach(valueSlot => {
      if (valueSlot.valueDef.label === 'Age min') {
        ageMin = parseInt(valueSlot.value);
      } else if (valueSlot.valueDef.label === 'Age max') {
        ageMax = parseInt(valueSlot.value);
      }
    });

    marker.valueSlots.forEach(valueSlot => {
      if (valueSlot.valueDef.label === 'Activité') {
        const activityValue = valueSlot.value;
        const sportActivities = updatedSports[sportType]?.activities;

        const isBetweenAges = ageMin <= sportAge && sportAge <= ageMax;

        if (!isFilterAgeOn) {
          const activity = sportActivities.find(
            activity => activity.value === activityValue
          );
          if (activity) {
            activity.nbOfPois += 1;
          }
        } else {
          if (isBetweenAges) {
            const activity = sportActivities.find(
              act => act.value === activityValue
            );
            if (activity) {
              activity.nbOfPois += 1;
            }
          }
          return;
        }
      }
    });
  });

  return updatedSports;
}
