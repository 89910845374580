export default class LocalStorage {
  static keys = {
    appData: 'app_data',
    linkTypes: 'link_types',
    mapConfig: 'map_config',
    phaseTypes: 'phase_types',
    pois: 'pois',
    poisLinks: 'pois_links',
    poiTypes: 'poi_types',
    userId: 'user_id',
    user: 'user',
    mapFilters: 'map-filters',
    mapCoordinates: 'map-coordinates',
    mapLayer: 'map-layer'
  };

  static get(key: string): any {
    const value: any = localStorage.getItem(key);
    if (value && value !== 'undefined') {
      return JSON.parse(value);
    }
    return value;
  }
  static set(key: string, value: any): void {
    if (value !== null) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }
  static reset(key: string, newValue: any): void {
    LocalStorage.set(key, newValue);
  }
  static delete(key: string): void {
    localStorage.removeItem(key);
  }
}
