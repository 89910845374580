// Utils
import { createContext } from 'react';

export interface IFavoriteLayersContext {
  favoriteLayers: string[]
  updateFavoriteLayers: (favoriteLayers: string[]) => void
}
const FavoriteLayersContext = createContext<IFavoriteLayersContext>({
  favoriteLayers: [],
  updateFavoriteLayers: (favoriteLayers: string[]) => { }
})

export default FavoriteLayersContext;
