// React libs
import React, { FC, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
// Components
import FavoriteButton from '../../../../../../../Core/Components/UiKit/Button/FavoriteButton/FavoriteButton';
// Contexts
import UserContext, {
  IUserContext,
} from '../../../../../../../Core/Data/Contexts/UserContext';
import MapLegendContext, { IMapLegendContext } from '../../../../../Data/Contexts/MapLegendContext';
import FavoriteLayersContext, { IFavoriteLayersContext } from '../../../../../Data/Contexts/FavoriteLayersContext';

// Type
import * as Types from './LayerPreview.type';
import { isAdmin, isWriter } from '../../../../../../../Core/Utils/User';
import FaIcon from '../../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';

const LayerPreview: FC<Types.IProps> = ({ active, layer, onLayerClick }) => {
  const { t } = useTranslation(['map']);

  // Contexts
  const { user }: IUserContext = useContext(UserContext);
  const { hiddenLegendMapId, updateHiddenLegendMapId }: IMapLegendContext = useContext(MapLegendContext);
  const { favoriteLayers, updateFavoriteLayers }: IFavoriteLayersContext = useContext(FavoriteLayersContext)

  // Variables
  const favoriteLayerIndex = favoriteLayers.indexOf(layer.id)
  const isFavorite = favoriteLayerIndex !== -1

  // Handlers
  const onPreviewClick = () => {
    if (onLayerClick) onLayerClick(layer);
    openLegend()
  };
  const toggleFavorite = useCallback((e: any) => {
    e.stopPropagation();

    const newFavoriteLayers = [...favoriteLayers]
    if (isFavorite) {
      newFavoriteLayers.splice(favoriteLayerIndex, 1)
    } else {
      newFavoriteLayers.push(layer.id)
    }
    updateFavoriteLayers(newFavoriteLayers)
  }, [favoriteLayerIndex, favoriteLayers, isFavorite, layer.id, updateFavoriteLayers])
  const openLegend = useCallback(() => updateHiddenLegendMapId(undefined), [updateHiddenLegendMapId])

  return (
    <div
      className={`border-2 my-3 p-2 rounded ${active ? 'border-white' : 'border-transparent'
        }`}
    >
      <div
        className={`border border-white cursor-pointer relative rounded`}
        data-testid='layer-preview'
        onClick={onPreviewClick}
      >
        <img
          src={layer.thumbnailUrl}
          alt={layer.name}
          className='h-auto object-cover rounded w-full sm:h-40'
        />
        <div className='absolute bottom-2 bg-white-80 left-2 max-w-1/2 overflow-ellipsis overflow-hidden p-1 text-center text-sm'>
          {layer.name.length <= 56
            ? layer.name
            : `${layer.name.slice(0, 56)}...`}
        </div>
        {hiddenLegendMapId === layer.id &&
          <div className='absolute cursor-pointer top-2 right-2 opacity-100'>
            <Tooltip title={t('map:legendBox.actions.openLegend') ?? ''}>
              <span>
                <FaIcon aria-label='open legend' className='text-xl' name='list' onClick={openLegend} />
              </span>
            </Tooltip>
          </div>

        }
        {user && (isAdmin(user) || isWriter(user)) && (
          <div className='absolute -bottom-2 -right-2'>
            <FavoriteButton
              value={isFavorite}
              onClick={toggleFavorite}
            />
          </div>
        )}
      </div>
    </div>
  );
};

LayerPreview.propTypes = {
  active: PropTypes.bool.isRequired,
  layer: PropTypes.any.isRequired,
  onLayerClick: PropTypes.func,
};

export default LayerPreview;
