// React libs
import React, { FC, useCallback, useMemo, useState } from 'react';
import { countBy, keyBy, uniqueId } from 'lodash';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@material-ui/core';
// Components
import Autocomplete from '../../UiKit/Form/Autocomplete/Autocomplete';
import DataTable from '../../UiKit/DataTable/DataTable';
import DeleteButton from '../../UiKit/Button/DeleteButton/DeleteButton';
import FaIcon from '../../UiKit/Icon/FaIcon/FaIcon';
import InfoButton from '../../UiKit/Button/InfoButton/InfoButton';
import TextField from '../../UiKit/Form/TextField/TextField';
import * as FormFields from '../FormFields/FormFields';
// Types
import * as Types from './PoiEcosystem.type'
import * as CoreTypes from '../../../Data/Models/Core.type'
// Common
import Common from '../../../../App/Resources/Common';
// Hooks
import useLinkTypes from '../../../Data/Hooks/LinkTypes';

interface ILinkDisplay {
  link: CoreTypes.IPoiLink
  onUpdateLinkType?: (...args: any) => void
}

const LinkDisplay = ({ onUpdateLinkType, link }: ILinkDisplay) => {
  // Variables
  const linkTypes = useLinkTypes()

  if (link.type === undefined) {
    return null
  }

  const label = <>
    <FaIcon
      className='mr-1'
      name={link.type.style.data.icon}
      style={{ color: link.type.style.data.color }}
    />
    {link.type.label}
  </>

  if (onUpdateLinkType === undefined) {
    return <p>{label}</p>
  }

  const options = linkTypes.data?.map(linkType => ({
    label: linkType.label,
    value: linkType.id
  })) ?? []

  return <FormFields.FieldWrapper label={label} bgColor='transparent'>
    <Autocomplete
      options={options}
      disableClearable={true}
      color='secondary'
      field={{ name: 'linkType', value: link.type.id }}
      form={{
        errors: [],
        touched: [],
        setFieldValue: (_: string, linkTypeId: string) =>
          onUpdateLinkType(linkTypes.data?.find(candidate => candidate.id === linkTypeId)),
        setFieldTouched() { }
      }}
    />
  </FormFields.FieldWrapper>
}

interface ICommentDisplay {
  comment?: string
  onUpdateComment?: (...args: any) => void
}

const CommentDisplay = ({ comment, onUpdateComment }: ICommentDisplay) => {
  const [inputValue, setInputValue] = useState(comment)

  if (onUpdateComment === undefined) {
    return <p>{comment && FormFields.getStringPreview(comment)}</p>
  }

  return <FormFields.FieldWrapper label={inputValue} bgColor='transparent'>
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => onUpdateComment(inputValue)}>
      <div>
        <TextField
          field={{
            name: 'text',
            value: inputValue,
            onChange: ({ target }: any) => setInputValue(target.value)
          }}
          multiline
          form={{
            setFieldTouched: () => { },
            setFieldValue: () => { }
          }}
          color='secondary'
        />
      </div>
    </ClickAwayListener>
  </FormFields.FieldWrapper>
}

const PoiEcosystem: FC<Types.IProps> = ({
  containerClassName,
  createOptions = {},
  disableIdentityLinks,
  links,
  onDeleteLink,
  onUpdateLink,
  poi,
  poiLinks: poiLinks_,
  pois
}) => {
  // State
  const [dataTableKey, setDataTableKey] = useState<string>(uniqueId('dataTableKey'))
  // Variables
  const { t } = useTranslation(['common']);
  const history = useHistory()
  const poisById = useMemo(() => keyBy(pois, 'id'), [pois])
  const poiLinks = useMemo(
    () => poiLinks_ ?? links.filter(link => link[poi.isProject ? 'fkpoiFrom' : 'fkpoiTo'] === poi.id),
    [poiLinks_, links, poi.id, poi.isProject]
  )
  const nLinksByPoi = useMemo(() => countBy(links, link => link[poi.isProject ? 'fkpoiTo' : 'fkpoiFrom']), [links, poi.isProject])

  const goToIdentity = useCallback((poi: CoreTypes.IPoi) => {
    if (poi.isProject) {
      history.push(`/${Common.Routes.routeProject}/${poi.id}/${Common.Routes.routePreviewProject}`)
    } else {
      history.push(`/${Common.Routes.routeResource}/${poi.id}/${Common.Routes.routePreviewResource}`)
    }
  }, [history])

  const columns: any = useMemo(() => {
    return [
      {
        id: 'linkName',
        field: 'linkName',
        name: t('common:forms.fields.link'),
        canTruncate: onUpdateLink === undefined,
        renderCell: ({ link }: any) => <LinkDisplay
          link={link}
          onUpdateLinkType={onUpdateLink && (newLink => onUpdateLink(link, 'type', newLink))}
        />
      },
      {
        id: 'linkedPoiName',
        field: 'linkedPoiName',
        name: t('common:forms.fields.name'),
        canTruncate: true,
        renderCell: ({ linkedPoi, link }: any) => <p>
          <FaIcon
            className='mr-1'
            name={linkedPoi.type.style.data.icon}
            style={{ color: link.type.style.data.color }}
          />
          {linkedPoi.name}
        </p>
      },
      {
        id: 'linkedPoiTypeName',
        field: 'linkedPoiTypeName',
        name: t('common:forms.fields.nature'),
        canTruncate: true,
        renderCell: ({ linkedPoi }: any) => <p style={{ color: linkedPoi.type.style.data.markerColor }}>
          {linkedPoi.type.label}
        </p>
      },
      {
        id: 'linkedPoiTerritory',
        field: 'linkedPoiTerritory',
        name: t('common:forms.fields.territory')
      },
      {
        id: 'linkedPoiThematic',
        field: 'linkedPoiThematic',
        name: t('common:forms.fields.thematic'),
      },
      {
        id: 'comment',
        field: 'comment',
        name: t('common:forms.fields.comment'),
        renderCell: ({ comment, link }: any) => <CommentDisplay
          comment={comment}
          onUpdateComment={onUpdateLink && (comment => onUpdateLink(link, 'comment', comment))}
        />
      },
      {
        id: 'nLinkedPoiLinks',
        field: 'nLinkedPoiLinks',
        name: t(poi.isProject ? 'common:forms.fields.projects' : 'common:forms.fields.resources'),
      },
      {
        id: 'action',
        field: 'action',
        align: 'center',
        name: '',
        renderCell: ({ linkedPoi, link }: any) => <div className='flex items-center justify-center'>
          {!disableIdentityLinks &&
            <InfoButton
              onClick={() => goToIdentity(linkedPoi)}
              tooltip={t('common:forms.tooltips.goToIdentity')}
              size='small'
            />
          }
          {onDeleteLink !== undefined &&
            <DeleteButton
              onClick={() => {
                onDeleteLink(link)
                setDataTableKey(uniqueId('dataTable'))
              }}
              tooltip={t(`common:forms.tooltips.deleteLink`)}
              size='small'
            />
          }
        </div>
      }
    ]
  }, [disableIdentityLinks, goToIdentity, onDeleteLink, onUpdateLink, poi.isProject, t])
  const searchColumnKeys = useMemo(() => [
    'linkName',
    'linkedPoiName',
    'linkedPoiTypeName',
    'linkedPoiTerritory',
    'linkedPoiThematic',
    'comment',
    'nLinkedPoiLinks'
  ], [])

  const data = useMemo(() => poiLinks.map(link => {
    const linkedPoi = poisById[poi.isProject ? link.fkpoiTo : link.fkpoiFrom]
    return {
      id: `${link.fkpoiFrom},${link.fkpoiTo}`,
      linkName: link.type?.label,
      link,
      linkedPoi,
      linkedPoiName: linkedPoi?.name,
      linkedPoiTypeName: linkedPoi?.type.label,
      linkedPoiTerritory: linkedPoi.territory?.label,
      linkedPoiThematic: linkedPoi.thematics?.map(({ thematic }: any) => thematic.label).join(', '),
      comment: link.comment,
      nLinkedPoiLinks: nLinksByPoi[linkedPoi.id]
    }
  }), [poiLinks, poisById, poi.isProject, nLinksByPoi])

  // Handlers
  const onDeleteAllRows = useCallback((links: any) => {
    if (onDeleteLink !== undefined) {
      links.forEach((id: string) => {
        onDeleteLink(data.find(link => link.id === id)?.link)
      })
      setDataTableKey(uniqueId('dataTable'))
    }
  }, [data, onDeleteLink])

  return <div data-testid='PoiEcosystem' className='h-full w-full'>
    <DataTable
      key={dataTableKey}
      columns={columns}
      defaultSortColumnId='linkName'
      rows={data}
      searchColumnKeys={searchColumnKeys}
      onDeleteAllRows={onDeleteLink !== undefined ? onDeleteAllRows : undefined}
      onRowDblClick={!disableIdentityLinks ? ({ linkedPoi }: any) => goToIdentity(linkedPoi) : undefined}
      containerClassName={containerClassName}
      {...createOptions}
    />
  </div>
}

export default PoiEcosystem