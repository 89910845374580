// React libs
import React, { FC, useMemo } from 'react';
// Components
import GenericIconButton from '../GenericIconButton/GenericIconButton';
// Types
import * as Types from './FavoriteButton.type'

const FavoriteButton: FC<Types.IProps> = ({ value, onClick, tooltip }) => {
  const icon = useMemo(() => value
    ? {
      name: 'star',
      className: 'text-selection-inverse fill-current text-lg',
      container: 'bg-active h-6 w-6'
    }
    : {
      name: 'star-o',
      className: 'text-black fill-current text-lg',
      container: 'bg-white-80 h-6 w-6'
    },
    [value])
  return <span data-testid='FavoriteButton'>
    <GenericIconButton
      ariaLabel='favorite button'
      onClick={onClick}
      icon={icon.name}
      iconClassName={icon.className}
      tooltip={tooltip}
      className={icon.container}
    />
  </span>
}

export default FavoriteButton