// Types
import * as CoreTypes from '../Data/Models/Core.type';
// Services
import CommonService from '../Data/Services/CommonService';

export const deletePoi = async (poi: CoreTypes.IPoi, poisLinks: CoreTypes.IPoiLink[]) => {
  const phases = poi.phases;
  if (phases != null) {
    for (let i = 0; i < phases.length; i++) {
      await CommonService.deletePoiPhase(phases[i].id);
    }
  }

  const valueSlots = poi.valueSlots
  await Promise.all(valueSlots.map(({ id }) => CommonService.deleteValueSlot(id)))

  const poiLinks = poisLinks.filter(link => link.fkpoiTo === poi.id || link.fkpoiFrom === poi.id)
  await Promise.all(poiLinks.map(link => CommonService.deletePoiLink(link.fkpoiFrom, link.fkpoiTo)))

  const allowedPersons: any = poi.allowedPersons
  if (allowedPersons != null) {
    await Promise.all(allowedPersons.map((allowedPerson: any) => CommonService.deletePoiAllowedPerson(poi.id, allowedPerson.person.id)))
  }

  const associatedPersons: any = poi.persons
  if (associatedPersons != null) {
    await Promise.all(associatedPersons.map((associatedPerson: any) => CommonService.deletePoiAssociatedPerson(poi.id, associatedPerson.person.id)))
  }

  const thematics: any = poi.thematics
  if (thematics != null) {
    await Promise.all(thematics.map((thematic: any) => CommonService.deletePoiThematic(thematic.thematic.id, poi.id)))
  }

  await CommonService.deletePoi(poi.id)
}