export default class Routes {
  // Home
  static routeHome = '';

  /**
   * AUTH
   */
  // Login
  static routeLogin = 'connexion';
  // Forgot password
  static routeForgotPassword = 'mot-de-passe-oublie';
  // Reset password
  static routeResetPassword = 'nouveau-mot-de-passe';
  // Logout
  static routeLogout = 'deconnexion';

  /**
   * Contacts
   */
  static routeCreateContact = 'contact/new';
  static routeUpdateContact = 'contact/edit';
  static routeContacts = 'contacts';

  /**
   * APP
   */
  // Map
  static routeMap = 'carte';
  static routeSharedMap = 'mia/mapviewer'

  // Dataviz
  static dataviz = 'dataviz'

  // Projects
  static routeProjects = 'projets'
  static routeProject = 'projet';
  static routeCreateProject = `creation`;
  static routePreviewProject = `détails`;
  static routeUpdateProject = `modification`;
  static routeFurtherInformationPreviewProject = 'futher-information/details'
  static routeUpdateFurtherInformationProject = 'futher-information/modification'
  static routeContactPreviewProject = 'contact/details'
  static routeUpdateContactProject = 'contact/modification'
  static routeEcosystemPreviewProject = 'ecosystem/details'
  static routeUpdateEcosystemProject = 'ecosystem/modification'
  static routeTasksManagementPreviewProject = 'tasksManagement/details'
  static routeUpdateTasksManagementProject = 'tasksManagement/modification'
  static routeFinancingPreviewProject = 'financing/details'
  static routeUpdateFinancingProject = 'financing/modification'
  static routeStatisticReportPreviewProject = 'statisticReport/details'
  static routeGedPreviewProject = 'ged/details'
  static routeUpdateGedProject = 'ged/modification'

  // Resources
  static routeResources = 'resources';
  static routeResource = 'resource';
  static routeCreateResource = 'creation';
  static routePreviewResource = 'détails';
  static routeUpdateResource = 'modification';
  static routeFurtherInformationPreviewResource = 'futher-information/details'
  static routeUpdateFurtherInformationResource = 'futher-information/modification'
  static routeContactPreviewResource = 'contact/details'
  static routeUpdateContactResource = 'contact/modification'
  static routeEcosystemPreviewResource = 'ecosystem/details'

  // Planning
  static routePlanning = 'planning'

  /**
   * ADMIN
   */
  static routeAdmin = 'admin';
  static routeLayerManager = `${Routes.routeAdmin}/fonds-de-carte`;

  /**
   * MISC
   */
  // Error
  static routeError = 'erreur';
}
