// React libs
import React, { FC, useState, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Form, FormikProps, Formik } from 'formik';
import { FormikHelpers } from 'formik'
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
// Components
import * as FormFields from '../../../../../Core/Components/Form/FormFields/FormFields';
import CloseIconButton from '../../../../../Core/Components/UiKit/Button/CloseIconButton/CloseIconButton';
import CreateLinkForm from '../../../../../Core/Components/Form/CreateLink/CreateLinkForm';
import Separator from '../../../../../Core/Components/UiKit/Separator/Separator';
import PoiEcosystem from '../../../../../Core/Components/Form/PoiEcosystem/PoiEcosystem';
// Type
import * as Types from './ProjectEcosystemForm.type';
import * as linkFormTypes from '../../../../../Core/Components/Form/CreateLink/CreateLinkForm.type'
// Hooks
import usePois from '../../../../../Core/Data/Hooks/Pois';
import useLinkTypes from '../../../../../Core/Data/Hooks/LinkTypes';
// Utils
import {
  getFormPropTypes, isDisabled, preventKeyDownSubmit,
} from '../../../../../Core/Utils/FormUtils';

const ProjectEcosystemForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
    validateOnBlur={false}
  >
    {(formikProps: FormikProps<any>) => (
      <ProjectEcosystemFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}
  </Formik>
);

ProjectEcosystemForm.propTypes = getFormPropTypes();
export default ProjectEcosystemForm;

interface ICreateLink {
  poiLinks: Types.poiLinksType
  onAddLink: (...args: any) => void
}

const CreateLink: FC<ICreateLink> = ({ poiLinks, onAddLink }) => {
  // Variables
  const { t } = useTranslation(['common']);

  const defaultValues: linkFormTypes.IFormValues = {
    linkType: '',
    comment: '',
    resource: ''
  }
  const validationSchema = Yup.object().shape({
    linkType: Yup.string().required(t('errors.defaultRequiredField')),
    resource: Yup.string().required(t('errors.defaultRequiredField')),
    comment: Yup.string()
  })

  const resources = usePois({
    filter: {
      isProject: false
    }
  })
  const linkTypes = useLinkTypes()

  const resourcesOptions: any = useMemo(
    () => resources.data != null
      ? sortBy(resources.data.filter(resource => !poiLinks.some(link => link.fkpoiTo === resource.id)).map(resource => ({
        label: resource.name,
        value: resource.id
      })), 'label')
      : [],
    [poiLinks, resources.data]
  )
  const linkTypesOptions: any = useMemo(
    () => linkTypes.data != null
      ? sortBy(linkTypes.data.map(linkType => ({
        label: linkType.label,
        value: linkType.id
      })), 'label')
      : [],
    [linkTypes.data]
  )

  // handlers
  const onSubmit = useCallback(async (values: linkFormTypes.IFormValues, { resetForm, setSubmitting }: FormikHelpers<linkFormTypes.IFormValues>) => {
    setSubmitting(true)
    onAddLink({
      comment: values.comment,
      fkpoiTo: values.resource,
      type: linkTypes.data?.find(link => link.id === values.linkType)
    })
    resetForm({ values: defaultValues });
    setSubmitting(false)
  }, [defaultValues, linkTypes.data, onAddLink])

  return <CreateLinkForm
    defaultValues={defaultValues}
    onFormSubmit={onSubmit}
    miscData={{
      linkTypes: linkTypesOptions,
      color: 'primary',
      resources: resourcesOptions,
      isNested: true,
      showResourceField: true
    }}
    validationSchema={validationSchema}
  />
}

const ProjectEcosystemFormikForm = ({
  errors,
  isSubmitting,
  touched,
  miscData,
  miscFunctions,
  setFieldTouched,
  setFieldValue,
  values
}: FormikProps<any> & any) => {
  // Variables
  const { t } = useTranslation(['project']);
  const poiLinks = useMemo(() => values.poiLinks?.filter((poiLink: any) => !poiLink.toDelete) ?? [], [values])

  // State
  const [isLinkCreationMode, setIsLinksCreationMode] = useState<boolean>(false)

  // handlers
  const toggleLinkCreationMode = useCallback(() => setIsLinksCreationMode(isLinkCreationMode => !isLinkCreationMode), [])
  const onAddLink = useCallback(newValue => {
    setFieldTouched('poiLinks', true)
    setFieldValue(`poiLinks.${values.poiLinks.length}`, {
      ...newValue,
      fkpoiFrom: miscData.poi.id,
      toCreate: true
    })
  }, [miscData.poi.id, setFieldTouched, setFieldValue, values.poiLinks.length])
  const onDeleteLink = useCallback(link => {
    const index = values.poiLinks.findIndex(({ fkpoiTo }: any) => fkpoiTo === link.fkpoiTo)
    setFieldTouched('poiLinks', true)

    if (link.toCreate) {
      const poiLinksCopy = [...values.poiLinks]
      poiLinksCopy.splice(index, 1)
      setFieldValue('poiLinks', poiLinksCopy)
    } else {
      setFieldValue(`poiLinks.${index}`, {
        ...values.poiLinks[index],
        toDelete: true
      })
    }
  }, [setFieldTouched, setFieldValue, values])
  const onUpdateLink = useCallback((link, field: string, value: string) => {
    const index = values.poiLinks.findIndex(({ fkpoiTo }: any) => fkpoiTo === link.fkpoiTo)
    setFieldTouched('poiLinks', true)
    setFieldValue(`poiLinks.${index}`, {
      ...values.poiLinks[index],
      [field]: value,
      toUpdate: !link.toCreate
    })
  }, [setFieldTouched, setFieldValue, values.poiLinks])

  return <Form data-testid='project-ecosystem-form' className='w-full h-full' onKeyDown={preventKeyDownSubmit}>
    <div className='w-full flex h-9.3/10'>
      <div className='flex-1 w-4/5 h-full'>
        <PoiEcosystem
          poi={miscData.poi}
          pois={miscData.pois}
          links={miscData.links}
          poiLinks={poiLinks}
          disableIdentityLinks={true}
          onDeleteLink={onDeleteLink}
          onUpdateLink={onUpdateLink}
          createOptions={{
            onCreate: toggleLinkCreationMode,
            createButtonTooltip: t('project:ecosystem.createLink')
          }}
          containerClassName='table-ecosystem-edition'
        />
      </div>
      {isLinkCreationMode && <div className='flex w-1/5 h-full'>
        <Separator type='vertical' className='ml-4' />
        <div className='h-full w-full'>
          <div className='w-full flex justify-end'>
            <CloseIconButton
              onClose={toggleLinkCreationMode}
            />
          </div>
          <CreateLink
            poiLinks={poiLinks}
            onAddLink={onAddLink}
          />
        </div>
      </div>}
    </div>
    <div className='w-full h-0.7/10'>
      <FormFields.FormSubmitButtons
        isSubmitting={isSubmitting}
        disabled={isDisabled(errors, isSubmitting, touched)}
        onCancel={miscFunctions?.onCancel}
      />
    </div>
  </Form>
}
