// React libs
import React, { FC, useMemo } from 'react';
import ReactECharts from 'echarts-for-react'
// Types
import * as Types from './Doughnut.type'
import * as CoreTypes from '../../../../Data/Models/Core.type'
// Hooks
import useDatavizPalettes from '../../../../Data/Hooks/DatavizPalettes';
// Components
import LocalLoader from '../../Loader/LocalLoader/LocalLoader';

const OPTIONS_BY_SIZE: { [size in CoreTypes.datavizChartSizeType]: any } = {
  mini: {
    globalStyle: {
      height: '5em',
      width: '5em'
    },
    series: {
      radius: ['40%', '60%']
    },
    title: {
      fontSize: 28
    }
  },
  medium: {
    globalStyle: {
      height: '100%',
      width: '60em'
    },
    series: {
      radius: ['40%', '60%']
    },
    title: {
      fontSize: 28
    }
  },
  maxi: {
    globalStyle: {
      height: '45em',
      width: '100em'
    },
    series: {
      radius: ['60%', '90%']
    },
    title: {
      fontSize: 40
    }
  }
}

interface IDoughnutComponent extends Types.IProps {
  colorPalette: string[]
}

const DoughnutComponent: FC<IDoughnutComponent> = ({ colorPalette, size = 'medium', title, data }) => {
  // Variables
  const chartDynamicOption = OPTIONS_BY_SIZE[size]
  const stringData = useMemo(() => JSON.stringify(data), [data])
  const newData = useMemo(() => [
    ...data.map((value, key) => ({
      value,
      itemStyle: {
        color: colorPalette[key],
        borderRadius: 9,
        borderWidth: 10
      }
    })),
    {
      value: 0,
      name: title,
      label: {
        formatter: '{b}',
        position: 'center',
        fontSize: chartDynamicOption.title.fontSize,
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [stringData])

  const option: any = {
    toolbox: {
      feature: {
        saveAsImage: {},
        dataView: { show: true, readOnly: true }
      }
    },
    series: [
      {
        type: 'pie',
        radius: chartDynamicOption.series.radius,
        avoidLabelOverlap: true,
        labelLine: {
          show: false
        },
        data: newData
      }
    ]
  }

  return <ReactECharts option={option} style={chartDynamicOption.globalStyle} />
}

const Doughnut: FC<Types.IProps> = props => {
  // Variables
  const colorPalette = useDatavizPalettes('doughnut').data

  return <div data-testid='Doughnut' className='h-full w-full flex items-center justify-center'>
    {colorPalette === undefined
      ? <LocalLoader />
      : <DoughnutComponent {...props} colorPalette={colorPalette} />
    }
  </div>
}

export default Doughnut