// Utils
import { createContext } from 'react';
// Types
import * as MapTypes from '../Models/Map.type';

export interface IMapFiltersContext {
  areAdvancedFiltersSelected: boolean,
  areFavoriteFiltersSelected: boolean,
  mapFilters: MapTypes.IMapFilters;
  updateAreAdvancedFiltersSelected: (value: boolean) => void,
  updateAreFavoriteFiltersSelected: (value: boolean) => void,
  updateMapFilters: (filters: MapTypes.IMapFilters) => void;
}
const MapFiltersContext = createContext<IMapFiltersContext>({
  areAdvancedFiltersSelected: false,
  areFavoriteFiltersSelected: false,
  mapFilters: {
    initialized: false,
    needMapRefresh: true,
    markers: { links: {}, projects: {}, resources: {} },
    links: false,
    advanced: {}
  },
  updateAreAdvancedFiltersSelected: (value: boolean) => { },
  updateAreFavoriteFiltersSelected: (value: boolean) => { },
  updateMapFilters: (newFilters: MapTypes.IMapFilters) => { },
});

export default MapFiltersContext;
