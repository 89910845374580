// React libs
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// UI
import App from './App/Pages/AppScene';
// Worker
import * as serviceWorker from './serviceWorker';
// Components
import PageLoader from './Core/Components/UiKit/Loader/PageLoader/PageLoader';
// I18n
import './i18n';
// CSS
import './App/Resources/assets/css/main.css';
import '@bryntum/gantt/gantt.material.css';
import '@bryntum/scheduler/scheduler.material.css';
import '@bryntum/taskboard/taskboard.material.css';

const root = document.getElementById('root');

if (root !== null) {
  ReactDOM.render(
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <App />
      </Suspense>
    </BrowserRouter>,
    root
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
