// React libs
import React, { FC, useCallback } from 'react';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
// Components
import FormHeader from '../../../../Core/Components/Form/FormHeader/FormHeader';
import LocalLoader from '../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import ProjectBudget from '../../components/ProjectFinancing/ProjectBudget/ProjectBudget';
import ProjectEngagedFunds from '../../components/ProjectFinancing/ProjectEngagedFunds/ProjectEngagedFunds'
import ProjectFinancingForm from '../../components/Form/ProjectFinancingForm/ProjectFinancingForm';
import ProjectFinancingSources from '../../components/ProjectFinancing/ProjectFinancingSources/ProjectFinancingSources'
// Types
import * as ProjectFinancingFormTypes from '../../components/Form/ProjectFinancingForm/ProjectFinancingForm.type';
import * as Types from './ProjectFinancing.type'
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
// Services
import CommonService from '../../../../Core/Data/Services/CommonService';
// Common
import Common from '../../../../App/Resources/Common'
// Hooks
import useFundTypes from '../../../../Core/Data/Hooks/FundTypes';
// Utils
import useServiceErrorHandler from '../../../../Core/Utils/useServiceErrorHandler';

interface IProjectFinancingPreview {
  poi: CoreTypes.IPoi
  fundTypes: CoreTypes.IFundType[]
}

const ProjectFinancingPreview: FC<IProjectFinancingPreview> = ({ poi, fundTypes }) => {
  const { expenseLines = [], expenseFundings = [], expenseEngaged = [] } = poi
  return <div className='h-full w-full overflow-auto'>
    <ProjectBudget expenseLines={expenseLines} />
    <ProjectFinancingSources
      expenseFundings={
        expenseFundings.map(({ fundType, ...props }) => ({
          ...props,
          fkfundType: fundType?.id
        }))
      }
      fundTypes={fundTypes}
    />
    <ProjectEngagedFunds
      expenseEngaged={
        expenseEngaged.map(({ fundType, ...props }) => ({
          ...props,
          fkfundType: fundType?.id
        }))
      }
      fundTypes={fundTypes}
    />
  </div>
}

interface IProjectFinancingEdition {
  poi: CoreTypes.IPoi
  refreshPoi: () => void
  toggleEditionMode: () => void
  fundTypes: CoreTypes.IFundType[]
}

const ProjectFinancingEdition: FC<IProjectFinancingEdition> = ({ poi, toggleEditionMode, fundTypes, refreshPoi }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const handleServiceError = useServiceErrorHandler()
  const { expenseLines = [], expenseFundings = [], expenseEngaged = [] } = poi
  const defaultValues: ProjectFinancingFormTypes.IFormProject = {
    expenseLines,
    expenseFundings: expenseFundings.map(({ fundType, ...props }) => ({
      ...props,
      fkfundType: fundType?.id
    })),
    expenseEngaged: expenseEngaged.map(({ fundType, ...props }) => ({
      ...props,
      fkfundType: fundType?.id
    }))
  }

  const validationSchema = Yup.object({
    expenseLines: Yup.array(Yup.object({
      name: Yup.string().required(t('common:errors.defaultRequiredAllFields')),
      amount: Yup.number().required(t('common:errors.defaultRequiredAllFields')),
      amountVat: Yup.number().required(t('common:errors.defaultRequiredAllFields'))
    })),
    expenseFundings: Yup.array(Yup.object({
      name: Yup.string().required(t('common:errors.defaultRequiredAllFields')),
      amount: Yup.number().required(t('common:errors.defaultRequiredAllFields')),
      fkfundType: Yup.string().required(t('common:errors.defaultRequiredAllFields')),
      requestDate: Yup.string().required(t('common:errors.defaultRequiredAllFields')),
      deadlineDate: Yup.string().required(t('common:errors.defaultRequiredAllFields')),
    })),
    expenseEngaged: Yup.array(Yup.object({
      foundSrc: Yup.string().required(t('common:errors.defaultRequiredAllFields')),
      amount: Yup.number().required(t('common:errors.defaultRequiredAllFields')),
      fkfundType: Yup.string().required(t('common:errors.defaultRequiredAllFields')),
      amountPaid: Yup.number().required(t('common:errors.defaultRequiredAllFields')),
    })),
  })

  // handlers
  const handleCollectionSubmit = useCallback(async ({
    values,
    fkpoi,
    createService,
    updateService,
    deleteService
  }: {
    values: any[],
    fkpoi: string,
    createService: (props: any) => Promise<any>,
    updateService: (id: string, props: any) => Promise<any>,
    deleteService: (id: string) => Promise<any>,
  }) => {
    const props: any[] = []
    const promises = []

    const { toCreate = [], toUpdate = [], toDelete = [] } = groupBy(
      values,
      item => item.toCreate
        ? 'toCreate'
        : item.toUpdate
          ? 'toUpdate'
          : item.toDelete
            ? 'toDelete'
            : 'others'
    )

    promises.push(
      ...toCreate.map(({ toCreate, id, ...item }) => {
        props.push(item)
        return createService({
          ...item,
          fkpoi
        })
      }),
      ...toUpdate.map(({ toUpdate, ...item }) => {
        props.push(item)
        return updateService(item.id, {
          ...item,
          fkpoi
        })
      }),
      ...toDelete.map(item => {
        props.push({
          id: item.id,
          toDelete: true
        })
        return deleteService(item.id)
      })
    )

    await Promise.all(promises)

    return props
  }, [])

  const onSubmit = useCallback(async (
    { expenseLines, expenseFundings, expenseEngaged }: ProjectFinancingFormTypes.IFormProject,
    { setSubmitting }: FormikHelpers<ProjectFinancingFormTypes.IFormProject>
  ) => {
    setSubmitting(true)
    try {
      const poiUpdateProps: any = {
        id: poi.id,
        isProject: true
      }

      const [expenseLinesProps, expenseFundingsProps, expenseEngagedProps] = await Promise.all([
        handleCollectionSubmit({
          values: expenseLines,
          fkpoi: poi.id,
          createService: CommonService.saveExpenseLine.bind(CommonService),
          updateService: CommonService.updateExpenseLine.bind(CommonService),
          deleteService: CommonService.deleteExpenseLine.bind(CommonService)
        }),
        handleCollectionSubmit({
          values: expenseFundings,
          fkpoi: poi.id,
          createService: CommonService.saveExpenseFund.bind(CommonService),
          updateService: CommonService.updateExpenseFund.bind(CommonService),
          deleteService: CommonService.deleteExpenseFund.bind(CommonService)
        }),
        handleCollectionSubmit({
          values: expenseEngaged,
          fkpoi: poi.id,
          createService: CommonService.saveExpenseEngaged.bind(CommonService),
          updateService: CommonService.updateExpenseEngaged.bind(CommonService),
          deleteService: CommonService.deleteExpenseEngaged.bind(CommonService)
        })
      ])

      if (expenseLinesProps.length > 0) {
        poiUpdateProps.expenseLines = expenseLinesProps
      }
      if (expenseFundingsProps.length > 0) {
        poiUpdateProps.expenseFundings = expenseFundingsProps
      }
      if (expenseEngagedProps.length > 0) {
        poiUpdateProps.expenseEngaged = expenseEngagedProps
      }

      await CommonService.updatePoi(poi.id, poiUpdateProps)
    } catch (error) {
      handleServiceError(error)
    } finally {
      setSubmitting(false)
      refreshPoi()
      toggleEditionMode()
    }
  }, [handleCollectionSubmit, handleServiceError, poi.id, refreshPoi, toggleEditionMode])

  return <ProjectFinancingForm
    defaultValues={defaultValues}
    validationSchema={validationSchema}
    onFormSubmit={onSubmit}
    miscData={{ fundTypes }}
    miscFunctions={{ onCancel: toggleEditionMode }}
  />
}

const ProjectFinancing: FC<Types.IProps> = ({ poi, isEditionMode, refreshPoi }) => {
  // Variables
  const { t } = useTranslation(['project', 'common']);
  const history = useHistory()
  const fundTypes = useFundTypes()

  // Handlers
  const toggleEditionMode = useCallback(() => {
    const path = isEditionMode ? Common.Routes.routeFinancingPreviewProject : Common.Routes.routeUpdateFinancingProject
    history.push(
      `/${Common.Routes.routeProject}/${poi.id}/${path}`
    )
  }, [history, isEditionMode, poi.id])

  return <div data-testid='ProjectFinancing' className='h-full w-full'>
    <div className='h-0.7/10 w-full pl-2 pt-1'>
      <FormHeader
        title={t('project:financing.title')}
        isEditionMode={isEditionMode}
        toggleEditionMode={toggleEditionMode}
      />
    </div>
    {fundTypes.isLoading
      ? <div className='h-9.3/10 w-full flex item-center justify-center'>
        <LocalLoader type='contained' message={t('common:loading.dataLoading')} />
      </div>
      : <div className='h-9.3/10 w-full p-1 pt-2'>
        {isEditionMode
          ? <ProjectFinancingEdition poi={poi} refreshPoi={refreshPoi} toggleEditionMode={toggleEditionMode} fundTypes={fundTypes.data ?? []} />
          : <ProjectFinancingPreview poi={poi} fundTypes={fundTypes.data ?? []} />
        }
      </div>
    }
  </div>
}

export default ProjectFinancing