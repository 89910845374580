// React libs
import React, { FC, useMemo } from 'react';
import moment from 'moment'
import { useTranslation } from 'react-i18next';
// Components
import Autocomplete from '../../../../../Core/Components/UiKit/Form/Autocomplete/Autocomplete';
import DataTable from '../../../../../Core/Components/UiKit/DataTable/DataTable';
import DatePicker from '../../../../../Core/Components/UiKit/Form/DatePicker/DatePicker';
import FaIcon from '../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import FormTitle from '../../../../../Core/Components/Form/FormTitle/FormTitle';
import TextField from '../../../../../Core/Components/UiKit/Form/TextField/TextField';
import * as FormFields from '../../../../../Core/Components/Form/FormFields/FormFields';
import { IconButton } from '@material-ui/core';
// Types
import * as Types from './FinancingTable.type'
// Utils
import { momentToISOString } from '../../../../../Core/Utils/Misc';

const FinancingTable: FC<Types.IProps> = props => {
  // Variables
  const { t } = useTranslation(['project']);
  const totalId = 'totalId'
  const columns = useMemo(() => {
    const columns: any = [...props.columns.map((column, key) => ({
      id: column.id,
      field: column.field,
      name: column.name,
      renderCell: (cell: any) => {
        const value = cell[column.field]

        if (cell.id === totalId) {
          return key === 0
            ? <p><b>{value.toUpperCase()}</b></p>
            : <p>{value}{column.type === 'percent' ? '%' : ''}</p>
        }

        switch (column.type) {
          case 'percent': return <p>{value}%</p>
          case 'integer': case 'float': case 'text':
            if (props.onChange === undefined) return <p>{value}</p>
            const textProps: any = {
              field: { name: 'text', value },
              form: {
                setFieldTouched: () => { },
                setFieldValue: (_: any, value: any) => props.onChange?.({
                  id: cell.id,
                  key: column.dataField,
                  value
                })
              },
              transform: (value: any) => value,
              color: 'secondary'
            }
            if (column.type === 'integer') {
              textProps.transform = 'integer'
              textProps.type = 'number'
            }
            if (column.type === 'float') {
              textProps.transform = 'float'
              textProps.type = 'number'
            }
            return <FormFields.FieldWrapper align='start' label={value}>
              <TextField  {...textProps} />
            </FormFields.FieldWrapper>
          case 'select':
            const selectedValue = column.options?.find(option => option.value === value)?.label ?? ''
            return props.onChange === undefined
              ? <p>{selectedValue}</p>
              : <FormFields.FieldWrapper align='start' label={selectedValue}>
                <Autocomplete
                  options={column.options ?? []}
                  color='secondary'
                  field={{ name: 'text', value }}
                  form={{
                    setFieldTouched: () => { },
                    setFieldValue: (_: any, value: any) => props.onChange?.({
                      id: cell.id,
                      key: column.dataField,
                      value
                    }),
                    errors: [],
                    touched: []
                  }}
                />
              </FormFields.FieldWrapper>
          case 'date':
            const formattedValue = (value === '' || value == null) ? '' : moment(value).format('DD/MM/YYYY')
            return props.onChange === undefined
              ? <p>{formattedValue}</p>
              : <FormFields.FieldWrapper align='start' label={formattedValue}>
                <DatePicker
                  transform={(date: any) => (date !== '' && date != null) ? momentToISOString(date) : ''}
                  color='secondary'
                  field={{ name: 'text', value }}
                  form={{
                    setFieldTouched: () => { },
                    setFieldValue: (_: any, value: any) => props.onChange?.({
                      id: cell.id,
                      key: column.dataField,
                      value
                    }),
                    errors: [],
                    touched: []
                  }}
                />
              </FormFields.FieldWrapper>
        }
      }
    }))]

    if (props.onDel !== undefined) {
      columns.push({
        id: 'action',
        field: 'id',
        align: 'center',
        name: '',
        renderCell: ({ id }: any) => <div className='flex items-end justify-end'>
          {id !== totalId &&
            <IconButton
              aria-label='delete'
              className='text-xl'
              onClick={e => props.onDel?.({ id })}
            >
              <FaIcon name='trash' />
            </IconButton>
          }
        </div>
      })
    }

    return columns
  }, [props.onDel, props.columns, props.onChange])

  const rows: any = useMemo(() => {
    const totalPerKey: any = {}
    props.rows.forEach(r => {
      Object.entries(r).forEach(([key, value]) => {
        if (typeof value === 'number') {
          totalPerKey[key] = value + (totalPerKey[key] ?? 0)
        }
      })
    })

    return [
      ...props.rows,
      {
        id: totalId,
        [columns[0]?.field]: `${t('project:financing.total')} (${props.rows.length})`,
        ...totalPerKey,
        stickyRow: true
      }
    ]
  }, [columns, props.rows, t])

  return <div data-testid='FinancingTable'>
    <FormTitle title={props.title} />
    <br />
    <DataTable
      columns={columns}
      rows={rows}
      searchColumnKeys={props.searchColumnKeys}
      onDeleteAllRows={props.onDelMultiple}
      onCreate={props.onAdd}
    />
  </div>
}

export default FinancingTable