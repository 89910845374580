// React libs
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../Button'
import FaIcon from '../../Icon/FaIcon/FaIcon'
// Type
import * as Types from './ApplyButton.type'

const ApplyButton: FC<Types.IProps> = props => {
  // Variables
  const { t } = useTranslation(['common'])

  return <Button
    color='secondary'
    data-testid='ApplyButton'
    size='small'
    {...props}
  >
    <FaIcon name='check-circle' className='text-lg mr-1' />
    <div className='relative'>{t('common:forms.buttons.apply')}</div>
  </Button>
}


export default ApplyButton
