export default class PdfStyles {
  static centerRowItems = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  };

  static centerColumnItems = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  };
}