// React libs
import React, { useState } from 'react'
// Components
import ConfirmMessageModal from '../Components/Modals/ConfirmMessageModal/ConfirmMessageModal'
// Utils
import useConfirmModal from './useConfirmModal'
import { ifDef } from './Misc'

const useConfirmMessageModal = (defaultMessage?: string) => {
  // Variables
  const { isOpened, onClose, openModal } = useConfirmModal()
  // state
  const [message, setMessage] = useState<string>(defaultMessage ?? '')

  return {
    modal: <ConfirmMessageModal
      handleClose={onClose}
      isOpened={isOpened}
      message={message}
    />,
    openModal: (newMessage?: string) => {
      ifDef(newMessage, setMessage)
      return openModal()
    }
  }
}

export default useConfirmMessageModal