// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Hooks
import useGenericData, { IGetGenericData } from './GenericData';

export interface IGetPoiLinks extends IGetGenericData<CoreTypes.ILinkType> {
}

const useLinkTypes = (): IGetPoiLinks => {
  return useGenericData(() => CommonService.getLinkTypes())
}

export default useLinkTypes