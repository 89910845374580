// React libs
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
// Components
import ActiveButton from '../ActiveButton';
// Types
import * as Types from './EcosystemButton.type'

const EcosystemButton: FC<Types.IProps> = ({ onClick, isActive, tooltip }) => {
  // Variables
  const { t } = useTranslation(['map']);

  return <Tooltip title={tooltip}>
    <div data-testid='ecosystem-button'>
      <ActiveButton
        handler={onClick}
        icon='bezier-curve'
        iconType='fas'
        isActive={isActive}
        label={t('map:sidebar.filters.ecosystems.title')}
      />
    </div>
  </Tooltip>
}

export default EcosystemButton