// React libs
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Layout
import AuthLayout from '../../../Components/Layout/AuthLayout/AuthLayout';
// Components
import ForgotPasswordForm from '../../../Components/Form/FormType/Auth/ForgotPasswordForm/ForgotPasswordForm';
import Typography from '../../../../Core/Components/UiKit/Typography/Typography';
// Services
import AuthService from '../../../Data/Services/AuthService';
// Type
import * as Types from './ForgotPasswordScene.type';
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
import * as AuthTypes from '../../../Data/Models/Auth.type';
import * as FormTypes from '../../../Components/Form/FormType/Auth/ForgotPasswordForm/ForgotPasswordForm.type';
// Common
import Common from '../../../Resources/Common';
import CoreCommon from '../../../../Core/Resources/Common';

const ForgotPasswordScene: FC<Types.IProps> = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'auth']);

  const renderForm = () => {
    const defaultValues: FormTypes.IFormValues = {
      email: '',
    };
    const validationSchema = Yup.object({
      email: Yup.string().required(
        t('auth:forgotPassword.form.email.errors.missing')
      ),
    });
    const handleSubmit = (
      values: FormTypes.IFormValues,
      { setSubmitting }: FormikHelpers<FormTypes.IFormValues>
    ) => {
      setSubmitting(true);
      AuthService.forgotPassword(values.email)
        .then((data: AuthTypes.ILoginData) => {
          setSubmitting(false);
          enqueueSnackbar(t('auth:forgotPassword.form.success'), {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'success',
          });
          history.push(`/${Common.Routes.routeLogin}`);
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
          setSubmitting(false);
        });
    };
    return (
      <div data-testid='forgot-password-page'>
        <div className='flex flex-col items-center'>
          <Typography variant='h6' className='text-center'>
            {t('auth:forgotPassword.title')}
          </Typography>
        </div>
        <ForgotPasswordForm
          defaultValues={defaultValues}
          onFormSubmit={handleSubmit}
          validationSchema={validationSchema}
        />
      </div>
    );
  };

  return <AuthLayout>{renderForm()}</AuthLayout>;
};

export default ForgotPasswordScene;
