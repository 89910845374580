// React libs
import React, { FC, useState, useEffect, useCallback, useContext } from 'react';
import { MapContainer, ZoomControl, TileLayer } from 'react-leaflet';
import { uniqueId } from 'lodash';
// Context
import MapContext, { IMapContext } from '../../../Data/Contexts/MapContext';
// Type
import * as Types from './MapLayer.type';
// Utils
import {
  createClusters,
  createMarkers,
  createLinks,
} from '../../../Utils/Leaflet';
// Css
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
// Misc
require('leaflet.markercluster/dist/leaflet.markercluster.js');

const DEFAULT_CENTER = [45.7601609, 4.8522794];
const DEFAULT_ZOOM = 8;

const MapLayer: FC<Types.IProps> = ({
  poisRefresh,
  data,
  onMarkerCreation,
  containerId,
  hideZoom,
  onHomeClick,
  isSharedMap,
  setPopupActive,
  ...props
}) => {
  // State

  const [tileKey, setTileKey] = useState<string>(uniqueId());
  const [displayedCluster, setDisplayedCluster] = useState<any>(null);
  const [displayedMarkers, setDisplayedMarkers] = useState<any>({});
  const [displayedLinks, setDisplayedLinks] = useState<any>({});
  const [redrawLinksClusters, setRedrawLinksClusters] = useState<any>();

  // Contexts
  const { map, setMap, searchedPoi }: IMapContext = useContext(MapContext);

  // Variables
  const layerUrl =
    props.layerUrl ?? 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  const center = props.center ?? DEFAULT_CENTER;
  const stringCenter = JSON.stringify(center);
  const zoom = props.zoom ?? DEFAULT_ZOOM;
  // Refs
  const markersRef: any = React.useRef(displayedMarkers);
  const updateDisplayedMarkers = (data: any) => {
    markersRef.current = data;
    setDisplayedMarkers(data);
  };
  const mapRef = React.useRef<any>();

  const linksRef: any = React.useRef({});
  const updateDisplayedLinks = (data: any) => {
    linksRef.current = data;
    setDisplayedLinks(data);
  };

  // Handlers
  const redrawLinks = useCallback(
    (clusters: any) => {
      if (map === undefined) {
        return;
      }

      Object.values(linksRef.current).forEach((link: any) =>
        link.removeFrom(map)
      );

      const layers = clusters._featureGroup._layers;
      const newLinks = createLinks({
        links: data.poisLinks,
        linkTypes: data.linkTypes,
        markers: markersRef.current,
        map,
        clusters: Object.keys(layers)
          .filter((k: string) => layers[k]?.cluster === true)
          .map((key: string) => layers[key]),
        category: data.category,
      });
      Object.keys(newLinks).forEach(key => newLinks[key].addTo(map));
      updateDisplayedLinks(newLinks);
    },
    [data.linkTypes, data.poisLinks, data.category, map]
  );

  // Effects
  useEffect(() => {
    if (redrawLinksClusters !== undefined) {
      redrawLinks(redrawLinksClusters);
      setRedrawLinksClusters(undefined);
    }
  }, [redrawLinks, redrawLinksClusters]);

  useEffect(() => {
    // TileLayer url is immutable
    setTileKey(uniqueId());
  }, [props.layerUrl]);

  // Update the zoom and the center because MapContainer props are immutable
  useEffect(() => {
    mapRef.current?.setView(center, zoom);
  }, [center, stringCenter, zoom]);

  // Create home button
  useEffect(() => {
    if (map === undefined || hideZoom) {
      return;
    }

    const homeButtonId = 'home-redirection';
    const homeButtonListener: any = [
      'click',
      () => {
        if (onHomeClick !== undefined) {
          onHomeClick();
        } else {
          map.setView(center, zoom);
        }
      },
    ];
    const buttonElement = document.querySelector(
      `#${containerId} #${homeButtonId}`
    );

    if (buttonElement !== null) {
      buttonElement.removeEventListener(
        homeButtonListener[0],
        homeButtonListener[1]
      );
      buttonElement.addEventListener(
        homeButtonListener[0],
        homeButtonListener[1]
      );
      return;
    }

    const zoomBtn = document.querySelector(
      `#${containerId} .leaflet-control-zoom-in`
    );
    if (zoomBtn === null) {
      return;
    }

    const btn: HTMLAnchorElement = document.createElement('a');
    btn.setAttribute('class', 'leaflet-control-zoom-home');
    btn.setAttribute('href', '#');
    btn.setAttribute('role', 'button');
    btn.setAttribute('aria-label', 'Home');
    btn.setAttribute('title', 'Home');
    btn.setAttribute('id', homeButtonId);
    btn.innerHTML =
      '<svg class="MuiSvgIcon-root" focusable="false" viewBox="-4 -4 32 32" aria-hidden="true"><path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"></path></svg>';
    btn.addEventListener(homeButtonListener[0], homeButtonListener[1]);

    zoomBtn.parentNode?.insertBefore(btn, zoomBtn.nextSibling);
  }, [map, hideZoom, containerId, onHomeClick, center, zoom]);

  // Create POIs
  useEffect(() => {
    if (map === undefined || !poisRefresh.needPoisRefresh) {
      return;
    }
    poisRefresh.setNeedPoisRefresh(false);

    let cluster = displayedCluster;
    if (!cluster) {
      cluster = createClusters();
      cluster.addTo(map);
      setDisplayedCluster(cluster);
    } else {
      cluster.clearLayers();
      Object.values(displayedLinks).forEach((link: any) =>
        link.removeFrom(map)
      );
    }

    // markers
    const markers = createMarkers({
      ...data,
      searchedPoi,
      isSharedMap,
      setPopupActive,
      onMarkerCreation: (marker, poi) => {
        if (typeof onMarkerCreation === 'function') {
          onMarkerCreation(marker, poi);
        }

        marker.on('click', () => {
          map.panTo(marker.getLatLng());
        });
      },
    });

    updateDisplayedMarkers(markers);
    cluster.addLayers(Object.values(markers));

    // links
    const links = createLinks({
      links: data.poisLinks,
      linkTypes: data.linkTypes,
      markers,
      map,
      category: data.category,
    });
    updateDisplayedLinks(links);
    cluster.on('animationend', (e: any) => {
      setRedrawLinksClusters(e.target);
    });

    Object.values(links).forEach((link: any) => link.addTo(map));
    redrawLinks(cluster);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    map,
    onMarkerCreation,
    poisRefresh.needPoisRefresh,
    poisRefresh.setNeedPoisRefresh,
  ]);

  // Handlers
  const onMapCreated = useCallback(
    (map: any) => {
      setMap(map);
      mapRef.current = map;
      props.onMapCreated?.(map);

      // init
      // map.setView(DEFAULT_CENTER, DEFAULT_ZOOM)
    },
    [props.onMapCreated, setMap]
  );

  return (
    <MapContainer
      id={containerId}
      center={center}
      zoom={props.zoom}
      minZoom={props.minZoom}
      maxZoom={props.maxZoom}
      zoomControl={false}
      whenCreated={onMapCreated}
      className='h-full w-full'
      maxBounds={props.maxBounds}
    >
      {!hideZoom && <ZoomControl position='topright' />}
      <TileLayer
        key={tileKey}
        url={layerUrl}
        attribution='MIA - © Openstreetmap France | Données <a href="http://www.openstreetmap.org/copyright">© les contributeurs OpenStreetMap</a> | Imagery © <a href="http://mapbox.com">Mapbox</a>'
      />
    </MapContainer>
  );
};

export default MapLayer;
