// React libs
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import ChartBuilder from '../../../../Core/Components/UiKit/ChartBuilder/ChartBuilder';
import FormHeader from '../../../../Core/Components/Form/FormHeader/FormHeader';
import LocalLoader from '../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
// Types
import * as Types from './ProjectStatisticReport.type'
// Common
import charts from '../../Resources/Common/ProjectStatisticReportCharts';
import hooks from '../../Resources/Common/ProjectStatisticReportHooks';
import sizes from '../../Resources/Common/ProjectStatisticReportSizes';
// Hooks
import useDatavizComposition from '../../../../Core/Data/Hooks/DatavizComposition';

const ProjectStatisticReport: FC<Types.IProps> = ({ isLoading, project, refresh, refreshTime }) => {
  // Variables
  const { t } = useTranslation(['project']);
  const datavizComposition = useDatavizComposition('project')
  const dataviz: any[] = datavizComposition.data ?? []

  if (datavizComposition.isLoading) {
    return <div data-testid='ProjectStatisticReport' className='h-full w-full flex items-center justify-center'>
      <LocalLoader />
    </div>
  }

  return <div data-testid='ProjectStatisticReport' className='h-full w-full'>
    <div className='h-0.7/10 w-full pl-2 pt-1'>
      <FormHeader
        title={t('project:statisticReport.title')}
      />
    </div>
    <div className='flex flex-wrap justify-around h-9.3/10 w-full gap-2'>
      {dataviz.map((item: any, key: number) => {
        const chart = (charts as any)[item.chart]
        const hook = (hooks as any)[item.hook]

        return <div className={(sizes as any)[item.size]} key={key}>
          {chart && hook && <ChartBuilder
            chart={chart}
            hookData={{
              project,
              refresh,
              isLoading,
              refreshTime
            }}
            hook={hook}
            title={t(`project:statisticReport.chartTitles.${item.title}`)}
          />}
        </div>
      })}
    </div>
  </div>
}

export default ProjectStatisticReport
