// Utils
import { createContext } from 'react';
// Types
import * as CoreTypes from '../Models/Core.type';

export interface IData {
  project: {
    types: CoreTypes.IPoiType[]
    valueDefs: { [id: string]: CoreTypes.IValueDef }
  },
  resource: {
    types: CoreTypes.IPoiType[]
    valueDefs: { [id: string]: CoreTypes.IValueDef }
  }
  thematic: CoreTypes.IThematic[]
  territories: CoreTypes.ITerritory[]
}

export const defaultValue = {
  project: {
    types: [],
    valueDefs: {}
  },
  resource: {
    types: [],
    valueDefs: {}
  },
  thematic: [],
  territories: []
}

export interface IAdvancedFiltersContext {
  data: IData
  updateData: (data: IData) => void
}
const AdvancedFiltersContext = createContext<IAdvancedFiltersContext>({
  data: defaultValue,
  updateData: (data: IData) => { },
});

export default AdvancedFiltersContext;
