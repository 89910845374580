import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types';

export const getFormPropTypes = () => ({
  defaultValues: PropTypes.any.isRequired,
  miscData: PropTypes.any,
  miscFunctions: PropTypes.any,
  onFormSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.any,
});

export const isDisabled = (
  errors: { [key: string]: boolean },
  isSubmitting: boolean,
  touched?: { [key: string]: boolean }
) => {
  let hasBeenTouched = touched ? Object.keys(touched).length > 0 : true;
  let hasError = hasBeenTouched && Object.keys(errors).length > 0;
  return isSubmitting || !hasBeenTouched || hasError;
};

export const hasErrors = (form: any, fieldName?: string) =>
  !isEmpty(form.errors) &&
  !isEmpty(form.touched) &&
  (fieldName === undefined || form.errors[fieldName] !== undefined)

export type IOption = {
  label: string
  value: any
}

export const patchFormValues = (initialValues: any, newValues: any) => {
  const values: { [key: string]: any } = {}
  Object.entries(newValues).forEach(([key, value]) => {
    if (!isEqual(initialValues[key], typeof value === 'string' ? value.trim() : value)) {
      values[key] = value
    }
  })
  return values
}

export const preventKeyDownSubmit = (e: any) => {
  if (e.key === 'Enter' && e.target.type !== 'textarea') {
    e.preventDefault()
  }
}

export const formatPersonName = (person: any) => person != null ? `${person.name} ${person.firstname}` : ''