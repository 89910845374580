// React libs
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MaterialSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React, { FC, useMemo, useCallback } from 'react';
import { get, uniqueId } from 'lodash';
// Components
import FieldError from '../../Error/FieldError/FieldError';
// Type
import * as Types from './Select.type';
// Utils
import { hasErrors, IOption } from '../../../../Utils/FormUtils'

const Select: FC<Types.IProps> = ({ predicate = () => true, onSelectValue, field, form, label, options, ...props }) => {
  // Variables
  const formId = useMemo(() => uniqueId('select_'), [])

  // Handlers
  const onChange = useCallback(e => {
    form.setFieldTouched(field.name, true);

    const value = e.target.value
    form.setFieldValue(field.name, value)
    if (onSelectValue !== undefined) {
      onSelectValue(value)
    }
  }, [form, field, onSelectValue])

  return <FormControl data-testid='select' className='w-full h-full' size='small' variant='filled'>
    {label && <InputLabel id={formId} classes={{ root: 'text-selection text-xs' }}>{label}</InputLabel>}
    <MaterialSelect
      labelId={formId}
      id={`label_${formId}`}
      value={field.value}
      onChange={onChange}
      classes={{ selectMenu: 'bg-main-light text-xs' }}
      MenuProps={{
        style: { zIndex: 1600 }
      }}
      {...props}
    >
      {options.map((option: IOption, key: number) =>
        <MenuItem key={key} value={option.value} classes={{ root: `text-selection text-xs ${predicate(option) ? '' : 'hidden'}` }}>
          {option.label}
        </MenuItem>
      )}
    </MaterialSelect>
    {hasErrors(form, field.name) && (
      <FieldError
        color='secondary'
        errors={get(form.errors, field.name)}
        touched={form.touched}
      />
    )}
  </FormControl>
}

export default Select
