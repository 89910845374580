import { LocaleManager } from '@bryntum/scheduler/scheduler.umd.js'

const locale = {
  localeName: 'FR',
  localeDesc: 'Francais',

  PresetManager: {
    weekDateAndMonth: {
      topDateFormat: 'MMMM YYYY',
    },
    weekAndDay: {
      middleDateFormat: 'DD MMMM YYYY',
    },
    hourAndDay: {
      topDateFormat: 'ddd DD/MM',
    },
    minuteAndHour: {
      topDateFormat: 'ddd DD/MM, hA',
    }
  },

  DateHelper: {
    locale: 'fr',
    unitNames: [
      { single: 'Milliseconde', plural: 'Millisecondes', abbrev: 'ms' },
      { single: 'Seconde', plural: 'Secondes', abbrev: 's' },
      { single: 'Minute', plural: 'Minutes', abbrev: 'min' },
      { single: 'Heure', plural: 'Heures', abbrev: 'h' },
      { single: 'Jour', plural: 'Jours', abbrev: 'J' },
      { single: 'Semaine', plural: 'Semaines', abbrev: 'sem' },
      { single: 'Mois', plural: 'Mois', abbrev: 'mo' },
      { single: 'Trimestre', plural: 'Trimestres', abbrev: 'tr' },
      { single: 'Année', plural: 'Années', abbrev: 'An' },
      { single: 'Décennie', plural: 'Décennies', abbrev: 'Dec' }
    ],
    unitAbbreviations: [
      ['ms'],
      ['s', 'sec'],
      ['m', 'min'],
      ['h', 'hr'],
      ['j'],
      ['sem'],
      ['mo'],
      ['tr'],
      ['a', 'an'],
      ['dec']
    ],
  },

  TimeAxisHeaderMenu: {
    activeDateRange: "Plage de dates",
    startText: "Date de début",
    endText: "Date de fin",
    pickZoomLevel: "Zoom",
    todayText: "Aujourd'hui"
  }
}

export default locale;

LocaleManager.registerLocale('FR', { desc: 'Francais', locale: locale });