// Services
import CommonService from '../Services/CommonService';
// Hooks
import useGenericData, { IGetGenericSingleData } from './GenericData';
// Types
import * as CoreTypes from '../Models/Core.type';

export interface IGetPoiFolders extends IGetGenericSingleData<CoreTypes.IPoiFolders> {
}

const usePoiFolders = (id: string): IGetPoiFolders => {
  return useGenericData(() => CommonService.getPoiFolders(id))
}

export default usePoiFolders