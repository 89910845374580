// React libs
import React, { FC } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
// Types
import * as Types from './DatePicker.type'

const DatePicker: FC<Types.IProps> = ({ label, value, onChange, minDate }) => {
  // Variables
  const { t } = useTranslation(['components']);

  return <KeyboardDatePicker
    className='w-40'
    data-testid='date-picker'
    disableToolbar
    variant="inline"
    format="DD/MM/YYYY"
    margin="none"
    color='secondary'
    label={label}
    value={value}
    minDate={minDate}
    invalidDateMessage={t('components:chartBuilder.date.invalidDateMessage')}
    onChange={onChange}
    KeyboardButtonProps={{
      'aria-label': 'change date',
    }}
  />

}

export default DatePicker