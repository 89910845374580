// Utils
import ServicesUtils from '../../../../Core/Utils/Services';
import MapUrls from './MapUrls';

export default class MapService {
  // Data
  static getCountries() {
    return ServicesUtils.get(MapUrls.getCountries(), false);
  }
  static getPoiTypes() {
    return ServicesUtils.get(MapUrls.getPoiTypes(), false);
  }
  static getPhaseTypes() {
    return ServicesUtils.get(MapUrls.getPhaseTypes(), false);
  }
  static getLinkTypes() {
    return ServicesUtils.get(MapUrls.getLinkTypes(), false);
  }
  // Config
  static getMapConfig() {
    return ServicesUtils.get(MapUrls.getMapConfig(), false);
  }
  static updateMapConfig(values: any) {
    return ServicesUtils.put(MapUrls.updateMapConfig(), values, false);
  }
  // Layers
  static getLayers(filter?: any) {
    return ServicesUtils.get(MapUrls.getLayers(filter), false);
  }
  static createLayer(data: any) {
    return ServicesUtils.postJSON(MapUrls.createLayer(), data, false);
  }
  static updateLayer(id: string, data: any) {
    return ServicesUtils.put(MapUrls.updateLayer(id), data, false);
  }
  static deleteLayer(id: string) {
    return ServicesUtils.delete(MapUrls.deleteLayer(id), false);
  }
  // Layers legends
  static createLegendImage(data: any) {
    return ServicesUtils.postJSON(MapUrls.createLegendImage(), data, false);
  }

  // Pois
  static getPois(filters: any) {
    return filters
      ? ServicesUtils.postJSON(MapUrls.getPois(), filters, false)
      : ServicesUtils.get(MapUrls.getPois(), false);
  }
  static savePoi(data: any) {
    return ServicesUtils.postJSON(MapUrls.savePoi(), data, false);
  }
  static updatePoi(id: string, data: any) {
    return ServicesUtils.put(MapUrls.updatePoi(id), data, false);
  }
  static deletePoi(id: string) {
    return ServicesUtils.delete(MapUrls.deletePoi(id), false);
  }
  static getPoiDetails(id: string) {
    return ServicesUtils.get(MapUrls.getPoiDetails(id), false);
  }
  static savePoiPhase(data: any) {
    return ServicesUtils.postJSON(MapUrls.savePoiPhase(), data, false);
  }
  static deletePoiPhase(id: string) {
    return ServicesUtils.delete(MapUrls.deletePoiPhase(id), false);
  }
  static searchPoi(search: string, size?: number, signal?: AbortSignal) {
    return ServicesUtils.get(MapUrls.searchPoi(search, size), false, signal);
  }
  static getPoiLinks() {
    return ServicesUtils.get(MapUrls.getPoiLinks())
  }
  static addPoiLink(data: any) {
    return ServicesUtils.postJSON(MapUrls.addPoiLink(), data, false);
  }
  static updatePoiLink(data: any) {
    return ServicesUtils.put(MapUrls.updatePoiLink(data.fkpoiFrom, data.fkpoiTo), data, false);
  }
  static deletePoiLink(fkpoiFrom: string, fkpoiTo: string) {
    return ServicesUtils.delete(MapUrls.deletePoiLink(fkpoiFrom, fkpoiTo), false);
  }
  static deletePoiAllowedPerson(poiId: string, allowedPerson: string) {
    return ServicesUtils.delete(MapUrls.deletePoiAllowedPerson(poiId, allowedPerson), false);
  }
  static deletePoiThematic(thematicId: string, poiId: string) {
    return ServicesUtils.delete(MapUrls.deletePoiThematic(thematicId, poiId), false);
  }

  // External territories
  static getExternalTerritories() {
    return ServicesUtils.get(MapUrls.getExternalTerritories(), false);
  }

  // Addresses
  static saveAddress(data: any) {
    return ServicesUtils.postJSON(MapUrls.saveAdress(), data, false);
  }
  static searchMapboxAddress(
    token: string,
    query: string,
    countries: string,
    signal?: AbortSignal
  ) {
    return ServicesUtils.get(
      MapUrls.searchMapboxAddress(token, query, countries),
      false,
      signal
    );
  }

  // Thematic
  static getThematic() {
    return ServicesUtils.get(MapUrls.getThematic(), false)
  }

  // Territories
  static getTerritories() {
    return ServicesUtils.get(MapUrls.getTerritories(), false)
  }

  // Selections
  static getSelections(fkaccount: string | undefined, size?: number) {
    return ServicesUtils.get(MapUrls.getSelections(fkaccount, size), false)
  }

  static saveSelection(data: any) {
    return ServicesUtils.postJSON(MapUrls.saveSelection(), data, false);
  }

  static updateSelection(id: string, data: any) {
    return ServicesUtils.put(MapUrls.updateSelection(id), data, false);
  }

  static deleteSelection(id: string) {
    return ServicesUtils.delete(MapUrls.deleteSelection(id), false);
  }

  // Map shares
  static getMapShares(fkaccount: string | undefined) {
    return ServicesUtils.get(MapUrls.getMapShares(fkaccount), false)
  }

  static getMapSharesPois(id: string) {
    return ServicesUtils.get(MapUrls.getMapSharesPois(id), false)
  }

  static shareMap(data: any) {
    return ServicesUtils.postJSON(MapUrls.shareMap(), data, false);
  }

  static stopMapShare(id: string) {
    return ServicesUtils.delete(MapUrls.stopMapShare(id), false);
  }

  // Favorites
  static updateFavorites(prefId: string, layerMapFavorites: string[]) {
    return ServicesUtils.put(MapUrls.updatePref(prefId), { data: { layerMapFavorites } }, false);
  }
}
