// React libs
import React, { FC, useCallback, useState } from 'react'
import { BryntumScheduler } from '@bryntum/scheduler-react'
import { BryntumTaskBoard } from '@bryntum/taskboard-react'
import { uniqueId } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
// Components
import Switch from '../../../../Core/Components/UiKit/Switch/Switch';
import * as FormFields from '../../../../Core/Components/Form/FormFields/FormFields'
import FormHeader from '../../../../Core/Components/Form/FormHeader/FormHeader';
// Types
import * as Types from './ProjectTasksManagement.type'
// Common
import Common from '../../../../App/Resources/Common'
// Hooks
import useTaskBoardOptions from './useTaskBoardOptions';
import useSchedulerOptions from './useSchedulerOptions';
// Utils
import useServiceErrorHandler from '../../../../Core/Utils/useServiceErrorHandler'

export const TASK_COLOR_BY_STATUS = {
  todo: 'blue',
  doing: 'orange',
  done: 'light-green'
}

const ProjectTasksManagement: FC<Types.IProps> = ({ project, isEditionMode }) => {
  // state
  const [isSchedulerVue, setIsSchedulerVue] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [taskBoarKkey, setTaskBoardKey] = useState<string>(uniqueId('taskboard'))
  const [schedulerKey, setSchedulerKey] = useState<string>(uniqueId('scheduler'))

  // Variable
  const history = useHistory()
  const { t } = useTranslation(['project'])
  const taskBoardOptions = useTaskBoardOptions({ project, isEditionMode })
  const schedulerOptions = useSchedulerOptions({ data: taskBoardOptions.data, setRecord: taskBoardOptions.setRecord, isEditionMode })
  const handleServiceError = useServiceErrorHandler()

  // Handlers
  const toggleEditionMode = useCallback(() => {
    history.push(`/${Common.Routes.routeProject}/${project.id}/${isEditionMode ? Common.Routes.routeTasksManagementPreviewProject : Common.Routes.routeUpdateTasksManagementProject}`)

    // some bryntum options are immutable
    setTaskBoardKey(uniqueId('taskboard'))
    setSchedulerKey(uniqueId('scheduler'))
  }, [history, isEditionMode, project.id])
  const toggleSchedulerVue = useCallback(() => setIsSchedulerVue(isSchedulerVue => !isSchedulerVue), [])
  const onSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      await taskBoardOptions.onSubmit()
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsSubmitting(false)
      toggleEditionMode()
    }
  }, [handleServiceError, taskBoardOptions, toggleEditionMode])
  const onCancel = useCallback(async () => {
    toggleEditionMode()
    await taskBoardOptions.onCancel()
  }, [taskBoardOptions, toggleEditionMode])

  return <div className='h-full w-full' data-testid='ProjectTasksManagement'>
    <div className='h-0.7/10 w-full px-2 pt-1'>
      <FormHeader
        title={t('project:tasksManagement.title')}
        toggleEditionMode={toggleEditionMode}
        isEditionMode={isEditionMode}
        actions={<Switch
          checkedIconOptions={{
            type: 'fas',
            name: 'tasks'
          }}
          checkedLabel={t('project:tasksManagement.kanbanVue')}
          isChecked={isSchedulerVue}
          onChange={toggleSchedulerVue}
          unCheckedIconOptions={{
            name: 'calendar'
          }}
          unCheckedLabel={t('project:tasksManagement.calendarVue')}
        />}
      />
    </div>
    <div className='h-9.3/10 w-full'>
      <div className='h-9.3/10 w-full overflow-auto'>
        {isSchedulerVue
          ? <BryntumScheduler {...schedulerOptions.options} key={schedulerKey} />
          : <BryntumTaskBoard {...taskBoardOptions.options} key={taskBoarKkey} />
        }
      </div>
      <div className='h-0.7/10 w-full'>
        {isEditionMode &&
          <FormFields.FormSubmitButtons
            onCancel={onCancel}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
          />
        }
      </div>
    </div>
  </div>
}

export default ProjectTasksManagement