// Utils
import { createContext } from 'react';
// Types
import * as MapTypes from '../Models/Map.type';

export interface IMapConfigContext {
  mapConfig: MapTypes.IMapConfig | undefined;
  updateMapConfig: (config: MapTypes.IMapConfig | undefined) => void;
}
const MapConfigContext = createContext<IMapConfigContext>({
  mapConfig: undefined,
  updateMapConfig: (config: MapTypes.IMapConfig | undefined) => { },
});

export default MapConfigContext;
