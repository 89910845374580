import { useCallback, useState } from 'react';

export interface IUseConfirmModal {
  isOpened: boolean
  openModal: () => Promise<any>
  onClose: (id: string | undefined, result: boolean) => void
}

const useConfirmModal = (): IUseConfirmModal => {
  // state
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [watcher, setWatcher] = useState<{ promise: Promise<any>, resolve: Function, reject: Function }>({ promise: Promise.resolve(), resolve: () => { }, reject: () => { } })

  // Actions
  const openModal = useCallback(() => {
    if (isOpened) {
      return watcher.promise
    }

    let promise: Promise<any>
    promise = new Promise((resolve, reject) => {
      setWatcher({
        resolve,
        reject,
        promise
      })
    })

    setIsOpened(true)

    return promise
  }, [isOpened, watcher])
  const onClose = useCallback((id: string | undefined, result: boolean) => {
    if (result) {
      watcher.resolve()
    } else {
      watcher.reject()
    }

    setIsOpened(false)
  }, [watcher])

  return { isOpened, openModal, onClose }
}

export default useConfirmModal