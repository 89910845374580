// React libs
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { countBy } from 'lodash';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'
import * as ProjectTypes from '../../Data/Models/Project.type'

const useLinksMetrics = ({ project, ...props }: ProjectTypes.IMetricsHook): CoreTypes.IDatavizHook => {
  // Variables
  const { t } = useTranslation(['project']);
  const data = useMemo(() => project.links == null
    ? []
    : Object.entries(countBy(project.links, link => link.type.label))
      .map(([name, value]) => ({
        name,
        value
      }))
      .filter(metric => metric.value !== 0)
      .sort((a, b) => a.value > b.value ? -1 : 1),
    [project.links])

  return {
    ...props,
    data,
    chart: {
      title: t('project:statisticReport.titles.links'),
      formatter: '{b} : {d}% ({c})'
    }
  }
}

export default useLinksMetrics