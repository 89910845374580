// React libs
import React, { FC, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// Components
import DataTable from '../../../UiKit/DataTable/DataTable';
import FaIcon from '../../../UiKit/Icon/FaIcon/FaIcon';
import { getStringPreview } from '../../FormFields/FormFields';
// Types
import * as Types from './PoiContactTable.type'

const PoiContactTable: FC<Types.IProps> = ({ className, contacts, onCreate, createButtonTooltip, onDelete, customPositionCell }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const columns = useMemo(() => {
    const columns: any = [
      {
        id: 'name',
        field: 'name',
        name: t('common:forms.fields.contact'),
        canTruncate: true,
        renderCell: ({ person }: any) => <p>{`${person.name} ${person.firstname}`}</p>
      },
      {
        id: 'mail',
        field: 'mail',
        name: t('common:forms.fields.email'),
        canTruncate: true,
        renderCell: ({ person }: any) => <p>{person.mail}</p>
      },
      {
        id: 'phone',
        field: 'phone',
        name: t('common:forms.fields.tel'),
        canTruncate: true,
        renderCell: ({ person }: any) => <p>{person.phone}</p>
      },
      {
        id: 'positionName',
        field: 'positionName',
        name: t('common:forms.fields.function'),
        canTruncate: !customPositionCell,
        renderCell: customPositionCell ?? (({ position }: any) => <p>{position?.name}</p>)
      },
      {
        id: 'comment',
        field: 'comment',
        name: t('common:forms.fields.comment'),
        renderCell: ({ person }: any) => <p>{person.comment != null ? getStringPreview(person.comment) : person.comment}</p>
      }
    ]
    if (onDelete !== undefined) {
      columns.push({
        id: 'action',
        field: 'id',
        align: 'center',
        name: '',
        renderCell: (params: any) => <div className='flex items-center justify-center'>
          <IconButton
            aria-label='delete'
            onClick={e => onDelete(params)}
            size='small'
          >
            <FaIcon name='trash' />
          </IconButton>
        </div>
      })
    }
    return columns
  }, [customPositionCell, t, onDelete])
  return <div data-testid='PoiContactTable' className='h-full w-full'>
    <DataTable
      columns={columns}
      rows={contacts}
      onCreate={onCreate}
      createButtonTooltip={createButtonTooltip}
      containerClassName={className}
    />
  </div>
}

export default PoiContactTable