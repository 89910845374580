// React libs
import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// Components
import ChartBuilder from '../../../Core/Components/UiKit/ChartBuilder/ChartBuilder';
// Layout
import AppLayout from '../../../App/Components/Layout/AppLayout/AppLayout';
// Type
import * as Types from './Dataviz.type';
// Common
import charts from '../Resources/Common/charts';
import hooks from '../Resources/Common/hooks';
import sizes from '../Resources/Common/sizes';
// Hooks
import useDatavizComposition from '../../../Core/Data/Hooks/DatavizComposition';

const Dataviz: FC<Types.IProps> = () => {
  // Variables
  const { t } = useTranslation(['dataviz']);
  const datavizComposition = useDatavizComposition('dashboard')
  const dataviz: any[] = datavizComposition.data ?? []

  return <AppLayout
    headerConf={{
      title: {
        label: t('dataviz:title'),
        icon: 'pie-chart'
      }
    }}
    className='bg-map overflow-auto p-1'
    isLoading={datavizComposition.isLoading}
  >
    <div className='flex flex-wrap justify-around h-full w-full gap-2'>
      {dataviz.map((item: any, key: number) => {
        const chart = (charts as any)[item.chart]
        const hook = (hooks as any)[item.hook]

        return <div className={(sizes as any)[item.size]} key={key}>
          {chart && hook && <ChartBuilder chart={chart} hook={hook} title={t(`dataviz:chartTitles.${item.title}`)} />}
        </div>
      })}
    </div>
    <Box className='mt-32' />
  </AppLayout>
}

export default Dataviz