// React libs
import React, { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
// Components
import ActiveButton from '../../../Core/Components/UiKit/Button/ActiveButton';
import AppLayout from '../../../App/Components/Layout/AppLayout/AppLayout'
import ContactsTable, { COLUMN_IDS } from '../../../Core/Components/Form/Contacts/Contacts';
// Types
import * as Types from './Contacts.type'
import * as CoreTypes from '../../../Core/Data/Models/Core.type'
// Hooks
import usePersons from '../../../Core/Data/Hooks/Persons';
import usePois from '../../../Core/Data/Hooks/Pois';
import usePoiLinks from '../../../Core/Data/Hooks/PoiLinks';
// Utils
import useConfirmMessageModal from '../../../Core/Utils/useConfirmMessageModal';
import useServiceErrorHandler from '../../../Core/Utils/useServiceErrorHandler';
import { deletePoi } from '../../../Core/Utils/ServiceHelpers'
import { ifDef } from '../../../Core/Utils/Misc';
// Services
import CommonService from '../../../Core/Data/Services/CommonService';
// Common
import Common from '../../../App/Resources/Common'

const Contacts: FC<Types.IProps> = () => {
  // state
  const [contactsFilter, setContactsFilter] = useState<any>()

  // Variables
  const { t } = useTranslation(['contacts']);
  const history = useHistory()
  const persons = usePersons(contactsFilter)
  const pois = usePois()
  const poisLinks = usePoiLinks()
  const handleServiceError = useServiceErrorHandler()
  const GenericConfirmModal = useConfirmMessageModal()

  // Handlers
  const onAddNewContact = useCallback(() => {
    history.push(`/${Common.Routes.routeCreateContact}`)
  }, [history])
  const onEditContact = useCallback((person: any) => {
    history.push(`/${Common.Routes.routeUpdateContact}/${person.id}`)
  }, [history])
  const getPersonLinks = useCallback((person: any) => {
    const createdPois: CoreTypes.IPoi[] = []
    const associatedPois: CoreTypes.IPoi[] = []
    const poisContributor: CoreTypes.IPoi[] = []

    pois.data?.forEach(poi => {
      if (poi.createdBy === person.account?.subjectId) {
        createdPois.push(poi)
      }
      if (poi.persons?.some(candidate => candidate.person.id === person.id)) {
        associatedPois.push(poi)
      }
      if (poi.allowedPersons?.some(candidate => candidate.person.id === person.id)) {
        poisContributor.push(poi)
      }
    })

    if ([createdPois, associatedPois, poisContributor].some(collection => collection.length > 0)) {
      return {
        createdPois,
        associatedPois,
        poisContributor
      }
    }
  }, [pois.data])
  const deletePerson = useCallback(async (person: any) => {
    await CommonService.deletePerson(person.id).catch(handleServiceError)
    persons.refresh()
  }, [handleServiceError, persons])
  const deletePersonWithLinks = useCallback(async (person, { createdPois, associatedPois, poisContributor }) => {
    try {
      await Promise.all([
        ...createdPois.map((poi: any) => deletePoi(poi, poisLinks.data ?? [])),
        ...associatedPois.map((poi: any) => CommonService.deletePoiAssociatedPerson(poi.id, person.id)),
        ...poisContributor.map((poi: any) => CommonService.deletePoiAllowedPerson(poi.id, person.id))
      ])
      await deletePerson(person)
    } catch (error) {
      handleServiceError(error)
    }
  }, [deletePerson, handleServiceError, poisLinks.data])
  const onDeleteContact = useCallback((person: any) => {
    const personLinks = getPersonLinks(person)
    if (personLinks !== undefined) {
      GenericConfirmModal.openModal(t('contacts:delete.warningMessage')).then(() => deletePersonWithLinks(person, personLinks), () => { })
    } else {
      GenericConfirmModal.openModal(t('contacts:delete.message')).then(() => deletePerson(person), () => { })
    }
  }, [GenericConfirmModal, deletePerson, deletePersonWithLinks, getPersonLinks, t])
  const onDeleteAllContacts = useCallback(
    ids => GenericConfirmModal.openModal(t(`contacts:delete.multipleMessage_${ids.length === 1 ? 'one' : 'other'}`, { count: ids.length }))
      .then(
        () => Promise.all(ids.map((id: any) =>
          ifDef(
            persons.data?.find(person => person.id === id),
            (person: any) =>
              ifDef(
                getPersonLinks(person),
                (personLinks: any) => deletePersonWithLinks(person, personLinks),
                () => deletePerson(person)
              )
          )
        )),
        () => { }
      ),
    [GenericConfirmModal, deletePerson, deletePersonWithLinks, getPersonLinks, persons.data, t]
  )
  const columnPredicate = useCallback((column: any) => column.id !== COLUMN_IDS.addAction, [])
  const toggleContactsFilter = useCallback(() => {
    setContactsFilter((filter: any) => filter === undefined ? {
      fkaccount: {
        $isnotnull: true
      }
    } : undefined)
  }, [])

  return <AppLayout
    headerConf={{
      title: {
        label: t('contacts:title'),
        icon: 'users'
      }
    }}
    isLoading={persons.isLoading}
    loadingMessages={[t('contacts:loadingMessage')]}
    className='h-full w-full p-2 overflow-auto'
  >
    <div className='text-right h-0.5/10'>
      <ActiveButton
        handler={toggleContactsFilter}
        icon='key'
        isActive={contactsFilter !== undefined}
        label={t('contacts:users')}
      />
    </div>
    <div className='h-9.5/10'>
      <ContactsTable
        contacts={persons.data}
        columnPredicate={columnPredicate}
        onAddNewContact={onAddNewContact}
        onDeleteContact={onDeleteContact}
        onDeleteAllContacts={onDeleteAllContacts}
        onEditContact={onEditContact}
        onRowDblClick={onEditContact}
        className='dataTable-contacts-container'
      />
    </div>
    {GenericConfirmModal.modal}
  </AppLayout >
}

export default Contacts