// React libs
import React, { FC } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
// Components
import FaIcon from '../../Icon/FaIcon/FaIcon';
// Types
import * as Types from './GenericIconButton.type'

const GenericIconButton: FC<Types.IProps> = ({ ariaLabel, onClick, icon, iconClassName, iconType, tooltip, disabled, ...props }) => {
  const button = <span data-testid='GenericIconButton'>
    <IconButton
      {...props}
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={disabled}
    >
      <FaIcon name={icon} className={iconClassName} type={iconType} />
    </IconButton>
  </span>
  return tooltip !== undefined
    ? <Tooltip title={tooltip}>
      {button}
    </Tooltip>
    : button
}

export default GenericIconButton