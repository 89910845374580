// React libs
import React, { FC } from 'react';
import { View } from '@react-pdf/renderer';
// Types
import * as Types from './PdfBody.type';
// Common
import Common from '../../../Resources/Common';

const STYLES = {
  body: [
    Common.PdfStyles.centerRowItems,
    {
      marginVertical: 'auto',
      width: '100%',
    }
  ],
  contentsWrap: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  content: {
    maxHeight: '70vh',
    objectFit: 'contain',
    padding: '1rem'
  }
}

const PdfBody: FC<Types.IProps> = ({ content }) => {
  const contents = React.useMemo(() => Array.isArray(content) ? content : [content], [content])
  return <View style={STYLES.body}>
    <View style={STYLES.contentsWrap}>
      {contents.map((content, key) => <View key={key} style={{
        ...STYLES.content,
        width: `${Math.round(94 / contents.length) - 1}vw`,
      }}>{content}</View>)}
    </View>
  </View>
}


export default PdfBody