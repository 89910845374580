// React libs
import React, { FC, useState } from 'react';
import { Field, Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  useMapEvents,
} from 'react-leaflet';
import 'leaflet-draw';
import L from 'leaflet';
// Components
import Button from '../../../../../Core/Components/UiKit/Button/Button';
import FaIcon from '../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import HelperTooltip from '../../../../../Core/Components/UiKit/Form/HelperTooltip/HelperTooltip';
import TextField from '../../../../../Core/Components/UiKit/Form/TextField/TextField';
import Typography from '../../../../../Core/Components/UiKit/Typography/Typography';
import Autocomplete from '../../../../../Core/Components/UiKit/Form/Autocomplete/Autocomplete';
import Separator from '../../../../../Core/Components/UiKit/Separator/Separator';
// Hooks
import useCountries from '../../../Data/Hooks/Countries';
// Type
import * as Types from './MapConfigForm.type';
import * as AutocompleteTypes from '../../../../../Core/Components/UiKit/Form/Autocomplete/Autocomplete.type';
import * as MapTypes from '../../../Data/Models/Map.type';
// Utils
import {
  getFormPropTypes,
  isDisabled,
} from '../../../../../Core/Utils/FormUtils';
import { translateStringCenter } from '../../../Utils/Map';
// CSS
import 'leaflet/dist/leaflet.css';

const MapConfigForm: FC<Types.IProps> = ({
  defaultValues,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
  >
    {(formikProps: FormikProps<any>) => (
      <MapConfigFormikForm {...formikProps} miscFunctions={miscFunctions} />
    )}
  </Formik>
);

MapConfigForm.propTypes = getFormPropTypes();

export default MapConfigForm;

const MapConfigFormikForm = ({
  errors,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
  miscFunctions,
}: FormikProps<any> & any) => {
  // Types
  type TMapMode = 'position' | 'bounds' | undefined;

  // Variables
  const { t } = useTranslation(['common', 'map']);
  const mapCenter = translateStringCenter(values.mapConfigCenter);
  const drawnItems = new L.FeatureGroup();
  const drawControlFull = new (L.Control as any).Draw({
    draw: {
      circle: false,
      circlemarker: false,
      marker: false,
      polygon: false,
      polyline: false,
    },
    edit: {
      featureGroup: drawnItems,
    },
  });
  const drawControlEditOnly = new (L.Control as any).Draw({
    edit: {
      featureGroup: drawnItems,
    },
    draw: false,
  });
  const LocationMarker = ({
    onLocationChange,
  }: {
    onLocationChange: (...args: any) => void;
  }) => {
    useMapEvents({
      click(e: any) {
        onLocationChange(e.latlng);
      },
    });

    return (
      <Marker
        position={userPosition}
        draggable={false}
        ref={(marker: any) => {
          if (marker) {
            window.setTimeout(() => {
              marker.openPopup();
            });
          }
        }}
      >
        <Popup>
          <div>{`Latitude : ${userPosition[0]}`}</div>
          <div>{`Longitude : ${userPosition[1]}`}</div>
        </Popup>
      </Marker>
    );
  };

  // Hooks
  const countriesOptions = useCountries();

  // State
  const [isMapOpen, setMapOpen]: [boolean, Function] = useState(false);
  const [mapMode, setMapMode]: [TMapMode, Function] = useState(undefined);
  const [userPosition, setUserPosition]: [
    [number, number],
    Function
  ] = useState(mapCenter);
  const [isBoundsValidationBtnEnabled, setBoundsValidationBtnEnabled]: [
    boolean,
    Function
  ] = useState(true);

  // Handlers
  const displayMap = (mode: 'position' | 'bounds') => {
    if (!isMapOpen) {
      setMapMode(mode);
      setMapOpen(true);
    }
  };
  const onMapCreated = (map: any) => {
    if (mapMode === 'bounds') {
      if (values.mapConfigBoundaries) {
        const coords = JSON.parse(values.mapConfigBoundaries);
        const shape = new L.Rectangle(coords, {
          color: '#3388ff',
          opacity: 0.5,
          fillOpacity: 0.2,
          weight: 4,
        });
        drawnItems.addLayer(shape);
        map.addControl(drawControlEditOnly);
      } else {
        map.addControl(drawControlFull);
      }

      map.on((L as any).Draw.Event.CREATED, (e: any) =>
        onBoundsCreated(
          e,
          map,
          drawnItems,
          drawControlFull,
          drawControlEditOnly
        )
      );
      map.on((L as any).Draw.Event.EDITSTART, (e: any) =>
        setBoundsValidationBtnEnabled(false)
      );
      map.on((L as any).Draw.Event.EDITSTOP, (e: any) =>
        setBoundsValidationBtnEnabled(true)
      );
      map.on((L as any).Draw.Event.EDITED, (e: any) =>
        onBoundsEdited(drawnItems)
      );
      map.on((L as any).Draw.Event.DELETED, (e: any) =>
        onBoundsDeleted(map, drawnItems, drawControlFull, drawControlEditOnly)
      );
      drawnItems.addTo(map);
    }
  };
  const onBoundsCreated = (
    e: any,
    map: any,
    drawnItems: any,
    drawControlFull: any,
    drawControlEditOnly: any
  ) => {
    const layer = e.layer;
    drawnItems.addLayer(layer);
    updateMapBounds(layer);
    drawControlFull.remove(map);
    drawControlEditOnly.addTo(map);
  };
  const onBoundsEdited = (drawnItems: any) => {
    const layer = drawnItems.getLayers()[0];
    updateMapBounds(layer);
  };
  const updateMapBounds = (layer: any) => {
    const coords = layer.getLatLngs()[0];
    const value = `[${coords.reduce(
      (result: string, value: any, index: number) =>
        `${result}${index > 0 ? ',' : ''}[${value.lat},${value.lng}]`,
      '' // init value
    )}]`;
    setFieldValue('mapConfigBoundaries', value);
  };
  const onBoundsDeleted = (
    map: any,
    drawnItems: any,
    drawControlFull: any,
    drawControlEditOnly: any
  ) => {
    setFieldValue('mapConfigBoundaries', '');
    if (drawnItems.getLayers().length === 0) {
      drawControlEditOnly.remove(map);
      drawControlFull.addTo(map);
    }
  };
  const updateUserLocation = (newLocation: { lat: number; lng: number }) => {
    setUserPosition([newLocation.lat, newLocation.lng]);
    setFieldValue(
      'mapConfigCenter',
      `[${newLocation.lat}, ${newLocation.lng}]`
    );
  };

  // Renders
  const renderGeospatialPart = () => (
    <div className='flex flex-1 flex-col'>
      <div className='flex flex-col'>
        <Typography variant='h4' className='mb-2'>
          {t('map:modale.updateConfig.geospatial.title')}
        </Typography>
        <div className='flex items-center'>
          <Field
            id='mapConfigCenter'
            name='mapConfigCenter'
            component={TextField}
            label={t('map:modale.updateConfig.geospatial.form.center.label')}
            disabled
            color='secondary'
            icon={{
              component: 'map-marker',
              position: 'end',
              onClick: () => displayMap('position'),
            }}
          />
          <HelperTooltip
            message={t(
              'map:modale.updateConfig.geospatial.form.center.tooltip'
            )}
          />
        </div>
        <div className='flex items-center'>
          <Field
            id='mapConfigBoundaries'
            name='mapConfigBoundaries'
            component={TextField}
            label={t('map:modale.updateConfig.geospatial.form.limits.label')}
            disabled
            color='secondary'
            icon={{
              component: 'window-maximize',
              position: 'end',
              onClick: () => displayMap('bounds'),
            }}
          />
          <HelperTooltip
            message={t(
              'map:modale.updateConfig.geospatial.form.limits.tooltip'
            )}
          />
        </div>
        <div className='flex items-center'>
          <Field
            id='mapConfigLayerToken'
            name='mapConfigLayerToken'
            component={TextField}
            label={t('map:modale.updateConfig.geospatial.form.mapboxKey.label')}
            color='secondary'
          />
          <HelperTooltip
            message={t(
              'map:modale.updateConfig.geospatial.form.mapboxKey.tooltip'
            )}
          />
        </div>
      </div>
      <div className='flex -mx-1'>
        <div className='flex items-center'>
          <Field
            id='mapConfigMinZoom'
            name='mapConfigMinZoom'
            component={TextField}
            type='number'
            label={t('map:modale.updateConfig.geospatial.form.zoom.min.label')}
            color='secondary'
            className='mx-1'
          />
          <HelperTooltip
            message={t(
              'map:modale.updateConfig.geospatial.form.zoom.min.tooltip'
            )}
          />
        </div>
        <div className='flex items-center'>
          <Field
            id='mapConfigMaxZoom'
            name='mapConfigMaxZoom'
            component={TextField}
            type='number'
            label={t('map:modale.updateConfig.geospatial.form.zoom.max.label')}
            color='secondary'
            className='mx-1'
          />
          <HelperTooltip
            message={t(
              'map:modale.updateConfig.geospatial.form.zoom.max.tooltip'
            )}
          />
        </div>
        <div className='flex items-center'>
          <Field
            id='mapConfigZoom'
            name='mapConfigZoom'
            component={TextField}
            type='number'
            label={t(
              'map:modale.updateConfig.geospatial.form.zoom.default.label'
            )}
            color='secondary'
            className='mx-1'
          />
          <HelperTooltip
            message={t(
              'map:modale.updateConfig.geospatial.form.zoom.default.tooltip'
            )}
          />
        </div>
      </div>
    </div>
  );

  const renderMiscPart = () => (
    <div className='flex flex-1 flex-col'>
      <Typography variant='h4' className='mb-2'>
        {t('map:modale.updateConfig.misc.title')}
      </Typography>
      <div className='flex flex-1 flex-col justify-center'>
        <div className='flex items-center'>
          <Field
            id='mapConfigUpdateInterval'
            name='mapConfigUpdateInterval'
            component={TextField}
            type='number'
            label={t('map:modale.updateConfig.misc.form.refresh.label')}
            color='secondary'
          />
          <HelperTooltip
            message={t('map:modale.updateConfig.misc.form.refresh.tooltip')}
          />
        </div>
        {!countriesOptions.isLoading && (
          <div className='flex items-center'>
            <Field
              multiple
              id='mapConfigGeocodageCountry'
              name='mapConfigGeocodageCountry'
              label={t('map:modale.updateConfig.misc.form.searchCountry.label')}
              color='secondary'
              component={Autocomplete}
              options={countriesOptions.data?.reduce(
                (list: AutocompleteTypes.IOption[], c: MapTypes.ICountry) => {
                  list.push({ label: c.label, value: c.id });
                  return list;
                },
                []
              )}
            />
            <HelperTooltip
              message={t(
                'map:modale.updateConfig.misc.form.searchCountry.tooltip'
              )}
            />
          </div>
        )}
      </div>
    </div>
  );

  const renderMapPart = () => (
    <div className='flex flex-1 flex-col my-4'>
      <Typography variant='h5' className='mb-1'>
        {mapMode === 'position' &&
          t('map:modale.updateConfig.geospatial.map.position.title')}
        {mapMode === 'bounds' &&
          t('map:modale.updateConfig.geospatial.map.bounds.title')}
      </Typography>
      <div className='flex-1'>
        <MapContainer
          center={mapCenter}
          zoom={values.mapConfigZoom}
          zoomControl={false}
          className='h-full w-full'
          whenCreated={onMapCreated}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          {mapMode === 'position' && (
            <LocationMarker onLocationChange={updateUserLocation} />
          )}
          <ZoomControl position='topright' />
        </MapContainer>
      </div>
      <div className='flex justify-center mt-2'>
        <Button
          variant='outlined'
          size='small'
          onClick={() => {
            setMapMode(undefined);
            setMapOpen(false);
            let touchedField = '';
            if (mapMode === 'position') {
              touchedField = 'mapConfigCenter';
            } else if (mapMode === 'bounds') {
              touchedField = 'mapConfigBoundaries';
            }
            if (touchedField) {
              setFieldTouched(touchedField, true);
            }
          }}
          disabled={mapMode === 'bounds' && !isBoundsValidationBtnEnabled}
        >
          {mapMode === 'position' &&
            t('map:modale.updateConfig.geospatial.map.position.validate')}
          {mapMode === 'bounds' &&
            t('map:modale.updateConfig.geospatial.map.bounds.validate')}
        </Button>
      </div>
    </div>
  );

  return (
    <Form data-testid='map-config-form'>
      <div className='flex flex-col md:flex-row'>
        {renderGeospatialPart()}
        <Separator type='vertical' className='hidden md:flex mx-4' />
        {isMapOpen ? renderMapPart() : renderMiscPart()}
      </div>
      <div className='flex items-center justify-center mt-4'>
        <Button variant='text' size='large' onClick={miscFunctions?.cancel}>
          {t('map:modale.updateConfig.cancel')}
        </Button>
        <Button
          type='submit'
          variant='text'
          size='large'
          disabled={isDisabled(errors, isSubmitting, touched)}
          startIcon={
            isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : null
          }
        >
          {isSubmitting
            ? t('map:modale.updateConfig.submit.label.submitting')
            : t('map:modale.updateConfig.submit.label.normal')}
        </Button>
      </div>
    </Form>
  );
};
