// React libs
import React, { FC, Fragment, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
//Components
import Button from '../../../../../Core/Components/UiKit/Button/Button';
import FaIcon from '../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import LocalLoader from '../../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import DataTable from '../../../../../Core/Components/UiKit/DataTable/DataTable';
import ConfirmModale from '../../../../../Core/Components/UiKit/Modales/ConfirmModale/ConfirmModale';
import Typography from '../../../../../Core/Components/UiKit/Typography/Typography';
// Hooks
import useMapLayers, { IGetLayersHook } from '../../../Data/Hooks/MapLayers';
// Type
import * as Types from './LayersList.type';
import * as CoreTypes from '../../../../../Core/Data/Models/Core.type';
import * as MapTypes from '../../../Data/Models/Map.type';
import * as DataTableTypes from '../../../../../Core/Components/UiKit/DataTable/DataTable.type';
// Common
import CoreCommon from '../../../../../Core/Resources/Common';
// Utils
import * as serviceHelpers from '../../../Utils/Services'
import { ifDef } from '../../../../../Core/Utils/Misc';

const LayersList: FC<Types.IProps> = ({
  layerEditing,
  needRefresh,
  onOpenEdit,
}) => {
  // Variables
  const { t } = useTranslation(['admin']);
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [isDeleting, setIsDeleting]: [boolean, Function] = useState<boolean>(
    false
  );
  const [refreshNeeded, setRefreshNeeded]: [boolean, Function] = useState<
    boolean
  >(needRefresh);
  const [layerDeleting, setLayerDeleting]: [
    MapTypes.ILayer | undefined,
    Function
  ] = useState<MapTypes.ILayer | undefined>(undefined);

  // Effects
  useEffect(() => {
    const updateNeedRefresh = () => {
      setRefreshNeeded(needRefresh);
    };
    updateNeedRefresh();
  }, [needRefresh]);
  useEffect(() => {
    const updateRefreshNeeded = () => {
      setRefreshNeeded(false);
    };
    if (refreshNeeded) updateRefreshNeeded();
  }, [refreshNeeded]);

  // Hooks
  const layers: IGetLayersHook = useMapLayers(refreshNeeded);

  // Getters
  const isLoading = () => {
    return isDeleting || layers.isLoading;
  };

  // Handlers
  const askToDelete = (e: any, l: MapTypes.ILayer) => {
    e.stopPropagation();
    setLayerDeleting(l);
  };
  const handlerConfirmClose = (id: string | undefined, result: boolean) => {
    setLayerDeleting(undefined);
    if (result && id) {
      deleteLayer(id);
    }
  };
  const deleteLayer = (id: string) => {
    setIsDeleting(true);
    ifDef(layers.data?.data.find(layer => layer.id === id), (layer: MapTypes.ILayer) => serviceHelpers.deleteLayer(layer)
      .then(() => {
        setIsDeleting(false);
        setRefreshNeeded(true);
      })
      .catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
        setIsDeleting(false);
      }))
  };

  // Getters
  const getColumnsConf = (): DataTableTypes.ITableColumn[] => [
    {
      id: 'name',
      field: 'name',
      name: t('admin:layers.list.columns.name.label'),
      renderCell: (params: DataTableTypes.ITableRow) => {
        return (
          <div className='flex'>
            {params.default ? (
              <Typography variant='h6'>{`${params.name} ${t(
                'admin:layers.list.columns.name.default'
              )}`}</Typography>
            ) : (
              <div>{params.name}</div>
            )}
          </div>
        );
      },
    },
    {
      id: 'thematic',
      field: 'thematic',
      name: t('admin:layers.list.columns.thematic.label'),
      align: 'center',
      headerAlign: 'center',
    },
    {
      id: 'zone',
      field: 'zone',
      name: t('admin:layers.list.columns.zone.label'),
      align: 'center',
      headerAlign: 'center',
    },
    {
      id: 'subThematic',
      field: 'subThematic',
      name: t('admin:layers.list.columns.subThematic.label'),
      align: 'center',
      headerAlign: 'center',
    },
    {
      id: 'order',
      field: 'order',
      name: t('admin:layers.list.columns.order.label'),
      align: 'center',
      orderBy: 'asc',
      headerAlign: 'center',
    },
    {
      id: 'action',
      field: 'id',
      name: t('admin:layers.list.columns.actions.label'),
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: DataTableTypes.ITableRow) => {
        return (
          <div className='flex items-center justify-center'>
            <IconButton
              aria-label='delete'
              onClick={e => askToDelete(e, params as MapTypes.ILayer)}
            >
              <FaIcon name='trash' />
            </IconButton>
          </div>
        );
      },
    },
  ];
  const getFormattedRows = (): DataTableTypes.ITableRow[] =>
    layers.data?.data ? layers.data.data : [];
  const getLayperMapImageDetails = (
    image: MapTypes.ILayerMapImage | undefined
  ) => {
    return image ? (
      <div
        key={image.image.id}
        className='flex flex-col overflow-hidden p-2 w-1/3'
      >
        <div className='my-1'>{image.legend}</div>
        <div className='my-1'>
          <img src={`/api/images/${image.image.id}/media`} alt='legend' />
        </div>
      </div>
    ) : null;
  };
  const getRowDetails = (l: MapTypes.ILayer) => {
    return (
      <div className='flex flex-col my-2'>
        <div className='flex flex-col'>
          <Typography variant='h4' className='font-bold my-2'>
            {t('admin:layers.list.data.tile.title')}
          </Typography>
          <div className='flex items-center justify-start mx-4'>
            <img
              src={l.thumbnailUrl}
              className='max-w-24'
              alt={t('admin:layers.list.data.tile.imgPreview.alt')}
            />
            <Typography variant='h6' className='mx-2 break-all'>
              {l.url}
            </Typography>
          </div>
        </div>
        {l.images && (
          <div className='flex flex-col my-2'>
            <Typography variant='h4' className='font-bold my-2'>
              {t('admin:layers.list.data.legend.title')}
            </Typography>
            <div className='flex items-center justify-start mx-4'>
              {['analysis', 'method', 'knowMore'].map((key: string) =>
                getLayperMapImageDetails(
                  l.images.find(
                    (i: MapTypes.ILayerMapImage) => i.legendType === key
                  )
                )
              )}
            </div>
          </div>
        )}

        <div className='flex items-center justify-end m-4'>
          <Button
            variant='outlined'
            size='small'
            startIcon={<FaIcon name='pencil' />}
            disabled={layerEditing === l.id}
            onClick={() => onOpenEdit(l)}
          >
            {layerEditing === l.id
              ? t('admin:layers.list.data.btn.edit.editing')
              : t('admin:layers.list.data.btn.edit.normal')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className='h-full w-full' data-testid='admin-layers-list'>
      {isLoading() ? (
        <div className='flex h-full items-center justify-center w-full'>
          <LocalLoader
            message={t('admin:layers.list.loading')}
            type='contained'
          />
        </div>
      ) : (
        layers.data?.data && (
          <Fragment>
            <DataTable
              columns={getColumnsConf()}
              isCollapsible={true}
              getRowDetails={getRowDetails}
              rows={getFormattedRows()}
              onCreate={() => onOpenEdit(-1)}
              color='primary'
            />
          </Fragment>
        )
      )}
      <ConfirmModale
        id={layerDeleting?.id}
        message={t('admin:layers.delete.message', {
          name: layerDeleting?.name,
        })}
        isOpened={typeof layerDeleting !== 'undefined'}
        handleClose={handlerConfirmClose}
      />
    </div>
  );
};

LayersList.propTypes = {
  layerEditing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  needRefresh: PropTypes.bool.isRequired,
  onOpenEdit: PropTypes.func.isRequired,
};

export default LayersList;
