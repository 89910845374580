// React libs
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../Button'
import FaIcon from '../../Icon/FaIcon/FaIcon'
// Type
import * as Types from './ExportButton.type'

const ExportButton: FC<Types.IProps> = ({ pending = false, ...props }) => {
  // Variables
  const { t } = useTranslation(['common'])

  return <Button
    color='secondary'
    data-testid='ExportButton'
    size='small'
    className='flex items-center'
    {...props}
  >
    {pending
      ? <FaIcon name='spinner' className='fa-spin text-l mr-1' />
      : <FaIcon name='file-download' type='fas' className='text-lg mr-1' />
    }
    <div className='relative'>{t('common:forms.buttons.export')}</div>
  </Button>
}


export default ExportButton
