// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Hooks
import useGenericData, { IGetGenericData } from './GenericData';

export interface IGetFundTypes extends IGetGenericData<CoreTypes.IFundType> {
}

const useFundTypes = (): IGetFundTypes => {
  return useGenericData(() => CommonService.getFundTypes())
}

export default useFundTypes