import Constantes from './Constantes';
import Routes from './Routes';
import Texts from './Texts';

const Common = {
  Constantes,
  Routes,
  Texts,
};

export default Common;
