// React libs
import React, { FC } from 'react';
import PropTypes from 'prop-types';
// Layout
import BaseLayout from '../BaseLayout/BaseLayout';
// Components
import BaseCard from '../../../../Core/Components/UiKit/Card/BaseCard/BaseCard';
import Typography from '../../../../Core/Components/UiKit/Typography/Typography';
// Type
import * as Types from './AuthLayout.type';
// Images
import BACKGROUND from '../../../Resources/assets/img/login/background.jpg';
import LOGO from '../../../Resources/assets/img/common/logo.png';
// Package.json
import { version } from '../../../../../package.json';

const AuthLayout: FC<Types.IProps> = ({ children, className }) => {
  const renderContent = () => (
    <div>
      <img className='h-20' src={LOGO} alt='logo' />
      {children}
      <div className='flex items-center justify-between mt-4'>
        <Typography
          variant='caption'
          className='text-gray-500 '
        >{`V${version}`}</Typography>
        <Typography variant='caption' className='text-gray-500 '>
          www.mapinaction.com
        </Typography>
      </div>
    </div>
  );
  return (
    <BaseLayout>
      <img
        className='absolute h-full object-cover object-left w-full'
        src={BACKGROUND}
        alt='Background'
        data-testid='auth-layout'
      />
      <div className='absolute bg-gray-1000 h-full opacity-25 w-full z-10' />
      <div className='flex flex-col h-9/10 items-center justify-center w-11/12 sm:w-8/12 lg:w-1/3 z-20'>
        <BaseCard content={renderContent()} type='primary' />
      </div>
    </BaseLayout>
  );
};

AuthLayout.propTypes = {
  className: PropTypes.string,
};

AuthLayout.defaultProps = {
  className: '',
};

export default AuthLayout;
