// React libs
import React, { FC, Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Button, Menu, MenuItem } from '@material-ui/core';
// Components
import FaIcon from '../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import MapConfigModale from '../../../Dimensions/Map/Components/Modales/MapConfig/MapConfigModale';
import UserLogoutConfirmModal from '../../../Dimensions/Map/Components/Modales/UserLogout/UserLogoutConfirmModal'
// Context
import UserContext, {
  IUserContext,
} from '../../../Core/Data/Contexts/UserContext';
//Services
import AuthService from '../../Data/Services/AuthService';
import LocalStorage from '../../../Core/Data/Services/Storage/LocalStorage';
// Type
import * as Types from './Header.type';
import * as CoreTypes from '../../../Core/Data/Models/Core.type';
// Common
import Common from '../../Resources/Common';
import CoreCommon from '../../../Core/Resources/Common';
// Utils
import { isAdmin, isWriter } from '../../../Core/Utils/User';
import useConfirmModal from '../../../Core/Utils/useConfirmModal';
// Images
import LOGO from '../../Resources/assets/img/common/logo-white.png';
// Package.json
import { version } from '../../../../package.json';

const Header: FC<Types.IProps> = ({ title, methods }) => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const buttonClasses = 'min-w-0 p-1 text-white border border-white mx-2';
  const buttonIconClasses = 'text-2xl';
  const menuIconClasses = 'mr-2 w-5';
  const menuKeys = {
    app: 'app',
    logout: 'logout',
    map: 'map',
    profile: 'profile',
  };
  const menuProps: any = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    PopoverClasses: {
      root: 'z-1600',
    },
  };

  // State
  const [anchorParamEl, setAnchorParamEl] = useState<null | HTMLElement>(null);
  const [anchorUserEl, setAnchorUserEl] = useState<null | HTMLElement>(null);
  const [isMapConfigOpened, setIsMapConfigOpened]: [
    boolean,
    Function
  ] = useState<boolean>(false);
  const userLogoutModal = useConfirmModal()

  // Handlers
  const onLogout = async (updateUser: (...args: any) => void) => {
    userLogoutModal.openModal().then(() => {
      AuthService.logout()
        .then(() => {
          LocalStorage.delete(LocalStorage.keys.userId);
          LocalStorage.delete(LocalStorage.keys.user);
          updateUser(undefined);
          history.push(`/${Common.Routes.routeHome}`);
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
        });
    }, () => { })
  };
  const handleParamOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorParamEl(event.currentTarget);
  };
  const handleParamClose = (menu: string = '') => {
    setAnchorParamEl(null);
    switch (menu) {
      case menuKeys.app:
        history.push(`/${Common.Routes.routeAdmin}`);
        break;
      case menuKeys.map:
        setIsMapConfigOpened(true);
        break;
    }
  };
  const handleUserOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorUserEl(event.currentTarget);
  };
  const handleUserClose = (
    menu: string = '',
    callback?: (...args: any) => void
  ) => {
    setAnchorUserEl(null);
    switch (menu) {
      case menuKeys.logout:
        onLogout(callback || (() => { }));
        break;
      case menuKeys.profile:
        history.push(`/${Common.Routes.routeUpdateContact}`);
        break;
    }
  };
  const onMapConfigClose = () => {
    setIsMapConfigOpened(false);
  };

  // Renders
  const getParamMenu = () => (
    <Fragment>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        className={buttonClasses}
        onClick={handleParamOpen}
      >
        <FaIcon name='cog' className={buttonIconClasses} />
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorParamEl}
        keepMounted
        open={Boolean(anchorParamEl)}
        onClose={() => handleParamClose('')}
        {...menuProps}
      >
        <MenuItem onClick={() => handleParamClose(menuKeys.map)}>
          <FaIcon name='map' className={menuIconClasses} />
          {t('common:header.menu.param.map')}
        </MenuItem>
        <MenuItem onClick={() => handleParamClose(menuKeys.app)}>
          <FaIcon name='database' className={menuIconClasses} />
          {t('common:header.menu.param.app')}
        </MenuItem>
      </Menu>
    </Fragment>
  );
  const getUserMenu = (
    user: CoreTypes.IUser,
    updateUser: (...args: any) => void
  ) => (
    <Fragment>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        className={buttonClasses}
        onClick={handleUserOpen}
      >
        <FaIcon name='user' className={buttonIconClasses} />
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorUserEl}
        keepMounted
        open={Boolean(anchorUserEl)}
        onClose={() => handleUserClose('')}
        getContentAnchorEl={null}
        {...menuProps}
      >
        {(isAdmin(user) || isWriter(user)) && (
          <MenuItem
            onClick={() => handleUserClose(menuKeys.profile, undefined)}
          >
            <FaIcon name='info' className={menuIconClasses} />
            {t('common:header.menu.user.profile')}
          </MenuItem>
        )}

        <MenuItem onClick={() => handleUserClose(menuKeys.logout, updateUser)}>
          <FaIcon name='sign-out' className={menuIconClasses} />
          {t('common:header.menu.user.logout')}
        </MenuItem>
      </Menu>
    </Fragment>
  );

  return (
    <div
      className='bg-red-610 flex h-16 items-center justify-between p-0 sm:p-2 text-white w-full'
      data-testid='header'
    >
      <Link
        className='flex h-full items-center relative w-24'
        to={`/${Common.Routes.routeHome}`}
      >
        <img src={LOGO} alt='logo' />
        <div className='absolute bottom-0 right-0  text-2xs'>{version}</div>
      </Link>
      <div className='flex-1 text-center'>{title}</div>
      <div className='flex justify-end w-24'>
        <UserContext.Consumer>
          {({ user, updateUser }: IUserContext) =>
            user && (
              <Fragment>
                {isAdmin(user) && getParamMenu()}
                {getUserMenu(user, updateUser)}
              </Fragment>
            )
          }
        </UserContext.Consumer>
      </div>

      <MapConfigModale
        handleClose={onMapConfigClose}
        isOpened={isMapConfigOpened}
      />

      <UserLogoutConfirmModal
        handleClose={userLogoutModal.onClose}
        isOpened={userLogoutModal.isOpened}
      />
    </div>
  );
};

Header.propTypes = {
  methods: PropTypes.any,
  title: PropTypes.node.isRequired || PropTypes.string.isRequired,
};
export default Header;
