// React libs
import { useState, useEffect, useCallback } from 'react';
// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Utils
import useServiceErrorHandler from '../../Utils/useServiceErrorHandler';

export interface IGetPoiHook extends CoreTypes.IHook<CoreTypes.IPoi | null> {
  refresh: () => void
  refreshTime: number
}

const usePoi = (id: string): IGetPoiHook => {
  // Variables
  const handleServiceError = useServiceErrorHandler()

  // State
  const [data, setData] = useState<CoreTypes.IPoi | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [needRefresh, setNeedRefresh] = useState<boolean>(true);
  const [refreshTime, setRefreshTime] = useState<number>(Date.now())

  // Actions
  const refresh = useCallback(() => {
    setNeedRefresh(true)
  }, [])

  // Effects
  useEffect(() => {
    if (needRefresh && !isLoading) {
      setIsLoading(true)
      CommonService.getPoi(id)
        .then((data: CoreTypes.ISingleData<CoreTypes.IPoi>) => {
          setData(data.data)
          setRefreshTime(Date.now())
        })
        .catch(handleServiceError)
        .finally(() => {
          setNeedRefresh(false)
          setIsLoading(false);
        })
    }
  }, [handleServiceError, id, isLoading, needRefresh])

  return { data, isLoading, refresh, refreshTime }
}

export default usePoi