// React libs
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Service
import MapService from '../Services/MapService';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
import * as MapTypes from '../Models/Map.type';
// Common
import CoreCommon from '../../../../Core/Resources/Common';

export interface IGetLayersHook extends CoreTypes.IHook<MapTypes.ILayersData> { }

const useMapLayers = (needRefresh: any = true, filter?: any): IGetLayersHook => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);

  // State
  const [data, setData]: [MapTypes.ILayersData | null, Function] = useState(
    null
  );
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);

  // Effects
  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      MapService.getLayers(filter)
        .then((data: MapTypes.ILayersData) => {
          setData(data);
          setIsLoading(false);
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
          setIsLoading(false);
        });
    };

    getData();
  }, [enqueueSnackbar, filter, needRefresh, t]);

  return { data, isLoading };
};

export default useMapLayers;
