// React libs
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// Components
import ExportButton from '../../../../../Core/Components/UiKit/Button/ExportButton/ExportButton';
import FormHeader from '../../../../../Core/Components/Form/FormHeader/FormHeader';
import FormTitle from '../../../../../Core/Components/Form/FormTitle/FormTitle';
import * as FormFields from '../../../../../Core/Components/Form/FormFields/FormFields';
// Types
import * as Types from './ProjectIdentityPreview.type'
// Utils
import useServiceErrorHandler from '../../../../../Core/Utils/useServiceErrorHandler';
import { formatProjectAddress, getPoiImageUrl } from '../../../../../Core/Utils/Misc';
// Services
import CommonService from '../../../../../Core/Data/Services/CommonService';

const ProjectIdentityPreview: FC<Types.IProps> = ({ project, toggleEditionMode }) => {
  // State
  const [isExporting, setIsExporting] = useState<boolean>(false)
  // Variables
  const { t } = useTranslation(['project']);
  const thematic = useMemo(() => project.thematics && project.thematics.map(({ thematic }: any) => thematic.label), [project.thematics])
  const imageUrl = project.image != null ? getPoiImageUrl(project.image.id) : `${process.env.PUBLIC_URL}/data/images/noPhoto.png`
  const currentPhase = useMemo(() => project.phases.find(({ type }: any) => type.id === project.currentPhaseType), [project.currentPhaseType, project.phases])
  const address = useMemo(() => formatProjectAddress(project.address), [project.address])
  const contributors = useMemo(() => project.allowedPersons.map(({ person }: any) => `${person.name} ${person.firstname}`), [project.allowedPersons])
  const handleServiceError = useServiceErrorHandler()
  // Handlers
  const exportProject = useCallback(async () => {
    setIsExporting(true)
    try {
      const { data: { url } } = await CommonService.exportProjectInfo(project.id)
      window.open(url, 'Download')
    } catch (error) {
      handleServiceError(error)
    } finally {
      setIsExporting(false)
    }
  }, [handleServiceError, project.id])

  return <div className='h-full w-full p-1'>
    <div className='h-0.7/10 w-full px-2 pt-1'>
      <FormHeader
        title={t('project:identity')}
        toggleEditionMode={toggleEditionMode}
        isEditionMode={false}
        actions={<ExportButton
          pending={isExporting}
          onClick={exportProject}
        />}
      />
    </div>
    <div className='h-9.3/10 w-full p-1 pt-2 overflow-auto'>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormFields.FormPreviewFieldValue label={t('project:type')} value={project.type?.label} />
          <FormFields.FormPreviewFieldValue label={t('project:name')} value={project.name} />
          <FormFields.FormPreviewFieldValue label={t('project:thematic')} value={thematic} />
        </Grid>
        <Grid item xs={6} className='flex justify-end'>
          <img src={imageUrl} alt='preview' className='h-41' />
        </Grid>
        <Grid item xs={12}>
          <FormTitle title={t('project:projectCurrentState')} />
          <div>
            <FormFields.FormPreviewFieldValue label={t('project:currentState')} value={currentPhase?.type.label} color={currentPhase?.type.style.data.markerColor} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <FormTitle title={t('project:localization')} />
          <div>
            <FormFields.FormPreviewFieldValue label={t('project:territory')} value={project.territory?.label} />
            <FormFields.FormPreviewFieldValue label={t('project:coordinates')} value={project.geo?.coordinates} />
            <FormFields.FormPreviewFieldValue label={t('project:address')} value={address} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <FormTitle title={t('project:description')} />
          <div>
            <FormFields.FormPreviewFieldValue label={t('project:comment')} value={project.comment} />
            <FormFields.FormPreviewFieldValue label={t('project:keywords')} value={project.keywords} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <FormTitle title={t('project:management')} />
          <div>
            <FormFields.FormPreviewFieldValue label={t('project:contributors')} value={contributors} />
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
}

export default ProjectIdentityPreview