// React libs
import PropTypes from 'prop-types';
import React, { FC } from 'react';
// Components
import ConfirmModal from '../../UiKit/Modales/ConfirmModale/ConfirmModale';
// Types
import * as Types from './ConfirmMessageModal.type';

const ConfirmMessageModal: FC<Types.IProps> = ({ handleClose, isOpened, message }) => {
  return <div data-testid='ConfirmMessageModal'>
    <ConfirmModal
      handleClose={handleClose}
      id='ConfirmMessageModal'
      message={message}
      isOpened={isOpened}
    />
  </div>
}

ConfirmMessageModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
}

export default ConfirmMessageModal