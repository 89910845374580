// React libs
import React, { FC, useCallback, useMemo, useState } from 'react';
import Humanize from 'humanize-plus'
import moment from 'moment'
import { ClickAwayListener } from '@material-ui/core';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
// Components
import DataTable from '../../../../../Core/Components/UiKit/DataTable/DataTable';
import DeleteButton from '../../../../../Core/Components/UiKit/Button/DeleteButton/DeleteButton';
import FaIcon from '../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import GenericIconButton from '../../../../../Core/Components/UiKit/Button/GenericIconButton/GenericIconButton';
import TextField from '../../../../../Core/Components/UiKit/Form/TextField/TextField';
import * as FormFields from '../../../../../Core/Components/Form/FormFields/FormFields';
// Types
import * as DataTableTypes from '../../../../../Core/Components/UiKit/DataTable/DataTable.type';
import * as Types from './ProjectGedTreeView.type'
import * as CoreTypes from '../../../../../Core/Data/Models/Core.type'

const InputText = ({ value, onChange }: { value: string, onChange: (value: string) => void }) => {
  const [inputValue, setInputValue] = useState(value)
  return <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => onChange(inputValue)}>
    <div>
      <TextField
        field={{
          name: 'text',
          value: inputValue,
          onChange: ({ target }: any) => setInputValue(target.value)

        }}
        form={{
          setFieldTouched: () => { },
          setFieldValue: () => { }
        }}
        color='secondary'
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            onChange(inputValue)
          }
        }}
      />
    </div>
  </ClickAwayListener>
}

const ProjectGedTreeView: FC<Types.IProps> = ({ poi, folders, actions }) => {
  // State
  const [itemsPathByIds, setItemsPathByIds] = useState<{ [id: string]: string }>({})
  const [expendedItems, setExpendedItems] = useState<{ [id: string]: boolean }>({})
  // Variables
  const { t } = useTranslation(['project'])
  const iconByType: any = {
    folder: 'folder-o',
    application: 'file-o',
    audio: 'file-audio-o',
    image: 'file-image-o',
    video: 'file-video-o',
    text: 'file-text-o',
    font: 'file-o'
  }
  const columns: any = useMemo(() => [
    {
      id: 'name',
      field: 'name',
      name: t('project:ged.name'),
      renderCell: (cell: any) => {
        const type = cell.isFile ? cell.type.split('/')[0] : 'folder'
        const label = <p><FaIcon name={iconByType[type]} className='mr-1' />{cell.name}</p>
        return actions === undefined
          ? label
          : <FormFields.FieldWrapper align='start' label={label} hideOnKeyDown={true}>
            <InputText
              value={cell.name}
              onChange={value =>
                cell.isFile
                  ? actions.onFileRename(value, cell.filePath)
                  : actions.onFolderRename(value, cell.folderPath)
              }
            />
          </FormFields.FieldWrapper>
      }
    },
    {
      id: 'type',
      field: 'type',
      name: t('project:ged.type'),
      renderCell: (cell: any) => <p>{cell.isFile ? cell.type : ''}</p>
    },
    {
      id: 'size',
      field: 'size',
      name: t('project:ged.size'),
      renderCell: (cell: any) => <p>{Humanize.fileSize(cell.size)}</p>
    },
    {
      id: 'creator',
      field: 'creator',
      name: t('project:ged.creator')
    },
    {
      id: 'creationDate',
      field: 'creationDate',
      name: t('project:ged.creationDate'),
      renderCell: (cell: any) => moment(cell.creationDate).format('DD/MM/YYYY')
    },
    {
      id: 'action',
      align: 'center',
      name: '',
      renderCell: (item: any) => <div className='flex items-center justify-center'>
        {item.isFile && !item.toCreate &&
          <GenericIconButton
            ariaLabel='download file'
            onClick={() => {
              window.open(`/api/pois/${poi.id}/files/${item.id}/media`, 'Download');
            }}
            icon='file-download'
            tooltip={t('project:ged.download')}
            size='small'
            iconType='fas'
          />
        }
        {actions !== undefined && <>
          {!item.isFile && <>
            <GenericIconButton
              ariaLabel='create folder'
              onClick={() => {
                const index = item.children?.filter((_: any) => !_.isFile).length ?? 0
                actions.onFolderAdd(`${item.folderPath}.content.folders.${index}`)
                setExpendedItems(expendedItems => ({
                  ...expendedItems,
                  [item.id]: true
                }))
              }}
              icon='folder-plus'
              tooltip={t('project:ged.addSubFolder')}
              size='small'
              iconType='fas'
            />
            <GenericIconButton
              ariaLabel='upload files'
              onClick={() => {
                const index = item.children?.filter((_: any) => _.isFile).length ?? 0
                actions.onFilesAdd(`${item.folderPath}.content.files.${index}`)
                setExpendedItems(expendedItems => ({
                  ...expendedItems,
                  [item.id]: true
                }))
              }}
              icon='file-upload'
              tooltip={t('project:ged.addFiles')}
              size='small'
              iconType='fas'
            />
          </>}
          <DeleteButton
            onClick={() => {
              const [, parentChildrenPath] = /^(.+)\.\d+$/.exec(item.isFile ? item.filePath : item.folderPath) ?? []
              actions.onDelete([{ parentChildrenPath, id: item.id }])
            }}
            size='small'
          />
        </>}
      </div>
    }
  ], [actions, iconByType, poi.id, t])
  const rows = useMemo(() => {
    const rows: DataTableTypes.ITableRow[] = []

    const sortFolders = (folders: CoreTypes.IPoiFolder[]) => sortBy(folders, folder => folder.name.toLowerCase())
    const sortFiles = (files: CoreTypes.IPoiFile[]) => sortBy(files, file => file.name.toLowerCase())

    const handleFile = (collection: any[], file: CoreTypes.IPoiFile & { toCreate?: boolean, toUpdate?: boolean, toDelete?: boolean, content?: any }, filePath: string) => {
      setItemsPathByIds(itemsPathByIds => ({
        ...itemsPathByIds,
        [file.id]: filePath
      }))

      if (!file.toDelete) {
        collection.push({
          id: file.id,
          name: file.name,
          type: file.format,
          isFile: file.isFile,
          size: file.size,
          creator: file.creator,
          creationDate: file.creation,
          toCreate: file.toCreate,
          filePath
        })
      }
    }
    const handleFolder = (collection: any[], folder: CoreTypes.IPoiFolder & { toCreate?: boolean, toUpdate?: boolean, toDelete?: boolean }, folderPath: string) => {
      setItemsPathByIds(itemsPathByIds => ({
        ...itemsPathByIds,
        [folder.id]: folderPath
      }))

      if (folder.toDelete) {
        return
      }

      const newFolder: any = {
        id: folder.id,
        name: folder.name,
        type: folder.format,
        isFile: folder.isFile,
        size: 0,
        creator: folder.creator,
        creationDate: folder.creation,
        toCreate: folder.toCreate,
        folderPath,
        children: []
      }
      if (folder.content !== undefined) {
        const { folders: foldersContent = [], files: filesContent = [] } = folder.content
        sortFolders(foldersContent).forEach(folder =>
          handleFolder(
            newFolder.children,
            folder,
            `${folderPath}.content.folders.${foldersContent.findIndex(candidate => folder.id === candidate.id)}`
          )
        )
        sortFiles(filesContent).forEach(file =>
          handleFile(
            newFolder.children,
            file,
            `${folderPath}.content.files.${filesContent.findIndex(candidate => file.id === candidate.id)}`
          )
        )

        newFolder.children.forEach((child: any) => {
          newFolder.size += child.size
        })
      } else {
        newFolder.children = undefined
      }
      collection.push(newFolder)
    }

    const { folders: foldersContent = [], files: filesContent = [] } = folders.content ?? {}
    sortFolders(foldersContent).forEach(folder =>
      handleFolder(
        rows,
        folder,
        `folders.content.folders.${foldersContent.findIndex(candidate => folder.id === candidate.id)}`
      )
    )
    sortFiles(filesContent).forEach(file =>
      handleFile(
        rows,
        file,
        `folders.content.files.${filesContent.findIndex(candidate => file.id === candidate.id)}`)
    )

    return rows
  }, [folders.content])
  const searchColumnKeys = [
    'name',
    'type',
    'size',
    'creator',
    'creationDate'
  ]

  const actionButtons = useMemo(() => actions && [
    {
      handler: () => {
        const index = folders.content?.folders?.length ?? 0
        actions.onFolderAdd(`folders.content.folders.${index}`)
      },
      label: t('project:ged.addFolder'),
      tooltip: t('project:ged.addRootFolder'),
      className: 'mr-1'
    },
    {
      handler: () => {
        const index = folders.content?.files?.length ?? 0
        actions.onFilesAdd(`folders.content.files.${index}`)
      },
      label: t('project:ged.addFiles'),
      tooltip: t('project:ged.addRootFiles'),
    },
  ], [actions, folders.content, t])

  // Handlers
  const onDeleteMultipleRows = useCallback((ids: string[]) => {
    actions?.onDelete(ids.map(id => {
      const [, parentChildrenPath] = /^(.+)\.\d+$/.exec(itemsPathByIds[id]) ?? []
      return ({
        id,
        parentChildrenPath
      })
    }))
  }, [actions, itemsPathByIds])

  const toggleItemExpanded = useCallback((id: string, expanded: boolean) => {
    setExpendedItems(expendedItems => ({
      ...expendedItems,
      [id]: expanded
    }))
  }, [])

  return <div data-testid='ProjectGedTreeView' className='h-full w-full'>
    <DataTable
      treeOptions={{
        expendedItems,
        toggleItemExpanded
      }}
      columns={columns}
      rows={rows}
      onDeleteMultipleRows={actions && (onDeleteMultipleRows as any)}
      searchColumnKeys={searchColumnKeys}
      actionButtons={actionButtons}
      containerClassName={actions && 'dataTable-poiGed-container'}
    />
  </div>
}

export default ProjectGedTreeView