// React libs
import React, { FC } from 'react';
import { useFormikContext, Form, FormikProps, Formik } from 'formik';
// Components
import { UserProfilFormikForm } from '../Auth/UserProfilForm/UserProfilForm';
import { UserAddressFormikForm } from '../Auth/AddressProfilForm/AddressProfilForm';
import { UserPasswordFormikForm } from '../Auth/PasswordProfilForm/PasswordProfilForm';
import * as FormFields from '../../../../../Core/Components/Form/FormFields/FormFields';
// Type
import * as Types from './NewContactForm.type';
// Utils
import { getFormPropTypes, isDisabled } from '../../../../../Core/Utils/FormUtils';

const NewContactForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
  >
    {(formikProps: FormikProps<any>) => (
      <NewContactFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}
  </Formik>
);

NewContactForm.propTypes = getFormPropTypes();

export default NewContactForm;

const NewContactFormikForm = (props: FormikProps<any> & any) => {
  // Variables
  const { submitForm } = useFormikContext();
  const newProps = {
    ...props,
    miscData: {
      ...props.miscData,
      nested: true,
      newContact: true
    }
  }

  return <Form data-testid='new-contact-form' className='w-full h-full overflow-auto'>
    <div className='h-9.3/10 w-full overflow-auto p-1'>
      <UserProfilFormikForm {...newProps} />
      <br />
      <UserAddressFormikForm {...newProps} />
      {props.miscData?.createAccount && <>
        <br />
        <UserPasswordFormikForm {...newProps} />
      </>}
    </div>
    <div className='h-0.7/10 w-full'>
      <FormFields.FormSubmitButtons
        isSubmitting={props.isSubmitting}
        disabled={isDisabled(props.errors, props.isSubmitting, props.touched)}
        onCancel={props.miscFunctions?.onCancel}
        onSubmit={submitForm}
        size='medium'
      />
    </div>
  </Form>
};
