// Hooks
import BudgetDistributionsMetrics from '../../Data/Hooks/useBudgetDistributionsMetrics';
import FinancingSourceMetrics from '../../Data/Hooks/useFinancingSourceMetrics';
import LinkMetrics from '../../Data/Hooks/useLinksMetrics';
import ResourceTypesMetrics from '../../Data/Hooks/useResourceTypesMetrics';
import ProjectEvolution from '../../Data/Hooks/useProjectEvolution';

export default {
  BudgetDistributionsMetrics,
  FinancingSourceMetrics,
  LinkMetrics,
  ResourceTypesMetrics,
  ProjectEvolution
}