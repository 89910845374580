// React libs
import React, { FC, useCallback, useMemo } from 'react'
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Components
import FormHeader from '../../FormHeader/FormHeader';
import PoiContactForm from '../PoiContactForm/PoiContactForm';
import PoiContactTable from '../PoiContactTable/PoiContactTable';
// Common
import CoreCommon from '../../../../Resources/Common';
import Common from '../../../../../App/Resources/Common'
// Types
import * as CoreTypes from '../../../../Data/Models/Core.type';
import * as Types from './PoiContactPage.type'
import * as PoiContactTypes from '../PoiContactForm/PoiContactForm.type'
// Utils
import useServiceErrorHandler from '../../../../Utils/useServiceErrorHandler';
// Services
import CommonService from '../../../../../Core/Data/Services/CommonService'

interface IPoiContactPageEdition {
  poi: CoreTypes.IPoi
  toggleEditionMode: () => void
  refreshPoi: () => void
  contacts: Types.IContact[]
}

const PoiContactPageEdition: FC<IPoiContactPageEdition> = ({ contacts, poi, toggleEditionMode, refreshPoi }) => {
  // Variables
  const handleServiceError = useServiceErrorHandler()
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: PoiContactTypes.IFormValues = {
    contacts
  }
  const validationSchema = Yup.object({
    contacts: Yup.array()
  })

  // Handlers
  const onSubmit = useCallback(async ({ contacts }: PoiContactTypes.IFormValues, { setSubmitting }: FormikHelpers<any>) => {
    setSubmitting(true);
    try {
      const persons: any = []
      const serviceCallFns: any = []

      contacts.forEach(contact => {
        const id = `${contact.person.id},${poi.id}`
        if (contact.toAdd) {
          persons.push({
            fkperson: contact.person.id,
            fkposition: contact.position?.id
          })
          serviceCallFns.push(() => CommonService.savePoiAssociatedPerson({
            fkpoi: poi.id,
            fkperson: contact.person.id,
            fkposition: contact.position?.id
          }))
          return
        }
        if (contact.toDelete) {
          persons.push({
            id,
            toDelete: true
          })
          serviceCallFns.push(() => CommonService.deletePoiAssociatedPerson(poi.id, contact.person.id))
          return
        }
        if (contact.position?.toUpdate || contact.position?.toDelete) {
          const value = contact.position.toUpdate ? contact.position.id : null
          persons.push({
            fkposition: value,
            id
          })
          serviceCallFns.push(() => CommonService.updatePoiAssociatedPerson(poi.id, contact.person.id, {
            fkpoi: poi.id,
            fkperson: contact.person.id,
            fkposition: value,
          }))
        }
      })

      await CommonService.updatePoi(poi.id, {
        id: poi.id,
        isProject: poi.isProject,
        persons
      })
      await Promise.all(serviceCallFns.map((fn: any) => fn()))
      enqueueSnackbar(t('common:forms.validation.saveSucceeded'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'success',
      })
    } catch (error) {
      handleServiceError(error)
    } finally {
      setSubmitting(false);
      refreshPoi()
      toggleEditionMode()
    }
  }, [enqueueSnackbar, handleServiceError, poi.id, poi.isProject, refreshPoi, t, toggleEditionMode])

  return <PoiContactForm
    onFormSubmit={onSubmit}
    defaultValues={defaultValues}
    validationSchema={validationSchema}
    miscData={{ poi }}
    miscFunctions={{ onCancel: toggleEditionMode }}
  />
}

const PoiContactPage: FC<Types.IProps> = ({ poi, isEditionMode, refreshPoi }) => {
  // Variables
  const history = useHistory()
  const { t } = useTranslation(['common']);
  const contacts = useMemo(() => poi.persons.map(({ person, position }) => ({
    person,
    position,

    // indexes used for searching and sorting
    ...person,
    positionName: position?.name

  })), [poi.persons])

  // Handlers
  const toggleEditionMode = useCallback(() => {
    if (poi.isProject) {
      const route = isEditionMode ? Common.Routes.routeContactPreviewProject : Common.Routes.routeUpdateContactProject
      history.push(`/${Common.Routes.routeProject}/${poi.id}/${route}`)
    } else {
      const route = isEditionMode ? Common.Routes.routeContactPreviewResource : Common.Routes.routeUpdateContactResource
      history.push(`/${Common.Routes.routeResource}/${poi.id}/${route}`)
    }
  }, [history, isEditionMode, poi.id, poi.isProject])

  return <>
    <div className='h-0.7/10 w-full pl-2 pt-1'>
      <FormHeader
        title={t('common:forms.titles.contacts')}
        toggleEditionMode={toggleEditionMode}
        isEditionMode={isEditionMode}
      />
    </div>
    <div className='h-9.3/10 w-full p-1 pt-2'>
      {isEditionMode
        ? <PoiContactPageEdition poi={poi} contacts={contacts} toggleEditionMode={toggleEditionMode} refreshPoi={refreshPoi} />
        : <PoiContactTable contacts={contacts} className='dataTable-poiContactsPreview-container' />
      }
    </div>
  </>
}

export default PoiContactPage