// React libs
import { useEffect, useMemo } from 'react'
import { sortBy } from 'lodash'
import { useTranslation } from 'react-i18next';
// hooks
import useGenericData from '../../../../Core/Data/Hooks/GenericData'
import usePoiFilters from './PoiFilters'
// Services
import DatavizService from '../Services/DatavizService';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'
// Utils
import { ifDef } from '../../../../Core/Utils/Misc'

const useData = (dateStart: string, dateEnd: string, filters: any) => {
  // Variables
  const { t } = useTranslation(['common']);
  const { data: metrics, ...props } = useGenericData(() => DatavizService.getProjectsMetricsBudget(dateStart, dateEnd, filters))

  const data = useMemo(() => {
    const legends: string[] = []
    const series: any[] = []
    sortBy(metrics, 'total').forEach(item => {
      legends.push(item.label ?? t('common:forms.fields.undefined'))
      series.push(item.total)
    })
    return {
      legends,
      series,
      formatter: ({ value }: any) => `${(+value).toLocaleString('fr-FR')} €`
    }
  }, [metrics, t])

  return {
    ...props,
    data
  }
}

const ProjectMetricsBudget = (dateStart: string, dateEnd: string): CoreTypes.IDatavizHook => {
  // Variables
  const { filters, ...filtersProps } = usePoiFilters('project')
  const data = useData(dateStart, dateEnd, filters)
  const stringFilters = useMemo(() => ifDef(filters, JSON.stringify), [filters])

  // Effects
  useEffect(() => {
    data.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, stringFilters])

  return {
    ...data,
    filter: filtersProps
  }
}

export default ProjectMetricsBudget