import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import type { TabProps } from '@material-ui/core/Tab';
import type { IPropsTabsFilter } from './SharedMap.type';

import {
  Checkbox,
  Tooltip,
  withStyles,
  Box,
  Tabs,
  Tab,
  Collapse,
} from '@material-ui/core';

import Separator from '../../../../Core/Components/UiKit/Separator/Separator';
import TransparentPanel from '../../../../Core/Components/UiKit/Panel/TransparentPanel/TransparentPanel';
import removeDiacritics from '../../../../App/Resources/Common/removeDiacritics';
import FaIcon from '../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import * as Types from '../../Pages/SharedMap/SharedMap.type';
import _ from 'lodash';

const customClasses = {
  root: 'w-8 h-8',
  colorPrimary: 'text-selection',
  colorSecondary: 'text-white',
};

const sortByLabel = (activities: Types.IActivity[]) => {
  return activities.sort((a, b) => {
    const aLabel = a.label ?? "";
    const bLabel = b.label ?? "";
    return aLabel.localeCompare(bLabel);
  })
}

const TabPanel: FC<TabProps & { index: number }> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const getNbActiveFilters = (filters: Types.IFilters) => {
  const { clubs, facilities, sports, sportsAge, id } = filters;

  return clubs.length + facilities.length + sports.length + (sportsAge ? 1 : 0) + (id ? 1 : 0);
}

const TabsFilter = ({
  filters,
  setFilters,
  toggleSidebar,
  facilities,
  clubs,
  sports,
  isSidebarOpen,
  resetFilters,
  showLegend
}: IPropsTabsFilter) => {
  const { t } = useTranslation(['map']);

  const [tabValue, setTabValue] = useState(localStorage.getItem('tabFiltersValue') ? parseInt(localStorage.getItem('tabFiltersValue') as string) : 0 );
  const [clubSearch, setClubSearch] = useState<string>('');
  const [age, setAge] = useState<string>(filters.sportsAge ?? '');

  const filteredClubs = useMemo(() => {
    return clubSearch.length === 0 ? clubs : clubs.filter((marker: any) => {
      return removeDiacritics(marker?.name).includes(clubSearch)
    })
  }, [clubs, clubSearch])

  const sortedSports : Types.IActivity = useMemo(() => {
    const sortedSportsArray = Object.entries(sports).sort((a, b) => a[0].localeCompare(b[0]));
    
    const sortedResult = sortedSportsArray.map(([sportType, sportData]) => {
      const sortedActivities = sortByLabel(sportData.activities);
      return [sportType, { ...sportData, activities: sortedActivities }];
      
    });
    
    return Object.fromEntries(sortedResult);
  }, [sports]);


  const handleTabChange: (event: React.ChangeEvent<{}>, value: any) => void = (
    _,
    newValue
  ) => {
    resetFilters()
    setTabValue(newValue);
    localStorage.setItem('tabFiltersValue', newValue.toString());
  };

  const StyledTab = withStyles({
    root: {
      '@media (min-width:600px)': {
        minWidth: '80px !important',
      },
    },
  })(Tab);



  const handleFilterClub = useCallback(
    (clubId: string) => {
      setFilters((prev: Types.IFilters) => ({
        ...prev,
        id: undefined,
        clubs: prev.clubs.includes(clubId)
          ? prev.clubs.filter((id: string) => id !== clubId)
          : [...prev.clubs, clubId],
      }));
    },
    [setFilters]
  );

  

  const handleFilterFacility = useCallback(
    (facilityId: string) => {
      setFilters((prev: Types.IFilters) => ({
        ...prev,
        facilities: prev.facilities.includes(facilityId)
          ? prev.facilities.filter((id: string) => id !== facilityId)
          : [...prev.facilities, facilityId],
      }));
    },
    [setFilters]
  );
  
  const handleFilterSports = useCallback((sport: string) => {
    setFilters((prev: Types.IFilters) => ({
        ...prev,
        id: undefined,
        sports: prev.sports.includes(sport)
          ? prev.sports.filter((s: string) => s !== sport)
          : [...prev.sports, sport] 
      }))
    }, [setFilters]);

    const handleSportFullCheck = useCallback(
      (labels) => {
        setFilters((prev: Types.IFilters) => ({
          ...prev,
          id: undefined,
          sports: prev.sports.some((sport: string) => labels.includes(sport)) ? prev.sports.filter((sport: string) => !labels.includes(sport)) : [...prev.sports, ...labels]
        }))
      },  
      [setFilters]
    );

  const handleChangeAge = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
        setAge(event.target.value);
      },
    [setAge]
  );

  useEffect(() => {
      setFilters((prev: Types.IFilters) => ({
        ...prev,
        id: undefined,
        sportsAge: age
      }))
  }, [age, setFilters]);

  useEffect(() => {
    setAge(filters.sportsAge ?? '');
  }, [filters.sportsAge, resetFilters]);

  const StyledTreeItem = (props: TreeItemProps) => {
    return <TreeItem {...props} TransitionComponent={Collapse} classes={{label: "text-white text-xl"}} />;
  };

  console.log("showLegend", showLegend)

  return (
    <div className={`fixed top-${showLegend ? "12" : 0} bg-transparent flex flex-col h-full left-0 overflow-hidden w-full md:w-96 z-1500 px-2 transition-all transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
    >
      <TransparentPanel />
      <div className='z-10'>
        <div className='flex justify-between text-white py-4 px-1'>
          <Tooltip title={t('map:sidebar.filters.delete') ?? ''}>
            <button
              onClick={resetFilters}
              className={`relative ${getNbActiveFilters(filters) > 0 ? 'opacity-100': 'opacity-0'}`}
            >
              <FaIcon
                name='trash'
                className='text-2xl text-white cursor-pointer'
              />
              <span className='absolute bg-red-600 -right-2 -top-2 rounded-full text-xs w-4 h-4 flex items-center justify-center'>{getNbActiveFilters(filters)}</span>
            </button>
          </Tooltip> 

          <p className='text-xl font-medium text-white'>
            {t('map:header.filter')}
          </p>
          <Tooltip title={t('map:header.panel.close') ?? ''}>
            <button
              onClick={toggleSidebar}
            >
              <FaIcon
                name='chevron-left'
                className='text-xl text-white cursor-pointer'
              />
            </button>
          </Tooltip>
        </div>
        <Separator type='horizontal' className='mb-2' />
      </div>
      <div className='flex-1 overflow-auto pb-12 px-1 md:px-0 z-10'>
        <Tabs value={tabValue} onChange={handleTabChange} variant='fullWidth'>
          <StyledTab
            label={t('map:sidebar.shared.sports')}
            {...a11yProps(0)}
            className='text-white'
          />
          <StyledTab
            label={t('map:sidebar.shared.facilities')}
            {...a11yProps(1)}
            className='text-white'
          />
          <StyledTab
            label={t('map:sidebar.shared.clubs')}
            {...a11yProps(2)}
            className='text-white'
          />
        </Tabs>

        <div className='mt-2'>
          <TabPanel value={tabValue} index={0}>
              <div className='p-2 flex items-center mb-2 gap-3'>
                <p className='text-white text-base'>{t('map:sidebar.shared.yourAge')} :</p>
                <input
                  className='bg-white w-16 rounded-md p-2'
                  type='number'
                  onChange={handleChangeAge}
                  value={age}
                  placeholder='Âge'
                  max={100}
                  min={0}
                  />
                  <p className='text-white text-base'>{t('map:sidebar.shared.yearsOld')}</p>
              </div>
              <TreeView
                  defaultCollapseIcon={<FaIcon name='caret-down' className='text-xl text-white' />}
                  defaultExpandIcon={<FaIcon name='caret-right' className='text-xl text-white' />}
                >
                  {Object.entries(sortedSports).map(([category, data], index) => {
                    const totalCount = _.sumBy(data.activities, 'nbOfPois')

                  return (
                    <StyledTreeItem
                      key={category}
                      nodeId={String(index + 1)}
                      label={<div className='flex items-center gap-1'>
                        <Checkbox
                            classes={customClasses}
                            color='secondary'
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                            }}
                            onChange={(e) => {
                              handleSportFullCheck(data.activities.map(((act: any) => act.value)))
                            }}
                            checked={filters.sports.some((sport: string) => data.activities.map(((act: any) => act.value)).includes(sport))}
                          />
                        <div className='flex items-center gap-2'>
                          <i
                          className={`bg-white-80 fa fa-${data.style.data.icon} flex items-center justify-center rounded-full text-sm w-6 h-6`}
                          style={{ color: data.style.data.markerColor }}
                          />
                          <span className='text-left text-lg font-medium'>{category}</span>
                          <span className='text-left text-base'>({totalCount})</span>
                        </div>
                    </div>
                    }
                    >
                      {data.activities.map((activity: Types.IActivity, activityIndex: number) => {
                      const isDisabled = activity.nbOfPois === 0;
                        return <button 
                          key={activityIndex} className={`pl-6 flex items-center gap-2 ${isDisabled ? "cursor-not-allowed": ""}`} disabled={isDisabled} onClick={() => handleFilterSports(activity.value ?? "")}>
                            <Checkbox
                              classes={customClasses}
                              color='secondary'
                              checked={isDisabled ? false : filters.sports.includes(activity.value ?? "")}
                              disabled={isDisabled}
                            />
                            <div className='flex items-center gap-2'>
                              <div className={`p-1 rounded-full flex items-center justify-center ${isDisabled ? "bg-disabled": "bg-white"}`} >
                                <img
                                  src={activity.picto}
                                  alt={activity.label}
                                  className='w-4 h-4 object-contain'
                                />
                              </div>
                              <span className={`text-left text-base ${isDisabled ? "text-disabled": "text-white"}`}>{activity.label} ({activity.nbOfPois})</span>
                            </div>
                          </button>
                    }
                    )}
                    </StyledTreeItem>
                  )})}
                </TreeView>

          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {facilities.map((facility: any, index: number) => (
              <div key={index} className='flex items-center text-white w-full gap-2'>
                <Checkbox
                  classes={customClasses}
                  color='secondary'
                  checked={filters.facilities.includes(facility.id)}
                  onClick={() => handleFilterFacility(facility.id)}
                />
                <div className='flex items-center gap-2'>
                  <i
                    className={`bg-white-80 fa fa-${facility.style.data.icon} flex h-6 items-center justify-center rounded-full text-xs w-6`}
                    style={{ color: facility.style.data.markerColor }}
                  />
                  <span className='font-normal text-base'>{facility.label}</span>
                </div>
              </div>
            ))}
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <div className='p-2'>
              <input
                className='bg-white w-full rounded-md mb-2 p-2'
                placeholder='Rechercher un club'
                onChange={event =>
                  setClubSearch(removeDiacritics(event.target.value))
                }
                value={clubSearch}
              />
            </div>
          <div className='flex flex-col gap-y-1'>
            {filteredClubs.map((club: Types.IMarker, index: number) => (
                <button key={index} className='flex items-center text-white w-full gap-2'  onClick={() => handleFilterClub(club.id)}>
                    <Checkbox
                      classes={customClasses}
                      color='secondary'
                      checked={filters.clubs.includes(club.id)}
                    />
                    <div className='flex items-center gap-2'>
                        <i
                          className={`bg-white-80 fa fa-${club.type.style.data.icon} flex items-center justify-center rounded-full text-xs w-6 h-6 flex-shrink-0`}
                          style={{ color: club.type.style.data.markerColor }}
                        />
                      <span className='font-normal text-base text-left'>{club.name}</span>
                    </div>
                </button>
              ))}
           </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default TabsFilter;
