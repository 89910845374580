// Utils
import { createContext } from 'react';
// Types
import * as MapTypes from '../Models/Map.type';

export const sidebarDefaultProjectData = {
  project: undefined,
  projectLinks: [],
  isEcosystemEnabled: false,
  needSidebarClose: false,
  ro: false
}

export interface ISidebarProjectData {
  project: MapTypes.IPoi | undefined
  projectLinks: (MapTypes.IPoiLink & { toEdit?: boolean, toCreate?: boolean, isCreationPending?: boolean })[]
  isEcosystemEnabled: boolean,
  activeLink?: MapTypes.IPoiLink,
  needSidebarClose?: boolean,
  ro?: boolean
}

export interface IMapSidebarProjectContext {
  data: ISidebarProjectData
  setData: (data: ISidebarProjectData) => void
}

const MapSidebarProjectContext = createContext<IMapSidebarProjectContext>({
  data: sidebarDefaultProjectData,
  setData: (data: ISidebarProjectData) => { }
})

export default MapSidebarProjectContext;
