// React libs
import React, { FC } from 'react';
// Components
import ErrorIcon from '../../Icon/ErrorIcon/ErrorIcon';
// Type
import * as Types from './FieldError.type';

const FieldError: FC<Types.IProps> = ({ color, errors, touched }) => {
  const textColors = { secondary: 'text-red-500', primary: 'text-red-100' };
  return (
    <div className='mt-1' data-testid='field-error'>
      {touched && errors ? (
        <div
          className={`flex items-center text-xs font-bold ${textColors[color || 'secondary']
            }`}
        >
          <ErrorIcon color={color} className='mr-1 text-sm' />
          {errors}
        </div>
      ) : (
        <div className='h-4-1/2' />
      )}
    </div>
  );
};

FieldError.defaultProps = {
  color: 'secondary',
};
export default FieldError;
