// React libs
import React, { FC, useCallback, useMemo } from 'react';
import { Form, FormikProps, Formik } from 'formik';
import { uniqueId } from 'lodash';
// Components
import FieldError from '../../../../../Core/Components/UiKit/Error/FieldError/FieldError';
import ProjectBudget from '../../ProjectFinancing/ProjectBudget/ProjectBudget';
import ProjectEngagedFunds from '../../ProjectFinancing/ProjectEngagedFunds/ProjectEngagedFunds';
import ProjectFinancingSources from '../../ProjectFinancing/ProjectFinancingSources/ProjectFinancingSources';
import * as FormFields from '../../../../../Core/Components/Form/FormFields/FormFields';
// Type
import * as Types from './ProjectFinancingForm.type';
// Utils
import { getFormPropTypes, preventKeyDownSubmit, isDisabled, hasErrors } from '../../../../../Core/Utils/FormUtils';

const ProjectFinancingForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
    validateOnBlur={false}
  >
    {(formikProps: FormikProps<any>) => (
      <ProjectFinancingFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}
  </Formik>
);

ProjectFinancingForm.propTypes = getFormPropTypes();
export default ProjectFinancingForm;

const ProjectFinancingFormikForm = ({
  errors,
  isSubmitting,
  touched,
  miscData,
  miscFunctions,
  values,
  setFieldTouched,
  setFieldValue
}: FormikProps<any> & any) => {
  // Helpers
  const getDisplayedValues = useCallback(values => values.filter((value: any) => !value.toDelete), [])
  const getError = useCallback(errors => errors?.find((error: any) => error !== undefined), [])

  // Variables
  const displayedData: any = useMemo(() => ({
    expenseLines: getDisplayedValues(values.expenseLines),
    expenseFundings: getDisplayedValues(values.expenseFundings),
    expenseEngaged: getDisplayedValues(values.expenseEngaged)
  }), [getDisplayedValues, values.expenseEngaged, values.expenseFundings, values.expenseLines])
  const formErrors = useMemo(() => ({
    expenseLines: getError(errors.expenseLines),
    expenseFundings: getError(errors.expenseFundings),
    expenseEngaged: getError(errors.expenseEngaged)
  }), [errors.expenseEngaged, errors.expenseFundings, errors.expenseLines, getError])

  // Handlers
  const onChange = useCallback((field, { id, key, value }) => {
    const fieldValues = values[field]
    const index = fieldValues.findIndex((item: any) => item.id === id)
    const oldValue = fieldValues[index]

    setFieldTouched(field, true)
    setFieldValue(`${field}.${index}`, {
      ...oldValue,
      [key]: value,
      toUpdate: !oldValue.toCreate
    })
  }, [setFieldTouched, setFieldValue, values])
  const onAdd = useCallback((field, props) => {
    const index = values[field].length

    setFieldTouched(field, true)
    setFieldValue(`${field}.${index}`, {
      id: uniqueId(field),
      ...props,
      toCreate: true
    })
  }, [setFieldTouched, setFieldValue, values])
  const onDel = useCallback((field, { id }) => {
    const fieldValues = values[field]
    const index = fieldValues.findIndex((item: any) => item.id === id)
    const value = fieldValues[index]

    setFieldTouched(field, true)

    if (value.toCreate) {
      const fieldValuesCopy = [...fieldValues]
      fieldValuesCopy.splice(index, 1)
      setFieldValue(field, fieldValuesCopy)
    } else {
      setFieldValue(`${field}.${index}`, {
        ...value,
        toDelete: true
      })
    }
  }, [setFieldTouched, setFieldValue, values])

  const onDelMultiple = useCallback((field, ids) => {
    setFieldTouched(field, true)

    const fieldValues = [...values[field]]

    ids.forEach((id: string) => {
      const index = fieldValues.findIndex((item: any) => item.id === id)
      const value = fieldValues[index]

      if (value.toCreate) {
        fieldValues.splice(index, 1)
      } else {
        fieldValues.splice(index, 1, {
          ...value,
          toDelete: true
        })
      }
    })

    setFieldValue(field, fieldValues)
  }, [setFieldTouched, setFieldValue, values])

  return <Form className='h-full w-full' onKeyDown={preventKeyDownSubmit}>
    <div className='h-9.3/10 w-full overflow-auto'>
      <ProjectBudget
        expenseLines={displayedData.expenseLines}
        onChange={props => onChange('expenseLines', props)}
        onAdd={() => onAdd('expenseLines', {
          name: '',
          amount: 0,
          amountVat: 0,
        })}
        onDel={props => onDel('expenseLines', props)}
        onDelMultiple={ids => onDelMultiple('expenseLines', ids)}
      />
      {hasErrors({ errors: formErrors.expenseLines, touched }) && (
        <FieldError
          errors={(Object.values(formErrors.expenseLines)[0] as any)}
          touched={touched}
        />
      )}
      <ProjectFinancingSources
        expenseFundings={displayedData.expenseFundings}
        onChange={props => onChange('expenseFundings', props)}
        onAdd={() => onAdd('expenseFundings', {
          name: '',
          amount: 0,
          fkfundType: '',
          requestDate: '',
          deadlineDate: ''
        })}
        onDel={props => onDel('expenseFundings', props)}
        onDelMultiple={ids => onDelMultiple('expenseFundings', ids)}
        fundTypes={miscData?.fundTypes}
      />
      {hasErrors({ errors: formErrors.expenseFundings, touched }) && (
        <FieldError
          errors={(Object.values(formErrors.expenseFundings)[0] as any)}
          touched={touched}
        />
      )}
      <ProjectEngagedFunds
        expenseEngaged={displayedData.expenseEngaged}
        onChange={props => onChange('expenseEngaged', props)}
        onAdd={() => onAdd('expenseEngaged', {
          foundSrc: '',
          amount: 0,
          fkfundType: '',
          amountPaid: 0
        })}
        onDel={props => onDel('expenseEngaged', props)}
        onDelMultiple={ids => onDelMultiple('expenseEngaged', ids)}
        fundTypes={miscData?.fundTypes}
      />
      {hasErrors({ errors: formErrors.expenseEngaged, touched }) && (
        <FieldError
          errors={(Object.values(formErrors.expenseEngaged)[0] as any)}
          touched={touched}
        />
      )}
    </div>
    <div className='h-0.7/10 w-full'>
      <FormFields.FormSubmitButtons
        isSubmitting={isSubmitting}
        disabled={isDisabled(errors, isSubmitting, touched)}
        onCancel={miscFunctions?.onCancel}
      />
    </div>
  </Form>
}
