// React libs
import React, { FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { keyBy, isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Types
import * as CoreTypes from '../../../../Data/Models/Core.type';
import * as Types from './PoiFurtherInformationPage.type'
import * as PoiFurtherInformationFormTypes from '../PoiFurtherInformationForm/PoiFurtherInformationForm.type';
// Components
import PoiFurtherInformationForm from '../PoiFurtherInformationForm/PoiFurtherInformationForm';
import FormHeader from '../../FormHeader/FormHeader';
import * as FormFields from '../../../../../Core/Components/Form/FormFields/FormFields';
// Common
import CoreCommon from '../../../../Resources/Common';
import Common from '../../../../../App/Resources/Common'
// Utils
import useServiceErrorHandler from '../../../../Utils/useServiceErrorHandler';
// Services
import CommonService from '../../../../../Core/Data/Services/CommonService'

interface IPoiFurtherInformationEdition {
  poi: CoreTypes.IPoi
  toggleEditionMode: () => void
  refreshPoi: () => void
}

const PoiFurtherInformationEdition: FC<IPoiFurtherInformationEdition> = ({ poi, toggleEditionMode, refreshPoi }) => {
  // Variables
  const handleServiceError = useServiceErrorHandler()
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();
  const valueDefValues = useMemo(() => {
    const valuesByValueDefs: { [id: string]: any } = {}
    poi.valueSlots.forEach(valueSlot => {
      const valueDef = valueSlot.valueDef
      const value = valueSlot.value
      switch (valueDef.widget.id) {
        case 'VALUESLIST': case 'CHECKBOXES':
          valuesByValueDefs[valueDef.id] = value.split(',')
          break
        case 'NUMBER': case 'FLOAT':
          valuesByValueDefs[valueDef.id] = +value
          break
        default:
          valuesByValueDefs[valueDef.id] = value
      }
    })
    return valuesByValueDefs
  }, [poi.valueSlots])
  const defaultValues: PoiFurtherInformationFormTypes.IFormValues = useMemo(() => {
    const values: any = {}
    poi.type.valueDefs.forEach(({ valueDef }) => {
      values[valueDef.id] = valueDefValues[valueDef.id] ?? ''
    })
    return values
  }, [poi.type.valueDefs, valueDefValues])
  const validationSchema = Yup.object()

  // Handlers
  const handleSubmit = useCallback(async (values: PoiFurtherInformationFormTypes.IFormValues, { setSubmitting, resetForm }: FormikHelpers<any>) => {
    setSubmitting(true);
    try {
      const valueSlotsByValueDef = keyBy(poi.valueSlots, valueSlot => valueSlot.valueDef.id)
      const valueDefsById = keyBy(poi.type.valueDefs.map(({ valueDef }) => valueDef), 'id')

      const valueSlots: any = []
      const serviceCallFns: any = []

      Object.keys(values).forEach(valueDefId => {
        const valueDef = valueDefsById[valueDefId]
        const valueSlot = valueSlotsByValueDef[valueDefId]

        const oldValue = defaultValues[valueDefId]
        let newValue = values[valueDefId]

        if (isEqual(oldValue, newValue?.length === 0 ? '' : newValue)) {
          return
        }

        if (newValue !== '' && valueDef.widget.id === 'BOOLEAN') {
          newValue = newValue === 'true'
        }

        if (newValue === '' && ['NUMBER', 'FLOAT'].includes(valueDef.widget.id)) {
          newValue = null
        }

        if (['VALUESLIST', 'CHECKBOXES'].includes(valueDef.widget.id)) {
          newValue = newValue.length === 0 ? '' : newValue.join(',')
        }

        if (oldValue === '') {
          valueSlots.push({
            value: newValue,
            valueDef: {
              id: valueDefId
            }
          })
          serviceCallFns.push(() => CommonService.saveValueSlot({
            fkpoi: poi.id,
            fkvalueDef: valueDefId,
            value: newValue,
            valueDef: {
              id: valueDefId
            }
          }))
          return
        }

        if (newValue === '' || newValue == null) {
          valueSlots.push({
            value: newValue,
            id: valueSlot.id
          })

          serviceCallFns.push(() => CommonService.deleteValueSlot(valueSlot.id))
          return
        }

        valueSlots.push({
          value: newValue,
          id: valueSlot.id
        })

        serviceCallFns.push(() => CommonService.updateValueSlot(valueSlot.id, {
          fkpoi: poi.id,
          value: newValue,
          id: valueSlot.id
        }))
      })

      await CommonService.updatePoi(poi.id, {
        id: poi.id,
        isProject: poi.isProject,
        valueSlots
      })
      await Promise.all(serviceCallFns.map((fn: any) => fn()))
      enqueueSnackbar(t('common:forms.validation.saveSucceeded'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'success',
      })
    } catch (error) {
      handleServiceError(error)
    } finally {
      setSubmitting(false);
      resetForm({ values: defaultValues });
      refreshPoi()
      toggleEditionMode()
    }
  }, [refreshPoi, defaultValues, enqueueSnackbar, handleServiceError, poi.id, poi.isProject, poi.type.valueDefs, poi.valueSlots, t, toggleEditionMode])

  return <PoiFurtherInformationForm
    defaultValues={defaultValues}
    onFormSubmit={handleSubmit}
    validationSchema={validationSchema}
    miscData={{ isEditionMode: true, poi }}
    miscFunctions={{ toggleEditionMode }}
  />
}

interface IPoiFurtherInformationPreview {
  poi: CoreTypes.IPoi
  toggleEditionMode: () => void
}

const PoiFurtherInformationPreview: FC<IPoiFurtherInformationPreview> = ({ poi, toggleEditionMode }) => {
  // Variables
  const { t } = useTranslation(['common']);

  return <>
    <div className='h-0.7/10 w-full pl-2 pt-1'>
      <FormHeader
        title={t('common:forms.titles.furtherInformation')}
        toggleEditionMode={toggleEditionMode}
        isEditionMode={false}
      />
    </div>
    <div className='h-9.3/10 w-full p-1 pt-2 overflow-auto'>
      <FormFields.FormPoiCustomValuesPreview poi={poi} />
    </div>
  </>
}

const PoiFurtherInformationPage: FC<Types.IProps> = ({ poi, isEditionMode, refreshPoi }) => {
  // Variables
  const history = useHistory()

  // Handlers
  const toggleEditionMode = useCallback(() => {
    if (poi.isProject) {
      const route = isEditionMode ? Common.Routes.routeFurtherInformationPreviewProject : Common.Routes.routeUpdateFurtherInformationProject
      history.push(`/${Common.Routes.routeProject}/${poi.id}/${route}`)
    } else {
      const route = isEditionMode ? Common.Routes.routeFurtherInformationPreviewResource : Common.Routes.routeUpdateFurtherInformationResource
      history.push(`/${Common.Routes.routeResource}/${poi.id}/${route}`)
    }
  }, [history, isEditionMode, poi.id, poi.isProject])

  return <div data-testid='PoiFurtherInformationPage' className='h-full w-full p-1'>
    {isEditionMode
      ? <PoiFurtherInformationEdition
        poi={poi}
        toggleEditionMode={toggleEditionMode}
        refreshPoi={refreshPoi}
      />
      : <PoiFurtherInformationPreview
        poi={poi}
        toggleEditionMode={toggleEditionMode}
      />
    }
  </div>
}

export default PoiFurtherInformationPage