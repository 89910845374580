// Utils
import ServicesUtils from '../../../Core/Utils/Services';
import CommonUrls from '../../../Core/Data/Services/CommonUrls';
// Common
import Common from '../../Resources/Common';

export default class AuthService {
  static login(user: string, password: string) {
    return ServicesUtils.postForm(
      CommonUrls.getLogin(),
      { user, password },
      false
    );
  }
  static forgotPassword(email: string) {
    return ServicesUtils.postJSON(
      CommonUrls.getForgotPassword(),
      {
        userOrMail: email,
        resetPasswordUrl: `${process.env.REACT_APP_HOST}/${Common.Routes.routeResetPassword}/$encryptedkey`,
      },
      false
    );
  }
  static resetPassword(encrytedString: string, password: string) {
    return ServicesUtils.postJSON(
      CommonUrls.getResetPassword(encrytedString),
      {
        password,
      },
      false
    );
  }
  static logout() {
    return ServicesUtils.postJSON(CommonUrls.getLogout(), {}, false);
  }

  static getCurrentUser(id:string) 
  {
    return ServicesUtils.get(
      CommonUrls.getUser(id), 
      true
    );
  }
  
  static setCurrentUser(id:string , data:any)
  {
    return ServicesUtils.put(
      CommonUrls.setUser(id), 
      data,
      true
    );

  }
  static getAdressUser() 
  {
    return ServicesUtils.get(
      CommonUrls.getAddress(), 
      true
    );
  }
  static getAdressUserWhitId(id:string) 
  {
    return ServicesUtils.get(
      CommonUrls.getAddressWhitId(id), 
      true
    );
  }
  static getProfilUser(id:string) 
  {
    return ServicesUtils.get(
      CommonUrls.getProfil(id), 
      true
    );
  }
  static getAccessType() 
  {
    return ServicesUtils.get(
      CommonUrls.getAccessTypes(), 
      true
    );
  }
}

