// Utils
import { createContext } from 'react';
// Types
import * as MapTypes from '../Models/Map.type';

export interface IPoisLinksContext {
  poisLinks: MapTypes.IPoiLink[];
  updatePoisLinks: (links: MapTypes.IPoiLink[]) => void;
}
const PoisLinksContext = createContext<IPoisLinksContext>({
  poisLinks: [],
  updatePoisLinks: (linksData: MapTypes.IPoiLink[]) => {},
});

export default PoisLinksContext;
