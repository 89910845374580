// Utils
import { createContext } from 'react';
// Types
import * as MapTypes from '../Models/Map.type';

export interface IPhaseTypeContext {
  phaseTypes: MapTypes.IPhaseType[];
  updatePhaseTypes: (types: MapTypes.IPhaseType[]) => void;
}
const PhaseTypeContext = createContext<IPhaseTypeContext>({
  phaseTypes: [],
  updatePhaseTypes: (types: MapTypes.IPhaseType[]) => {},
});

export default PhaseTypeContext;
