// React libs
import React, { FC, useContext, useState, useCallback, useEffect, useMemo, Fragment } from 'react';
import { keyBy, differenceBy } from 'lodash'
import { List, Tooltip, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Popover } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// Components
import ActiveButton from '../../../../../../Core/Components/UiKit/Button/ActiveButton';
import CloseIconButton from '../../../../../../Core/Components/UiKit/Button/CloseIconButton/CloseIconButton';
import CreateLinkForm from '../../../../../../Core/Components/Form/CreateLink/CreateLinkForm';
import EcosystemButton from '../../../../../../Core/Components/UiKit/Button/EcosystemButton/EcosystemButton';
import FaIcon from '../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import Separator from '../../../../../../Core/Components/UiKit/Separator/Separator';
import Typography from '../../../../../../Core/Components/UiKit/Typography/Typography';
// Types
import * as Types from './MapSidebarProject.type'
import * as CoreTypes from '../../../../../../Core/Data/Models/Core.type';
import * as MapTypes from '../../../../Data/Models/Map.type'
// hooks
import useLinkTypes from '../../../../Data/Hooks/LinkTypes';
import usePhaseTypes from '../../../../Data/Hooks/PhaseTypes';
import usePoiLinks from '../../../../Data/Hooks/PoiLinks';
import usePois from '../../../../Data/Hooks/Pois';
// Contexts
import MapContext, { IMapContext } from '../../../../Data/Contexts/MapContext';
import MapLegendContext, { IMapLegendContext } from '../../../../Data/Contexts/MapLegendContext';
import MapFiltersContext, { IMapFiltersContext } from '../../../../Data/Contexts/MapFiltersContext';
import MapSidebarProjectContext, { IMapSidebarProjectContext, sidebarDefaultProjectData } from '../../../../Data/Contexts/MapSidebarProjectContext';
import PoisContext, { IPoisContext } from '../../../../Data/Contexts/PoisContext';
import PoisLinksContext, { IPoisLinksContext } from '../../../../Data/Contexts/PoisLinksContext';
// Common
import CoreCommon from '../../../../../../Core/Resources/Common';
// Services
import MapService from '../../../../Data/Services/MapService'
// Utils
import { formatProjectAddress } from '../../../../../../Core/Utils/Misc';

const ProjectInfo = ({ info }: { info: { key: string, value: string }[] }) => <div className='grid grid-cols-4 gap-1 mt-4'>
  {info.map(({ key, value }) => <Fragment key={key}>
    <div className='col-span-1 font-extrabold'>{key} :</div>
    <div className='col-span-3'>{value}</div>
  </Fragment>)}
</div>

interface IPoiLink {
  activeLink: MapTypes.IPoiLink | undefined
  link: MapTypes.IPoiLink
  poisById: { [id: string]: MapTypes.IPoi }
  linkTypesById: { [id: string]: MapTypes.ILinkType }
  deleteProjectLink: Function
  centerOnPoi: Function
  index: number
  roMode: boolean
}

const PoiLink: FC<IPoiLink> = ({ roMode, activeLink, link, poisById, linkTypesById, deleteProjectLink, centerOnPoi, index }) => {
  // State
  const [linkTypeAnchor, setLinkTypeAnchor] = useState<null | HTMLElement>(null);

  // Contexts
  const sidebarProjectContext: IMapSidebarProjectContext = useContext(MapSidebarProjectContext)

  // Variables
  const { t } = useTranslation(['map']);
  const isActive = useMemo(() => activeLink !== undefined && activeLink.fkpoiFrom === link.fkpoiFrom && activeLink.fkpoiTo === link.fkpoiTo, [activeLink, link.fkpoiFrom, link.fkpoiTo])
  const linkTypesOptions = Object.values(linkTypesById).map(linkType => ({
    label: linkType.label,
    value: linkType.id
  }))
  const defaultValues = {
    linkType: link.fklinkType,
    comment: link.comment ?? ''
  }
  const validationSchema = Yup.object().shape({
    linkType: Yup.string().required(t('map:sidebar.project.errors.linkTypeRequired')),
    comment: Yup.string()
  })
  const isLinkTypeFormOpened = Boolean(linkTypeAnchor)
  const bgClassName = useMemo(() => isActive ? 'bg-active-hover' : index % 2 === 0 ? 'bg-main-light' : 'bg-white', [index, isActive])

  // Handlers
  const openLinkTypeForm = useCallback(event => {
    setLinkTypeAnchor(event.currentTarget)
  }, [setLinkTypeAnchor])
  const closeLinkTypeForm = useCallback(() => {
    setLinkTypeAnchor(null)
  }, [setLinkTypeAnchor])
  const onLinTypeSubmit = useCallback((value: any, actions: any) => {
    const { projectLinks = [] } = sidebarProjectContext.data
    const links = [...projectLinks]
    links.splice(projectLinks.findIndex(candidate => candidate.fkpoiTo === link.fkpoiTo), 1, {
      ...link,
      comment: value.comment,
      fklinkType: value.linkType,
      toEdit: true
    })
    sidebarProjectContext.setData({
      ...sidebarProjectContext.data,
      projectLinks: links
    })
    actions.setSubmitting(false)
    closeLinkTypeForm()
  }, [link, sidebarProjectContext, closeLinkTypeForm])

  return <ListItem className={bgClassName}>
    <ListItemText
      className='truncate w-1 mr-2'
      primary={
        <Tooltip title={poisById[link.fkpoiTo]?.name ?? ''}>
          <span>
            {poisById[link.fkpoiTo]?.name}
          </span>
        </Tooltip>
      }
    />
    <ListItemText
      onClick={roMode ? undefined : openLinkTypeForm}
      className='cursor-pointer'
      primary={
        <Tooltip title={linkTypesById[link.fklinkType]?.label}>
          <p
            className='w-20 truncate'
            style={{
              color: linkTypesById[link.fklinkType]?.style?.data.color
            }}
          >
            {linkTypesById[link.fklinkType]?.label}
          </p>
        </Tooltip>
      }
    />
    <ListItemSecondaryAction>
      {!roMode && <IconButton edge="end" aria-label="remove-link">
        <FaIcon
          name='trash'
          className='text-xl'
          onClick={() => deleteProjectLink(link)}
        />
      </IconButton>
      }
      <IconButton edge="end" aria-label="center-link">
        <FaIcon
          name='map-marker'
          className='text-xl'
          onClick={() => centerOnPoi(link.fkpoiTo)}
        />
      </IconButton>
    </ListItemSecondaryAction>
    {!roMode &&
      <Popover
        open={isLinkTypeFormOpened}
        anchorEl={linkTypeAnchor}
        onClose={closeLinkTypeForm}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CreateLinkForm
          defaultValues={defaultValues}
          onFormSubmit={onLinTypeSubmit}
          miscData={{ linkTypes: linkTypesOptions }}
          validationSchema={validationSchema}
        />
      </Popover>
    }
  </ListItem>
}

const MapSidebarProject: FC<Types.IProps> = () => {
  // Contexts
  const sidebarProjectContext: IMapSidebarProjectContext = useContext(MapSidebarProjectContext)
  const { setCurrentCenter, map }: IMapContext = useContext(MapContext)
  const { mapFilters, updateMapFilters }: IMapFiltersContext = useContext(MapFiltersContext)
  const { updatePoisLinks }: IPoisLinksContext = useContext(PoisLinksContext)
  const { updatePois }: IPoisContext = useContext(PoisContext)
  const { setNeedRefreshLegendPosition }: IMapLegendContext = useContext(MapLegendContext)

  // Variables
  const project = sidebarProjectContext.data.project
  const isRo = sidebarProjectContext.data.ro ?? false
  const phaseTypes = usePhaseTypes()
  const poiLinks = usePoiLinks()
  const pois = usePois()
  const linkTypes = useLinkTypes()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation(['map']);
  const projectInfo = useMemo(() =>
    project !== undefined
      ? [
        { key: t('map:sidebar.project.project'), value: project.name },
        { key: t('map:sidebar.project.address'), value: formatProjectAddress(project.address) },
        { key: t('map:sidebar.project.state'), value: phaseTypes.data?.find(phaseType => phaseType.id === project.currentPhaseType)?.label ?? '' },
      ]
      : [],
    [phaseTypes.data, project, t]
  )
  const persistedProjectLinks = useMemo(() => (project && poiLinks.data?.filter(poiLink => poiLink.fkpoiFrom === project.id)), [poiLinks.data, project])
  const poisById = useMemo(() => keyBy(pois.data?.data, 'id'), [pois.data])
  const linkTypesById = useMemo(() => keyBy(linkTypes.data, 'id'), [linkTypes.data])
  const initialMapFilters = useMemo(
    () => mapFilters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // Actions
  const refreshMap = useCallback(() => {
    updateMapFilters({
      ...initialMapFilters,
      needMapRefresh: true
    })
  }, [updateMapFilters, initialMapFilters])

  // Handlers
  const onSidebarClose = useCallback(() => {
    sidebarProjectContext.setData(sidebarDefaultProjectData)
    refreshMap()
  }, [refreshMap, sidebarProjectContext])
  const toggleEcosystem = useCallback(() => {
    if (sidebarProjectContext.data.isEcosystemEnabled) {
      onSidebarClose()
    } else {
      sidebarProjectContext.setData({
        ...sidebarProjectContext.data,
        isEcosystemEnabled: !sidebarProjectContext.data.isEcosystemEnabled
      })
    }
  }, [onSidebarClose, sidebarProjectContext])
  const clearProjectLinks = useCallback(() => {
    sidebarProjectContext.setData({
      ...sidebarProjectContext.data,
      projectLinks: []
    })
  }, [sidebarProjectContext])
  const deleteProjectLink = useCallback(projectLink => {
    const { projectLinks = [] } = sidebarProjectContext.data
    const links = [...projectLinks]
    links.splice(projectLinks.findIndex(candidate => candidate.fkpoiTo === projectLink.fkpoiTo), 1)
    sidebarProjectContext.setData({
      ...sidebarProjectContext.data,
      projectLinks: links
    })
  }, [sidebarProjectContext])
  const centerOnPoi = useCallback(poiId => {
    setCurrentCenter(poisById[poiId]?.geo.coordinates)
  }, [setCurrentCenter, poisById])
  const onSave = useCallback(() => {
    const displayedLinks = sidebarProjectContext.data.projectLinks ?? []

    const linksToAdd = differenceBy(displayedLinks, persistedProjectLinks ?? [], link => link.fkpoiTo)
    const linksToRemove = differenceBy(persistedProjectLinks, displayedLinks, link => link.fkpoiTo)
    const linksToEdit = displayedLinks.filter(link => link.toEdit)

    Promise.all([
      ...linksToAdd.map(link => MapService.addPoiLink({
        comment: link.comment,
        fklinkType: link.fklinkType,
        fkpoiFrom: link.fkpoiFrom,
        fkpoiTo: link.fkpoiTo
      })),
      ...linksToEdit.map(link => MapService.updatePoiLink({
        comment: link.comment,
        fklinkType: link.fklinkType,
        fkpoiFrom: link.fkpoiFrom,
        fkpoiTo: link.fkpoiTo
      })),
      ...linksToRemove.map(link => MapService.deletePoiLink(link.fkpoiFrom, link.fkpoiTo))
    ]).catch((e: CoreTypes.IWsException) => {
      enqueueSnackbar(
        e?.error?.message || t('common:errors.defaultMessage'),
        {
          ...CoreCommon.Constantes.snackbarDefaultProps,
          variant: 'error',
        }
      );
    }).finally(() => {
      onSidebarClose()
      refreshMap()
    })
  }, [sidebarProjectContext.data.projectLinks, persistedProjectLinks, enqueueSnackbar, t, onSidebarClose, refreshMap])

  // Effects

  // Handle requiring sidebarClose
  useEffect(() => {
    if (sidebarProjectContext.data.needSidebarClose) {
      onSidebarClose()
    }
  }, [onSidebarClose, sidebarProjectContext.data.needSidebarClose])

  // Handle Ecosystem
  useEffect(() => {
    if (pois.data == null) {
      return
    }

    if (sidebarProjectContext.data.isEcosystemEnabled) {
      const projectLinks = sidebarProjectContext.data.projectLinks
      const resourceIds = projectLinks.map(link => link.fkpoiTo)
      const ecosystem = pois.data.data.filter(poi => poi.id === project?.id || resourceIds.includes(poi.id))

      const linkTypes: any = {}
      projectLinks.forEach(link => {
        linkTypes[link.fklinkType] = true
      })

      if (isRo) {
        const advancedFilter = {
          $or: [
            {
              isProject: true,
              id: project?.id
            },
            ...resourceIds.map(id => ({
              isProject: false,
              id
            }))
          ]
        }

        updateMapFilters({
          ...mapFilters,
          links: true,
          markers: {
            ...mapFilters.markers,
            links: { ...mapFilters.markers.links, ...linkTypes }
          },
          advanced: advancedFilter,
          temporal: true,
          needMapRefresh: true
        })
      } else {
        updatePois(ecosystem ?? [])
        updateMapFilters({
          ...mapFilters,
          links: true,
          temporal: true,
          markers: {
            ...mapFilters.markers,
            links: { ...mapFilters.markers.links, ...linkTypes }
          }
        })
      }

      map.fitBounds(ecosystem.map(poi => poi.geo.coordinates))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRo, pois.data, project, sidebarProjectContext.data.projectLinks, sidebarProjectContext.data.isEcosystemEnabled])

  // Display temporary links
  useEffect(() => {
    if (poiLinks.data != null && project !== undefined) {
      updatePoisLinks([
        ...sidebarProjectContext.data.projectLinks,
        ...poiLinks.data.filter(poiLink => poiLink.fkpoiFrom !== project.id)
      ])
    }
  }, [sidebarProjectContext.data.projectLinks, updatePoisLinks, poiLinks.data, project])

  // Info message
  useEffect(() => {
    if (project !== undefined && !sidebarProjectContext.data.isEcosystemEnabled) {
      enqueueSnackbar(
        <div className='w-full flex justify-between gap-2'>
          <div className='whitespace-pre-wrap'>
            <Trans
              i18nKey='map:sidebar.project.linkInfoMessage'
            />
          </div>
          <div className='flex items-center'>
            <CloseIconButton onClose={() => closeSnackbar()} />
          </div>
        </div>,
        {
          ...CoreCommon.Constantes.snackbarDefaultProps,
          action: <span />,
          variant: 'info',
          persist: true,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom'
          },
        }
      )

      return () => closeSnackbar()
    }
  }, [closeSnackbar, enqueueSnackbar, project, sidebarProjectContext.data.isEcosystemEnabled])

  // recalculate the legend position on toggling the sidebar
  useEffect(() => {
    setNeedRefreshLegendPosition(true)
    return () => setNeedRefreshLegendPosition(true)
  }, [project, setNeedRefreshLegendPosition])

  if (project === undefined) {
    return null
  }

  return <div data-testid='map-sidebar-project' className='relative bg-selection-light h-full w-96 overflow-auto text-white p-2'>
    <div className='flex justify-between items-center'>
      <div></div>
      <div>
        <Typography variant='h4' className='font-bold truncate w-64 text-center'>
          <Tooltip title={project.name.toUpperCase()}>
            <span>
              {project.name.toUpperCase()}
            </span>
          </Tooltip>
        </Typography>
      </div>
      <div>
        <CloseIconButton
          onClose={onSidebarClose}
        />
      </div>
    </div>

    <Separator type='horizontal' className='my-2 text-2xl' />

    <div className='flex justify-between items-center'>
      <div>
        <EcosystemButton
          tooltip={t('map:sidebar.project.filterMapByEchosystem') ?? ''}
          onClick={toggleEcosystem}
          isActive={sidebarProjectContext.data.isEcosystemEnabled}
        />
      </div>
      <div />
      <div>
        <Tooltip title={t('map:sidebar.project.locateProjectInMap') ?? ''}>
          <div>
            <FaIcon
              name='map-marker'
              className='cursor-pointer text-2xl hover:text-active-hover'
              onClick={() => centerOnPoi(project.id)}
            />
          </div>
        </Tooltip>
      </div>
    </div>

    <div className='h-8.4/10 w-full overflow-auto'>
      <ProjectInfo
        info={projectInfo}
      />

      <div className='w-full bg-main-light h-9 mt-3 flex justify-between items-center p-2'>
        <div />
        <div>
          <Typography variant='h4' className='font-bold text-selection'>{t('map:sidebar.project.linkedResources').toUpperCase()}</Typography>
        </div>
        <div>
          {!isRo && <Tooltip title={t('map:sidebar.project.deleteAllLinks') ?? ''}>
            <div>
              <FaIcon
                name='trash'
                className='cursor-pointer text-xl text-selection hover:text-active-hover'
                onClick={clearProjectLinks}
              />
            </div>
          </Tooltip>}
        </div>
      </div>

      {!isRo &&
        <div className='text-center mt-1'>
          <FaIcon name='info-circle mr-1' />
          {t('map:sidebar.project.selectProjectResourcesInfoMessage')}
        </div>
      }

      <div className='h-6.5/10 w-full overflow-auto'>
        {sidebarProjectContext.data.projectLinks !== undefined && sidebarProjectContext.data.projectLinks.length > 0 &&
          <List dense classes={{
            root: 'bg-main-light text-selection mt-2'
          }}>
            {sidebarProjectContext.data.projectLinks.map((projectLink, key) => <PoiLink
              activeLink={sidebarProjectContext.data.activeLink}
              link={projectLink}
              key={key}
              index={key}
              roMode={isRo}
              poisById={poisById}
              linkTypesById={linkTypesById}
              centerOnPoi={centerOnPoi}
              deleteProjectLink={deleteProjectLink}
            />)}
          </List>
        }
      </div>
    </div>

    {!isRo && <div className='w-full text-center p-2'>
      <ActiveButton
        handler={onSave}
        icon='check-circle'
        isActive={true}
        label={t('map:sidebar.project.save')}
      />
    </div>}
  </div>
}

export default MapSidebarProject
