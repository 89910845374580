// React libs
import { useEffect, useMemo } from 'react';
// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Hooks
import useGenericData, { IGetGenericData } from './GenericData';
// Utils
import { ifDef } from '../../Utils/Misc';

export interface IGetPoisHook extends IGetGenericData<CoreTypes.IPoi> {}

const usePois = (
  params?: CoreTypes.IUrlQuery,
  fetchData: boolean = true
): IGetPoisHook => {
  // Variables
  const stringParams = useMemo(() => ifDef(params, JSON.stringify), [params]);
  const data = useGenericData(() => CommonService.getPois(params), false);
  // Effects
  useEffect(() => {
    if (fetchData) {
      data.refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringParams, fetchData]);
  return {
    ...data,
    data: fetchData ? data.data : [],
  };
};

export default usePois;
