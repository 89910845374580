// React libs
import React, { FC, Fragment } from 'react';
import { View, Text } from '@react-pdf/renderer';
// Types
import * as Types from './PdfFooter.type';

const STYLES = {
  pageNumber: {
    textAlign: 'right',
    fontSize: 10
  }
}

const PdfFooter: FC<Types.IProps> = ({ pageNumber, nPages, footer }) =>
  <Fragment>
    {footer !== undefined &&
      <View>
        {footer}
      </View>
    }
    <View style={STYLES.pageNumber}>
      <Text>{pageNumber} / {nPages}</Text>
    </View>
  </Fragment>

export default PdfFooter