// React libs
import { useMemo } from 'react'
import moment from 'moment'
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'
import * as ProjectTypes from '../../Data/Models/Project.type'

const useProjectEvolution = ({ project, ...props }: ProjectTypes.IMetricsHook): CoreTypes.IDatavizHook => {
  // Variables
  const data = useMemo(() => project.phases?.map(phase => ({
    name: phase.type.label,
    startDate: moment(phase.startAt).format('YYYY-MM-DD'),
    endDate: moment(phase.endAt ?? new Date()).format('YYYY-MM-DD'),
    color: phase.type.style.data.markerColor,
    icon: phase.type.style.data.icon
  })) ?? [], [project.phases])

  return {
    ...props,
    data
  }
}

export default useProjectEvolution