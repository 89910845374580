// React libs
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// Components
import ProjectIdentityEdition from './ProjectIdentityEdition/ProjectIdentityEdition'
import ProjectIdentityPreview from './ProjectIdentityPreview/ProjectIdentityPreview';
// Types
import * as Types from './ProjectIdentity.type'
// Common
import Common from '../../../../App/Resources/Common'

const ProjectIdentity: FC<Types.IProps> = ({ project, isEditionMode, refreshPoi }) => {
  // Variables
  const history = useHistory()

  // Handlers
  const toggleEditionMode = useCallback(() => {
    history.push(
      `/${Common.Routes.routeProject}/${project.id}/${Common.Routes[isEditionMode ? 'routePreviewProject' : 'routeUpdateProject']}`
    )
  }, [history, isEditionMode, project.id])

  return <div data-testid='project-identity' className='h-full w-full'>
    {isEditionMode
      ? <ProjectIdentityEdition project={project} toggleEditionMode={toggleEditionMode} refreshPoi={refreshPoi} />
      : <ProjectIdentityPreview project={project} toggleEditionMode={toggleEditionMode} />
    }
  </div>
}

export default ProjectIdentity