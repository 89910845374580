// React libs
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Services
import DatavizService from '../Services/DatavizService';
// Hooks
import usePoiFilters from './PoiFilters'
import useGenericData from '../../../../Core/Data/Hooks/GenericData';
// Utils
import { ifDef } from '../../../../Core/Utils/Misc';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'
import * as DatavizTypes from '../Models/Dataviz.type'

const useResourceMetrics = (dateStart: string, dateEnd: string): CoreTypes.IDatavizHook => {
  // Variables
  const { t } = useTranslation(['dataviz', 'common']);
  const { filters, ...filtersProps } = usePoiFilters('resource')
  const { data: metrics, ...props } = useGenericData(() => DatavizService.getResourcesMetrics(dateStart, dateEnd, filters))
  const stringFilters = useMemo(() => ifDef(filters, JSON.stringify), [filters])
  const data = useMemo(() => metrics?.map(({ total, label }: DatavizTypes.IResourceMetric) => ({
    name: label ?? t('common:forms.fields.undefined') ?? '',
    value: total,
  })) ?? [], [metrics, t])

  useEffect(() => {
    props.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, stringFilters])

  return {
    ...props,
    data,
    filter: filtersProps,
    chart: {
      title: t('dataviz:titles.resources'),
      formatter: '{b} : {d}% ({c})'
    }
  }
}

export default useResourceMetrics
