// React libs
import React, { useCallback, useMemo, useRef, useState } from 'react'
// Components
import PoisFilter from '../../../../Core/Components/UiKit/PoisFilter/PoisFilter'

const useProjectFilters = (type: 'project' | 'resource' | 'all') => {
  // Ref
  const toggleFilterVueRef = useRef({ toggleFilterVue: () => { } })

  // State
  const [filters, setFilters] = useState<any>()
  const areFiltersEnabled = useMemo(
    () => type === 'all'
      ? filters?.$or.some(({ $and }: any) => $and.length > 1)
      : filters?.$and.length > 1,
    [filters, type]
  )

  // Handlers
  const onFiltersClear = useCallback(() => {
    setFilters(undefined)
  }, [])
  const onFiltersChange = useCallback(filters => {
    setFilters(filters)
    toggleFilterVueRef.current.toggleFilterVue()
  }, [])

  return {
    filters,
    Component: <PoisFilter filters={filters} onFiltersChange={onFiltersChange} type={type} containerClassName='chart-filters-container' />,
    onClear: onFiltersClear,
    areEnabled: areFiltersEnabled,
    toggleFilterVueRef
  }
}

export default useProjectFilters