// React libs
import React, { FC, Fragment, useState } from 'react';
// Components
import FaIcon from '../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import Header from '../../Header/Header';
import Typography from '../../../../Core/Components/UiKit/Typography/Typography';
// Contexts
import UserContext from '../../../../Core/Data/Contexts/UserContext';
// Services
import LocalStorage from '../../../../Core/Data/Services/Storage/LocalStorage';
// Type
import * as Types from './BaseLayout.type';
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';

const BaseLayout: FC<Types.IProps> = ({ children, className, headerConf }) => {
  // State
  const [user, updateUser]: [
    CoreTypes.IUser,
    (user: CoreTypes.IUser) => void
  ] = useState<CoreTypes.IUser>(LocalStorage.get(LocalStorage.keys.user));

  // Getters
  const getTitle = () => {
    const title = headerConf?.title;
    return (
      <div className='flex items-center justify-center'>
        <Typography
          variant='h1'
          className='flex flex-no-wrap items-center uppercase'
        >
          {title?.icon && (
            <FaIcon name={title.icon} className='mr-2 text-2xl' />
          )}
          <div>{title && title.label}</div>
          {title?.subLabel && (
            <Fragment>
              <div className='hidden sm:flex mx-2'>-</div>
              <div className='hidden sm:flex'>{title.subLabel}</div>
            </Fragment>
          )}
        </Typography>
      </div>
    );
  };
  return (
    <UserContext.Provider value={{ user, updateUser }}>
      <div
        className={`flex flex-col items-center justify-center h-full overflow-hidden w-full ${className}`}
        data-testid='base-layout'
      >
        {headerConf && (
          <Header title={getTitle()} methods={headerConf.methods} />
        )}

        {children}
      </div>
    </UserContext.Provider>
  );
};

BaseLayout.defaultProps = {
  className: '',
};

export default BaseLayout;
