// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Hooks
import useGenericData, { IGetGenericData } from './GenericData';

export interface IGetPoiLinks extends IGetGenericData<CoreTypes.IPoiLink> {
}

const usePoiLinks = (params?: CoreTypes.IUrlQuery): IGetPoiLinks => {
  return useGenericData(() => CommonService.getPoiLinks(params))
}

export default usePoiLinks