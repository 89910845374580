// Utils
import { createContext } from 'react';
// Types
import * as MapTypes from '../Models/Map.type';

export type poiPanelType = 'project' | 'resource' | undefined

export interface IPoiFormPanelContext {
  activePoiPanelType: poiPanelType
  poiToEdit: MapTypes.IPoi | undefined
  setActivePoiPanelType: Function
  setPoiToEdit: Function
}

const PoiFormPanelContext = createContext<IPoiFormPanelContext>({
  activePoiPanelType: undefined,
  poiToEdit: undefined,
  setActivePoiPanelType: (activePoiPanelType: poiPanelType) => { },
  setPoiToEdit: (poi: MapTypes.IPoi) => { }
})

export default PoiFormPanelContext;
