// React libs
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sumBy, round } from 'lodash';
// Components
import FinancingTable from '../FinancingTable/FinancingTable';
// Types
import * as Types from './ProjectEngagedFunds.type'
import * as FinancingTableTypes from '../FinancingTable/FinancingTable.type';

const ProjectEngagedFunds: FC<Types.IProps> = ({ expenseEngaged, fundTypes, onChange, onAdd, onDel, onDelMultiple }) => {
  // Variables
  const { t } = useTranslation(['project']);
  const fundTypesOptions = useMemo(() => fundTypes.map(fundType => ({
    label: fundType.label,
    value: fundType.id
  })), [fundTypes])
  const columns: FinancingTableTypes.IColumn[] = [
    {
      id: 'foundSource',
      field: 'foundSource',
      name: t('project:financing.fundsSource'),
      type: 'text',
      dataField: 'foundSrc'
    },
    {
      id: 'expenseAmount',
      field: 'expenseAmount',
      name: t('project:financing.requestedAmount'),
      type: 'float',
      dataField: 'amount'
    },
    {
      id: 'expenseAmountPercent',
      field: 'expenseAmountPercent',
      name: t('project:financing.inPercent'),
      type: 'percent'
    },
    {
      id: 'expenseFundType',
      field: 'expenseFundType',
      name: t('project:financing.fundsType'),
      type: 'select',
      options: fundTypesOptions,
      dataField: 'fkfundType'
    },
    {
      id: 'expenseAmountPaid',
      field: 'expenseAmountPaid',
      name: t('project:financing.payedAmount'),
      type: 'float',
      dataField: 'amountPaid'
    },
  ]

  const rows: any = useMemo(() => {
    const totalAmount = sumBy(expenseEngaged, 'amount')
    return expenseEngaged.map(expenseEngagedItem => ({
      id: expenseEngagedItem.id,
      foundSource: expenseEngagedItem.foundSrc,
      expenseAmount: expenseEngagedItem.amount,
      expenseAmountPercent: totalAmount === 0 ? 0 : round(expenseEngagedItem.amount * 100 / totalAmount, 1),
      expenseFundType: expenseEngagedItem.fkfundType,
      expenseAmountPaid: (expenseEngagedItem.amountPaid as any) === '' ? '' : +expenseEngagedItem.amountPaid
    }))
  }, [expenseEngaged])

  const searchColumnKeys = [
    'foundSource',
    'expenseAmount',
    'expenseAmountPercent',
    'expenseFundType',
    'expenseAmountPaid'
  ]

  return <div data-testid='ProjectEngagedFunds'>
    <FinancingTable
      columns={columns}
      onAdd={onAdd}
      onChange={onChange}
      onDel={onDel}
      onDelMultiple={onDelMultiple}
      rows={rows}
      searchColumnKeys={searchColumnKeys}
      title={t('project:financing.engagedFunds')}
    />
  </div>
}

export default ProjectEngagedFunds