// React libs
import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Scenes
import Dataviz from '../../../Dimensions/Dataviz/Pages/Dataviz';
import Contacts from '../../../Dimensions/Contacts/Pages/Contacts';
import ErrorScene from '../../Pages/Error/ErrorScene';
import ForgotPasswordScene from '../../Pages/Auth/ForgotPassword/ForgotPasswordScene';
import LayerManagerScene from '../../Pages/Admin/LayerManager/LayerManagerScene';
import LoginScene from '../../Pages/Auth/Login/LoginScene';
import MapScene from '../../../Dimensions/Map/Pages/MapScene';
import NewContact from '../../Pages/Contact/NewContact';
import Planning from '../../../Dimensions/Planning/Pages/Planning/Planning';
import Projects from '../../../Dimensions/Projects/Pages/Projects';
import ProjectScene from '../../../Dimensions/Project/Pages/ProjectScene';
import ResetPasswordScene from '../../Pages/Auth/ResetPassword/ResetPasswordScene';
import Resources from '../../../Dimensions/Resources/Pages/Resources';
import ResourceScene from '../../../Dimensions/Resource/Pages/ResourceScene';
import SharedMap from '../../../Dimensions/Map/Pages/SharedMap/SharedMap';
import UserProfil from '../../Pages/Admin/Profil/ProfilUser'
// Services
import LocalStorage from '../../../Core/Data/Services/Storage/LocalStorage';
// Common
import Common from '../../Resources/Common';

const defaultRoute = Common.Routes.routeMap;
const defaultAdminRoute = Common.Routes.routeLayerManager;


const PrivateRoute = ({ component, ...rest }: any) => (
  <Route
    {...rest}
    render={props => {
      const userId = LocalStorage.get(LocalStorage.keys.userId);
      return userId ? (
        React.createElement(component, props)
      ) : (
        <Redirect
          to={{
            pathname: `/${Common.Routes.routeLogin}`,
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const PublicRoute = ({ component, params, ...rest }: any) => (
  <Route
    {...rest}
    render={(props: any) =>
      React.createElement(component, { ...props, ...params })
    }
  />
);

const NotFoundRoute = () => (
  <Redirect
    to={{
      pathname: `/${Common.Routes.routeError}/404`,
    }}
  />
);

const RouterComponent: FC = () => (
  <main>
    <Switch>
      {/* LOGIN */}
      <PublicRoute
        exact
        path={`/${Common.Routes.routeLogin}`}
        component={LoginScene}
      />
      {/* FORGOT PASSWORD */}
      <PublicRoute
        exact
        path={`/${Common.Routes.routeForgotPassword}`}
        component={ForgotPasswordScene}
      />
      {/* RESET PASSWORD */}
      <PublicRoute
        path={`/${Common.Routes.routeResetPassword}/:encryptedString`}
        component={ResetPasswordScene}
      />
      {/* HOME */}
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeHome}`}
        component={(props: any) => (
          <Redirect
            to={{
              pathname: `${defaultRoute}`,
              state: { from: props.location },
            }}
          />
        )}
      />
      {/* APP */}
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeMap}`}
        component={MapScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.dataviz}`}
        component={Dataviz}
      />
      {/* ADMIN */}
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeAdmin}`}
        component={(props: any) => (
          <Redirect
            to={{
              pathname: `${defaultAdminRoute}`,
              state: { from: props.location },
            }}
          />
        )}
      />
      {/* Contacts */}
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeUpdateContact}/:id?`}
        component={UserProfil}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeCreateContact}`}
        component={NewContact}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeContacts}`}
        component={Contacts}
      />

      <PrivateRoute
        exact
        path={`/${Common.Routes.routeLayerManager}`}
        component={LayerManagerScene}
      />
      {/* ERRORS */}
      <PublicRoute
        path={`/${Common.Routes.routeError}/:code`}
        component={ErrorScene}
      />
      {/* SHARED MAP */}
      <PublicRoute
        path={`/${Common.Routes.routeSharedMap}`}
        component={SharedMap}
      />
      {/* PROJECTS */}
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProjects}`}
        component={Projects}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routePreviewProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeUpdateProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeFurtherInformationPreviewProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeUpdateFurtherInformationProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeContactPreviewProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeUpdateContactProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeEcosystemPreviewProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeUpdateEcosystemProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeTasksManagementPreviewProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeUpdateTasksManagementProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeFinancingPreviewProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeUpdateFinancingProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeStatisticReportPreviewProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeGedPreviewProject}`}
        component={ProjectScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProject}/:id/${Common.Routes.routeUpdateGedProject}`}
        component={ProjectScene}
      />
      {/* RESOURCE */}
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeResources}`}
        component={Resources}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeResource}/:id/${Common.Routes.routePreviewResource}`}
        component={ResourceScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeResource}/:id/${Common.Routes.routeUpdateResource}`}
        component={ResourceScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeResource}/:id/${Common.Routes.routeFurtherInformationPreviewResource}`}
        component={ResourceScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeResource}/:id/${Common.Routes.routeUpdateFurtherInformationResource}`}
        component={ResourceScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeResource}/:id/${Common.Routes.routeContactPreviewResource}`}
        component={ResourceScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeResource}/:id/${Common.Routes.routeUpdateContactResource}`}
        component={ResourceScene}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeResource}/:id/${Common.Routes.routeEcosystemPreviewResource}`}
        component={ResourceScene}
      />

      // Planning
      <PrivateRoute
        exact
        path={`/${Common.Routes.routePlanning}`}
        component={Planning}
      />

      <NotFoundRoute />
    </Switch>
  </main>
);

export default RouterComponent;
