// React libs
import React, { FC, useMemo } from 'react'
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
// Components
import AdvancedPoiFiltersForm from '../../Form/AdvancedPoiFilters/AdvancedPoiFiltersForm'
// Types
import * as Types from './PoisFilter.type'
import * as AdvancedFiltersTypes from '../../Form/AdvancedPoiFilters/AdvancedPoiFiltersForm.type'
// Utils
import { getFormatPoiFilter, parsePoiFilter } from '../../../Utils/Filters';
import { ifDef } from '../../../Utils/Misc';

const PoisFilter: FC<Types.IProps> = ({ type, filters, onFiltersChange, containerClassName }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const requiredFieldMessage = t('common:errors.defaultRequiredField')
  const itemSchema: any = Yup.object().shape({
    name: Yup.string().required(requiredFieldMessage),
    operator: Yup.string().required(requiredFieldMessage),
    value: Yup.mixed().required(requiredFieldMessage)
  })
  const parsedFilters = useMemo(() => ifDef(filters, (filters: any) => parsePoiFilter(type === 'all' ? filters.$or : [filters], type)), [filters, type])

  const optionsByType = useMemo(() => ({
    all: {
      defaultValues: parsedFilters ?? {
        project: [],
        resource: []
      },
      validationSchema: Yup.object().shape({
        project: Yup.array().of(itemSchema),
        resource: Yup.array().of(itemSchema)
      }),
      onFormSubmit({ project, resource }: AdvancedFiltersTypes.IFormValues) {
        const { advanced, formatPoiFilter } = getFormatPoiFilter('all')
        project?.forEach((filter: AdvancedFiltersTypes.IFormValue) => formatPoiFilter('project', filter))
        resource?.forEach((filter: AdvancedFiltersTypes.IFormValue) => formatPoiFilter('resource', filter))
        onFiltersChange(advanced)
      }
    },
    project: {
      defaultValues: parsedFilters ?? {
        project: [],
      },
      validationSchema: Yup.object().shape({
        project: Yup.array().of(itemSchema),
      }),
      onFormSubmit({ project }: AdvancedFiltersTypes.IFormValues) {
        const { advanced, formatPoiFilter } = getFormatPoiFilter('project')
        project?.forEach((filter: AdvancedFiltersTypes.IFormValue) => formatPoiFilter('project', filter))
        onFiltersChange(advanced)
      }
    },
    resource: {
      defaultValues: parsedFilters ?? {
        resource: []
      },
      validationSchema: Yup.object().shape({
        resource: Yup.array().of(itemSchema),
      }),
      onFormSubmit({ resource }: AdvancedFiltersTypes.IFormValues) {
        const { advanced, formatPoiFilter } = getFormatPoiFilter('resource')
        resource?.forEach((filter: AdvancedFiltersTypes.IFormValue) => formatPoiFilter('resource', filter))
        onFiltersChange(advanced)
      }
    }
  }), [parsedFilters, itemSchema, onFiltersChange])

  return <div className='h-full w-full' data-testid='PoisFilter'>
    <AdvancedPoiFiltersForm
      miscData={{ hideResource: type === 'project', hideProject: type === 'resource', containerClassName }}
      {...optionsByType[type]}
    />
  </div>
}

export default PoisFilter