// React libs
import { useCallback, useEffect, useMemo } from 'react'
import moment from 'moment'
import { keyBy, sortBy } from 'lodash'
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'
// Services
import DatavizService from '../Services/DatavizService';
// Hooks
import usePoiFilters from './PoiFilters'
import usePoiTypes from '../../../../Core/Data/Hooks/PoiTypes';
import useGenericData from '../../../../Core/Data/Hooks/GenericData';
// Utils
import { ifDef } from '../../../../Core/Utils/Misc'

const useData = (dateStart: string, dateEnd: string, filters: any) => {
  // Variable
  const resourceEvolutionMetrics = useGenericData(() => DatavizService.getResourceEvolutionMetrics(dateStart, dateEnd, filters))
  const poiTypes = usePoiTypes('resource')

  const isLoading = resourceEvolutionMetrics.isLoading || poiTypes.isLoading
  const refreshTime = useMemo(() => Math.max(resourceEvolutionMetrics.refreshTime, poiTypes.refreshTime), [poiTypes.refreshTime, resourceEvolutionMetrics.refreshTime])
  const poiTypesById = useMemo(() => keyBy(poiTypes.data, 'id'), [poiTypes])
  const legends = useMemo(() => {
    if (resourceEvolutionMetrics.data == null || poiTypes.data == null || poiTypes.data.length === 0) {
      return []
    }

    const [entry] = resourceEvolutionMetrics.data
    return Object.keys(entry).filter(key => key !== 'date').map(id => poiTypesById[id]?.label)
  }, [poiTypes.data, poiTypesById, resourceEvolutionMetrics.data])
  const { dates, series } = useMemo(() => {
    const dates: string[] = []
    const dataByLegend: { [key: string]: any } = {}
    sortBy(resourceEvolutionMetrics.data, 'date').forEach(({ date, ...poiTypes }) => {
      dates.push(moment(date).format('MMM YY'))

      Object.entries(poiTypes).forEach(([poiTypeId, value]) => {
        const poiTypeLabel = poiTypesById[poiTypeId]?.label
        if (dataByLegend[poiTypeLabel] === undefined) {
          dataByLegend[poiTypeLabel] = []
        }
        dataByLegend[poiTypeLabel].push(value)
      })
    })
    return {
      dates,
      series: Object.entries(dataByLegend).map(([legend, data]) => ({
        legend,
        data
      }))
    }
  }, [poiTypesById, resourceEvolutionMetrics.data])

  // Handlers
  const refresh = useCallback(() => {
    if (!isLoading) {
      resourceEvolutionMetrics.refresh()
      poiTypes.refresh()
    }
  }, [isLoading, poiTypes, resourceEvolutionMetrics])

  return {
    isLoading,
    refresh,
    refreshTime,
    data: {
      legends,
      dates,
      series
    },
  }
}

const ResourceEvolutionMetrics = (dateStart: string, dateEnd: string): CoreTypes.IDatavizHook => {
  // Variables
  const { filters, ...filtersProps } = usePoiFilters('resource')
  const data = useData(dateStart, dateEnd, filters)
  const stringFilters = useMemo(() => ifDef(filters, JSON.stringify), [filters])

  // Effects
  useEffect(() => {
    data.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, stringFilters])

  return {
    ...data,
    filter: filtersProps
  }
}

export default ResourceEvolutionMetrics