// React libs
import React, { FC } from 'react';
// Type
import * as Types from './FormTitle.type';
// Components
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const FormTitle: FC<Types.IProps> = ({ title }) =>
  <div className='my-1' data-testid='form-title'>
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <Divider orientation="horizontal" variant="middle" />
  </div>

export default FormTitle