// React libs
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@material-ui/core';
// Components
import DataTable from '../../UiKit/DataTable/DataTable';
import DeleteButton from '../../UiKit/Button/DeleteButton/DeleteButton';
import FaIcon from '../../UiKit/Icon/FaIcon/FaIcon';
import InfoButton from '../../UiKit/Button/InfoButton/InfoButton';
import { getStringPreview } from '../FormFields/FormFields';
// Types
import * as Types from './Contacts.type'

export const COLUMN_IDS = {
  addAction: 'addAction',
  name: 'name',
  firstName: 'firstname',
  mail: 'mail',
  mobile: 'mobile',
  tel: 'phone',
  website: 'url',
  comment: 'comment',
  accountPermission: 'accountPermission',
  editActions: 'editActions'
}

const Contacts: FC<Types.IProps> = ({
  contacts,
  className,
  columnPredicate = () => true,
  onAddNewContact,
  onAssociateContact = () => { },
  onDeleteContact = () => { },
  onDeleteAllContacts,
  onEditContact = () => { },
  onRowDblClick
}) => {
  // Variables
  const { t } = useTranslation(['common']);
  const columns: any = useMemo(() => [
    {
      id: COLUMN_IDS.addAction,
      field: 'addAction',
      align: 'center',
      name: '',
      renderCell: (person: any) => <div className='flex items-center justify-center'>
        <Tooltip title={t('common:forms.tooltips.clickToAddContact') ?? ''}>
          <IconButton
            aria-label='add'
            onClick={() => onAssociateContact(person)}
            size='small'
          >
            <FaIcon name='plus' className='text-sm' />
          </IconButton>
        </Tooltip>
      </div>
    },
    {
      id: COLUMN_IDS.name,
      field: 'name',
      name: t('common:forms.fields.name'),
    },
    {
      id: COLUMN_IDS.firstName,
      field: 'firstname',
      name: t('common:forms.fields.firstName'),
    },
    {
      id: COLUMN_IDS.mail,
      field: 'mail',
      name: t('common:forms.fields.email'),
    },
    {
      id: COLUMN_IDS.mobile,
      field: 'mobile',
      name: t('common:forms.fields.mobile'),
    },
    {
      id: COLUMN_IDS.tel,
      field: 'phone',
      name: t('common:forms.fields.tel'),
    },
    {
      id: COLUMN_IDS.website,
      field: 'url',
      name: t('common:forms.fields.website'),
      renderCell: ({ url }: any) => <p>
        <Tooltip title={url}>
          <div className='truncate max-w-40 whitespace-nowrap'>
            <a
              href={url}
              target='_blank'
              rel='noopener noreferrer'
              style={{
                textDecoration: 'underline',
                color: 'blue'
              }}
            >
              {url}
            </a>
          </div>
        </Tooltip>
      </p>
    },
    {
      id: COLUMN_IDS.comment,
      field: 'comment',
      name: t('common:forms.fields.comment'),
      renderCell: ({ comment }: any) => <p>{comment != null ? getStringPreview(comment) : comment}</p>
    },
    {
      id: COLUMN_IDS.accountPermission,
      field: 'accountPermission',
      name: t('common:forms.fields.account'),
      canTruncate: true,
      renderCell: ({ accountPermission }: any) => <p>{
        accountPermission !== undefined
          ? <span><FaIcon name='key' /> {accountPermission}</span>
          : <span><FaIcon name='address-card-o' /> {t('common:forms.fields.contact')}</span>
      }</p>
    },
    {
      id: COLUMN_IDS.editActions,
      field: 'editActions',
      align: 'center',
      name: '',
      renderCell: (params: any) => <div className='flex items-center justify-center'>
        <InfoButton
          onClick={e => onEditContact(params)}
          size='small'
          tooltip={t('common:forms.tooltips.goToIdentity')}
        />
        <DeleteButton
          onClick={e => onDeleteContact(params)}
          size='small'
          tooltip={t('common:forms.tooltips.deleteContact')}
        />
      </div>
    }
  ].filter(columnPredicate), [columnPredicate, onAssociateContact, onDeleteContact, onEditContact, t])
  const rows = useMemo(() => contacts?.map(person => ({
    ...person,
    accountPermission: person.account?.accessType?.label
  })) ?? [], [contacts])

  return <div data-testid='Contacts' className='h-full w-full'>
    <DataTable
      columns={columns}
      rows={rows}
      onCreate={onAddNewContact}
      createButtonTooltip={t('common:forms.tooltips.addNewContact')}
      onRowDblClick={onRowDblClick}
      onDeleteAllRows={onDeleteAllContacts}
      containerClassName={className}
    />
  </div>
}

export default Contacts