export default class MapUrls {
  static path = process.env.REACT_APP_HOST;
  static mapBoxApi = 'https://api.mapbox.com/geocoding/v5/mapbox.places';

  static get apiPath(): string {
    return `${MapUrls.path || ''}/api`;
  }
  // Data
  static getCountries() {
    return `${process.env.PUBLIC_URL}/data/countries.json`;
  }
  static getPoiTypes() {
    return `${MapUrls.apiPath}/poitypes?expand=all`;
  }
  static getPhaseTypes() {
    return `${MapUrls.apiPath}/phasetypes?expand=all`;
  }
  static getLinkTypes() {
    return `${MapUrls.apiPath}/linktypes?expand=all`;
  }
  // Config
  static getMapConfig() {
    return `${MapUrls.apiPath}/configs/mapConfig`;
  }
  static updateMapConfig() {
    return `${MapUrls.apiPath}/configs/mapConfig`;
  }
  // Layers
  static getLayers(filter?: any) {
    let parameters = 'expand=all';
    if (filter !== undefined) {
      parameters += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    return `${MapUrls.apiPath}/layermaps?${parameters}`;
  }
  static createLayer() {
    return `${MapUrls.apiPath}/layermaps`;
  }
  static updateLayer(id: string) {
    return `${MapUrls.apiPath}/layermaps/${id}`;
  }
  static deleteLayer(id: string) {
    return `${MapUrls.apiPath}/layermaps/${id}`;
  }
  // Layers legend images
  static createLegendImage() {
    return `${MapUrls.apiPath}/layermapimages`;
  }
  // Pois
  static getPois() {
    return `${MapUrls.apiPath}/markers?content=provenance&expand=allowedPerson%2Caddress`;
  }
  static savePoi() {
    return `${MapUrls.apiPath}/pois`;
  }
  static updatePoi(id: string) {
    return `${MapUrls.apiPath}/pois/${id}`;
  }
  static deletePoi(id: string) {
    return `${MapUrls.apiPath}/pois/${id}`;
  }
  static getPoiDetails(id: string) {
    return `${MapUrls.apiPath}/pois/${id}?expand=link%2Caddress%2Cphase%2Cperson%2CvalueSlot%2Ctask%2Cthematic%2CallowedPerson%2CexpenseLine%2CexpenseFunding%2CexpenseEngaged%2Cpoi%2CtaskComment%2Cged&content=provenance%2CcountLinkType%2CcountPhaseType`;
  }
  static searchPoi(search: string, size: number = 25) {
    let url = `${MapUrls.apiPath}/pois`;
    let hasParameter = false;
    if (search !== '') {
      url = `${url}${!hasParameter ? '?' : ''}filter=${search}`;
      hasParameter = true;
    }
    if (size !== -1) {
      url = `${url}${!hasParameter ? '?' : '&'}size=${size}`;
    }
    return url;
  }
  static savePoiPhase() {
    return `${MapUrls.apiPath}/poiphases`;
  }
  static deletePoiPhase(id: string) {
    return `${MapUrls.apiPath}/poiphases/${id}`;
  }
  static getPoiLinks() {
    return `${MapUrls.apiPath}/poilinks`;
  }
  static addPoiLink() {
    return `${MapUrls.apiPath}/poilinks`;
  }
  static updatePoiLink(fkpoiFrom: string, fkpoiTo: string) {
    return `${MapUrls.apiPath}/poilinks/${fkpoiFrom}%2C${fkpoiTo}`;
  }
  static deletePoiLink(fkpoiFrom: string, fkpoiTo: string) {
    return `${MapUrls.apiPath}/poilinks/${fkpoiFrom}%2C${fkpoiTo}`;
  }
  static deletePoiAllowedPerson(poiId: string, allowedPerson: string) {
    return `${MapUrls.apiPath}/poiallowedpersons/${poiId}%2C${allowedPerson}`;
  }
  static deletePoiThematic(thematicId: string, poiId: string) {
    return `${MapUrls.apiPath}/poithematics/${thematicId}%2C${poiId}`;
  }

  // Addresses
  static saveAdress() {
    return `${MapUrls.apiPath}/addresses`;
  }
  static searchMapboxAddress(token: string, query: string, countries: string) {
    const encodedQuery = encodeURIComponent(query);
    let parameters = `access_token=${token}&search=${encodedQuery}&language=fr`;
    if (countries !== '')
      parameters += `&country=${encodeURIComponent(countries)}`;
    return `${MapUrls.mapBoxApi}/${encodedQuery}.json?${parameters}`;
  }
  // External territories
  static getExternalTerritories() {
    return `${process.env.PUBLIC_URL}/data/territories.json`;
  }

  // Thematic
  static getThematic() {
    return `${MapUrls.apiPath}/thematic`;
  }

  // Territories
  static getTerritories() {
    return `${MapUrls.apiPath}/territories`;
  }

  // Selections
  static getSelections(fkaccount: string | undefined, size: number = 20) {
    let params = `size=${size}&expand=layerMap`;
    if (fkaccount !== undefined) {
      const filter = encodeURIComponent(
        JSON.stringify({
          $and: [{ fkaccount }],
        })
      );
      params += `&filter=${filter}`;
    }
    return `${MapUrls.apiPath}/selections?${params}`;
  }

  static saveSelection() {
    return `${MapUrls.apiPath}/selections`;
  }

  static updateSelection(id: string) {
    return `${MapUrls.apiPath}/selections/${id}`;
  }

  static deleteSelection(id: string) {
    return `${MapUrls.apiPath}/selections/${id}`;
  }

  // Map shares
  static getMapShares(fkaccount: string | undefined) {
    let params = '';
    if (fkaccount !== undefined) {
      const filter = encodeURIComponent(
        JSON.stringify({
          fkaccount,
        })
      );
      params += `&filter=${filter}`;
    }
    return `${MapUrls.apiPath}/mapshares?${params}`;
  }

  static getMapSharesPois(id: string) {
    return `${MapUrls.apiPath}/shares/public/markers?pt=${id}&expand=all`;
  }

  static shareMap() {
    return `${MapUrls.apiPath}/mapshares`;
  }

  static stopMapShare(id: string) {
    return `${MapUrls.apiPath}/mapshares/${id}`;
  }

  // Prefs
  static updatePref(id: string) {
    return `${MapUrls.apiPath}/prefs/${id}`;
  }
}
