// React libs
import React, { FC } from 'react';
import { Tooltip } from '@material-ui/core';
// Components
import FaIcon from '../../Icon/FaIcon/FaIcon';
// Type
import * as Types from './HelperTooltip.type';

const HelperTooltip: FC<Types.IProps> = ({ message }) => {
  return (
    <Tooltip
      title={<div dangerouslySetInnerHTML={{ __html: message }} />}
      classes={{ popper: 'whitespace-pre-line' }}
      data-i18n='[html]content.body'
    >
      <div data-testid='helper-tooltip'>
        <FaIcon
          name='question-circle'
          className='cursor-pointer ml-2 text-lg'
        />
      </div>
    </Tooltip>
  );
};

export default HelperTooltip;
