// React libs
import React, { FC } from 'react';
// Components
import GenericIconButton from '../GenericIconButton/GenericIconButton';
// Types
import * as Types from './MarkerButton.type'

const MarkerButton: FC<Types.IProps> = ({ onClick, tooltip, size }) => {
  return <span data-testid='MarkerButton'>
    <GenericIconButton
      ariaLabel='marker button'
      onClick={onClick}
      icon='map-marker'
      tooltip={tooltip}
      size={size}
    />
  </span>
}

export default MarkerButton