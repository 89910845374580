// React libs
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
// Components
import FaIcon from '../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import Typography from '../../../Core/Components/UiKit/Typography/Typography';
// Type
import * as Types from './ErrorScene.type';
// Common
import Common from '../../Resources/Common';
// Images
import LOGO from '../../Resources/assets/img/common/logo.png';

const ErrorBoundary: FC<Types.IProps> = ({ match }) => {
  const code: number = parseInt(match.params.code, 10);

  const renderLogo = () => <img className='h-40' src={LOGO} alt='logo' />;
  const render404Page = () => {
    return (
      <div
        data-testid='error-404-page'
        className='flex flex-col h-full items-center justify-center w-full border border-red-500'
      >
        {renderLogo()}
        <Typography variant='h4'>Page introuvable</Typography>
        <Typography variant='h6'>
          <Link
            to={`/${Common.Routes.routeHome}`}
            className='flex items-center text-gray-600'
          >
            <FaIcon name='home' className='mr-1' />
            <span>Retour à l'accueil</span>
          </Link>
        </Typography>
      </div>
    );
  };
  const renderErrorPage = () => {
    return <div data-testid='error-500-page'>ERROR</div>;
  };

  return code === 404 ? render404Page() : renderErrorPage();
};

export default ErrorBoundary;
