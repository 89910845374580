// React libs 
import { Toolbar, Gantt } from '@bryntum/gantt/gantt.umd.js'

export const type = 'planningToolbar'

class PlanningToolbar extends Toolbar {
  gantt: Gantt | undefined

  static get type() {
    return type
  }

  static get $name() {
    return 'PlanningToolbar'
  }

  // Called when toolbar is added to the Gantt panel
  set parent(parent: Gantt) {
    // @ts-ignore: Unreachable code error
    super.parent = parent
    this.gantt = parent
  }

  get parent() {
    // @ts-ignore: Unreachable code error
    return super.parent
  }

  static get configurable() {
    return {
      defaults: {
        localeClass: this
      },
      items: [
        {
          type: 'container',
          cls: 'w-full flex justify-end gap-2',
          items: [
            {
              type: 'button',
              color: 'b-red',
              icon: 'b-fa-square',
              pressedIcon: 'b-fa-check-square',
              ref: 'collapse',
              text: 'L{PlanningToolbar.unCollapseAll}',
              toggleable: true,
              onToggle: 'up.toggleCollapse',
            },
            {
              type: 'buttonGroup',
              items: [
                {
                  cls: 'b-raised bg-active',
                  ref: 'previousButton',
                  icon: 'b-fa b-fa-backward',
                  tooltip: 'L{PlanningToolbar.previousTimeSpan}',
                  onAction: 'up.previous',
                },
                {
                  cls: 'b-raised bg-active',
                  ref: 'nextButton',
                  icon: 'b-fa b-fa-forward',
                  tooltip: 'L{PlanningToolbar.nextTimeSpan}',
                  onAction: 'up.next',
                },
                {
                  cls: 'b-raised bg-active',
                  ref: 'zoomInButton',
                  icon: 'b-fa b-fa-search-plus',
                  tooltip: 'L{PlanningToolbar.zoomIn}',
                  onAction: 'up.zoomIn'
                },
                {
                  cls: 'b-raised bg-active',
                  ref: 'zoomOutButton',
                  icon: 'b-fa b-fa-search-minus',
                  tooltip: 'L{PlanningToolbar.zoomOut}',
                  onAction: 'up.zoomOut',
                },
              ]
            },
            {
              type: 'textfield',
              ref: 'filterByName',
              cls: 'filter-by-name',
              flex: '0 0 15em',
              placeholder: 'L{PlanningToolbar.searchByProjectName}',
              clearable: true,
              keyStrokeChangeDelay: 100,
              onChange: 'up.filterByProjectName'
            }
          ]
        }
      ]
    }
  }

  zoomIn() {
    this.gantt?.zoomIn()
  }

  zoomOut() {
    this.gantt?.zoomOut()
  }

  previous() {
    this.gantt?.shiftPrevious()
  }

  next() {
    this.gantt?.shiftNext()
  }

  filterByProjectName({ value }: any) {
    if (value.trim() === '') {
      return this.gantt?.taskStore.clearFilters()
    }

    const shouldDisplayTask: any = (task: any) => task.parent.isRoot
      ? task.name?.toLowerCase().includes(value.toLowerCase())
      : shouldDisplayTask(task.parent)

    this.gantt?.taskStore.filter({
      filters: shouldDisplayTask,
      replace: true
    })
  }

  toggleCollapse({ pressed }: any) {
    if (pressed) {
      this.gantt?.expandAll()
    } else {
      this.gantt?.collapseAll()
    }
  }
}

PlanningToolbar.initClass()