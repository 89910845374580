import aikido from '../pictograms/sports/shodokan.png';
import aquabike from '../pictograms/sports/stationary-bike.png';
import aquadance from '../pictograms/sports/pool-exercises.png';
import aquafitness from '../pictograms/sports/aqua-gym.png';
import aquagym from '../pictograms/sports/aquagym.png';
import athletisme from '../pictograms/sports/running.png';
import aviron from '../pictograms/sports/rowing.png';
import badminton from '../pictograms/sports/badminton.png';
import baignade from '../pictograms/sports/swim.png';
import basket from '../pictograms/sports/basketball-ball-variant.png';
import basketAdapte from '../pictograms/sports/disabled.png';
import bebesNageurs from '../pictograms/sports/goggles.png';
import boxeAnglaise from '../pictograms/sports/boxing-glove.png';
import boxeFrancaise from '../pictograms/sports/french-boxe.png';
import boxeThailandaise from '../pictograms/sports/muay-thai.png';
import canoeKayak from '../pictograms/sports/kayak.png';
import capoeira from '../pictograms/sports/capoeira.png';
import circuitTraining from '../pictograms/sports/weightlift.png';
import cirque from '../pictograms/sports/circus.png';
import crossfitness from '../pictograms/sports/weight-lifting.png';
import cyclisme from '../pictograms/sports/cycling.png';
import danse from '../pictograms/sports/dancing.png';
import dimancheDecouverte from '../pictograms/sports/dimanche-decouverte.png'; // Used the same picto for 'Dimanche liberté'
import doubleDutch from '../pictograms/sports/skipping-rope.png';
import escalade from '../pictograms/sports/climber.png';
import escrime from '../pictograms/sports/fencing.png';
import fitness from '../pictograms/sports/yoga-pose.png';
import football from '../pictograms/sports/soccer-player.png';
import futsal from '../pictograms/sports/futsal.png';
import golf from '../pictograms/sports/golf-player.png';
import gymnastique from '../pictograms/sports/stretching-exercises.png';
import gymDouce from '../pictograms/sports/warm-up.png';
import gymVolontaire from '../pictograms/sports/fitness.png';
import handball from '../pictograms/sports/handball.png';
import hockeySurGlace from '../pictograms/sports/hockeySurGlace.png';
import judo from '../pictograms/sports/karate.png';
import karate from '../pictograms/sports/karate-classic.png';
import kayak from '../pictograms/sports/kayak-solo.png';
import multisport from '../pictograms/sports/multisports.png';
import musculation from '../pictograms/sports/testosterone.png';
import natation from '../pictograms/sports/swimmer.png';
import patinageArtistique from '../pictograms/sports/skate.png';
import padel from '../pictograms/sports/paddling.png';
import petanque from '../pictograms/sports/playing.png';
import pilates from '../pictograms/sports/exercise.png';
import plongee from '../pictograms/sports/diver.png';
import promenade from '../pictograms/sports/walking.png';
import randonnee from '../pictograms/sports/hiking.png';
import roller from '../pictograms/sports/roller.png';
import rugby from '../pictograms/sports/rugby-runner-with-the-ball.png';
import step from '../pictograms/sports/step.png';
import streetWorkout from '../pictograms/sports/gym.png';
import stretching from '../pictograms/sports/dancer-pose.png';
import stretchingEnergetique from '../pictograms/sports/female-streching-one-leg-position.png';
import taiChi from '../pictograms/sports/tai-chi-chuan-silhouette.png';
import tennis from '../pictograms/sports/tennis-player-with-racket.png';
import tennisDeTable from '../pictograms/sports/tennis-de-table.png';
import tirALarc from '../pictograms/sports/hunter-hunting-with-bow-and-arrow.png';
import tirALarcAdapte from '../pictograms/sports/archery.png';
import trampoline from '../pictograms/sports/trampoline.png';
import triathlon from '../pictograms/sports/triathlon.png';
import voVietnam from '../pictograms/sports/occupation.png';
import volley from '../pictograms/sports/volleyball-player-motion.png';
import yoga from '../pictograms/sports/meditation.png';
import zumba from '../pictograms/sports/zumba.png';

export const sportsPicto = [
  {
    slug: 'aikido',
    picto: aikido,
  },
  {
    slug: 'aquabike',
    picto: aquabike,
  },
  {
    slug: 'aquadance',
    picto: aquadance,
  },
  {
    slug: 'aquafitness',
    picto: aquafitness,
  },
  {
    slug: 'aquagym',
    picto: aquagym,
  },
  {
    slug: 'athletisme',
    picto: athletisme,
  },
  {
    slug: 'aviron',
    picto: aviron,
  },
  {
    slug: 'badminton',
    picto: badminton,
  },
  {
    slug: 'baignade',
    picto: baignade,
  },
  {
    slug: 'basket',
    picto: basket,
  },
  {
    slug: 'basket-adapte',
    picto: basketAdapte,
  },
  {
    slug: 'bebes-nageurs',
    picto: bebesNageurs,
  },
  {
    slug: 'boxe-anglaise',
    picto: boxeAnglaise,
  },
  {
    slug: 'boxe-française',
    picto: boxeFrancaise,
  },
  {
    slug: 'boxe-thaïlandaise',
    picto: boxeThailandaise,
  },
  {
    slug: 'canoe-kayak',
    picto: canoeKayak,
  },
  {
    slug: 'capoeira',
    picto: capoeira,
  },
  {
    slug: 'circuit-training',
    picto: circuitTraining,
  },
  {
    slug: 'cirque',
    picto: cirque,
  },
  {
    slug: 'crossfitness',
    picto: crossfitness,
  },
  {
    slug: 'cyclisme',
    picto: cyclisme,
  },
  {
    slug: 'danse',
    picto: danse,
  },
  {
    slug: 'dimanche-decouverte',
    picto: dimancheDecouverte,
  },
  {
    slug: 'dimanche-liberte',
    picto: dimancheDecouverte,
  },
  {
    slug: 'double-dutch',
    picto: doubleDutch,
  },
  {
    slug: 'escalade',
    picto: escalade,
  },
  {
    slug: 'escrime',
    picto: escrime,
  },
  {
    slug: 'fitness',
    picto: fitness,
  },
  {
    slug: 'football',
    picto: football,
  },
  {
    slug: 'futsal',
    picto: futsal,
  },
  {
    slug: 'golf',
    picto: golf,
  },
  {
    slug: 'gymnastique',
    picto: gymnastique,
  },
  {
    slug: 'gym-douce',
    picto: gymDouce,
  },
  {
    slug: 'gym-volontaire',
    picto: gymVolontaire,
  },
  {
    slug: 'handball',
    picto: handball,
  },
  {
    slug: 'hockey-sur-glace',
    picto: hockeySurGlace,
  },
  {
    slug: 'judo',
    picto: judo,
  },
  {
    slug: 'karate',
    picto: karate,
  },
  {
    slug: 'kayak',
    picto: kayak,
  },
  {
    slug: 'multisport',
    picto: multisport,
  },
  {
    slug: 'musculation',
    picto: musculation,
  },
  {
    slug: 'natation',
    picto: natation,
  },
  {
    slug: 'patinage-artistique',
    picto: patinageArtistique,
  },
  {
    slug: 'padel',
    picto: padel,
  },
  {
    slug: 'petanque',
    picto: petanque,
  },
  {
    slug: 'pilates',
    picto: pilates,
  },
  {
    slug: 'plongee',
    picto: plongee,
  },
  {
    slug: 'promenade',
    picto: promenade,
  },
  {
    slug: 'randonnee',
    picto: randonnee,
  },
  {
    slug: 'renforcement-musculaire',
    picto: musculation,
  },
  {
    slug: 'roller',
    picto: roller,
  },
  {
    slug: 'rugby',
    picto: rugby,
  },
  {
    slug: 'sport-adapte',
    picto: basketAdapte,
  },
  {
    slug: 'step',
    picto: step,
  },
  {
    slug: 'street-workout',
    picto: streetWorkout,
  },
  {
    slug: 'stretching',
    picto: stretching,
  },
  {
    slug: 'stretching-energetique',
    picto: stretchingEnergetique,
  },
  {
    slug: 'taekwondo',
    picto: judo,
  },
  {
    slug: 'tai-chi',
    picto: taiChi,
  },
  {
    slug: 'tennis',
    picto: tennis,
  },
  {
    slug: 'tennis-de-table',
    picto: tennisDeTable,
  },
  {
    slug: 'tir-a-larc',
    picto: tirALarc,
  },
  {
    slug: 'tir-a-larc-adapte',
    picto: tirALarcAdapte,
  },
  {
    slug: 'trampoline',
    picto: trampoline,
  },
  {
    slug: 'triathlon',
    picto: triathlon,
  },
  {
    slug: 'vo-vietnam',
    picto: voVietnam,
  },
  {
    slug: 'volley',
    picto: volley,
  },
  {
    slug: 'yoga',
    picto: yoga,
  },
  {
    slug: 'yang-taiji',
    picto: taiChi,
  },
  {
    slug: 'zumba',
    picto: zumba,
  },
];
