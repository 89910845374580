// React libs
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Components
import FaIcon from '../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
// Type
import * as Types from './AdminSidebar.type';
// Common
import Common from '../../../Resources/Common';
// Services
import CommonUrls from '../../../../Core/Data/Services/CommonUrls';

const AdminSidebar: FC<Types.IProps> = () => {
  // Variables
  const { t } = useTranslation(['admin']);
  const widthClass = 'w-56';
  const menuConfig: Types.IAdminMenuItem[] = [
    {
      id: 1,
      label: t('admin:menu.layer.label'),
      icon: <FaIcon name='picture-o' />,
      link: `/${Common.Routes.routeLayerManager}`,
    },
    {
      id: 2,
      label: t('admin:menu.addressesConf'),
      icon: <FaIcon name='address-card-o' />,
      link: CommonUrls.getAddressesPanel(),
      isExternalLink: true
    },
    {
      id: 3,
      label: t('admin:menu.advancedConfiguration'),
      icon: <FaIcon name='cog' />,
      link: CommonUrls.getAdminPanel(),
      isExternalLink: true
    },
  ];

  // Getters
  const isItemSelected = (route: string) => {
    const pathname = document.location.pathname;
    const paths = pathname.split('/');
    const linkElements = route.split('/');
    const linkToTest =
      linkElements[0] === '' ? linkElements[1] : linkElements[0];
    let isActive = false;
    if (paths.length > 1) {
      isActive = paths[1] === linkToTest;
    }
    return isActive;
  };
  return (
    <div className='h-full relative' data-testid='admin-sidebar'>
      <Drawer
        className={`${widthClass}`}
        variant='permanent'
        classes={{
          paper: `absolute bg-main-light flex flex-col px-2 py-1 ${widthClass}`,
        }}
      >
        <List>
          {menuConfig.map((item: Types.IAdminMenuItem) => (
            item.isExternalLink
              ? <a
                href={item.link}
                target='_blank'
                rel='noopener noreferrer'
                key={item.id}
              >
                <ListItem>
                  <ListItemIcon classes={{ root: 'min-w-0 mr-2' }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              </a>
              : <ListItem
                component={Link}
                to={item.link}
                selected={isItemSelected(item.link)}
                key={item.id}
              >
                <ListItemIcon classes={{ root: 'min-w-0 mr-2' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default AdminSidebar;
