// Services
import CommonService from '../../../Core/Data/Services/CommonService';
import MapService from '../Data/Services/MapService';
// Type
import * as MapTypes from '../Data/Models/Map.type';

export const deleteLayer = async (layer: MapTypes.ILayer) => {
  if (layer.images?.length > 0) {
    await Promise.all(layer.images.map(async ({ image, legendType }) => {
      await CommonService.deleteImageLegend(layer.id, legendType)
      await CommonService.deleteImage(image.id)
    }))
  }
  await MapService.deleteLayer(layer.id)
}