// React libs
import React, { FC, useCallback } from 'react';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Components
import NewContactForm from '../../../../../../App/Components/Form/FormType/NewContactForm/NewContactForm';
import Typography from '../../../../UiKit/Typography/Typography';
// Types
import * as Types from './NewContact.type'
import * as NewContactTypes from '../../../../../../App/Components/Form/FormType/NewContactForm/NewContactForm.type'
// Utils
import useServiceErrorHandler from '../../../../../Utils/useServiceErrorHandler';
import { patchFormValues } from '../../../../../Utils/FormUtils';
// Common
import CoreCommon from '../../../../../Resources/Common';
// Services
import CommonService from '../../../../../Data/Services/CommonService';

const NewContact: FC<Types.IProps> = ({ postSubmit }) => {
  // Variable
  const handleServiceError = useServiceErrorHandler()
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: NewContactTypes.IFormValues = {
    name: '',
    firstname: '',
    url: '',
    mail: '',
    phone: '',
    mobile: '',
    comment: '',
    fkaddress: ''
  }
  const validationSchema = Yup.object({
    name: Yup.string().required(t('common:errors.defaultRequiredField')),
    firstname: Yup.string().required(t('common:errors.defaultRequiredField')),
    url: Yup.string(),
    mail: Yup.string().email(t('common:errors.nonValidEmail')),
    phone: Yup.string(),
    mobile: Yup.string(),
    comment: Yup.string(),
    fkaddress: Yup.string()
  })
  // Handlers
  const onSubmit = useCallback(async (newValues: NewContactTypes.IFormValues, { setSubmitting }: FormikHelpers<any>) => {
    setSubmitting(true);
    try {
      const values = patchFormValues(defaultValues, newValues)

      await CommonService.savePerson(values)
      enqueueSnackbar(t('common:forms.validation.saveSucceeded'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'success',
      })
    } catch (error) {
      handleServiceError(error)
    } finally {
      setSubmitting(false)
      postSubmit()
    }
  }, [defaultValues, enqueueSnackbar, handleServiceError, t, postSubmit])

  return <div data-testid='NewContact' className='w-full h-full'>
    <div className='w-full h-0.5/10'>
      <Typography variant='h6'>
        {t('common:forms.titles.newContact')}
      </Typography>
    </div>
    <div className='w-full h-9.5/10'>
      <NewContactForm
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        onFormSubmit={onSubmit}
        miscFunctions={{ onCancel: postSubmit }}
      />
    </div>
  </div>
}

export default NewContact