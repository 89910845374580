// React libs
import React, { FC } from 'react';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// Components
import FaIcon from '../../Icon/FaIcon/FaIcon';
// Types
import * as Types from './CloseIconButton.type'

const CloseIconButton: FC<Types.IProps> = ({ onClose, hideTooltip }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const icon = <span data-testid='CloseIconButton'>
    <FaIcon
      aria-label='fermer'
      name='times'
      className='cursor-pointer text-xl'
      onClick={onClose}
    />
  </span>
  return hideTooltip
    ? icon
    : <Tooltip title={t('common:forms.tooltips.close') ?? ''}>
      {icon}
    </Tooltip>
}

export default CloseIconButton