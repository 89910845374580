// React libs
import React, { FC } from 'react';
// Components
import GenericIconButton from '../GenericIconButton/GenericIconButton';
// Types
import * as Types from './FullScreenButton.type'

const FullScreenButton: FC<Types.IProps> = ({ onClick, tooltip, ...props }) => {
  return <span data-testid='FullScreenButton'>
    <GenericIconButton
      {...props}
      ariaLabel='full screen button'
      onClick={onClick}
      icon='expand-arrows-alt'
      iconType='fas'
      tooltip={tooltip}
    />
  </span>
}

export default FullScreenButton