// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Hooks
import useGenericData, { IGetGenericData } from './GenericData';

export interface IGetPhaseTypes extends IGetGenericData<CoreTypes.IPhaseType> { }

const usePhaseTypes = (): IGetPhaseTypes => {
  return useGenericData(() => CommonService.getPhaseTypes())
};

export default usePhaseTypes;
