// React libs
import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import ConfirmModale from '../../../../../Core/Components/UiKit/Modales/ConfirmModale/ConfirmModale';
// Types
import * as Types from './DeleteSelectionConfirmModal.type';

const DeleteSelectionConfirmModal: FC<Types.IProps> = ({ handleClose, isOpened }) => {
  const { t } = useTranslation(['common'])
  return <div data-testid='delete-selection-confirm-modal'>
    <ConfirmModale
      handleClose={handleClose}
      id='delete-selection-confirm-modal'
      message={t('common:modales.selection.deleteConfirmation')}
      isOpened={isOpened}
    />
  </div>

}

DeleteSelectionConfirmModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired
}

export default DeleteSelectionConfirmModal