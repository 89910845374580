// React libs
import React, { FC } from 'react';
// Components
import FaIcon from '../../Icon/FaIcon/FaIcon';
// Type
import * as Types from './ErrorIcon.type';

const ErrorIcon: FC<Types.IProps> = ({ color, className }) => <span data-testid='error-icon'>
  <FaIcon
    name='exclamation-circle'
    className={`${color === 'primary' ? 'bg-white-80 rounded-full text-red-500' : ''} ${className}`}
  />
</span>

export default ErrorIcon