// React libs
import React, { FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next';
import { groupBy, cloneDeep } from 'lodash';
// Components
import FormHeader from '../../../../Core/Components/Form/FormHeader/FormHeader';
import LocalLoader from '../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import PoiEcosystem from '../../../../Core/Components/Form/PoiEcosystem/PoiEcosystem';
import ProjectEcosystemForm from '../../components/Form/ProjectEcosystem/ProjectEcosystemForm';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'
import * as Types from './ProjectEcosystem.type'
import * as ProjectEcosystemFormTypes from '../../components/Form/ProjectEcosystem/ProjectEcosystemForm.type';
// Utils
import useServiceErrorHandler from '../../../../Core/Utils/useServiceErrorHandler';
import { ifDef } from '../../../../Core/Utils/Misc';
// Services
import CommonService from '../../../../Core/Data/Services/CommonService';
// Common
import Common from '../../../../App/Resources/Common'
// Hooks
import usePoiLinks from '../../../../Core/Data/Hooks/PoiLinks';
import usePois from '../../../../Core/Data/Hooks/Pois';

interface IProjectEcosystemEdition {
  poi: CoreTypes.IPoi
  pois: CoreTypes.IPoi[]
  links: CoreTypes.IPoiLink[]
  refreshPoi: () => void
  toggleEditionMode: () => void
}

const ProjectEcosystemEdition: FC<IProjectEcosystemEdition> = ({ poi, pois, links, refreshPoi, toggleEditionMode }) => {
  // Variables
  const handleServiceError = useServiceErrorHandler()
  const poiLinks = useMemo(() => ifDef(
    poi.links,
    (links: any) => cloneDeep(links.map((link: any) => ({
      ...link,
      fkpoiTo: link.poi.id,
      fkpoiFrom: poi.id,
    }))),
    () => []
  ), [poi.id, poi.links])
  const defaultValues: ProjectEcosystemFormTypes.IFormValues = {
    poiLinks
  }
  const validationSchema = Yup.object({
    poiLinks: Yup.array()
  })
  // handlers
  const onSubmit = useCallback(async (values: ProjectEcosystemFormTypes.IFormValues, { setSubmitting }: FormikHelpers<ProjectEcosystemFormTypes.IFormValues>) => {
    setSubmitting(true)
    const { toCreate = [], toDelete = [], toUpdate = [] } = groupBy(
      values.poiLinks,
      link => link.toCreate
        ? 'toCreate'
        : link.toDelete
          ? 'toDelete'
          : link.toUpdate
            ? 'toUpdate' :
            'others'
    )

    try {
      await Promise.all(toDelete.map(link => CommonService.deletePoiLink(link.fkpoiFrom, link.fkpoiTo)))
      await Promise.all(toCreate.map(link => CommonService.addPoiLink({
        comment: link.comment === '' ? undefined : link.comment,
        fklinkType: link.type?.id,
        fkpoiTo: link.fkpoiTo,
        fkpoiFrom: link.fkpoiFrom
      })))
      await Promise.all(toUpdate.map(link => CommonService.updatePoiLink({
        comment: link.comment,
        fklinkType: link.type?.id,
        fkpoiTo: link.fkpoiTo,
        fkpoiFrom: link.fkpoiFrom
      })))
    } catch (error) {
      handleServiceError(error)
    } finally {
      setSubmitting(false)
      refreshPoi()
      toggleEditionMode()
    }
  }, [handleServiceError, refreshPoi, toggleEditionMode])

  return <ProjectEcosystemForm
    defaultValues={defaultValues}
    validationSchema={validationSchema}
    onFormSubmit={onSubmit}
    miscData={{ poi, pois, links }}
    miscFunctions={{ onCancel: toggleEditionMode }}
  />
}

const ProjectEcosystem: FC<Types.IProps> = ({ poi, refreshPoi, isEditionMode }) => {
  // Variables
  const history = useHistory()
  const { t } = useTranslation(['project', 'common']);
  const links = usePoiLinks({
    expand: ['linkType', 'linkStyle']
  })
  const stringLinks = JSON.stringify(links.data ?? {})
  const linkedResourcesFilter = useMemo(
    () => {
      const linkedResourcesIds = links.data?.filter(link => link.fkpoiFrom === poi.id).map(link => ({
        id: link.fkpoiTo
      }))
      return !!linkedResourcesIds?.length && {
        $and: [
          {
            isProject: false
          },
          {
            $or: linkedResourcesIds
          }
        ]
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringLinks, poi.id]
  )
  const pois = usePois(
    {
      filter: linkedResourcesFilter,
      expand: [
        'poiType',
        'poiStyle',
        'territory',
        'thematic'
      ]
    },
    !!linkedResourcesFilter
  )

  // Handlers
  const toggleEditionMode = useCallback(() => {
    history.push(
      `/${Common.Routes.routeProject}/${poi.id}/${isEditionMode ? Common.Routes.routeEcosystemPreviewProject : Common.Routes.routeUpdateEcosystemProject}`
    )
  }, [history, isEditionMode, poi.id])

  return <div data-testid='ProjectEcosystem' className='h-full w-full p-1'>
    <div className='h-0.7/10 w-full pl-2 pt-1'>
      <FormHeader
        title={t('project:ecosystem.title')}
        isEditionMode={isEditionMode}
        toggleEditionMode={toggleEditionMode}
      />
    </div>
    {links.data == null || pois.data == null
      ? <div className='h-9.3/10 w-full flex justify-center items-center'>
        <LocalLoader message={t('common:loading.dataLoading')} type='contained' />
      </div>
      : <div className='h-9.3/10 w-full p-1 pt-2'>
        {isEditionMode
          ? <ProjectEcosystemEdition
            poi={poi}
            pois={pois.data}
            links={links.data}
            refreshPoi={refreshPoi}
            toggleEditionMode={toggleEditionMode}
          />
          : <PoiEcosystem
            poi={poi}
            pois={pois.data}
            links={links.data}
          />
        }
      </div>
    }
  </div>
}

export default ProjectEcosystem