import BarChart from '../../../../Core/Components/UiKit/Charts/BarChart/BarChart';
import Doughnut from '../../../../Core/Components/UiKit/Charts/Doughnut/Doughnut';
import LineChart from '../../../../Core/Components/UiKit/Charts/LineChart/LineChart';
import Nightingale from '../../../../Core/Components/UiKit/Charts/Nightingale/Nightingale';

export default {
  BarChart,
  Doughnut,
  LineChart,
  Nightingale
}