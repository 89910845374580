// React libs
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Common
import CoreCommon from '../../Resources/Common';

export interface IGetTerritoriesHook extends CoreTypes.IHook<CoreTypes.ITerritory[]> { }

const useTerritories = (): IGetTerritoriesHook => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);

  // State
  const [data, setData] = useState<CoreTypes.ITerritory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    setIsLoading(true)
    CommonService.getTerritories()
      .then((data: CoreTypes.IData<CoreTypes.ITerritory>) => {
        setData(data.data)
        setIsLoading(false)
      })
      .catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
        setIsLoading(false);
      });
  }, [enqueueSnackbar, t])

  return { data, isLoading }
}

export default useTerritories