// React libs
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Field, Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import CloseIconButton from '../../../UiKit/Button/CloseIconButton/CloseIconButton';
import Contacts, { COLUMN_IDS } from '../../Contacts/Contacts';
import NewContact from './NewContact/NewContact';
import PoiContactTable from '../PoiContactTable/PoiContactTable';
import SelectPosition from './SelectPosition/SelectPosition';
import Separator from '../../../UiKit/Separator/Separator';
import * as FormFields from '../../FormFields/FormFields';
// Type
import * as CoreTypes from '../../../../Data/Models/Core.type'
import * as Types from './PoiContactForm.type';
// Utils
import { getFormPropTypes, isDisabled, preventKeyDownSubmit } from '../../../../Utils/FormUtils';
// Hooks
import usePersons from '../../../../Data/Hooks/Persons';

const PoiContactForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
  >
    {(formikProps: FormikProps<any>) => (
      <PoiContactFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}
  </Formik>
);

PoiContactForm.propTypes = getFormPropTypes();

export default PoiContactForm;

interface IContactList {
  field: any;
  form: any;
  poiContacts: any[]
  setMode: (...args: any) => void
}

const ContactsList: FC<IContactList> = ({ form, field, poiContacts, setMode }) => {
  // Variables
  const persons = usePersons()
  const contactsList = useMemo(() => persons.data?.filter((person: any) => !poiContacts.some((candidate: any) => candidate.id === person.id)), [persons.data, poiContacts])
  // Handlers
  const onAdd = useCallback((person: any) => {
    const index = field.value.length
    form.setFieldTouched('contacts', true)
    form.setFieldValue(`contacts.${index}`, {
      person,
      ...person,
      toAdd: true
    })
  }, [field.value.length, form])
  const columnPredicate = useCallback((column: any) => [COLUMN_IDS.addAction, COLUMN_IDS.name, COLUMN_IDS.firstName, COLUMN_IDS.mail, COLUMN_IDS.accountPermission, COLUMN_IDS.comment].includes(column.id), [])

  return <Contacts
    columnPredicate={columnPredicate}
    contacts={contactsList}
    onAssociateContact={onAdd}
    onAddNewContact={() => setMode('createContact')}
    className='dataTable-poiContactsEdition-contactList'
  />
}

interface IPoiContactTableForm {
  field: any;
  form: any;
  poi: CoreTypes.IPoi
}

const PoiContactTableForm = ({ field, form, poi }: IPoiContactTableForm) => {
  // Variables
  const { t } = useTranslation(['common']);
  const contacts = useMemo(() => field.value.filter((contact: any) => !contact.toDelete), [field.value])
  // State
  const [mode, setMode] = useState<'createContact' | 'associateContact' | undefined>()
  // Handlers
  const onDelete = useCallback((contact: any) => {
    const index = field.value.findIndex(({ id }: any) => id === contact.id)
    form.setFieldTouched('contacts', true)
    form.setFieldValue(`contacts.${index}`, {
      ...contact,
      toDelete: true
    })
  }, [field.value, form])

  const CustomPositionRenderCell = useCallback((contact: any) => {
    const position = contact.position?.toDelete ? {} : contact.position
    return <p>
      <FormFields.FieldWrapper label={position?.name ?? t('common:forms.titles.selectPosition')} bgColor='white'>
        <SelectPosition
          value={position?.id ?? ''}
          onChange={position => {
            const index = field.value.findIndex(({ id }: any) => id === contact.id)
            form.setFieldTouched('contacts', true)
            form.setFieldValue(`contacts.${index}.position`, position)
          }}
        />
      </FormFields.FieldWrapper>
    </p>
  }, [field.value, form, t])

  return <div className='flex h-full w-full'>
    <div className='flex-1 h-full w-5/10'>
      <PoiContactTable
        contacts={contacts}
        customPositionCell={CustomPositionRenderCell}
        onCreate={() => setMode('associateContact')}
        createButtonTooltip={t(poi.isProject ? 'common:forms.tooltips.associateContactProject' : 'common:forms.tooltips.associateContactResource')}
        onDelete={onDelete}
      />
    </div>
    {['associateContact', 'createContact'].includes(mode ?? '') && <div className='flex h-full w-5/10'>
      <Separator type='vertical' className='ml-4' />
      <div className='w-full h-full overflow-auto'>
        <div className='w-full h-0.5/10 flex justify-end p-1'>
          <CloseIconButton onClose={() => setMode(undefined)} />
        </div>
        <div className='w-full h-9.5/10 overflow-auto'>
          {mode === 'associateContact' &&
            <ContactsList field={field} form={form} poiContacts={contacts} setMode={setMode} />
          }
          {mode === 'createContact' &&
            <NewContact postSubmit={() => setMode('associateContact')} />
          }
        </div>
      </div>
    </div>}
  </div>
}

const PoiContactFormikForm = ({
  errors,
  isSubmitting,
  miscData,
  miscFunctions,
  touched,
}: FormikProps<any> & any) => {
  return <Form data-testid='poi-contact-form' className='h-full w-full' onKeyDown={preventKeyDownSubmit}>
    <div className='h-9.3/10 w-full overflow-auto'>
      <Field
        name='contacts'
        component={PoiContactTableForm}
        poi={miscData.poi}
      />
    </div>
    <div className='h-0.7/10 w-full'>
      <FormFields.FormSubmitButtons
        isSubmitting={isSubmitting}
        disabled={isDisabled(errors, isSubmitting, touched)}
        onCancel={miscFunctions.onCancel}
      />
    </div>
  </Form>
};
