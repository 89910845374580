// React libs
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Layout
import AuthLayout from '../../../Components/Layout/AuthLayout/AuthLayout';
// Components
import Typography from '../../../../Core/Components/UiKit/Typography/Typography';
import ResetPasswordForm from '../../../Components/Form/FormType/Auth/ResetPasswordForm/ResetPasswordForm';
// Services
import AuthService from '../../../Data/Services/AuthService';
// Type
import * as Types from './ResetPasswordScene.type';
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
import * as FormTypes from '../../../Components/Form/FormType/Auth/ResetPasswordForm/ResetPasswordForm.type';
// Common
import Common from '../../../Resources/Common';
import CoreCommon from '../../../../Core/Resources/Common';

const ResetPasswordScene: FC<Types.IProps> = ({ match }) => {
  const { encryptedString } = match.params;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'auth']);

  const renderForm = () => {
    const defaultValues: FormTypes.IFormValues = {
      password: '',
      passwordConfirm: '',
    };
    const validationSchema = Yup.object({
      password: Yup.string().required(
        t('auth:resetPassword.form.password.errors.missing')
      ),
      passwordConfirm: Yup.string()
        .required(t('auth:resetPassword.form.passwordConfirm.errors.missing'))
        .oneOf(
          [Yup.ref('password'), null],
          t('auth:resetPassword.form.passwordConfirm.errors.different')
        ),
    });
    const handleSubmit = (
      values: FormTypes.IFormValues,
      { setSubmitting }: FormikHelpers<FormTypes.IFormValues>
    ) => {
      setSubmitting(true);
      AuthService.resetPassword(encryptedString, values.password)
        .then((data: any) => {
          setSubmitting(false);
          enqueueSnackbar(t('auth:forgotPassword.form.success'), {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'success',
          });
          history.push(`/${Common.Routes.routeLogin}`);
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
          setSubmitting(false);
        });
    };
    return (
      <div data-testid='reset-password-page'>
        <div className='flex flex-col items-center'>
          <Typography variant='h6' className='text-center'>
            {t('auth:resetPassword.title')}
          </Typography>
        </div>
        <ResetPasswordForm
          defaultValues={defaultValues}
          onFormSubmit={handleSubmit}
          validationSchema={validationSchema}
        />
      </div>
    );
  };

  return <AuthLayout>{renderForm()}</AuthLayout>;
};

export default ResetPasswordScene;
