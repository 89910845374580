// React libs
import React, { FC, useCallback } from 'react'
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Utils
import useServiceErrorHandler from '../../../Core/Utils/useServiceErrorHandler';
import { patchFormValues } from '../../../Core/Utils/FormUtils';
// Components
import AppLayout from '../../Components/Layout/AppLayout/AppLayout';
import NewContactForm from '../../Components/Form/FormType/NewContactForm/NewContactForm';
// Types
import * as Types from './NewContact.type'
import * as NewContactTypes from '../../Components/Form/FormType/NewContactForm/NewContactForm.type'
// Common
import CoreCommon from '../../../Core/Resources/Common';
import { useHistory } from 'react-router';
// Services
import CommonService from '../../../Core/Data/Services/CommonService';

const NewContact: FC<Types.IProps> = () => {
  // Variable
  const handleServiceError = useServiceErrorHandler()
  const history = useHistory()
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: NewContactTypes.IFormValues = {
    name: '',
    firstname: '',
    url: '',
    mail: '',
    phone: '',
    mobile: '',
    comment: '',
    fkaddress: '',
    accountSubjectId: '',
    accountNewPassword: '',
    accountAccessType: '',
    accountConfirmPassword: '',
    accountComment: '',
  }

  const requiredSchemaFn = (newValue: string, field: any) => newValue !== undefined ? field.required(t('common:errors.defaultRequiredField')) : field
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('common:errors.defaultRequiredField')),
    firstname: Yup.string().required(t('common:errors.defaultRequiredField')),
    url: Yup.string(),
    mail: Yup.string().email(t('common:errors.nonValidEmail')),
    phone: Yup.string(),
    mobile: Yup.string(),
    comment: Yup.string(),
    fkaddress: Yup.string(),
    accountSubjectId: Yup.string().when('accountAccessType', requiredSchemaFn),
    accountNewPassword: Yup.string().when('accountAccessType', requiredSchemaFn).when('accountSubjectId', requiredSchemaFn),
    accountConfirmPassword: Yup.string().oneOf([Yup.ref('accountNewPassword')]).when('accountNewPassword', requiredSchemaFn),
    accountAccessType: Yup.string().when('accountSubjectId', requiredSchemaFn),
    accountComment: Yup.string()
  }, [['accountSubjectId', 'accountAccessType']])

  // Handlers
  const goBack = useCallback(() => { history.goBack() }, [history])

  const onSubmit = useCallback(async (newValues: NewContactTypes.IFormValues, { setSubmitting }: FormikHelpers<any>) => {
    setSubmitting(true)
    try {
      let { accountSubjectId, accountNewPassword, accountConfirmPassword, accountAccessType, accountComment, ...person } = patchFormValues(defaultValues, newValues)

      if (accountSubjectId !== undefined) {
        person.account = {
          subjectId: accountSubjectId,
          p: accountNewPassword,
          pc: accountConfirmPassword,
          fkaccessType: accountAccessType,
          comment: accountComment,
        }
      }

      await CommonService.savePerson(person)
      enqueueSnackbar(t('common:forms.validation.saveSucceeded'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'success',
      })
    } catch (error) {
      handleServiceError(error)
    } finally {
      setSubmitting(false)
      goBack()
    }
  }, [defaultValues, enqueueSnackbar, goBack, handleServiceError, t])

  return <div className='w-full h-full'>
    <AppLayout
      headerConf={{
        title: {
          label: t('common:forms.titles.contact'),
          icon: 'address-card-o'
        }
      }}
      className='overflow-auto'
    >
      <NewContactForm
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        onFormSubmit={onSubmit}
        miscFunctions={{ onCancel: goBack }}
        miscData={{ createAccount: true }}
      />
    </AppLayout>
  </div>
}

export default NewContact