// React libs
import { useState, useEffect, useCallback } from 'react';
// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Utils
import useServiceErrorHandler from '../../Utils/useServiceErrorHandler';

export interface IGetPositionsHook extends CoreTypes.IHook<CoreTypes.IPosition[]> {
  refresh: () => void
}

const usePositions = (): IGetPositionsHook => {
  // Variables
  const handleServiceError = useServiceErrorHandler()

  // State
  const [data, setData] = useState<CoreTypes.IPosition[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [needRefresh, setNeedRefresh] = useState<boolean>(true);

  // Actions
  const refresh = useCallback(() => {
    setNeedRefresh(true)
  }, [])

  // Effects
  useEffect(() => {
    if (needRefresh && !isLoading) {
      setIsLoading(true)
      CommonService.getPositions()
        .then((data: CoreTypes.IData<CoreTypes.IPosition>) => {
          setData(data.data)
        })
        .catch(handleServiceError)
        .finally(() => {
          setNeedRefresh(false)
          setIsLoading(false);
        })
    }
  }, [handleServiceError, isLoading, needRefresh])

  return { data, isLoading, refresh }
}

export default usePositions