// React libs
import { useCallback, useState, useContext } from 'react';
// Types
import * as AutocompleteTypes from '../../../../Core/Components/UiKit/Form/Autocomplete/Autocomplete.type'
import * as MapTypes from '../Models/Map.type';
// Services
import MapService from '../Services/MapService'
// Contexts
import MapConfigContext, { IMapConfigContext, } from '../Contexts/MapConfigContext'

export default function useMapboxAddress() {
  // Variables
  const controller = new AbortController()
  const signal = controller.signal;

  // State
  const [isSearchingAddress, setIsSearchingAddress] = useState<boolean>(false)
  const [addressOptions, setAddressOptions] = useState<AutocompleteTypes.IOption[]>([])
  const [fullAddressOptions, setAddressFullOptions] = useState<any[]>([])

  // Contexts
  const { mapConfig }: IMapConfigContext = useContext(MapConfigContext);
  // Actions
  const formatOptions = useCallback((values: any[]) => values.reduce(
    (list: AutocompleteTypes.IOption[], item: MapTypes.IMapboxPlace) => {
      list.push({
        value: item.id,
        label: item.place_name_fr,
      });
      return list;
    },
    []
  ), [])

  // Handlers
  const fetchAddresses = useCallback((value: string, onData?: (...args: any) => void) => {
    if (value.length <= 2) {
      return
    }

    if (isSearchingAddress) {
      controller.abort();
    }

    setIsSearchingAddress(true)
    MapService.searchMapboxAddress(
      mapConfig?.mapConfigLayerToken ?? '',
      value,
      mapConfig?.mapConfigGeocodageCountry ?? '',
      signal
    )
      .then((results: MapTypes.IMapboxPlacesData) => {
        const data = results.features
        onData?.(data)
        setAddressFullOptions(data)
        setAddressOptions(formatOptions(data))
      })
      .catch(() => { })
      .finally(() => {
        setIsSearchingAddress(false)
      })
  }, [controller, formatOptions, isSearchingAddress, mapConfig, signal])

  return {
    fetchAddresses,
    addressOptions,
    fullAddressOptions
  }
}