// Types
import * as CoreTypes from '../Data/Models/Core.type';

export const isAdmin = (user: CoreTypes.IUser) => {
  return user.roles.indexOf('PNP_PRJ_ADMIN') !== -1;
};

export const isWriter = (user: CoreTypes.IUser) => {
  return user.roles.indexOf('PNP_PRJ_WRITER') !== -1;
};

export const isReader = (user: CoreTypes.IUser) => {
  return user.roles.indexOf('PNP_PRJ_READER') !== -1;
};

export const hasPermissionsOnPoi = (poi: CoreTypes.IPoi, user: CoreTypes.IUser) => {
  return isAdmin(user) || (isWriter(user) && poi.createdBy === user.login)
};