// Utils
import { createContext } from 'react';
// Service
import LocalStorage from '../Services/Storage/LocalStorage';
// Types
import * as CoreTypes from '../Models/Core.type';

export interface IUserContext {
  user: CoreTypes.IUser | null;
  updateUser: (user: CoreTypes.IUser) => void;
}
const UserContext = createContext<IUserContext>({
  user: LocalStorage.get(LocalStorage.keys.user) || undefined,
  updateUser: (user: CoreTypes.IUser | undefined) => {
    LocalStorage.set(LocalStorage.keys.user, user);
  },
});

export default UserContext;
