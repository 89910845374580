// React libs
import React, { FC, useCallback, useState, useEffect, useImperativeHandle } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { CardContent, CardActions, ButtonGroup } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
// Types
import * as Types from './ChartContent.type'
// Components
import Button from '../../Button/Button'
import DatePicker from '../DatePicker/DatePicker';
import DeleteButton from '../../Button/DeleteButton/DeleteButton';
import FaIcon from '../../Icon/FaIcon/FaIcon'
import Separator from '../../Separator/Separator';
import Typography from '../../Typography/Typography';

const getUpdateDuration = (updateDate: moment.Moment | undefined) => updateDate && moment.duration(moment().diff(updateDate)).minutes()

const ChartContent: FC<Types.IProps> = ({ filter, date, Chart }) => {
  // State
  const [isFilterVueActive, setIsFilterVueActive] = useState<boolean>(false)
  const [dataUpdateDuration, setDataUpdateDuration] = useState<number | undefined>(getUpdateDuration(date.lastDataUpdate))

  // handlers
  const toggleFilterVue = useCallback(() => { setIsFilterVueActive(isFilterVueActive => !isFilterVueActive) }, [])

  // Effects
  useImperativeHandle(filter?.toggleFilterVueRef, () => ({
    toggleFilterVue
  }))

  // Refresh date each minute
  useEffect(() => {
    setTimeout(() => setDataUpdateDuration(getUpdateDuration(date.lastDataUpdate)), 60e3)
  }, [date.lastDataUpdate, dataUpdateDuration])

  // Variables
  const { t } = useTranslation(['components']);

  return <div data-testid='chart-content' className='h-full w-full relative'>
    <CardContent className='h-9.5/10 w-full'>
      {filter !== undefined
        ? <>
          <div className='flex justify-between mb-4'>
            <div className='self-center'>
              <ButtonGroup className='flex items-center'>
                <Button
                  variant='text'
                  color='secondary'
                  size='small'
                  startIcon={<FaIcon name='filter' className={filter.areEnabled ? 'bg-white-80 h-6 p-1 rounded-full text-red-610 w-6' : ''} />}
                  onClick={toggleFilterVue}
                  className={isFilterVueActive ? 'bg-active text-selection-inverse hover:bg-active-hover' : 'hover:bg-active-hover'}
                >
                  {t('components:chartBuilder.filters')}
                </Button>
                {filter.areEnabled && <DeleteButton
                  onClick={filter.onClear}
                  tooltip={t('components:chartBuilder.actions.deleteFilters')}
                  size='small'
                />}
              </ButtonGroup>
            </div>
            <div />
            <div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label={t('components:chartBuilder.date.from')}
                  value={date.dateStart}
                  onChange={date.onDateStartChange}
                />
                <DatePicker
                  label={t('components:chartBuilder.date.to')}
                  value={date.dateEnd}
                  minDate={date.dateStart}
                  onChange={date.onDateEndChange}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <Separator type='horizontal' className='my-2' />
          <div className='h-9/10 w-full'>
            {isFilterVueActive ? filter.Component : Chart}
          </div>
        </>
        : Chart
      }
    </CardContent>
    <CardActions className='h-0.5/10 w-full'>
      <Typography variant='caption'>
        {t('components:chartBuilder.lastUpdate', {
          minutes: dataUpdateDuration
        })}
      </Typography>
    </CardActions>
  </div>
}

export default ChartContent