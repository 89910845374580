// React libs
import React, { FC, useCallback } from 'react';
import * as Yup from 'yup';
import { cloneDeep } from 'lodash';
import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Types
import * as Types from './ProjectGed.type'
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
import * as ProjectGedFormTypes from '../../components/Form/ProjectGedForm/ProjectGedForm.type';
// Components
import FormHeader from '../../../../Core/Components/Form/FormHeader/FormHeader';
import LocalLoader from '../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import ProjectGedForm from '../../components/Form/ProjectGedForm/ProjectGedForm';
import ProjectGedTreeView from '../../components/ProjectGed/ProjectGedTreeView/ProjectGedTreeView';
// Hooks
import usePoiFolders from '../../../../Core/Data/Hooks/PoiFolders';
// Common
import Common from '../../../../App/Resources/Common'
// Services
import CommonService from '../../../../Core/Data/Services/CommonService';
// Utils
import useServiceErrorHandler from '../../../../Core/Utils/useServiceErrorHandler';

const TMP_ROOT_FOLDER_ID = 'tmpRoot'

interface IProjectGedEdition {
  poi: CoreTypes.IPoi
  refreshPoi: () => void
  toggleEditionMode: () => void
  folders: CoreTypes.IPoiFolders
}

const ProjectGedEdition: FC<IProjectGedEdition> = ({ poi, refreshPoi, toggleEditionMode, folders }) => {
  // Variables
  const handleServiceError = useServiceErrorHandler()
  const defaultValues: ProjectGedFormTypes.IFormProject = {
    folders: cloneDeep(folders)
  }
  const validationSchema = Yup.object({
    folders: Yup.object()
  })
  // Handlers
  const onSubmit = useCallback(async (values: ProjectGedFormTypes.IFormProject, { setSubmitting }: FormikHelpers<ProjectGedFormTypes.IFormProject>) => {
    setSubmitting(true)

    try {
      const tmpIdsTranslator: { [id: string]: string } = {}
      const handleFile = ({ id, toCreate, toUpdate, toDelete, ...file }: any, isSubFile: boolean = false) => {
        if (toDelete) {
          return CommonService.deletePoiFile(poi.id, id)
        }

        if (toCreate) {
          return CommonService.savePoiFile(
            poi.id,
            {
              targetFile: file.content
            },
            isSubFile ? tmpIdsTranslator[file.parent_id] ?? file.parent_id : undefined
          )
        }

        if (toUpdate) {
          return CommonService.updatePoiFile(poi.id, id, {
            name: file.name
          })
        }
      }

      const handleFolder = async ({ id, toCreate, toUpdate, toDelete, size, parent_id, ...folder }: any) => {
        if (toDelete) {
          return CommonService.deletePoiFolder(poi.id, id)
        }

        if (toCreate) {
          const response = parent_id === TMP_ROOT_FOLDER_ID
            ? await CommonService.savePoiFolderByPath(poi.id, {
              ...folder,
              folder_relative_path: folder.name
            })
            : await CommonService.savePoiFolder(poi.id, {
              ...folder,
              parent_id: tmpIdsTranslator[parent_id] ?? parent_id
            })
          tmpIdsTranslator[id] = response.data.id
        } else if (toUpdate) {
          await CommonService.updatePoiFolder(poi.id, id, {
            name: folder.name
          })
        }

        await Promise.all(folder.content?.folders?.map(handleFolder) ?? [])
        await Promise.all(folder.content?.files?.map((file: any) => handleFile(file, true)) ?? [])
      }

      await Promise.all(values.folders.content?.folders?.map(handleFolder) ?? [])
      await Promise.all(values.folders.content?.files?.map(file => handleFile(file)) ?? [])
    } catch (error) {
      handleServiceError(error)
    } finally {
      setSubmitting(false)
      refreshPoi()
      toggleEditionMode()
    }
  }, [handleServiceError, poi.id, refreshPoi, toggleEditionMode])

  return <ProjectGedForm
    defaultValues={defaultValues}
    validationSchema={validationSchema}
    onFormSubmit={onSubmit}
    miscData={{ poi }}
    miscFunctions={{ onCancel: toggleEditionMode }}
  />
}

const ProjectGed: FC<Types.IProps> = ({ poi, isEditionMode, refreshPoi }) => {
  // Variables
  const { t } = useTranslation(['project', 'common']);
  const history = useHistory()
  const folders = usePoiFolders(poi.id)
  const defaultFolder: CoreTypes.IPoiFolders = {
    id: TMP_ROOT_FOLDER_ID,
    name: '',
    path: '',
    isFile: false,
    size: 0
  }

  // Handlers
  const toggleEditionMode = useCallback(() => {
    const path = isEditionMode ? Common.Routes.routeGedPreviewProject : Common.Routes.routeUpdateGedProject
    history.push(
      `/${Common.Routes.routeProject}/${poi.id}/${path}`
    )
  }, [history, isEditionMode, poi.id])

  return <div data-testid='ProjectGed' className='h-full w-full'>
    <div className='h-0.7/10 w-full pl-2 pt-1'>
      <FormHeader
        title={t('project:ged.title')}
        isEditionMode={isEditionMode}
        toggleEditionMode={toggleEditionMode}
      />
    </div>
    {folders.isLoading
      ? <div className='h-9.3/10 w-full flex item-center justify-center'>
        <LocalLoader type='contained' message={t('common:loading.dataLoading')} />
      </div>
      : <div className='h-9.3/10 w-full p-1 pt-2'>
        {isEditionMode
          ? <ProjectGedEdition poi={poi} refreshPoi={refreshPoi} toggleEditionMode={toggleEditionMode} folders={folders.data ?? defaultFolder} />
          : <ProjectGedTreeView poi={poi} folders={folders.data ?? defaultFolder} />
        }
      </div>
    }
  </div>
}

export default ProjectGed