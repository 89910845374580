// React libs
import React, { FC } from 'react';
import ReactECharts from 'echarts-for-react'
import { sortBy } from 'lodash';
// Component
import LocalLoader from '../../Loader/LocalLoader/LocalLoader';
// Types
import * as Types from './LineChart.type'
import * as CoreTypes from '../../../../Data/Models/Core.type'
// Hooks
import useDatavizPalettes from '../../../../Data/Hooks/DatavizPalettes';

const OPTIONS_BY_SIZE: { [size in CoreTypes.datavizChartSizeType]: any } = {
  mini: {},
  medium: {
    globalStyle: {
      height: '25em',
      width: '60em'
    }
  },
  maxi: {
    globalStyle: {
      height: '45em',
      width: '110em'
    }
  }
}

interface ILineChartComponent extends Types.IProps {
  colorPalette: string[]
}

const LineChartComponent = ({ size = 'medium', data, colorPalette }: ILineChartComponent) => {
  // Variables
  const chartDynamicOption = OPTIONS_BY_SIZE[size]

  const option = {
    color: colorPalette,
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: data.legends
    },
    grid: {
      containLabel: false,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
        dataView: { show: true, readOnly: true }
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.dates
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: 'grey'
        },
        axisTick: {
          show: false
        }
      },
    },
    series: sortBy(data.series, 'legend').map(item => ({
      name: item.legend,
      type: 'line',
      lineStyle: {
        opacity: 1
      },
      itemStyle: {
        opacity: 1
      },
      smooth: true,
      data: item.data
    }))
  };

  return <ReactECharts option={option} style={chartDynamicOption.globalStyle} />
}

const LineChart: FC<Types.IProps> = props => {
  // Variables
  const colorPalette = useDatavizPalettes('nightingaleLineChart').data

  return <div data-testid='LineChart' className='h-full w-full flex items-center justify-center'>
    {colorPalette === undefined
      ? <LocalLoader />
      : <LineChartComponent {...props} colorPalette={colorPalette} />
    }
  </div>
}

export default LineChart