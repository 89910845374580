// React libs
import React, { FC } from 'react';
// Components
import GenericIconButton from '../GenericIconButton/GenericIconButton';
// Types
import * as Types from './InfoButton.type'

const InfoButton: FC<Types.IProps> = ({ onClick, tooltip, size }) => {
  return <span data-testid='InfoButton'>
    <GenericIconButton
      ariaLabel='info button'
      onClick={onClick}
      icon='info-circle'
      size={size}
      tooltip={tooltip}
    />
  </span>
}

export default InfoButton