// React libs
import React, { FC, useMemo } from 'react';
import { Grid } from '@material-ui/core'
import { Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import FormHeader from '../../FormHeader/FormHeader';
import FormTitle from '../../FormTitle/FormTitle';
import * as FormFields from '../../FormFields/FormFields';
// Type
import * as Types from './PoiFurtherInformationForm.type';
// Utils
import { formatPoiValueDefs } from '../../FormFields/FormFields';
import { getFormPropTypes, isDisabled } from '../../../../Utils/FormUtils';

const PoiFurtherInformationForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
  >
    {(formikProps: FormikProps<any>) => (
      <PoiFurtherInformationFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}
  </Formik>
);

PoiFurtherInformationForm.propTypes = getFormPropTypes();

export default PoiFurtherInformationForm;

const PoiFurtherInformationFormikForm = ({
  errors,
  isSubmitting,
  miscData,
  miscFunctions,
  touched
}: FormikProps<any> & any) => {
  // Variables
  const { t } = useTranslation(['common']);
  const isEditionMode = miscData?.isEditionMode ?? false
  const poi = miscData.poi
  const valueDefs = useMemo(() => formatPoiValueDefs(poi.type.valueDefs), [poi.type.valueDefs])

  return <>
    <div className='h-0.7/10 w-full pl-2 pt-1'>
      <FormHeader
        isEditionMode={isEditionMode}
        toggleEditionMode={miscFunctions?.toggleEditionMode}
        title={t('common:forms.titles.furtherInformation')}
      />
    </div>
    <Form data-testid='poi-further-information-form' className='h-9.3/10 w-full p-1'>
      <div className='h-9.3/10 w-full overflow-auto'>
        <Grid container spacing={1} className='w-full'>
          {valueDefs.map(([title, values]) =>
            <Grid item xs={12} key={title}>
              <FormTitle title={title} />
              <div>
                {values.map(({ valueDef }: any, key: any) => <FormFields.ValueDefField valueDef={valueDef} key={key} />)}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      <div className='h-0.7/10 w-full'>
        {isEditionMode && <FormFields.FormSubmitButtons
          isSubmitting={isSubmitting}
          disabled={isDisabled(errors, isSubmitting, touched)}
          onCancel={miscFunctions?.toggleEditionMode}
        />}
      </div>
    </Form>
  </>
};
