// React libs
import React, { useMemo, } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import AppNavigationLayout from '../../../App/Components/Layout/AppNavigationLayout/AppNavigationLayout';
import PageLoader from '../../../Core/Components/UiKit/Loader/PageLoader/PageLoader';
import PoiContactPage from '../../../Core/Components/Form/PoiContact/PoiContactPage/PoiContactPage';
import PoiFurtherInformationPage from '../../../Core/Components/Form/PoiFurtherInformation/PoiFurtherInformationPage/PoiFurtherInformationPage';
import ResourceEcosystem from './ResourceEcosystem/ResourceEcosystem';
import ResourceIdentity from './ResourceIdentity/ResourceIdentity';
// Type
import * as CoreTypes from '../../../Core/Data/Models/Core.type'
import * as Types from './ResourceScene.type';
// Hooks
import usePoi from '../../../Core/Data/Hooks/Poi'
// Common
import Common from '../../../App/Resources/Common'
// Contexts
import PoiContext from '../../../Core/Data/Contexts/PoiContext';

const resourceSceneWrapper = (Component: any) => (props: Types.IProps) => {
  // Variables
  const { t } = useTranslation(['resource']);
  const poi = usePoi(props.match.params.id)

  if (poi.isLoading) {
    return <PageLoader message={t('resource:loadingMessage')} />
  }

  if (poi.data == null) {
    return null
  }

  return <PoiContext.Provider value={{ poi: poi.data }}>
    <Component {...props} poi={poi.data} refresh={poi.refresh} />
  </PoiContext.Provider>
}

interface IResourceScene extends Types.IProps {
  poi: CoreTypes.IPoi
  refresh: () => void
}

const ResourceScene = ({ poi, match, refresh }: IResourceScene) => {
  // Variables
  const { t } = useTranslation(['resource']);
  const [, currentRoute] = /^\/resource\/:id\/(.+)$/.exec(match.path) ?? []
  const hasValueDefs = useMemo(() => poi.type.valueDefs != null && poi.type.valueDefs.length > 0, [poi.type])
  const pages = [
    {
      icon: 'info-circle',
      label: t('resource:identity'),
      component: <ResourceIdentity poi={poi} isEditionMode={currentRoute === Common.Routes.routeUpdateResource} refreshPoi={refresh} />,
      previewUrl: Common.Routes.routePreviewResource,
      updateUrl: Common.Routes.routeUpdateResource,
    },
    {
      icon: 'bars',
      label: t('resource:furtherInformation'),
      component: <PoiFurtherInformationPage poi={poi} isEditionMode={currentRoute === Common.Routes.routeUpdateFurtherInformationResource} refreshPoi={refresh} />,
      hidden: !hasValueDefs,
      previewUrl: Common.Routes.routeFurtherInformationPreviewResource,
      updateUrl: Common.Routes.routeUpdateFurtherInformationResource,
    },
    {
      icon: 'address-card-o',
      label: t('resource:contacts'),
      component: <PoiContactPage poi={poi} isEditionMode={currentRoute === Common.Routes.routeUpdateContactResource} refreshPoi={refresh} />,
      previewUrl: Common.Routes.routeContactPreviewResource,
      updateUrl: Common.Routes.routeUpdateContactResource
    },
    {
      icon: 'sitemap',
      label: t('resource:ecosystem.title'),
      component: <ResourceEcosystem poi={poi} />,
      previewUrl: Common.Routes.routeEcosystemPreviewResource,
    },
  ]

  return <>
    <AppNavigationLayout
      headerConf={{
        title: {
          label: `${t('resource:title')} / ${poi.type.label} / ${poi.name}`,
          icon: 'map-marker',
        },
      }}
      baseUrl={`/${Common.Routes.routeResource}/${poi.id}`}
      currentRoute={currentRoute}
      pages={pages}
    />
  </>
}

export default resourceSceneWrapper(ResourceScene)