// React libs
import React, { FC, useCallback, useMemo, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { sortedIndex } from 'lodash'
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Components
import FaIcon from '../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import LayerPreview from './LayerPreview/LayerPreview';
import LocalLoader from '../../../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import MapSearchBar from './MapSearchBar/MapSearchBar';
import Typography from '../../../../../../Core/Components/UiKit/Typography/Typography';
// Hooks
import useMapLayers, { IGetLayersHook } from '../../../../Data/Hooks/MapLayers';
import useUserPref, { IGetUserPref } from '../../../../Data/Hooks/UserPref';
// Services
import MapService from '../../../../Data/Services/MapService';
// Contexts
import FavoriteLayersContext from '../../../../Data/Contexts/FavoriteLayersContext';
// Type
import * as Types from './MapSidebarCard.type';
import * as MapTypes from '../../../../Data/Models/Map.type';
import * as CoreTypes from '../../../../../../Core/Data/Models/Core.type';
// Common
import CoreCommon from '../../../../../../Core/Resources/Common';

const StyledThreeItem: FC<{ title: string, nodeId: string } & React.ComponentProps<'div'>> = ({ children, title, nodeId }) => {
  const isSubThematic = useMemo(() => nodeId.startsWith('subThematic-'), [nodeId])
  return <TreeItem
    nodeId={nodeId}
    TransitionComponent={Collapse}
    label={<Typography variant={isSubThematic ? 'h6' : 'h5'} className='text-selection-inverse font-extrabold'>
      {title}
    </Typography>}>
    {children}
  </TreeItem>
}

const SubThematic = ({
  activeLayer,
  collection,
  onLayerClick
}: {
  activeLayer: MapTypes.ILayer | undefined,
  collection: any,
  onLayerClick: (...args: any) => void
}) => <>
    {collection.subThematicTitles.map((title: string) => {
      const subThematic = collection.subThematic[title]
      return <StyledThreeItem nodeId={`subThematic-${title}`} title={title} key={title}>
        {subThematic.map((layer: any) => <LayerPreview
          active={activeLayer?.id === layer.id}
          key={layer.id}
          layer={layer}
          onLayerClick={onLayerClick}
        />)}
      </StyledThreeItem>
    })}
    {collection.layersWithoutSubThematic.map((layer: any) => <LayerPreview
      active={activeLayer?.id === layer.id}
      key={layer.id}
      layer={layer}
      onLayerClick={onLayerClick}
    />)}
  </>

const MapSidebarCard: FC<Types.IProps> = ({ activeLayer, onLayerClick }) => {
  // state
  const [layersFilter, setLayersFilter] = useState<any>()
  const [expanded, setExpanded] = useState<string[]>([])

  // Hooks
  const layers: IGetLayersHook = useMapLayers(true, layersFilter);
  const userPref: IGetUserPref = useUserPref();

  // Variables
  const { t } = useTranslation(['map']);
  const { enqueueSnackbar } = useSnackbar();
  const favoritesNodeId = 'favorites'
  const favoriteLayerIds: string[] = useMemo(() => JSON.parse(userPref.data?.data ?? 'null')?.layerMapFavorites ?? [], [userPref.data])

  // Misc
  if (layers && layers.data)
    layers.data.data.sort((a: MapTypes.ILayer, b: MapTypes.ILayer) => {
      if (a.order < b.order) return -1;
      else if (a.order > b.order) return 1;
      else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      else return 0;
    });

  interface IGroupedLayers {
    favoriteLayers: MapTypes.ILayer[]
    layersWithoutThematic: MapTypes.ILayer[]
    sortedZoneTitles: string[]
    zone: {
      [title: string]: MapTypes.ILayer[]
    }
    thematicTitles: string[]
    thematic: {
      [title: string]: {
        sortedZoneTitles: string[]
        zone: {
          [title: string]: {
            layersWithoutSubThematic: MapTypes.ILayer[]
            subThematicTitles: string[]
            subThematic: { [title: string]: MapTypes.ILayer[] }
          }
        }
        layersWithoutSubThematic: MapTypes.ILayer[]
        subThematicTitles: string[]
        subThematic: { [title: string]: MapTypes.ILayer[] }
      }
    }
  }

  const groupedLayers: IGroupedLayers = useMemo(() => {
    const result: IGroupedLayers = {
      favoriteLayers: [],
      thematicTitles: [],
      thematic: {},
      sortedZoneTitles: [],
      zone: {},
      layersWithoutThematic: [],
    }
    if (layers.data == null) {
      return result
    }

    layers.data.data.forEach((layer: MapTypes.ILayer) => {
      // Favorite
      if (favoriteLayerIds.includes(layer.id)) {
        result.favoriteLayers.push(layer)

        if (layer.thematic == null && layer.zone == null) {
          return
        }
      }

      // Zone
      if (layer.thematic == null && layer.zone != null) {
        let zone = result.zone[layer.zone]
        if (zone === undefined) {
          zone = result.zone[layer.zone] = []
          // add a new title to the sorted array
          result.sortedZoneTitles.splice(sortedIndex(result.sortedZoneTitles, layer.zone), 0, layer.zone)
        }
        zone.push(layer)
      }

      // Thematic
      if (layer.thematic == null) {
        return result.layersWithoutThematic.push(layer)
      }

      let thematic: any = result.thematic[layer.thematic]
      if (thematic === undefined) {
        thematic = result.thematic[layer.thematic] = {
          layersWithoutSubThematic: [],
          sortedZoneTitles: [],
          zone: {},
          subThematicTitles: [],
          subThematic: {}
        }

        // add a new title to the sorted array
        result.thematicTitles.splice(sortedIndex(result.thematicTitles, layer.thematic), 0, layer.thematic)
      }

      // Zone
      let zone: any = layer.zone && thematic.zone[layer.zone]
      if (layer.zone != null && zone == null) {
        zone = thematic.zone[layer.zone] = {
          layersWithoutSubThematic: [],
          subThematicTitles: [],
          subThematic: {}
        }

        // add a new title to the sorted array
        thematic.sortedZoneTitles.splice(sortedIndex(thematic.sortedZoneTitles, layer.zone), 0, layer.zone)
      }

      const collection = zone ?? thematic

      // Sub-thematic
      if (layer.subThematic == null) {
        return collection.layersWithoutSubThematic.push(layer)
      }

      let subThematic = collection.subThematic[layer.subThematic]
      if (subThematic === undefined) {
        subThematic = collection.subThematic[layer.subThematic] = []

        // add a new title to the sorted array
        collection.subThematicTitles.splice(sortedIndex(collection.subThematicTitles, layer.subThematic), 0, layer.subThematic)
      }

      subThematic.push(layer)
    })

    return result
  }, [favoriteLayerIds, layers.data])

  // Handlers
  const handleToggle = useCallback((event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }, [])

  // Actions
  const expandAll = useCallback(() => {
    const nodeIds = [...groupedLayers.thematicTitles.map(title => `thematic-${title}`)]
    nodeIds.push(...groupedLayers.sortedZoneTitles.map(title => `zone-${title}`))
    Object.values(groupedLayers.thematic).forEach(({ subThematicTitles, zone }) => {
      nodeIds.push(...subThematicTitles.map(title => `subThematic-${title}`))
      Object.entries(zone).forEach(([title, value]) => {
        nodeIds.push(`subZone-${title}`)
        nodeIds.push(...value.subThematicTitles.map(title => `subThematic-${title}`))
      })
    })
    setExpanded(nodeIds)
  }, [groupedLayers])
  const collapseAll = useCallback(() => {
    setExpanded([])
  }, [])
  const updateFavoriteLayers = useCallback((favoriteLayers: string[]) => {
    if (!userPref.isLoading && userPref.data != null) {
      MapService.updateFavorites(userPref.data.id, favoriteLayers).then(
        () => {
          userPref.refresh()

          if (!expanded.includes(favoritesNodeId)) {
            setExpanded([...expanded, favoritesNodeId])
          }
        },
        (e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
        }
      )
    }
  }, [enqueueSnackbar, expanded, t, userPref])
  const onFilterChange = useCallback(filter => {
    setLayersFilter(filter)

    if (filter === undefined) {
      collapseAll()
    } else {
      expandAll()
    }
  }, [collapseAll, expandAll])

  return (
    <FavoriteLayersContext.Provider value={{ favoriteLayers: favoriteLayerIds, updateFavoriteLayers }}>
      <div className='h-full p-0 sm:p-2' data-testid='map-sidebar-card'>
        <MapSearchBar onFilterChange={onFilterChange} />
        {layers.isLoading ? (
          <div className='flex h-full items-center justify-center w-full'>
            <LocalLoader message={t('map:sidebar.cards.loading')} />
          </div>
        ) : (<div>
          <div>
            <TreeView
              onNodeToggle={handleToggle}
              expanded={expanded}
              defaultCollapseIcon={
                <FaIcon name='caret-down' className='text-3xl text-selection-inverse' />
              }
              defaultExpandIcon={<FaIcon name='caret-right' className='text-3xl text-selection-inverse' />}
            >
              {groupedLayers.favoriteLayers.length > 0 &&
                <StyledThreeItem
                  nodeId={favoritesNodeId}
                  title={t('map:sidebar.cards.MyFavorites')}
                >
                  {groupedLayers.favoriteLayers.map((layer: MapTypes.ILayer) => (
                    <LayerPreview
                      active={activeLayer?.id === layer.id}
                      key={layer.id}
                      layer={layer}
                      onLayerClick={onLayerClick}
                    />
                  ))}
                </StyledThreeItem>
              }
              {groupedLayers.thematicTitles.map(title => {
                const thematic = groupedLayers.thematic[title]
                return <StyledThreeItem nodeId={`thematic-${title}`} title={title} key={title}>
                  {thematic.sortedZoneTitles.map((title: string) => {
                    const zone = thematic.zone[title]
                    return <StyledThreeItem nodeId={`subZone-${title}`} title={title} key={title}>
                      <SubThematic
                        activeLayer={activeLayer}
                        collection={zone}
                        onLayerClick={onLayerClick}
                      />
                    </StyledThreeItem>
                  })}
                  <SubThematic
                    activeLayer={activeLayer}
                    collection={thematic}
                    onLayerClick={onLayerClick}
                  />
                </StyledThreeItem>
              })}
              {groupedLayers.sortedZoneTitles.map(title => {
                const zone = groupedLayers.zone[title]
                return <StyledThreeItem nodeId={`zone-${title}`} title={title} key={title}>
                  {zone.map(layer => <LayerPreview
                    active={activeLayer?.id === layer.id}
                    key={layer.id}
                    layer={layer}
                    onLayerClick={onLayerClick}
                  />)}
                </StyledThreeItem>
              })}
            </TreeView>
            {groupedLayers.layersWithoutThematic.map((layer: MapTypes.ILayer) => (
              <LayerPreview
                active={activeLayer?.id === layer.id}
                key={layer.id}
                layer={layer}
                onLayerClick={onLayerClick}
              />
            ))}
          </div>
        </div>
        )}
      </div>
    </FavoriteLayersContext.Provider>
  );
};

MapSidebarCard.propTypes = {
  activeLayer: PropTypes.any,
  onLayerClick: PropTypes.func.isRequired,
};

export default MapSidebarCard;
