// React libs
import React, { FC, useMemo } from 'react';
import { BryntumScheduler } from '@bryntum/scheduler-react'
import { LocaleManager } from '@bryntum/scheduler/scheduler.umd.js'
import { maxBy, minBy } from 'lodash';
import { useTranslation } from 'react-i18next';
// Types
import * as Types from './Timeline.type'
import * as CoreTypes from '../../../../Data/Models/Core.type'
// Locales
import '../../../../Resources/assets/locales/scheduler-local-fr'

if (navigator.language.includes('fr')) {
  LocaleManager.locale = 'FR'
}

const OPTIONS_BY_SIZE: { [size in CoreTypes.datavizChartSizeType]: any } = {
  mini: {
    globalStyle: {
      height: '5rem',
      width: '5rem'
    }
  },
  medium: {
    globalStyle: {
      height: '14rem',
      width: '57rem'
    }
  },
  maxi: {
    globalStyle: {
      height: '17rem',
      width: '65rem'
    }
  }
}

const Timeline: FC<Types.IProps> = ({ size = 'medium', data }) => {
  // Variables
  const { t } = useTranslation(['components']);
  const chartDynamicOption = OPTIONS_BY_SIZE[size]
  const resourceId = 'mainRow'

  const startDate = useMemo(() => minBy(data, item => new Date(item.startDate).getTime())?.startDate, [data])
  const endDate = useMemo(() => maxBy(data, item => new Date(item.endDate).getTime())?.endDate, [data])

  const options: any = {
    createEventOnDblClick: false,
    startDate,
    endDate,
    viewPreset: "dayAndWeek",
    listeners: {
      presetChange() {
        const scheduler: any = this
        const widgets = scheduler.widgetMap
        widgets.zoomOutButton.disabled = scheduler.zoomLevel <= scheduler.minZoomLevel
        widgets.zoomInButton.disabled = scheduler.zoomLevel >= scheduler.maxZoomLevel
      }
    },
    resources: [
      { id: resourceId }
    ],
    events: data.map(item => ({
      resourceId,
      name: item.name,
      startDate: item.startDate,
      endDate: item.endDate,
      eventColor: item.color,
      cls: 'dataviz-timeline'
    })),
    features: {
      eventResize: false,
      timeAxisHeaderMenu: {
        items: {
          eventsFilter: false,
        }
      },
      sort: false,
      cellEdit: false,
      cellMenu: false,
      eventCopyPaste: false,
      eventDrag: false,
      eventDragCreate: false,
      eventEdit: false,
      eventMenu: false,
      scheduleMenu: false,
    },
    tbar: {
      onZoomIn() {
        (this as any).parent.zoomIn()
      },
      onZoomOut() {
        (this as any).parent.zoomOut()
      },
      onPrevious() {
        (this as any).parent.shiftPrevious()
      },
      onNext() {
        (this as any).parent.shiftNext()
      },
      contentElementCls: 'justify-end',
      items: [
        {
          type: 'buttonGroup',
          items: [
            {
              cls: 'b-raised bg-active',
              ref: 'previousButton',
              icon: 'b-fa b-fa-backward',
              tooltip: t('components:scheduler.previousTimeSpan'),
              onAction: 'up.onPrevious',
            },
            {
              cls: 'b-raised bg-active',
              ref: 'nextButton',
              icon: 'b-fa b-fa-forward',
              tooltip: t('components:scheduler.nextTimeSpan'),
              onAction: 'up.onNext',
            },
            {
              cls: 'b-raised bg-active',
              ref: 'zoomInButton',
              icon: 'b-fa b-fa-search-plus',
              tooltip: t('components:scheduler.zoomIn'),
              onAction: 'up.onZoomIn'
            },
            {
              cls: 'b-raised bg-active',
              ref: 'zoomOutButton',
              icon: 'b-fa b-fa-search-minus',
              tooltip: t('components:scheduler.zoomOut'),
              onAction: 'up.onZoomOut',
            }
          ]
        }
      ]
    }
  }
  return <div data-testid='Timeline' className='h-full w-full flex items-center justify-center'>
    <div style={chartDynamicOption.globalStyle}>
      <BryntumScheduler {...options} />
    </div>
  </div>
}

export default Timeline