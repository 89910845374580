// React libs
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import AppNavigationLayout from '../../../App/Components/Layout/AppNavigationLayout/AppNavigationLayout';
import PageLoader from '../../../Core/Components/UiKit/Loader/PageLoader/PageLoader';
import PoiContactPage from '../../../Core/Components/Form/PoiContact/PoiContactPage/PoiContactPage'
import PoiFurtherInformationPage from '../../../Core/Components/Form/PoiFurtherInformation/PoiFurtherInformationPage/PoiFurtherInformationPage';
import ProjectEcosystem from './ProjectEcosystem/ProjectEcosystem';
import ProjectGed from './ProjectGed/ProjectGed';
import ProjectIdentity from './ProjectIdentity/ProjectIdentity';
import ProjectFinancing from './ProjectFinancing/ProjectFinancing';
import ProjectStatisticReport from './ProjectStatisticReport/ProjectStatisticReport';
import ProjectTasksManagement from './TasksManagement/ProjectTasksManagement';
// Type
import * as CoreTypes from '../../../Core/Data/Models/Core.type'
import * as Types from './ProjectScene.type';
// Hooks
import usePoi from '../../../Core/Data/Hooks/Poi'
// Common
import Common from '../../../App/Resources/Common'
// Contexts
import PoiContext from '../../../Core/Data/Contexts/PoiContext';

const projectSceneWrapper = (Component: any) => (props: Types.IProps) => {
  // Variables
  const { t } = useTranslation(['project']);
  const poi = usePoi(props.match.params.id)

  if (poi.isLoading) {
    return <PageLoader message={t('project:loadingMessage')} />
  }

  if (poi.data == null) {
    return null
  }

  return <PoiContext.Provider value={{ poi: poi.data }}>
    <Component {...props} poi={poi.data} refresh={poi.refresh} refreshTime={poi.refreshTime} isLoading={poi.isLoading} />
  </PoiContext.Provider>
}

interface IProjectScene extends Types.IProps {
  poi: CoreTypes.IPoi
  refresh: () => void
  refreshTime: number
  isLoading: boolean
}

const ProjectScene = ({ poi, match, refresh, refreshTime, isLoading }: IProjectScene) => {
  // Variables
  const { t } = useTranslation(['project']);
  const [, currentRoute] = /^\/projet\/:id\/(.+)$/.exec(match.path) ?? []
  const hasValueDefs = useMemo(() => poi.type.valueDefs != null && poi.type.valueDefs.length > 0, [poi.type])
  const pages = [
    {
      icon: 'info-circle',
      label: t('project:identity'),
      component: <ProjectIdentity project={poi} isEditionMode={currentRoute === Common.Routes.routeUpdateProject} refreshPoi={refresh} />,
      previewUrl: Common.Routes.routePreviewProject,
      updateUrl: Common.Routes.routeUpdateProject,
    },
    {
      icon: 'bars',
      label: t('project:furtherInformation'),
      component: <PoiFurtherInformationPage poi={poi} isEditionMode={currentRoute === Common.Routes.routeUpdateFurtherInformationProject} refreshPoi={refresh} />,
      hidden: !hasValueDefs,
      previewUrl: Common.Routes.routeFurtherInformationPreviewProject,
      updateUrl: Common.Routes.routeUpdateFurtherInformationProject,
    },
    {
      icon: 'address-card-o',
      label: t('project:contacts'),
      component: <PoiContactPage poi={poi} isEditionMode={currentRoute === Common.Routes.routeUpdateContactProject} refreshPoi={refresh} />,
      previewUrl: Common.Routes.routeContactPreviewProject,
      updateUrl: Common.Routes.routeUpdateContactProject
    },
    {
      icon: 'sitemap',
      label: t('project:ecosystem.title'),
      component: <ProjectEcosystem poi={poi} isEditionMode={currentRoute === Common.Routes.routeUpdateEcosystemProject} refreshPoi={refresh} />,
      previewUrl: Common.Routes.routeEcosystemPreviewProject,
      updateUrl: Common.Routes.routeUpdateEcosystemProject
    },
    {
      icon: 'bar-chart',
      label: t('project:tasksManagement.title'),
      component: <ProjectTasksManagement project={poi} isEditionMode={currentRoute === Common.Routes.routeUpdateTasksManagementProject} />,
      previewUrl: Common.Routes.routeTasksManagementPreviewProject,
      updateUrl: Common.Routes.routeUpdateTasksManagementProject
    },
    {
      icon: 'eur',
      label: t('project:financing.title'),
      component: <ProjectFinancing poi={poi} isEditionMode={currentRoute === Common.Routes.routeUpdateFinancingProject} refreshPoi={refresh} />,
      previewUrl: Common.Routes.routeFinancingPreviewProject,
      updateUrl: Common.Routes.routeUpdateFinancingProject
    },
    {
      icon: 'pie-chart',
      label: t('project:statisticReport.title'),
      component: <ProjectStatisticReport project={poi} refresh={refresh} refreshTime={refreshTime} isLoading={isLoading} />,
      previewUrl: Common.Routes.routeStatisticReportPreviewProject,
    },
    {
      icon: 'file',
      label: t('project:ged.title'),
      component: <ProjectGed poi={poi} isEditionMode={currentRoute === Common.Routes.routeUpdateGedProject} refreshPoi={refresh} />,
      previewUrl: Common.Routes.routeGedPreviewProject,
      updateUrl: Common.Routes.routeUpdateGedProject
    },
  ]

  return <AppNavigationLayout
    headerConf={{
      title: {
        label: `${t('project:title')} / ${poi.type.label} / ${poi.name}`,
        icon: 'cubes',
      },
    }}
    baseUrl={`/${Common.Routes.routeProject}/${poi.id}`}
    currentRoute={currentRoute}
    pages={pages}
  />
}

export default projectSceneWrapper(ProjectScene);
