// React libs
import React, { FC, useMemo } from 'react';
import ReactECharts from 'echarts-for-react'
import { sumBy, sortBy } from 'lodash';
// Types
import * as Types from './Nightingale.type'
import * as CoreTypes from '../../../../Data/Models/Core.type'
// Hooks
import useDatavizPalettes from '../../../../Data/Hooks/DatavizPalettes';
// Components
import LocalLoader from '../../Loader/LocalLoader/LocalLoader';

const OPTIONS_BY_SIZE: { [size in CoreTypes.datavizChartSizeType]: any } = {
  mini: {
    title: {
      fontSize: 15,
      formatTitle: (total: number) => total
    },
    label: {
      show: false
    },
    series: {
      radius: ['40%', '60%']
    },
    globalStyle: {
      height: '3em',
      width: '3em'
    }
  },
  medium: {
    title: {
      fontSize: 15,
      formatTitle: (total: number, title: string) => `${total}\n${title}`
    },
    label: {
      fontSize: 17,
      show: true
    },
    series: {
      radius: ['30%', '80%']
    },
    globalStyle: {
      height: '100%',
      width: '60em'
    },
    toolbox: {
      feature: {
        saveAsImage: {},
        dataView: { show: true, readOnly: true }
      }
    },
  },
  maxi: {
    title: {
      fontSize: 22,
      formatTitle: (total: number, title: string) => `${total}\n\n${title}`
    },
    label: {
      fontSize: 22,
      show: true
    },
    series: {
      radius: ['25%', '100%']
    },
    globalStyle: {
      height: '45em',
      width: '100em'
    },
    toolbox: {
      feature: {
        saveAsImage: {},
        dataView: { show: true, readOnly: true }
      }
    },
  }
}
interface INightingaleComponent extends Types.IProps {
  colorPalette: string[]
}

const NightingaleComponent = ({ title, size = 'medium', formatter, data, colorPalette }: INightingaleComponent) => {
  // Variables
  const chartDynamicOption = OPTIONS_BY_SIZE[size]

  const newData = useMemo(() => sortBy(data, 'name').map((data, key) => {
    const color = colorPalette[key] ?? colorPalette[key % colorPalette.length]
    return {
      ...data,
      label: {
        show: chartDynamicOption.label.show,
        color,
      },
      itemStyle: {
        color,
      }
    }
  }).sort((v1: any, v2: any) => v1.value > v2.value ? -1 : 1), [chartDynamicOption.label.show, colorPalette, data])
  const totalOfValues = useMemo(() => sumBy(data, ({ value }) => value), [data])

  const option: any = {
    legend: {
      show: false
    },
    tooltip: {
      triggerOn: 'mousemove',
      formatter
    },
    title: {
      textStyle: {
        fontSize: chartDynamicOption.title.fontSize
      },
      show: true,
      text: chartDynamicOption.title.formatTitle(totalOfValues, title),
      left: 'center',
      top: 'center'

    },
    toolbox: chartDynamicOption.toolbox,
    label: {
      fontSize: chartDynamicOption.label.fontSize,
      formatter
    },
    series: [
      {
        type: 'pie',
        center: ['50%', '50%'],
        roseType: 'area',
        color: colorPalette,
        radius: chartDynamicOption.series.radius,
        itemStyle: {
          borderRadius: 9
        },
        labelLine: {
          lineStyle: {
            type: 'dashed'
          }
        },
        data: newData
      }
    ]
  }

  return <ReactECharts option={option} style={chartDynamicOption.globalStyle} />
}

const Nightingale: FC<Types.IProps> = props => {
  // Variables
  const colorPalette = useDatavizPalettes('nightingaleLineChart').data

  return <div data-testid='nightingale' className='h-full w-full flex items-center justify-center'>
    {colorPalette === undefined
      ? <LocalLoader />
      : <NightingaleComponent {...props} colorPalette={colorPalette} />
    }
  </div>
}

export default Nightingale
