// React libs
import React, { FC } from 'react';
import PropTypes from 'prop-types';
// Layout
import BaseLayout from '../BaseLayout/BaseLayout';
// Components
import AdminSidebar from '../../Sidebar/AdminSidebar/AdminSidebar';
// Type
import * as Types from './AdminLayout.type';

const AdminLayout: FC<Types.IProps> = ({ children, headerConf }) => (
  <BaseLayout headerConf={headerConf}>
    <div className='flex flex-1 overflow-hidden w-full'>
      <AdminSidebar />
      <div className={`flex-1 relative`} data-testid='app-layout'>
        {children}
      </div>
    </div>
  </BaseLayout>
);

AdminLayout.propTypes = {
  headerConf: PropTypes.any,
};

AdminLayout.defaultProps = {
  className: '',
};

export default AdminLayout;
