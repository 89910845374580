// React libs
import React, { FC, useCallback } from 'react'
import * as Yup from 'yup';
// Components
import FormLinksFilter from '../../Form/LinksFilter/LinksFilter'
// Types
import * as Types from './LinksFilter.type'
import * as FormLinksFilterTypes from '../../Form/LinksFilter/LinksFilter.type'

const LinksFilter: FC<Types.IProps> = ({ filters = {}, onFiltersChange }) => {
  // Variables
  const defaultValues: FormLinksFilterTypes.IFormValues = filters
  const validationSchema = Yup.object().shape({
    fklinkType: Yup.string(),
    createdBy: Yup.string()
  })

  // Handlers
  const onSubmit = useCallback((values: FormLinksFilterTypes.IFormValues) => {
    onFiltersChange(
      [values.fklinkType, values.createdBy].every(value => value == null || value === '') ? undefined : values
    )
  }, [onFiltersChange])

  return <div data-testid='LinksFilter' className='h-full w-full'>
    <FormLinksFilter
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onFormSubmit={onSubmit}
    />
  </div>
}

export default LinksFilter