// React libs
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../../Button/Button';
import FaIcon from '../../Icon/FaIcon/FaIcon';
// Types
import * as Types from './CancelButton.type'

const CancelButton: FC<Types.IProps> = ({ className, onCancel }) => {
  // Variables
  const { t } = useTranslation(['common']);

  return <span data-testid='cancel-button'>
    <Button
      className={`text-selection hover:bg-active-hover hover:text-selection-inverse ${className}`}
      color='primary'
      size='small'
      onClick={onCancel}
      startIcon={<FaIcon name='arrow-circle-left' className='text-lg' />}
    >
      {t('common:buttons.cancel')}
    </Button>
  </span>
}

export default CancelButton