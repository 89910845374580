// React libs
import React, { FC } from 'react';
// Components
import Autocomplete from '../Autocomplete/Autocomplete';
// Type
import * as Types from './GeocodingField.type';
import * as AutocompleteTypes from '../Autocomplete/Autocomplete.type';

const GeocodingField: FC<Types.IProps> = ({
  form,
  field,
  handlerSelectedValue,
  onCompositionStart,
  options,
  fullOptions,
  ...otherProps
}) => {
  // Variables
  const props = {
    form,
    field,
    ...otherProps,
    onBlur: () => {},
  };

  // Handlers
  const onSelectValue = (option: AutocompleteTypes.IOption) => {
    if (option) {
      const optionData = fullOptions.find((o: any) => o.id === option.value);
      if (optionData) {
        handlerSelectedValue(optionData, option.value);
      }
    }
  };

  return (
    <div className='w-full' data-testid='geocodingfield'>
      <Autocomplete
        {...props}
        options={options}
        filterOptions={(options: any[], state: any) => options}
        onSelectValue={onSelectValue}
      />
    </div>
  );
};

GeocodingField.defaultProps = {};

export default GeocodingField;
