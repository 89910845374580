// React libs
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { countBy, keyBy } from 'lodash';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'
import * as ProjectTypes from '../../Data/Models/Project.type'
// Hooks
import usePoiTypes from '../../../../Core/Data/Hooks/PoiTypes';

const useResourceTypesMetrics = ({ project, ...props }: ProjectTypes.IMetricsHook): CoreTypes.IDatavizHook => {
  // Variables
  const { t } = useTranslation(['project']);
  const poiTypes = usePoiTypes('resource')
  const poiTypesById = useMemo(() => keyBy(poiTypes.data, 'id'), [poiTypes.data])

  const data = useMemo(() => project.links == null
    ? []
    : Object.entries(countBy(project.links, link => link.poi.type))
      .map(([typeId, value]) => ({
        name: poiTypesById[typeId]?.label,
        value
      }))
      .filter(metric => metric.value !== 0)
      .sort((a, b) => a.value > b.value ? -1 : 1),
    [poiTypesById, project.links])

  return {
    ...props,
    data,
    chart: {
      title: t('project:statisticReport.titles.resources'),
      formatter: '{b} : {d}% ({c})'
    }
  }
}

export default useResourceTypesMetrics