// React libs
import { useMemo } from 'react'
import { round } from 'lodash'
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'
import * as ProjectTypes from '../../Data/Models/Project.type'

const useBudgetDistributionsMetrics = ({ project, ...props }: ProjectTypes.IMetricsHook): CoreTypes.IDatavizHook => {
  // Variables
  const data = useMemo(() => {
    const legends: string[] = []
    const series: any[] = []

    let totalAmountVat = 0
    project.expenseLines?.forEach(line => {
      legends.push(line.name)
      series.push(line.amountVat)

      totalAmountVat += line.amountVat
    })

    const getPercent = (value: number) => round(value * 100 / totalAmountVat, 1)

    return {
      legends,
      series,
      formatter: ({ name, value }: any) => `${name}: ${(+value).toLocaleString('fr-FR')} € (${getPercent(+value)}%)`
    }
  }, [project.expenseLines])

  return {
    ...props,
    data
  }
}

export default useBudgetDistributionsMetrics