// Utils
import { createContext } from 'react';
// Types
import * as MapTypes from '../Models/Map.type';

export interface IPoisContext {
  pois: MapTypes.IPoi[];
  updatePois: (pois: MapTypes.IPoi[]) => void;
  needPoisRefresh: boolean;
  setNeedPoisRefresh: (needPoisRefresh: boolean) => void;
}
const PoisContext = createContext<IPoisContext>({
  pois: [],
  updatePois: (poisData: MapTypes.IPoi[]) => {},
  needPoisRefresh: false,
  setNeedPoisRefresh: (needPoisRefresh: boolean) => {},
});

export default PoisContext;
