// React libs
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import FinancingTable from '../FinancingTable/FinancingTable';
// Types
import * as Types from './ProjectBudget.type'
import * as FinancingTableTypes from '../FinancingTable/FinancingTable.type';

const ProjectBudget: FC<Types.IProps> = ({ expenseLines, onChange, onAdd, onDel, onDelMultiple }) => {
  // Variables
  const { t } = useTranslation(['project']);
  const columns: FinancingTableTypes.IColumn[] = [
    {
      id: 'expenseLineName',
      field: 'expenseLineName',
      name: t('project:financing.expenseLine'),
      type: 'text',
      dataField: 'name'
    },
    {
      id: 'expenseAmount',
      field: 'expenseAmount',
      name: t('project:financing.htAmount'),
      type: 'float',
      dataField: 'amount'
    },
    {
      id: 'expenseAmountVat',
      field: 'expenseAmountVat',
      name: t('project:financing.ttcAmount'),
      type: 'float',
      dataField: 'amountVat'
    },
  ]

  const rows: any = useMemo(() => expenseLines.map(expenseLine => ({
    id: expenseLine.id,
    expenseLineName: expenseLine.name,
    expenseAmount: expenseLine.amount,
    expenseAmountVat: expenseLine.amountVat
  })), [expenseLines])

  const searchColumnKeys = [
    'expenseLineName',
    'expenseAmount',
    'expenseAmountVat'
  ]

  return <div data-testid='ProjectBudget'>
    <FinancingTable
      columns={columns}
      onAdd={onAdd}
      onChange={onChange}
      onDel={onDel}
      onDelMultiple={onDelMultiple}
      rows={rows}
      searchColumnKeys={searchColumnKeys}
      title={t('project:financing.projectedBudget')}
    />
  </div>
}

export default ProjectBudget