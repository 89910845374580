// Utils
import ServicesUtils from '../../../../Core/Utils/Services';
import DatavizUrls from './DatavizUrls';

export default class DatavizService {
  // Resources
  static getResourcesMetrics(dateStart: string, dateEnd: string, filter?: any, size?: number) {
    return ServicesUtils.get(DatavizUrls.getResourcesMetrics(dateStart, dateEnd, filter, size))
  }

  static getResourceEvolutionMetrics(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return ServicesUtils.get(DatavizUrls.getResourceEvolutionMetrics(dateStart, dateEnd, filter, size))
  }

  static getResourceLinksEvolutionMetrics(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return ServicesUtils.get(DatavizUrls.getResourceLinksEvolutionMetrics(dateStart, dateEnd, filter, size))
  }

  // Projects
  static getProjectsMetrics(dateStart: string, dateEnd: string, filter?: any, size?: number) {
    return ServicesUtils.get(DatavizUrls.getProjectsMetrics(dateStart, dateEnd, filter, size))
  }

  static getProjectEvolutionMetrics(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return ServicesUtils.get(DatavizUrls.getProjectEvolutionMetrics(dateStart, dateEnd, filter, size))
  }

  static getProjectsMetricsBudget(dateStart: string, dateEnd: string, filter?: any, size: number = 25) {
    return ServicesUtils.get(DatavizUrls.getProjectsMetricsBudget(dateStart, dateEnd, filter, size))
  }

  // Links
  static getLinkMetrics(dateStart: string, dateEnd: string, filter?: any, size?: number) {
    return ServicesUtils.get(DatavizUrls.getLinkMetrics(dateStart, dateEnd, filter, size))
  }

  // Tasks
  static getProjectTasksMetrics(dateStart: string, dateEnd: string, filter?: any, size?: number) {
    return ServicesUtils.get(DatavizUrls.getProjectTasksMetrics(dateStart, dateEnd, filter, size))
  }
}
