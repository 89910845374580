// Utils
import { createContext } from 'react';
// Types
import * as MapTypes from '../Models/Map.type';

export interface ILinkTypeContext {
  linkTypes: MapTypes.ILinkType[];
  updateLinkTypes: (types: MapTypes.ILinkType[]) => void;
}
const LinkTypeContext = createContext<ILinkTypeContext>({
  linkTypes: [],
  updateLinkTypes: (types: MapTypes.ILinkType[]) => {},
});

export default LinkTypeContext;
