// React libs
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sumBy, round } from 'lodash';
// Components
import FinancingTable from '../FinancingTable/FinancingTable';
// Types
import * as Types from './ProjectFinancingSources.type'
import * as FinancingTableTypes from '../FinancingTable/FinancingTable.type';


const ProjectFinancingSources: FC<Types.IProps> = ({ expenseFundings, fundTypes, onChange, onAdd, onDel, onDelMultiple }) => {
  // Variables
  const { t } = useTranslation(['project']);
  const fundTypesOptions = useMemo(() => fundTypes.map(fundType => ({
    label: fundType.label,
    value: fundType.id
  })), [fundTypes])
  const columns: FinancingTableTypes.IColumn[] = [
    {
      id: 'financersName',
      field: 'financersName',
      name: t('project:financing.financers'),
      type: 'text',
      dataField: 'name'
    },
    {
      id: 'expenseAmount',
      field: 'expenseAmount',
      name: t('project:financing.requestedAmount'),
      type: 'float',
      dataField: 'amount'
    },
    {
      id: 'expenseAmountPercent',
      field: 'expenseAmountPercent',
      name: t('project:financing.inPercent'),
      type: 'percent'
    },
    {
      id: 'expenseFundType',
      field: 'expenseFundType',
      name: t('project:financing.fundsType'),
      type: 'select',
      options: fundTypesOptions,
      dataField: 'fkfundType'
    },
    {
      id: 'expenseDepositFolderDate',
      field: 'expenseDepositFolderDate',
      name: t('project:financing.depositFolder'),
      type: 'date',
      dataField: 'requestDate'
    },
    {
      id: 'expenseSubventionEndDate',
      field: 'expenseSubventionEndDate',
      name: t('project:financing.subventionEnd'),
      type: 'date',
      dataField: 'deadlineDate'
    },
  ]

  const rows: any = useMemo(() => {
    const totalAmount = sumBy(expenseFundings, 'amount')
    return expenseFundings.map(expenseFund => ({
      id: expenseFund.id,
      financersName: expenseFund.name,
      expenseAmount: expenseFund.amount,
      expenseAmountPercent: totalAmount === 0 ? 0 : round(expenseFund.amount * 100 / totalAmount, 1),
      expenseFundType: expenseFund.fkfundType,
      expenseDepositFolderDate: expenseFund.requestDate,
      expenseSubventionEndDate: expenseFund.deadlineDate
    }))
  }, [expenseFundings])

  const searchColumnKeys = [
    'financersName',
    'expenseAmount',
    'expenseAmountPercent',
    'expenseFundType',
    'expenseDepositFolderDate',
    'expenseSubventionEndDate'
  ]

  return <div data-testid='ProjectFinancingSources'>
    <FinancingTable
      columns={columns}
      onAdd={onAdd}
      onChange={onChange}
      onDel={onDel}
      onDelMultiple={onDelMultiple}
      rows={rows}
      searchColumnKeys={searchColumnKeys}
      title={t('project:financing.financingSources')}
    />
  </div>
}

export default ProjectFinancingSources