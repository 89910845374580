// React libs
import React, { FC } from 'react';
// Components
import GenericIconButton from '../GenericIconButton/GenericIconButton';
// Types
import * as Types from './DeleteButton.type'

const DeleteButton: FC<Types.IProps> = ({ onClick, tooltip, disabled, ...props }) => {
  return <span data-testid='DeleteButton'>
    <GenericIconButton
      {...props}
      ariaLabel='delete button'
      onClick={onClick}
      icon='trash'
      tooltip={tooltip}
      disabled={disabled}
    />
  </span>
}

export default DeleteButton