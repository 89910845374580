// React libs
import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { BryntumGantt } from '@bryntum/gantt-react'
import { LocaleManager } from '@bryntum/gantt/gantt.umd.js';
import { useTranslation } from 'react-i18next';
// Components
import * as planningToolbar from './Configs/PlanningToolbar'
import AppLayout from '../../../../App/Components/Layout/AppLayout/AppLayout';
// Types
import * as Types from './Planning.type'
// Locales
import '../../Resources/assets/locales/gant-local-fr'
// Services
import PlanningUrls from '../../Data/Services/PlanningUrls';

if (navigator.language.includes('fr')) {
  LocaleManager.locale = 'FR'
}

const DATE_FORMAT = 'DD/MM/YYYY'

const Planning: FC<Types.IProps> = () => {
  // Variables
  const { t } = useTranslation(['planning']);

  const options = useMemo(() => ({
    tbar: { type: planningToolbar.type },
    project: {
      autoLoad: true,
      transport: {
        load: {
          url: PlanningUrls.getTasks()
        }
      },
    },
    columns: [
      {
        type: 'name',
        text: t('planning:titles.projects'),
        renderer: ({ record }: any) => {
          const labelByStatus: any = {
            todo: t('planning:tasks.todo'),
            doing: t('planning:tasks.doing'),
            done: t('planning:tasks.done')
          }
          return record.name !== '' ? record.name : labelByStatus[record.data.status]
        }
      },
      {
        field: 'progress',
        text: t('planning:titles.progress'),
        renderer: ({ record }: any) => record.progress != null ? `${record.progress}%` : ''
      }
    ],
    listeners: {
      presetChange({ source: scheduler }: any) {
        const widgets = scheduler.widgetMap
        widgets.zoomOutButton.disabled = scheduler.zoomLevel <= scheduler.minZoomLevel
        widgets.zoomInButton.disabled = scheduler.zoomLevel >= scheduler.maxZoomLevel
      }
    },
    features: {
      tree: true,
      labels: {
        left: {
          field: 'name'
        }
      },
      taskTooltip: {
        template: ({ taskRecord }: any) => `
          <table>
            <tr>
              <td>${t('planning:titles.start')}:</td>
              <td>${moment(taskRecord.startDate).format(DATE_FORMAT)}</td>
            </tr>
            <tr>
              <td>${t('planning:titles.end')}:</td>
              <td>${moment(taskRecord.endDate).format(DATE_FORMAT)}</td>
            </tr>
          ${taskRecord.progress
            ? `<tr>
              <td>${t('planning:titles.progress')}:</td>
              <td>${taskRecord.progress}%</td>
            </tr>`
            : ''
          }
          </table>
        `
      },
      cellEdit: false,
      columnPicker: false,
      columnReorder: false,
      headerMenu: false,
      rowReorder: false,
      dependencies: false,
      eventFilter: false,
      projectLines: false,
      taskCopyPaste: false,
      taskDrag: false,
      taskDragCreate: false,
      taskEdit: false,
      taskMenu: false,
      taskResize: false,
    }
  }), [t])

  return <AppLayout
    headerConf={{
      title: {
        label: t('planning:title'),
        icon: 'tasks'
      }
    }}
    className='h-full w-full'
  >
    <div data-testid='Planning' className='h-full w-full p-2 overflow-auto'>
      <BryntumGantt
        {...options}
        cls='w-full h-full'
      />
    </div>
  </AppLayout>
}

export default Planning