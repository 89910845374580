// React libs
import React, { FC, useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { keyBy, sumBy } from 'lodash'
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Components
import AdvancedPoiFiltersForm from '../AdvancedPoiFilters/AdvancedPoiFiltersForm';
import DataTable from '../../UiKit/DataTable/DataTable';
import DeleteButton from '../../UiKit/Button/DeleteButton/DeleteButton';
import InfoButton from '../../UiKit/Button/InfoButton/InfoButton';
import MarkerButton from '../../UiKit/Button/MarkerButton/MarkerButton';
import Nightingale from '../../UiKit/Charts/Nightingale/Nightingale';
// Hooks
import useConfirmMessageModal from '../../../Utils/useConfirmMessageModal';
import useLinkTypes from '../../../Data/Hooks/LinkTypes';
import usePersons from '../../../Data/Hooks/Persons';
import usePhaseTypes from '../../../Data/Hooks/PhaseTypes';
import usePoiLinks from '../../../Data/Hooks/PoiLinks';
// Types
import * as Types from './PoisList.type'
import * as CoreTypes from '../../../Data/Models/Core.type'
// Utils
import useServiceErrorHandler from '../../../Utils/useServiceErrorHandler';
import { deletePoi } from '../../../Utils/ServiceHelpers';
import { formatPersonName } from '../../../Utils/FormUtils';
import { getFormatPoiFilter } from '../../../Utils/Filters';
import { ifDef } from '../../../Utils/Misc';
// Common
import CloseIconButton from '../../UiKit/Button/CloseIconButton/CloseIconButton';
import Common from '../../../../App/Resources/Common';
import CoreCommon from '../../../Resources/Common';

interface IEcosystemChart {
  linksCount: { type: string, count: number }[]
}

const EcosystemChart = ({ linksCount }: IEcosystemChart) => {
  // Variables
  const linkTypes = useLinkTypes()
  const linkTypesById = useMemo(() => keyBy(linkTypes.data, 'id'), [linkTypes.data])

  const data = useMemo(() => linksCount.map(({ type, count }) => ({
    name: linkTypesById[type]?.label,
    value: count
  })), [linkTypesById, linksCount])

  return <Nightingale data={data} size='mini' />
}

interface IProjectsChart {
  phasesCount: { type: string, count: number }[]
}

const ProjectsChart = ({ phasesCount }: IProjectsChart) => {
  // Variables
  const phaseTypes = usePhaseTypes()
  const phaseTypesById = useMemo(() => keyBy(phaseTypes.data, 'id'), [phaseTypes.data])

  const data = useMemo(() => phasesCount.map(({ type, count }) => ({
    name: phaseTypesById[type]?.label,
    value: count
  })), [phaseTypesById, phasesCount])

  return <Nightingale data={data} size='mini' />
}

type filterType = { [key in 'project' | 'resource']: any[] } | undefined

interface IFilters {
  filters: filterType
  isProject: boolean
  toggleFiltersSidebar: () => void
  updateFilters: (...args: any) => void
}

const Filters = ({ isProject, toggleFiltersSidebar, updateFilters, filters }: IFilters) => {
  // Variables
  const { t } = useTranslation(['common']);
  const defaultValues = filters ?? (isProject ? { project: [] } : { resource: [] })
  const validationSchema = Yup.object().shape({
    [isProject ? 'project' : 'resource']: Yup.array().of(Yup.object().shape({
      name: Yup.string().required(t('common:errors.defaultRequiredField')),
      operator: Yup.string().required(t('common:errors.defaultRequiredField')),
      value: Yup.mixed().required(t('common:errors.defaultRequiredField'))
    })),
  })

  // Handler
  const onSubmit = useCallback(filters => {
    const key = isProject ? 'project' : 'resource'
    const value = filters[key]
    updateFilters(value.length > 0 ? { [key]: value } : undefined)
  }, [updateFilters, isProject])

  return <>
    <div className='w-full h-0.5/10 text-right mb-1'>
      <CloseIconButton onClose={toggleFiltersSidebar} />
    </div>
    <div className='w-full h-9.5/10'>
      <AdvancedPoiFiltersForm
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        onFormSubmit={onSubmit}
        miscData={{ hideProject: !isProject, hideResource: isProject }}
      />
    </div>
  </>
}

const PoisList: FC<Types.IProps> = ({
  pois,
  refreshPois,
  isProject = false,
  poiFilters,
  updatePoiFilters,
  pagination,
  onPaginationChange,
  totalRows,
}) => {
  // State
  const [filters, setFilters] = useState<filterType>()
  const [isFiltersSidebarOpened, setIsFiltersSidebarOpened] = useState<boolean>(false)

  // Variables
  const { t } = useTranslation(['common']);
  const handleServiceError = useServiceErrorHandler()
  const history = useHistory()
  const poiLinks = usePoiLinks()
  const persons = usePersons()
  const deletePoisModal = useConfirmMessageModal()
  const { enqueueSnackbar } = useSnackbar();

  // Handlers
  const toggleFiltersSidebar = useCallback(() => setIsFiltersSidebarOpened(isFiltersSidebarOpened => !isFiltersSidebarOpened), [])
  const goToIdentity = useCallback((poi: CoreTypes.IPoi) => {
    if (isProject) {
      history.push(`/${Common.Routes.routeProject}/${poi.id}/${Common.Routes.routePreviewProject}`)
    } else {
      history.push(`/${Common.Routes.routeResource}/${poi.id}/${Common.Routes.routePreviewResource}`)
    }
  }, [history, isProject])
  const locateOnTheMap = useCallback((poi: CoreTypes.IPoi) => {
    history.push(`/${Common.Routes.routeMap}?coordinates=${JSON.stringify(poi.geo.coordinates)}`)
  }, [history])
  const deletePoiItems = useCallback((poiIds: (string | number)[]) =>
    deletePoisModal.openModal(t(`common:modales.confirm.${isProject ? 'deleteProjectsConfirmation' : 'deleteResourcesConfirmation'}`, { nPois: poiIds.length })).then(
      async () => {
        try {
          await Promise.all(poiIds.map(id =>
            ifDef(pois.find(poi => poi.id === id), (poi: any) => deletePoi(poi, poiLinks.data ?? []))
          ))

          enqueueSnackbar(t(`common:forms.validation.${isProject ? 'deleteProjectsSucceed' : 'deleteResourcesSucceed'}`, { nPois: poiIds.length }), {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'success',
          });
        } catch (error) {
          handleServiceError(error)
        } finally {
          refreshPois()
        }
      },
      () => { }
    ), [deletePoisModal, enqueueSnackbar, handleServiceError, isProject, poiLinks.data, pois, refreshPois, t])
  const updateFilters = useCallback(filters => {
    setFilters(filters)
    const searchFilter = poiFilters.$and?.find((filter: any) => ('$or' in filter))
    if (filters !== undefined) {
      const { advanced, formatPoiFilter } = getFormatPoiFilter()
      const key = isProject ? 'project' : 'resource'
      filters[key].forEach((filter: any) => formatPoiFilter(key, filter))
      const formattedFilters = advanced.$or.find(({ $and }: any) => $and.some((item: any) => item.isProject === isProject))
      updatePoiFilters(searchFilter
        ? {
          $and: [searchFilter, ...formattedFilters.$and]
        }
        : formattedFilters
      )
    } else {
      updatePoiFilters(searchFilter
        ? {
          $and: [searchFilter, { isProject }]
        }
        : { isProject }
      )
    }
  }, [isProject, updatePoiFilters, poiFilters])

  const columns: any = useMemo(() => {
    const columns = {
      name: {
        id: 'name',
        field: 'name',
        name: t('common:forms.fields.name')
      },
      type: {
        id: 'typeName',
        field: 'typeName',
        name: t('common:forms.fields.type'),
        renderCell: ({ typeName, typeColor }: any) => <p style={{ color: typeColor }}>{typeName}</p>
      },
      phase: {
        id: 'phaseName',
        field: 'phaseName',
        name: t('common:forms.fields.phase'),
        renderCell: ({ phaseName, phaseColor }: any) => phaseName && <p style={{ color: phaseColor }}>{phaseName}</p>
      },
      ecosystem: {
        id: 'nLinks',
        field: 'nLinks',
        name: t('common:forms.fields.ecosystem'),
        align: 'center',
        renderCell: ({ linksCount }: any) => <EcosystemChart linksCount={linksCount} />
      },
      projects: {
        id: 'nLinks',
        field: 'nLinks',
        name: t('common:forms.fields.projects'),
        align: 'center',
        renderCell: ({ phasesCount }: any) => <ProjectsChart phasesCount={phasesCount} />
      },
      territory: {
        id: 'territory',
        field: 'territory',
        name: t('common:forms.fields.territory'),
      },
      thematic: {
        id: 'thematic',
        field: 'thematic',
        name: t('common:forms.fields.thematic'),
      },
      projectManagerContributor: {
        id: 'projectManagerContributor',
        field: 'projectManagerContributor',
        name: t('common:forms.fields.projectManagerContributor'),
      },
      responsible: {
        id: 'responsible',
        field: 'responsible',
        name: t('common:forms.fields.responsible'),
      },
      actions: {
        id: 'action',
        field: 'action',
        align: 'center',
        name: '',
        renderCell: ({ poi }: any) => <div className='flex items-center justify-center'>
          <InfoButton
            onClick={() => goToIdentity(poi)}
            tooltip={t('common:forms.tooltips.goToIdentity')}
            size='small'
          />
          <MarkerButton
            onClick={() => locateOnTheMap(poi)}
            tooltip={t('common:forms.tooltips.locateOnTheMap')}
            size='small'
          />
          <DeleteButton
            onClick={() => deletePoiItems([poi.id])}
            tooltip={t(`common:forms.tooltips.${isProject ? 'deleteProject' : 'deleteResource'}`)}
            size='small'
          />
        </div>
      }
    }

    return isProject
      ? [
        columns.name,
        columns.type,
        columns.phase,
        columns.ecosystem,
        columns.territory,
        columns.thematic,
        columns.projectManagerContributor,
        columns.responsible,
        columns.actions
      ]
      : [
        columns.name,
        columns.type,
        columns.projects,
        columns.territory,
        columns.thematic,
        columns.responsible,
        columns.actions
      ]
  }, [t, isProject, goToIdentity, locateOnTheMap, deletePoiItems])

  const rows = useMemo(() => pois.map(poi => {
    const data: any = {
      poi,
      id: poi.id,
      name: poi.name,
      typeName: poi.type.label,
      typeColor: poi.type.style.data.markerColor,
      territory: poi.territory?.label,
      thematic: poi.thematics.map(({ thematic }: any) => thematic.label).join(', '),
      responsible: ifDef(
        persons.data?.find(person => person.account?.subjectId === poi.createdBy),
        formatPersonName,
        () => poi.createdBy
      ),

      // Used for search
      keywords: poi.keywords,
      comment: poi.comment
    }

    if (isProject) {
      // Phase
      const phase = ifDef(poi.currentPhaseType, (currentPhaseType: string) => poi.phases.find(phase => phase.type?.id === currentPhaseType))
      data.phaseName = phase?.type.label
      data.phaseColor = phase?.type.style.data.markerColor

      // Links
      data.linksCount = poi.linksCount
      data.nLinks = sumBy(poi.linksCount, 'count')

      // project Manager / Contributors
      data.projectManagerContributor = ifDef(poi.allowedPersons?.[0], ({ person }: any) => formatPersonName(person), () => data.responsible)
    } else {
      data.phasesCount = poi.phasesCount
      data.nLinks = sumBy(poi.phasesCount, 'count')
    }

    return data
  }), [isProject, persons.data, pois])

  const searchColumnKeys = useMemo(() => [
    'name',
    'typeName',
    'phaseName',
    'nLinks',
    'territory',
    'thematic',
    'projectManagerContributor',
    'responsible',
    'keywords',
    'comment'
  ], [])

  const onSearchSubmit = (search: string) => {
    const newFilters = poiFilters.$and?.filter((filter: any) => !('$or' in filter)) ?? [poiFilters]
    if (search.trim() !== '') {
      const searchKeys = isProject
        ? [
          'name',
          'type.label',
          'currentPhaseType',
          'territory.label',
          'thematics.thematic.label',
          'createdBy',
          'keywords',
          'comment',
        ]
        : [
          'name',
          'type.label',
          'territory.label',
          'thematics.thematic.label',
          'createdBy',
          'keywords',
          'comment'
        ]
      newFilters.push({
        $or: searchKeys.map(key => ({
          [key]: `/.*${search}.*/`
        }))
      })
    }
    updatePoiFilters({
      $and: newFilters
    })
  }

  return <div data-testid='PoisList' className='flex h-full w-full'>
    {isFiltersSidebarOpened &&
      <div className='h-full w-1/5 p-2'>
        <Filters isProject={isProject} toggleFiltersSidebar={toggleFiltersSidebar} updateFilters={updateFilters} filters={filters} />
      </div>
    }
    <div className='flex-1 h-full w-4/5'>
      <DataTable
        columns={columns}
        defaultSortColumnId='name'
        onRowDblClick={({ poi }: any) => goToIdentity(poi)}
        rows={rows}
        searchColumnKeys={searchColumnKeys}
        onDeleteAllRows={deletePoiItems}
        containerClassName='dataTable-poisList-container'
        areFiltersEnabled={filters !== undefined}
        isFiltersPanelOpened={isFiltersSidebarOpened}
        onFilter={toggleFiltersSidebar}
        serverModeOptions={{
          pagination,
          onPaginationChange,
          totalRows,
          onSearchSubmit
        }}
      />
    </div>
    {deletePoisModal.modal}
  </div>
}

export default PoisList