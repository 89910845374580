export default class PlanningUrls {
  static path = process.env.REACT_APP_HOST;

  static get apiPath(): string {
    return `${PlanningUrls.path ?? ''}/api`;
  }

  // Tasks
  static getTasks() {
    return `${PlanningUrls.apiPath}/gant`
  }
}
