// React libs
import React, { FC } from 'react';
import { Document } from '@react-pdf/renderer';
// Components
import PdfPage from './PdfPage'
// Types
import * as Types from './PdfDocument.type';

const PdfDocument: FC<Types.IProps> = ({ pages, footer }) => (
  <Document data-testid='pdf-document'>
    {pages.map(({ content, title }, key) => <PdfPage content={content} title={title} key={key} pageNumber={key + 1} nPages={pages.length} footer={footer} />)}
  </Document>
);

export default PdfDocument;
