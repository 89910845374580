// React libs
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Layout
import AuthLayout from '../../../Components/Layout/AuthLayout/AuthLayout';
// Components
import LoginForm from '../../../Components/Form/FormType/Auth/LoginForm/LoginForm';
import Typography from '../../../../Core/Components/UiKit/Typography/Typography';
// Contexts
import UserContext, {
  IUserContext,
} from '../../../../Core/Data/Contexts/UserContext';
// Services
import AuthService from '../../../Data/Services/AuthService';
import LocalStorage from '../../../../Core/Data/Services/Storage/LocalStorage';
// Type
import * as Types from './LoginScene.type';
import * as AuthTypes from '../../../Data/Models/Auth.type';
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
import * as FormTypes from '../../../Components/Form/FormType/Auth/LoginForm/LoginForm.type';
// Common
import Common from '../../../Resources/Common';
import CoreCommon from '../../../../Core/Resources/Common';

const LoginScene: FC<Types.IProps> = () => {
  // Variables
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'auth']);

  // Renders
  const renderForm = (updateUser: (user: CoreTypes.IUser) => void) => {
    const defaultValues: FormTypes.IFormValues = {
      user: '',
      password: '',
    };
    const validationSchema = Yup.object({
      user: Yup.string().required(t('auth:login.form.user.errors.missing')),
      password: Yup.string().required(
        t('auth:login.form.password.errors.missing')
      ),
    });
    const handleSubmit = (
      values: FormTypes.IFormValues,
      { setSubmitting }: FormikHelpers<FormTypes.IFormValues>
    ) => {
      setSubmitting(true);
      AuthService.login(values.user.trim(), values.password)
        .then((data: AuthTypes.ILoginData) => {
          setSubmitting(false);
          LocalStorage.set(
            LocalStorage.keys.userId,
            data?.data?.account?.profile?.person?.id
          );
          LocalStorage.set(LocalStorage.keys.user, data?.data?.account);
          updateUser(data?.data?.account);
          history.push(`/${Common.Routes.routeHome}`);
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
          setSubmitting(false);
        });
    };
    return (
      <div data-testid='login-page'>
        <div className='flex flex-col items-center'>
          <Typography variant='h6'>{t('auth:login.title.title1')}</Typography>
          <Typography variant='h6'>{t('auth:login.title.title2')}</Typography>
        </div>
        <LoginForm
          defaultValues={defaultValues}
          onFormSubmit={handleSubmit}
          validationSchema={validationSchema}
        />
      </div>
    );
  };

  return (
    <AuthLayout>
      <UserContext.Consumer>
        {({ user, updateUser }: IUserContext) => renderForm(updateUser)}
      </UserContext.Consumer>
    </AuthLayout>
  );
};

export default LoginScene;
