
// React libs
import React, { FC, useCallback, useContext, useState } from 'react';
import moment from 'moment';
import { Form, FormikProps, Formik } from 'formik';
import { uniqueId, get } from 'lodash';
import { useDropzone } from 'react-dropzone'
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Components
import FaIcon from '../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import CloseIconButton from '../../../../../Core/Components/UiKit/Button/CloseIconButton/CloseIconButton';
import Typography from '../../../../../Core/Components/UiKit/Typography/Typography';
import ProjectGedTreeView from '../../ProjectGed/ProjectGedTreeView/ProjectGedTreeView';
import Separator from '../../../../../Core/Components/UiKit/Separator/Separator';
import * as FormFields from '../../../../../Core/Components/Form/FormFields/FormFields';
// Type
import * as Types from './ProjectGedForm.type';
// Utils
import { getFormPropTypes, isDisabled, preventKeyDownSubmit } from '../../../../../Core/Utils/FormUtils';
import { ifDef } from '../../../../../Core/Utils/Misc';
// Contexts
import UserContext from '../../../../../Core/Data/Contexts/UserContext';

interface ISidebar {
  onDrop: (...args: any) => void
}

const Sidebar: FC<ISidebar> = ({ onDrop }) => {
  // Variables
  const { t } = useTranslation(['project'])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  return <div {...getRootProps({
    className: 'w-4/5 h-5/10 bg-gray-300 flex justify-center items-center rounded cursor-pointer '
  })}>
    <input {...getInputProps()} />
    <div>
      <div className='w-full text-center'>
        <FaIcon type='fas' name='cloud-upload-alt' className='text-5xl' />
      </div>
      <Typography variant='h5' className='text-center'>{t('project:ged.dropFilesMessage')}</Typography>
    </div>
  </div>
}

const ProjectGedForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
    validateOnBlur={false}
  >
    {(formikProps: FormikProps<any>) => (
      <ProjectGedFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}
  </Formik>
);

ProjectGedForm.propTypes = getFormPropTypes();
export default ProjectGedForm;

const ProjectGedFormikForm = ({
  errors,
  isSubmitting,
  touched,
  miscData,
  miscFunctions,
  values,
  setFieldTouched,
  setFieldValue
}: FormikProps<any> & any) => {
  // State
  const [isSidebarOpened, setIsSidebarOpened] = useState<boolean>(false)
  const [filesWatcher, setFilesWatcher] = useState<any>()

  // Variables
  const { t } = useTranslation(['project'])
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext)

  // Handlers
  const closeSideBar = useCallback(() => {
    filesWatcher?.reject()
    setIsSidebarOpened(false)
  }, [filesWatcher])
  const onFolderAdd = useCallback((folderPath: string) => {
    setFieldTouched('folders', true)

    const currentDate = moment().toISOString()
    const userName = user?.profile.person.name
    const userId = user?.profile.person.id

    const [, parentPath, key] = /^(.+)\.content\.folders\.(\d+)$/.exec(folderPath) ?? []
    setFieldValue(folderPath, {
      id: uniqueId('folder'),
      name: t('project:ged.newFolder') + key,
      isFile: false,
      parent_id: get(values, parentPath).id,
      creation: currentDate,
      creator: userName,
      creation_id: userId,
      owner: userName,
      owner_id: userId,
      modification: currentDate,
      modified_by: userId,
      size: 0,
      toCreate: true
    })
  }, [setFieldTouched, setFieldValue, t, user, values])

  const onFilesAdd = useCallback(async (filePath: string) => {
    const promise = new Promise((resolve, reject) => {
      setFilesWatcher({
        resolve,
        reject
      })
    })
    setIsSidebarOpened(true)
    try {
      const files: any = await promise

      setFieldTouched('folders', true)

      const currentDate = moment().toISOString()
      const userName = user?.profile.person.name
      const userId = user?.profile.person.id

      const [, parentPath, index] = /^(.+)\.content\.files\.(\d+)$/.exec(filePath) ?? []
      files.forEach((file: any, key: number) => {
        const parent = get(values, parentPath)
        if (parent.content?.files?.some((item: any) => item.name === file.name)) {
          return enqueueSnackbar(t('project:ged.existentFileError', { name: file.name }), { variant: 'error' })
        }
        setFieldValue(`${parentPath}.content.files.${+index + key}`, {
          id: uniqueId('file'),
          name: file.name,
          isFile: true,
          format: file.type,
          parent_id: parent.id,
          creation: currentDate,
          creator: userName,
          creation_id: userId,
          owner: userName,
          owner_id: userId,
          modification: currentDate,
          modified_by: userId,
          size: file.size,
          content: file,
          toCreate: true
        })
      })
    }
    catch { }
    finally {
      setIsSidebarOpened(false)
    }
  }, [enqueueSnackbar, setFieldTouched, setFieldValue, t, user, values])

  const onFolderRename = useCallback((name: string, path: string) => {
    const [, parentChildrenPath, index] = /^(.+)\.(\d+)$/.exec(path) ?? []
    const children = get(values, parentChildrenPath)
    const folder = children[index]
    if (folder.name === name) {
      return
    }

    if (children.some((child: any) => child.name === name)) {
      return enqueueSnackbar(t('project:ged.existentFolderError', { name }), { variant: 'error' })
    }

    setFieldTouched('folders', true)
    setFieldValue(path, {
      ...folder,
      name,
      toUpdate: !folder.toCreate
    })
  }, [enqueueSnackbar, setFieldTouched, setFieldValue, t, values])

  const onFileRename = useCallback((name: string, path: string) => {
    const [, parentChildrenPath, index] = /^(.+)\.(\d+)$/.exec(path) ?? []
    const children = get(values, parentChildrenPath)
    const file = children[index]
    if (file.name === name) {
      return
    }

    if (children.some((child: any) => child.name === name)) {
      return enqueueSnackbar(t('project:ged.existentFileError', { name }), { variant: 'error' })
    }

    setFieldTouched('folders', true)
    if (file.toCreate) {
      setFieldValue(path, {
        ...file,
        name,
        content: new File([file.content], name, {
          type: file.content.type,
          lastModified: file.content.lastModified
        })
      })
    } else {
      setFieldValue(path, {
        ...file,
        name,
        toUpdate: true
      })
    }
  }, [enqueueSnackbar, setFieldTouched, setFieldValue, t, values])

  const onDelete = useCallback((items: { parentChildrenPath: string, id: string }[]) => {
    setFieldTouched('folders', true)

    const valuesByPath: { [path: string]: any[] } = {}
    items.forEach(({ parentChildrenPath, id }) => {
      const parentValues = ifDef(
        valuesByPath[parentChildrenPath],
        (value: any) => value,
        () => (valuesByPath[parentChildrenPath] = [...get(values, parentChildrenPath)])
      )

      const itemIndex = parentValues.findIndex((item: any) => item.id === id)
      const value = parentValues[itemIndex]
      if (value.toCreate) {
        parentValues.splice(itemIndex, 1)
      } else {
        parentValues.splice(itemIndex, 1, {
          ...value,
          toDelete: true
        })
      }
    })

    Object.entries(valuesByPath).forEach(([path, values]) => setFieldValue(path, values))
  }, [setFieldTouched, setFieldValue, values])

  return <Form className='h-full w-full' onKeyDown={preventKeyDownSubmit}>
    <div className='h-9.3/10 w-full overflow-auto flex'>
      <div className='h-full w-4/5 flex-1'>
        <ProjectGedTreeView
          folders={values.folders}
          poi={miscData.poi}
          actions={{
            onFolderAdd,
            onFilesAdd,
            onFolderRename,
            onFileRename,
            onDelete
          }}
        />
      </div>
      {isSidebarOpened && <div className='h-full w-1/5 flex'>
        <Separator type='vertical' className='ml-4' />
        <div className='h-full w-full'>
          <div className='w-full h-0.5/10 flex justify-end'>
            <CloseIconButton
              onClose={closeSideBar}
            />
          </div>
          <div className='w-full h-9.5/10 flex justify-center items-center'>
            <Sidebar onDrop={files => filesWatcher?.resolve(files)} />
          </div>
        </div>
      </div>}
    </div>
    <div className='h-0.7/10 w-full'>
      <FormFields.FormSubmitButtons
        isSubmitting={isSubmitting}
        disabled={isDisabled(errors, isSubmitting, touched)}
        onCancel={miscFunctions?.onCancel}
      />
    </div>
  </Form>
}