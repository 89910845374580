// React libs
import React, { FC, useState, useEffect, Fragment } from 'react';
import { Form, FormikProps, Formik } from 'formik';
import { groupBy } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
// Components
import AdvancedFiltersTable from './AdvancedFiltersTable/AdvancedFiltersTable'
import ApplyButton from '../../UiKit/Button/ApplyButton/ApplyButton';
import ErrorIcon from '../../UiKit/Icon/ErrorIcon/ErrorIcon';
import FaIcon from '../../UiKit/Icon/FaIcon/FaIcon'
// Type
import * as Types from './AdvancedPoiFiltersForm.type';
import * as CoreTypes from '../../../Data/Models/Core.type'
// Context
import AdvancedFiltersContext, { IData, defaultValue } from '../../../Data/Contexts/AdvancedFiltersContext';
// Hooks
import usePoiTypes from '../../../Data/Hooks/PoiTypes'
import useTerritories from '../../../Data/Hooks/Territories'
import useThematic from '../../../Data/Hooks/Thematic'

const AdvancedPoiFiltersForm: FC<Types.IProps> = ({
  defaultValues,
  onFormSubmit,
  validationSchema,
  miscData
}) => {
  // State
  const [data, updateData] = useState<IData>(defaultValue)

  // Variables
  const poiTypes = usePoiTypes()
  const thematic = useThematic()
  const territories = useTerritories()
  const isDataLoading = [poiTypes, thematic, territories].some(data => data.isLoading)

  // Effects
  useEffect(() => {
    if (!isDataLoading && poiTypes.data != null && poiTypes.data.length > 0) {
      const { project, resource } = groupBy(poiTypes.data, poiType => poiType.isProject ? 'project' : 'resource')

      const getValueDefs = (collection: CoreTypes.IPoiType[]) => {
        const valueDefs: { [id: string]: CoreTypes.IValueDef } = {}
        collection.forEach(poiType => poiType.valueDefs.filter(valueDef => valueDef.allowFilter).forEach((valueDefWrapper: any) => {
          valueDefs[valueDefWrapper.valueDef.id] = valueDefWrapper.valueDef
        }))
        return valueDefs
      }

      updateData({
        project: {
          types: project,
          valueDefs: getValueDefs(project)
        },
        resource: {
          types: resource,
          valueDefs: getValueDefs(resource)
        },
        thematic: thematic.data ?? [],
        territories: territories.data ?? []
      })
    }

    // It fixes the error "Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function"
    return () => updateData(defaultValue)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoading])

  return <AdvancedFiltersContext.Provider value={{ data, updateData }}>
    <Formik
      initialValues={defaultValues}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps: FormikProps<any>) => (
        <AdvancedPoiFiltersFormikForm
          {...formikProps}
          miscData={miscData}
        />
      )}
    </Formik>
  </AdvancedFiltersContext.Provider>
}


export default AdvancedPoiFiltersForm

const AdvancedPoiFiltersFormikForm = ({
  isValid,
  submitCount,
  values,
  miscData
}: FormikProps<any> & any) => {
  // Variables
  const { t } = useTranslation('common')
  const containerClassName = miscData?.containerClassName ?? 'filters-container'

  return <Form className='h-full'>
    <div className='mb-2 text-center'>
      {submitCount > 0 && !isValid
        ? <Fragment>
          <ErrorIcon className='mr-1 text-sm' color='primary' />
          {t('common:advancedFilters.messages.selectionError')}
        </Fragment>
        : <Fragment>
          <FaIcon name='info-circle mr-1' />
          <Trans
            i18nKey='common:advancedFilters.messages.selectionInfo'
            components={{ icon: <FaIcon name='plus-circle' /> }}
          />
        </Fragment>
      }
    </div>
    <div className={`${containerClassName} overflow-auto`}>
      {!miscData?.hideProject &&
        <AdvancedFiltersTable type='project' values={values.project} />
      }
      {!miscData?.hideResource &&
        <AdvancedFiltersTable type='resource' values={values.resource} className='mt-4' />
      }
    </div>
    <div className='w-full text-center'>
      <ApplyButton type='submit' />
    </div>
  </Form>
}
