// Utils
import { createContext } from 'react';
// Types
import * as MapTypes from '../Models/Map.type';

export interface IActiveLayerContext {
  activeLayer: MapTypes.ILayer | undefined
  setActiveLayer: Function
}
const ActiveLayerContext = createContext<IActiveLayerContext>({
  activeLayer: undefined,
  setActiveLayer: (activeLayer: MapTypes.ILayer | undefined) => { },
})

export default ActiveLayerContext;
