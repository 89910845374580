// React libs
import React, { FC, useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { now } from 'moment';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
// Components
import CreateProjectForm from '../../../../Form/CreateProject/CreateProjectForm';
// Contexts
import PoisContext, {
  IPoisContext,
} from '../../../../../Data/Contexts/PoisContext';
import MapConfigContext, {
  IMapConfigContext,
} from '../../../../../Data/Contexts/MapConfigContext';
import PhaseTypeContext, {
  IPhaseTypeContext,
} from '../../../../../Data/Contexts/PhaseTypeContext';
import MapFiltersContext, {
  IMapFiltersContext,
} from '../../../../../Data/Contexts/MapFiltersContext';
import PoiFormPanelContext, { IPoiFormPanelContext } from '../../../../../Data/Contexts/PoiFormPanelContext'
// Services
import MapService from '../../../../../Data/Services/MapService';
// Type
import * as Types from './CreateProjectPanel.type';
import * as FormTypes from '../../../../Form/CreateProject/CreateProjectForm.type';
import * as MapTypes from '../../../../../Data/Models/Map.type';
// Utils
import {
  handleMarkerForPoiCreation,
  creationMarkerId,
} from '../../../../../Utils/Map';
import useServiceErrorHandler from '../../../../../../../Core/Utils/useServiceErrorHandler';
// Common
import Common from '../../../../../../../App/Resources/Common';
import CoreCommon from '../../../../../../../Core/Resources/Common';
// Hooks
import useMapboxAddress from '../../../../../Data/Hooks/MapboxAddress';

const CreateProjectPanel: FC<Types.IProps> = ({ onCreationDone }) => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'map']);
  const history = useHistory();
  const handleServiceError = useServiceErrorHandler()
  const { addressOptions, fullAddressOptions, fetchAddresses } = useMapboxAddress()

  // Contexts
  const { pois, updatePois }: IPoisContext = useContext(PoisContext);
  const { mapConfig }: IMapConfigContext = useContext(MapConfigContext);
  const { phaseTypes }: IPhaseTypeContext = useContext(PhaseTypeContext);
  const { mapFilters, updateMapFilters }: IMapFiltersContext = useContext(
    MapFiltersContext
  );
  const { poiToEdit }: IPoiFormPanelContext = useContext(PoiFormPanelContext)

  // State
  const [name, setName]: [string, Function] = useState<string>(poiToEdit?.name ?? '');
  const [poiType, setPoiType]: [string, Function] = useState<string>(poiToEdit?.fkpoiType ?? '');
  const [address, setAddress]: [string, Function] = useState<string>('');

  // Handlers
  const handleNameSelection = (event: any) => {
    setName(event.target.value);
  };
  const handlePoiTypeSelection = (option: any) => {
    setPoiType(option?.value);
  };
  const handleAddressSelection = (
    option: MapTypes.IMapboxPlace
  ) => {
    const position: [number, number] = option.geometry.coordinates;
    updatePois(
      handleMarkerForPoiCreation(
        [...pois],
        position.reverse() as [number, number],
        true
      )
    );
    setAddress(option.id);
  };
  const _handleSubmit = async (
    values: FormTypes.IFormValues,
    { setSubmitting }: Partial<FormikHelpers<FormTypes.IFormValues>>,
    options: { successMessage: string }
  ) => {
    setSubmitting && setSubmitting(true);
    try {
      const newAddress = await saveAddress(values);
      let newPoi
      if (poiToEdit === undefined) {
        const phaseType = phaseTypes.find(
          (type: MapTypes.IPhaseType) => type.order === 1
        );
        newPoi = await savePoi(values, newAddress.data.id, phaseType);
        await savePoiPhases(newPoi.data.id, phaseType);
      } else {
        newPoi = await savePoi(values, newAddress.data.id)
      }
      enqueueSnackbar(options.successMessage, {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'success',
      });
      updateMapFilters({ ...mapFilters, needMapRefresh: true });
      setSubmitting && setSubmitting(false);
      onCreationDone();
      return newPoi.data.id;
    } catch (e) {
      handleServiceError(e)
      setSubmitting && setSubmitting(false);
    }
  };
  const handleCancel = () => {
    updateMapFilters({ ...mapFilters, needMapRefresh: true });
    onCreationDone();
  };
  const handleDetails = async (
    values: FormTypes.IFormValues,
    setSubmitting: (value: boolean) => void,
    {
      setFieldTouched,
      validateForm
    }: FormikHelpers<any>
  ) => {
    const validationResult = await validateForm()
    const keys = Object.keys(validationResult)
    if (keys.length === 0) {
      const id = await _handleSubmit(values, { setSubmitting }, { successMessage: t('map:header.panel.project.detailsSuccess') });
      history.push(
        `/${Common.Routes.routeProject}/${id}/${Common.Routes.routeUpdateProject}`
      );
    } else {
      setFieldTouched(keys[0], true)
    }
  };

  const handleSubmit = (values: FormTypes.IFormValues, formikHelpers: Partial<FormikHelpers<FormTypes.IFormValues>>) =>
    _handleSubmit(values, formikHelpers, { successMessage: t('map:header.panel.project.success') })

  // Actions
  const saveAddress = (values: FormTypes.IFormValues) => {
    const addr = fullAddressOptions.find((a: any) => a.id === values.address);
    const addrArr = addr.place_name_fr.split(',');
    const context =
      addr.context &&
      addr.context.find((c: any) => (c.id as string).includes('region'));
    const addrToSave: MapTypes.IAddress = {
      city: (addrArr[1] && addrArr[1].trim().split(' ')[1]) || '',
      country: addrArr[2]?.trim() || '',
      line1: addrArr[0] || '',
      name: `${values.name} - Addresse`,
      town: context?.text_fr,
      zip: (addrArr[1] && addrArr[1].trim().split(' ')[0]) || '',
    };
    return MapService.saveAddress(addrToSave);
  };
  const savePoi = (
    values: FormTypes.IFormValues,
    addresseId: string,
    phaseType?: MapTypes.IPhaseType
  ) => {
    const addr = fullAddressOptions.find((a: any) => a.id === values.address);
    return poiToEdit === undefined
      ? MapService.savePoi({
        fkaddress: addresseId,
        fkpoiType: values.type,
        geo: { coordinates: addr?.center.reverse(), type: 'Point' },
        isProject: true,
        name: values.name,
        phases: phaseType && [{ startAt: now(), type: { id: phaseType.id } }],
      })
      : MapService.updatePoi(poiToEdit.id, {
        ...poiToEdit,
        fkaddress: addresseId,
        fkpoiType: values.type,
        geo: { coordinates: addr?.center.reverse(), type: 'Point' },
        name: values.name,
      })
  };
  const savePoiPhases = (poiId: string, phaseType?: MapTypes.IPhaseType) => {
    const phaseToSave: MapTypes.IPoiPhase = {
      fkphaseType: phaseType?.id || '',
      fkpoi: poiId,
      startAt: new Date(),
      type: { id: phaseType?.id || '' },
    };
    return MapService.savePoiPhase(phaseToSave);
  };

  // Effects
  useEffect(() => {
    const poisData = [...pois];
    const creatingPoi = poisData.pop();
    if (creatingPoi && creatingPoi?.id === creationMarkerId) {
      const position = creatingPoi.geo.coordinates;
      fetchAddresses([...position].reverse().join(','), (data?: any[]) => {
        if (data && data.length > 0) {
          setAddress(data[0].id);
        } else {
          setAddress('')
          enqueueSnackbar(t('map:header.panel.no_address'), {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'warning',
          });
        }
      })
    }
  }, [enqueueSnackbar, mapConfig, pois, t, JSON.stringify(fetchAddresses)]);


  // Renders
  const renderForm = () => {
    const defaultValues: FormTypes.IFormValues = {
      name: name || '',
      type: poiType || '',
      address: address || '',
    };
    const validationSchema = Yup.object({
      name: Yup.string().required(
        t('map:header.panel.project.form.name.errors.required')
      ),
      type: Yup.string().required(
        t('map:header.panel.project.form.type.errors.required')
      ),
      address: Yup.string().required(
        t('map:header.panel.project.form.address.errors.required')
      ),
    });
    return (
      <CreateProjectForm
        defaultValues={defaultValues}
        onFormSubmit={handleSubmit}
        validationSchema={validationSchema}
        miscData={{ addressOptions, fullAddressOptions }}
        miscFunctions={{
          cancel: handleCancel,
          details: handleDetails,
          nameSelection: handleNameSelection,
          poiTypeSelection: handlePoiTypeSelection,
          addressSelection: handleAddressSelection,
          getAddresses: (event: any, value: string) => fetchAddresses(value)
        }}
      />
    );
  };
  return (
    <div data-testid='map-create-project' className='w-full'>
      {renderForm()}
    </div>
  );
};

CreateProjectPanel.propTypes = {};

export default CreateProjectPanel;
