// React libs
import React, { FC } from 'react';
import { Grid } from '@material-ui/core'
import { FormikProps, Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import FormHeader from '../../../../Core/Components/Form/FormHeader/FormHeader'
import FormTitle from '../../../../Core/Components/Form/FormTitle/FormTitle';
import * as FormFields from '../../../../Core/Components/Form/FormFields/FormFields';
// Types
import * as Types from './FormResourceIdentity.type';
// Utils
import { getFormPropTypes, isDisabled } from '../../../../Core/Utils/FormUtils';

const FormResourceIdentity: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
  >
    {(formikProps: FormikProps<any>) => (
      <ResourceIdentityFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}
  </Formik>
);

FormResourceIdentity.propTypes = getFormPropTypes();
export default FormResourceIdentity;

const ResourceIdentityFormikForm = ({
  errors,
  isSubmitting,
  touched,
  miscData,
  miscFunctions,
  values
}: FormikProps<any> & any) => {
  // Variables
  const { t } = useTranslation(['common', 'resource']);
  const isEditionMode = miscData.isEditionMode ?? false

  return (
    <>
      <div className='h-0.7/10 w-full pl-2 pt-1'>
        <FormHeader
          isEditionMode={isEditionMode}
          toggleEditionMode={miscFunctions?.toggleEditionMode}
          title={t('resource:identity')}
        />
      </div>
      <Form data-testid='form-resource-identity' className='h-9.3/10 w-full p-1'>
        <div className='h-9.3/10 w-full overflow-auto'>
          <Grid container spacing={1} className='w-full'>
            <Grid item xs={6}>
              <FormFields.FormPoiType
                name='type'
                type='resource'
                disabled={!isEditionMode}
                onPoiTypeChange={miscFunctions?.onPoiTypeChange}
              />
              <FormFields.FormName
                name='name'
                disabled={!isEditionMode}
              />
              <FormFields.FormThematic
                name='thematics'
                disabled={!isEditionMode}
              />
            </Grid>
            <Grid item xs={6} className='flex justify-center'>
              <FormFields.FormImage
                name='image'
                disabled={!isEditionMode}
                images={miscData?.linkedImageUrls}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title={t('resource:localization')} />
            </Grid>
            <Grid item xs={6}>
              <FormFields.FormTerritory
                name='territory'
                disabled={!isEditionMode}
              />
              <FormFields.FormGeo
                name='geo'
                disabled={!isEditionMode}
              />
            </Grid>
            <Grid item xs={6}>
              <FormFields.FormAddress
                name='address'
                disabled={!isEditionMode}
                selectedAddressId={values.address}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title={t('resource:description')} />
              <FormFields.FormComment
                name='comment'
                disabled={!isEditionMode}
              />
              <FormFields.FormKeywords
                name='keywords'
                disabled={!isEditionMode}
              />
            </Grid>
          </Grid>
        </div>
        <div className='h-0.7/10 w-full'>
          {isEditionMode && <FormFields.FormSubmitButtons
            isSubmitting={isSubmitting}
            disabled={isDisabled(errors, isSubmitting, touched)}
            onCancel={miscFunctions?.toggleEditionMode}
          />}
        </div>
      </Form>
    </>
  );
};
