// React libs
import React, { FC } from 'react';
import { Page, View } from '@react-pdf/renderer';
// Components
import PdfBody from './PdfBody';
import PdfFooter from './PdfFooter';
import PdfHeader from './PdfHeader';
// Types
import * as Types from './PdfPage.type';

const STYLES = {
  page: { padding: '3vh 3vw' },
  content: {
    height: '89vh'
  },
  footer: {
    height: '5vh',
  },
}

const PdfPage: FC<Types.IProps> = ({ content, title, pageNumber, nPages, footer }) =>
  <Page size='A4' orientation='landscape' wrap style={STYLES.page}>
    <View style={STYLES.content}>
      <PdfHeader title={title} />
      <PdfBody content={content} />
    </View>
    <View style={STYLES.footer}>
      <PdfFooter pageNumber={pageNumber} nPages={nPages} footer={footer} />
    </View>
  </Page >

export default PdfPage;
