// React libs
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Field, Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../../../../../../Core/Components/UiKit/Button/Button';
import FaIcon from '../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import TextField from '../../../../../../Core/Components/UiKit/Form/TextField/TextField';
// Type
import * as Types from './LoginForm.type';
// Utils
import {
  isDisabled,
  getFormPropTypes,
} from '../../../../../../Core/Utils/FormUtils';
// Common
import Common from '../../../../../Resources/Common';

const LoginForm: FC<Types.IProps> = ({
  defaultValues,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
  >
    {(formikProps: FormikProps<any>) => (
      <LoginFormikForm {...formikProps} miscFunctions={miscFunctions} />
    )}
  </Formik>
);

LoginForm.propTypes = getFormPropTypes();

export default LoginForm;

const LoginFormikForm = ({
  errors,
  isSubmitting,
  touched,
}: FormikProps<any> & any) => {
  // Variables
  const { t } = useTranslation(['common', 'auth']);

  return (
    <Form data-testid='login-form'>
      <Field
        id='user'
        name='user'
        component={TextField}
        label={t('auth:login.form.user.label')}
        color='secondary'
      />
      <Field
        id='password'
        name='password'
        type='password'
        component={TextField}
        canDisplayPassword={true}
        label={t('auth:login.form.password.label')}
        color='secondary'
      />
      <div className='flex items-center justify-end text-2xs'>
        <Link to={`/${Common.Routes.routeForgotPassword}`}>
          {t('auth:login.misc.forgotPassword')}
        </Link>
      </div>
      <div className='flex items-center justify-center'>
        <Button
          type='submit'
          variant='text'
          size='large'
          disabled={isDisabled(errors, isSubmitting)}
          startIcon={
            isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : null
          }
        >
          {isSubmitting
            ? t('auth:login.form.submit.label.submitting')
            : t('auth:login.form.submit.label.normal')}
        </Button>
      </div>
    </Form>
  );
};
