// React libs
import React, { FC, createRef } from 'react';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
// Components
import ErrorBoundary from '../Components/Error/ErrorBoundary';
import FaIcon from '../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import Router from '../Components/Router/Router';
// Locales
import 'moment/locale/fr'

if (navigator.language.includes('fr')) {
  moment.locale('fr')
}

moment.fn.toJSON = function() {
  return this.format('YYYY-MM-DD HH:mm:ss');
};

const AppScene: FC = () => {
  // Toasts config
  const notistackRef = createRef();
  const onClickDismiss = (key: string) => () => {
    if (notistackRef && notistackRef.current) {
      (notistackRef as any).current.closeSnackbar(key);
    }
  };

  return (
    <ErrorBoundary>
      <SnackbarProvider
        maxSnack={5}
        ref={notistackRef}
        action={(key: string) => (
          <FaIcon
            name='times'
            onClick={onClickDismiss(key)}
            className='cursor-pointer text-xl'
          />
        )}
      >
        <div className='hidden' data-testid='app-scene' />
        <Router />
      </SnackbarProvider>
    </ErrorBoundary>
  );
};

export default AppScene;
