// React libs
import React, { FC } from 'react';
import PropTypes from 'prop-types';
// Layout
import BaseLayout from '../BaseLayout/BaseLayout';
// Components
import AppSidebar from '../../Sidebar/AppSidebar/AppSidebar';
import PageLoader from '../../../../Core/Components/UiKit/Loader/PageLoader/PageLoader';
// Context
import UserContext from '../../../../Core/Data/Contexts/UserContext';
// Utils
import { isAdmin, isWriter } from '../../../../Core/Utils/User';
// Type
import * as Types from './AppLayout.type';

const AppLayout: FC<Types.IProps> = ({
  children,
  className,
  isLoading,
  loadingMessages,
  headerConf,
}) => {
  // Getters
  const getLoadingMessages = () => (
    <div>
      {loadingMessages?.map((l: any, index: number) => (
        <div key={index}>{l}</div>
      ))}
    </div>
  );

  return (
    <BaseLayout headerConf={headerConf}>
      <UserContext.Consumer>
        {({ user }) => (
          <div className='flex flex-1 overflow-hidden w-full'>
            {user && (isAdmin(user) || isWriter(user)) && <AppSidebar />}
            <div className='h-full w-9.5/10 flex-1 flex overflow-auto'>
              <div
                className={`${className} flex-1 relative`}
                data-testid='app-layout'
              >
                {isLoading && <PageLoader message={getLoadingMessages()} />}
                {children}
              </div>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </BaseLayout>
  );
};

AppLayout.propTypes = {
  className: PropTypes.string,
  headerConf: PropTypes.any,
  isLoading: PropTypes.bool,
  loadingMessages: PropTypes.array,
};

AppLayout.defaultProps = {
  className: '',
  isLoading: false,
  loadingMessages: [],
};

export default AppLayout;
