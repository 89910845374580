// React libs
import React, { FC, useCallback } from 'react';
import * as Yup from 'yup';
import { difference } from 'lodash'
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Components
import FormProjectIdentity from '../../../components/Form/FormProjectIdentity';
// Types
import * as Types from './ProjectIdentityEdition.type'
import * as FormProjectIdentityTypes from '../../../components/Form/FormProjectIdentity.type';
// Utils
import useConfirmMessageModal from '../../../../../Core/Utils/useConfirmMessageModal'
import useServiceErrorHandler from '../../../../../Core/Utils/useServiceErrorHandler';
import { getPoiImageUrl } from '../../../../../Core/Utils/Misc';
// Services
import CommonService from '../../../../../Core/Data/Services/CommonService'
// Common
import CoreCommon from '../../../../../Core/Resources/Common';

const ProjectIdentityEdition: FC<Types.IProps> = ({ project, toggleEditionMode, refreshPoi }) => {
  // Variables
  const { t } = useTranslation(['common', 'project']);
  const { enqueueSnackbar } = useSnackbar();
  const poiTypeChangeConfirmModal = useConfirmMessageModal(t('common:modales.confirm.poiTypeChangeConfirmation'))
  const handleServiceError = useServiceErrorHandler()
  const linkedImageUrls = project.image != null ? [getPoiImageUrl(project.image.id)] : undefined
  const defaultValues: FormProjectIdentityTypes.IFormProject = {
    type: project.type?.id,
    name: project.name,
    territory: project.territory?.id,
    thematics: project.thematics?.map(({ thematic }: any) => thematic.id) ?? [],
    geo: project.geo?.coordinates,
    address: project.address?.id,
    comment: project.comment ?? '',
    keywords: project.keywords ?? '',
    currentPhaseType: project.currentPhaseType ?? '',
    allowedPersons: project.allowedPersons?.map(({ person }: any) => person.id) ?? []
  }
  const validationSchema = Yup.object({
    type: Yup.string().required(t('common:errors.defaultRequiredField')),
    name: Yup.string().required(t('common:errors.defaultRequiredField')),
    territory: Yup.string().nullable(),
    image: Yup.mixed(),
    currentPhaseType: Yup.string().required(t('common:errors.defaultRequiredField')),
    thematics: Yup.array(Yup.string()),
    geo: Yup.array(Yup.number().required(t('common:errors.defaultRequiredField'))),
    address: Yup.string().required(t('common:errors.defaultRequiredField')),
    comment: Yup.string().nullable(),
    keywords: Yup.string().nullable(),
    allowedPersons: Yup.array(Yup.string()),
  });

  // Handlers
  const handleSubmitPoi = async (values: FormProjectIdentityTypes.IFormProject, { setSubmitting, resetForm }: FormikHelpers<FormProjectIdentityTypes.IFormProject>) => {
    setSubmitting(true);
    const getValueIfChanged = (name: keyof FormProjectIdentityTypes.IFormProject) => {
      let value = values[name]
      if (value !== defaultValues[name]) {
        typeof value === 'string' && (value = value.trim())
        return value === '' || value === undefined ? null : value
      }
    }

    try {
      const serviceFns = []
      const updateProps: any = {
        id: project.id,
        isProject: true,
        fkpoiType: values.type,
        name: getValueIfChanged('name'),
        fkterritory: getValueIfChanged('territory'),
        fkimage: undefined,
        phases: undefined,
        thematics: undefined,
        geo: defaultValues.geo?.[0] !== values.geo?.[0] || defaultValues.geo?.[1] !== values.geo?.[1]
          ? {
            type: "Point",
            coordinates: values.geo?.map(pos => +pos)
          }
          : undefined,
        fkaddress: getValueIfChanged('address'),
        comment: values.comment !== defaultValues.comment ? values.comment : undefined,
        keywords: values.keywords !== defaultValues.keywords ? values.keywords : undefined,
        allowedPersons: undefined,
      }

      if (values.image !== undefined) {
        const image = await CommonService.uploadImage({
          file: values.image
        })
        updateProps.fkimage = image.data.id

        if (project.image !== undefined) {
          serviceFns.push(() => CommonService.deleteImage(project.image.id))
        }
      }

      if (values.currentPhaseType != null && values.currentPhaseType !== project.currentPhaseType) {
        const currentPhase = project.currentPhaseType && project.phases.find(({ type }: any) => type.id === project.currentPhaseType)
        const currentDate = new Date()
        updateProps.phases = currentPhase != null
          ? [
            {
              endAt: currentDate,
              fkphaseType: currentPhase.type.id,
              id: currentPhase.id
            },
            {
              startAt: currentDate,
              type: {
                id: values.currentPhaseType
              }
            }
          ]
          : [
            {
              startAt: currentDate,
              type: {
                id: values.currentPhaseType
              }
            }
          ]
        serviceFns.push(
          () => currentPhase && CommonService.updatePoiPhase(currentPhase.id, {
            endAt: currentDate,
            fkphaseType: currentPhase.type.id,
            id: currentPhase.id,
            fkpoi: project.id
          }),
          () => CommonService.savePoiPhase({
            fkphaseType: values.currentPhaseType,
            fkpoi: project.id,
            startAt: currentDate,
            type: {
              id: values.currentPhaseType
            }
          })
        )
      }

      if (values.thematics != null) {
        const thematicsToAdd = difference(values.thematics, defaultValues.thematics ?? [])
        const thematicsToDel = difference(defaultValues.thematics, values.thematics)
        if (thematicsToAdd.length > 0 || thematicsToDel.length > 0) {
          const thematics: any = updateProps.thematics = []
          thematicsToAdd.forEach((id: string) => {
            thematics.push({
              thematic: {
                id
              }
            })
            serviceFns.push(() => CommonService.savePoiThematics({
              fkpoi: project.id,
              fkthematic: id
            }))
          })
          thematicsToDel.forEach((id: string) => {
            thematics.push({
              thematic: {
                id,
                toDelete: true
              }
            })
            serviceFns.push(() => CommonService.deletePoiThematic(id, project.id))
          })
        }
      }

      if (values.allowedPersons != null) {
        const allowedPersonsToAdd = difference(values.allowedPersons, defaultValues.allowedPersons ?? [])
        const allowedPersonsToDel = difference(defaultValues.allowedPersons, values.allowedPersons)
        if (allowedPersonsToAdd.length > 0 || allowedPersonsToDel.length > 0) {
          const allowedPersons: any = updateProps.allowedPersons = []
          allowedPersonsToAdd.forEach((id: string) => {
            allowedPersons.push({
              fkaccessType: 'WRITER',
              person: {
                id
              }
            })
            serviceFns.push(() => CommonService.savePoiAllowedPersons({
              fkaccessType: 'WRITER',
              fkperson: id,
              fkpoi: project.id
            }))
          })
          allowedPersonsToDel.forEach((id: string) => {
            allowedPersons.push({
              fkaccessType: 'WRITER',
              person: {
                id,
                toDelete: true
              }
            })
            serviceFns.push(() => CommonService.deletePoiAllowedPerson(project.id, id))
          })
        }
      }

      await CommonService.updatePoi(project.id, updateProps)
      await Promise.all(serviceFns.map(fn => fn()))

      enqueueSnackbar(t('common:forms.validation.saveSucceeded'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'success',
      })
    } catch (error) {
      handleServiceError(error)
    } finally {
      setSubmitting(false);
      resetForm({ values: defaultValues });
      refreshPoi()
      toggleEditionMode()
    }
  };

  const onPoiTypeChange = useCallback(() => poiTypeChangeConfirmModal.openModal().then(() => true, () => false), [poiTypeChangeConfirmModal])

  return <div data-testid='project-identity-edition' className='h-full w-full'>
    <FormProjectIdentity
      defaultValues={defaultValues}
      onFormSubmit={handleSubmitPoi}
      validationSchema={validationSchema}
      miscFunctions={{ toggleEditionMode, onPoiTypeChange }}
      miscData={{ linkedImageUrls, isEditionMode: true }}
    />
    {poiTypeChangeConfirmModal.modal}
  </div>
}

export default ProjectIdentityEdition