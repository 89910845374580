// React libs
import React, { FC, useState } from 'react';
import { Field, Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../../../../../../Core/Components/UiKit/Button/Button';
import FaIcon from '../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import FormHeader from '../../../../../../Core/Components/Form/FormHeader/FormHeader';
import TextFieldMia from '../../../../../../Core/Components/UiKit/Form/TextField/TextField';
// Type
import * as Types from './UserPofilForm.type';
// Utils
import {
  getFormPropTypes, isDisabled,
} from '../../../../../../Core/Utils/FormUtils';


const UserProfilForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
  >
    {(formikProps: FormikProps<any>) => (
      <UserProfilFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}

  </Formik>
);

UserProfilForm.propTypes = getFormPropTypes();

export default UserProfilForm;


export const UserProfilFormikForm = ({
  errors,
  isSubmitting,
  touched,
  miscData,
}: FormikProps<any> & any) => {
  const { t } = useTranslation(['common', 'profile']);
  const isNested = miscData?.nested ?? false
  const hidePasswordField = miscData?.hidePasswordField ?? miscData?.newContact ?? false
  const fieldContainerClasses = 'flex w-full items-center';
  const fieldContainerFieldClasses = `${fieldContainerClasses} flex-1`;
  const [edit, setEdit] = useState<boolean>(isNested);

  const handleReset = () => {
    setTimeout(() => {
      setEdit(false)
    }, 100);

  }
  return (
    <>
      <FormHeader
        toggleEditionMode={() => setEdit(edit => !edit)}
        isEditionMode={edit}
        title={t("profile:user.title")}
      />
      <Form data-testid='form-user-profils' className='flex w-full'>
        <div className='flex flex-col w-11/12'>
          <div className={fieldContainerFieldClasses}>
            <Field
              id='firstname'
              name='firstname'
              component={TextFieldMia}
              label={t('profile:user.firstName')}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
            />
          </div>
          <div className={fieldContainerFieldClasses}>
            <Field
              id='name'
              name='name'
              component={TextFieldMia}
              label={t('profile:common.name')}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
            />
          </div>
          <div className={fieldContainerFieldClasses}>
            <Field
              id='url'
              name='url'
              component={TextFieldMia}
              label={t('profile:user.url')}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
            />
          </div>
          <div className={fieldContainerFieldClasses}>
            <Field
              id='mail'
              name='mail'
              component={TextFieldMia}
              label={t('profile:user.email')}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
            />
          </div>
          <div className={fieldContainerFieldClasses}>
            <Field
              id='phone'
              name='phone'
              component={TextFieldMia}
              label={t('profile:user.tel')}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
            />
          </div>
          <div className={fieldContainerFieldClasses}>
            <Field
              id='mobile'
              name='mobile'
              component={TextFieldMia}
              label={t('profile:user.mobile')}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
            />
          </div>
          <div className={fieldContainerFieldClasses}>
            <Field
              id='comment'
              name='comment'
              component={TextFieldMia}
              label={t('profile:common.comment')}
              color='secondary'
              className='flex-1'
              multiline
              disabled={edit ? false : true}
            />
          </div>
          {!hidePasswordField &&
            <div className={fieldContainerFieldClasses}>
              <Field
                id='password'
                name='password'
                type='password'
                component={TextFieldMia}
                canDisplayPassword
                label={t('profile:password.password')}
                color='secondary'
                className='flex-1'
                disabled={!edit}
              />
            </div>
          }
          {edit && !isNested &&
            <div className={`${fieldContainerClasses} justify-center`} >
              <Button
                type='reset'
                variant='text'
                size='large'
                className='mx-2'
                onClick={handleReset}
              >
                {t('profile:common.cancel')}
              </Button>
              <Button
                type='submit'
                variant='text'
                size='large'
                className='mx-2'
                onClick={handleReset}
                disabled={isDisabled(errors, isSubmitting, touched)}
                startIcon={
                  isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : null
                }
              >
                {t('profile:common.save')}
              </Button>
            </div>
          }
        </div>
      </Form>
    </>
  );
};
