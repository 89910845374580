// React libs
import { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Common
import CoreCommon from '../../Resources/Common';

export interface IGetPoiTypesHook
  extends CoreTypes.IHook<CoreTypes.IPoiType[]> {
  refreshTime: number
  refresh: Function
}

const usePoiTypes = (type?: 'project' | 'resource'): IGetPoiTypesHook => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);

  // State
  const [data, setData]: [CoreTypes.IPoiType[], Function] = useState([]);
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [refreshTime, setRefreshTime] = useState<number>(Date.now())
  const [needRefresh, setNeedRefresh] = useState<boolean>(true)

  // Actions
  const refresh = useCallback(() => {
    setNeedRefresh(true)
  }, [])

  // Effects
  useEffect(() => {
    if (needRefresh && !isLoading) {
      const getPoiTypes = () => {
        const restrictions = {
          project: (t: CoreTypes.IPoiType) => t.isProject,
          resource: (t: CoreTypes.IPoiType) => !t.isProject,
        };
        setIsLoading(true);
        CommonService.getPoiTypes()
          .then((data: CoreTypes.IData<CoreTypes.IPoiType>) => {
            const newData = data.data.filter((t: CoreTypes.IPoiType) => {
              return type && Object.keys(restrictions).includes(type)
                ? restrictions[type](t)
                : true;
            });
            setData(newData);
            setRefreshTime(Date.now())
          })
          .catch((e: CoreTypes.IWsException) => {
            enqueueSnackbar(
              e?.error?.message || t('common:errors.defaultMessage'),
              {
                ...CoreCommon.Constantes.snackbarDefaultProps,
                variant: 'error',
              }
            );
          })
          .finally(() => {
            setNeedRefresh(false)
            setIsLoading(false)
          })
      };
      getPoiTypes();
    }
  }, [enqueueSnackbar, t, type, isLoading, needRefresh]);

  return { data, isLoading, refreshTime, refresh };
};

export default usePoiTypes;
