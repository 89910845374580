// React libs
import { useMemo } from 'react'
import { round } from 'lodash'
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'
import * as ProjectTypes from '../../Data/Models/Project.type'

const useFinancingSourceMetrics = ({ project, ...props }: ProjectTypes.IMetricsHook): CoreTypes.IDatavizHook => {
  // Variables
  const data = useMemo(() => {
    const legends: string[] = []
    const series: any[] = []

    let totalAmount = 0
    project.expenseFundings?.forEach(expense => {
      legends.push(`${expense.name}/${expense.fundType.label}`)
      series.push(expense.amount)

      totalAmount += expense.amount
    })

    const getPercent = (value: number) => round(value * 100 / totalAmount, 1)

    return {
      legends,
      series,
      formatter: ({ name, value }: any) => `${name}: ${(+value).toLocaleString('fr-FR')} € (${getPercent(+value)}%)`
    }
  }, [project.expenseFundings])

  return {
    ...props,
    data: data
  }
}

export default useFinancingSourceMetrics