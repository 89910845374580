// React libs
import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import ConfirmModale from '../../../../../Core/Components/UiKit/Modales/ConfirmModale/ConfirmModale';
// Types
import * as Types from './UserLogoutConfirmModal.type';

const UserLogoutConfirmModal: FC<Types.IProps> = ({ handleClose, isOpened }) => {
  const { t } = useTranslation(['common'])
  return <div data-testid='user-logout-confirm-modal'>
    <ConfirmModale
      handleClose={handleClose}
      id='user-logout-confirm-modal'
      message={t('common:header.menu.user.logoutConfirmation')}
      isOpened={isOpened}
    />
  </div>

}

UserLogoutConfirmModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired
}

export default UserLogoutConfirmModal