// React libs
import React, { FC } from 'react';
import { Field, Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../../../../../../Core/Components/UiKit/Button/Button';
import FaIcon from '../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import TextField from '../../../../../../Core/Components/UiKit/Form/TextField/TextField';
// Type
import * as Types from './ResetPasswordForm.type';
// Utils
import {
  isDisabled,
  getFormPropTypes,
} from '../../../../../../Core/Utils/FormUtils';

const ResetPasswordForm: FC<Types.IProps> = ({
  defaultValues,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
  >
    {(formikProps: FormikProps<any>) => (
      <ResetPasswordFormikForm {...formikProps} miscFunctions={miscFunctions} />
    )}
  </Formik>
);

ResetPasswordForm.propTypes = getFormPropTypes();

export default ResetPasswordForm;

const ResetPasswordFormikForm = ({
  errors,
  isSubmitting,
  touched,
}: FormikProps<any> & any) => {
  // Variables
  const { t } = useTranslation(['common', 'auth']);

  return (
    <Form data-testid='reset-password-form'>
      <Field
        id='password'
        name='password'
        type='password'
        component={TextField}
        canDisplayPassword={true}
        label={t('auth:resetPassword.form.password.label')}
        color='secondary'
      />
      <Field
        id='passwordConfirm'
        name='passwordConfirm'
        type='password'
        component={TextField}
        canDisplayPassword={true}
        label={t('auth:resetPassword.form.passwordConfirm.label')}
        color='secondary'
      />
      <div className='flex items-center justify-center'>
        <Button
          type='submit'
          variant='text'
          size='large'
          disabled={isDisabled(errors, isSubmitting, touched)}
          startIcon={
            isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : null
          }
        >
          {isSubmitting
            ? t('auth:resetPassword.form.submit.label.submitting')
            : t('auth:resetPassword.form.submit.label.normal')}
        </Button>
      </div>
    </Form>
  );
};
