// React libs
import React, { FC } from 'react';
import { Grid } from '@material-ui/core'
import { FormikProps, Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import ApplyButton from '../../UiKit/Button/ApplyButton/ApplyButton';
import Typography from '../../UiKit/Typography/Typography';
import * as FormFields from '../../../../Core/Components/Form/FormFields/FormFields';
// Types
import * as Types from './LinksFilter.type';
// Utils
import { getFormPropTypes } from '../../../../Core/Utils/FormUtils';

const FormLinksFilter: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
  >
    {(formikProps: FormikProps<any>) => (
      <LinksFilterFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}
  </Formik>
);

FormLinksFilter.propTypes = getFormPropTypes();
export default FormLinksFilter;

const LinksFilterFormikForm = ({
  miscData,
}: FormikProps<any> & any) => {
  // Variables
  const { t } = useTranslation(['components']);
  const containerClassName = miscData?.containerClassName ?? 'chart-links-filters-container'

  return <Form data-testid='form-links-filter' className='h-full w-full'>
    <div className={containerClassName}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" className='mt-1 text-center'>
            {t('components:linksFilter.title')}
          </Typography>
        </Grid>
        <Grid item xs={2} className='flex items-end'>
          {t('components:linksFilter.linkType')}:
        </Grid>
        <Grid item xs={10}>
          <FormFields.FormLinkTypes
            name='fklinkType'
            label={t('components:linksFilter.allLinks')}
          />
        </Grid>
        <Grid item xs={2} className='flex items-end mt-2'>
          {t('components:linksFilter.createdBy')}:
        </Grid>
        <Grid item xs={10}>
          <FormFields.FormWriterPersons
            name='createdBy'
            label={t('components:linksFilter.allPersons')}
          />
        </Grid>
      </Grid>
    </div>
    <div className='w-full text-center'>
      <ApplyButton type='submit' />
    </div>
  </Form>
};
