// React libs
import React, { FC, useCallback, useMemo } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
// Components
import AppLayout from '../AppLayout/AppLayout';
import FaIcon from '../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
// Types
import * as Types from './AppNavigationLayout.type'
// Utils
import useConfirmMessageModal from '../../../../Core/Utils/useConfirmMessageModal'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
  },
  tabs: {
    '&.Mui-selected': {
      color: '#e55b5f',
    }
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  indicator: {
    backgroundColor: '#e55b5f',
  },
  flexContainer: {
    height: '100%',
    alignItems: 'center',
  },
  box: {
    height: "100vh"
  }
}));

const AppNavigationLayout: FC<Types.IProps> = ({ headerConf, pages, baseUrl, currentRoute }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const pageChangeConfirmModal = useConfirmMessageModal(t('common:modales.confirm.pageChangeConfirmation'))
  const history = useHistory()
  const theme = useTheme();
  const selectedPage = useMemo(() => pages.findIndex(page => [page.updateUrl, page.previewUrl].includes(currentRoute)), [currentRoute, pages])

  // Handlers
  const onPageSelect = useCallback(async (event, newPage) => {
    try {
      if (pages[selectedPage].updateUrl === currentRoute) {
        await pageChangeConfirmModal.openModal()
      }
      history.push(`${baseUrl}/${pages[newPage].previewUrl}`)
    } catch { }
  }, [baseUrl, currentRoute, history, pageChangeConfirmModal, pages, selectedPage])

  return <AppLayout headerConf={headerConf} className='overflow-auto'>
    <div className={classes.root} data-testid='app-navigation-layout'>
      <AppBar position="static" color="transparent" className='h-0.7/10 overflow-hidden'>
        <Tabs
          value={selectedPage}
          onChange={onPageSelect}
          textColor="inherit"
          aria-label="Représentation d'un POI"
          classes={{
            indicator: classes.indicator,
            flexContainer: classes.flexContainer
          }}
          variant='scrollable'
        >
          {pages.map((page, key) => <Tab
            key={key}
            wrapped={true}
            id={`full-width-tab-${key}`}
            className={`${classes.tabs} ${page.hidden ? 'hidden' : ''}`}
            icon={<FaIcon name={page.icon} className='text-xl mr-2 mt-1' />}
            label={page.label}
            classes={{
              wrapper: classes.wrapper
            }}
            aria-controls={`full-width-tabpanel-${key}`}
          />)}
        </Tabs>
      </AppBar>
      {pages.map((page, key) => <div
        className='h-9.3/10 overflow-auto'
        key={key}
        role="tabpanel"
        hidden={selectedPage !== key}
        id={`full-width-tabpanel-${key}`}
        aria-labelledby={`full-width-tab-${key}`}
        dir={theme.direction}
      >
        {selectedPage === key && (
          <div className='h-full w-full'>
            <Typography component='div' className='h-full w-full'>{page.component}</Typography>
          </div>
        )}
      </div>)}
      {pageChangeConfirmModal.modal}
    </div>
  </AppLayout>
}

export default AppNavigationLayout
