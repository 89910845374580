// React libs
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
// Components
import LinksFilter from '../../../../Core/Components/UiKit/LinksFilter/LinksFilter';
// Services
import DatavizService from '../Services/DatavizService';
// Hooks
import useGenericData from '../../../../Core/Data/Hooks/GenericData';
// Utils
import { ifDef } from '../../../../Core/Utils/Misc';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'

const useLinkMetrics = (dateStart: string, dateEnd: string): CoreTypes.IDatavizHook => {
  // Ref
  const toggleFilterVueRef = useRef({ toggleFilterVue: () => { } })

  // State
  const [filters, setFilters] = useState<any>()

  // Variables
  const { t } = useTranslation(['common, dataviz']);

  const { data: metrics, ...props } = useGenericData(() => DatavizService.getLinkMetrics(dateStart, dateEnd, filters))

  const stringFilters = useMemo(() => ifDef(filters, JSON.stringify), [filters])
  const areFiltersEnabled = filters !== undefined
  const data = useMemo(() => metrics?.map(({ total, label }: CoreTypes.IDatavizGenericMetric) => ({
    name: label ?? t('common:forms.fields.undefined') ?? '',
    value: total,
  })) ?? [], [t, metrics])

  // Refresh data
  useEffect(() => {
    props.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, stringFilters])


  // Handlers
  const onFiltersClear = useCallback(() => {
    setFilters(undefined)
  }, [])
  const onFiltersChange = useCallback(filters => {
    setFilters(filters)
    toggleFilterVueRef.current.toggleFilterVue()
  }, [])

  return {
    ...props,
    data,
    filter: {
      Component: <LinksFilter filters={filters} onFiltersChange={onFiltersChange} />,
      onClear: onFiltersClear,
      areEnabled: areFiltersEnabled,
      toggleFilterVueRef
    },
    chart: {
      title: t('dataviz:titles.links'),
      formatter: '{b} : {d}% ({c})'
    }
  }
}

export default useLinkMetrics
