// React libs
import React, { FC } from 'react';
// Components
import GenericIconButton from '../GenericIconButton/GenericIconButton';
// Types
import * as Types from './RefreshButton.type'

const RefreshButton: FC<Types.IProps> = ({ onClick, tooltip, ...props }) => {
  return <span data-testid='RefreshButton'>
    <GenericIconButton
      {...props}
      ariaLabel='refresh button'
      onClick={onClick}
      icon='redo'
      tooltip={tooltip}
    />
  </span>
}

export default RefreshButton