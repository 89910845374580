// Utils
import { createContext, useContext } from 'react';
// Types
import * as MapTypes from '../Models/Map.type';

export interface IMapContext {
  map: any;
  setMap: (map: any) => void;
  setCurrentZoom: Function;
  setCurrentCenter: Function;
  searchedPoi?: MapTypes.IPoi;
  setSearchedPoi: (poi?: MapTypes.IPoi) => void;
}
const LinkTypeContext = createContext<IMapContext>({
  map: undefined,
  searchedPoi: undefined,
  setMap: (map: any) => {},
  setCurrentZoom: (zoom: number) => {},
  setCurrentCenter: (center: [number, number]) => {},
  setSearchedPoi: (poi?: MapTypes.IPoi) => {},
});

export const useMapContext = () => useContext(LinkTypeContext);

export default LinkTypeContext;
