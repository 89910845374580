// React libs
import React, { FC } from 'react';
import AutocompleteComponent from '@material-ui/lab/Autocomplete';
import get from 'lodash/get'
import TextFieldComponent from '@material-ui/core/TextField';
// Components
import FieldError from '../../Error/FieldError/FieldError';
// Type
import * as Types from './Autocomplete.type';
// Utils
import { hasErrors } from '../../../../Utils/FormUtils'

const Autocomplete: FC<Types.IProps> = ({
  color,
  disabled,
  field,
  form,
  label,
  disableClearable = false,
  options,
  multiple,
  onInputChange,
  onSelectValue,
  onChangePredicate,
  ...props
}) => {
  const { errors, touched } = form;

  const getValue = () => {
    let value: any[] | any = multiple ? [] : null;
    if (field.value) {
      if (multiple) {
        field.value.forEach((v: string) => {
          const res = options.find(
            (o: Types.IOption) =>
              v.toString().toLowerCase() === o.value.toString().toLowerCase()
          );
          if (res) {
            value?.push(res);
          }
        });
      } else {
        const option = options.find(
          (o: any) =>
            o.value.toString().toLowerCase() ===
            field.value.toString().toLowerCase()
        );
        value = option;
      }
    }
    return value;
  };
  const updateFieldValue = async (event: any, newValue: any) => {
    form.setFieldTouched(field.name, true);
    if (onChangePredicate !== undefined && !(await onChangePredicate(newValue))) {
      form.setFieldValue(field.name, field.value)
      return
    }

    if (multiple) {
      form.setFieldValue(
        field.name,
        newValue
          ? newValue.reduce((list: any[], v: Types.IOption) => {
            list.push(v.value);
            return list;
          }, [])
          : []
      );
    } else {
      form.setFieldValue(field.name, newValue ? newValue.value : '');
    }

    if (onSelectValue) {
      onSelectValue(newValue);
    }
  };
  let otherProps: any = {};
  if (onInputChange) {
    otherProps = Object.assign(otherProps, { onInputChange });
  }
  return (
    <div className='w-full' data-testid='autocomplete'>
      <AutocompleteComponent
        multiple={multiple}
        options={options as any}
        getOptionLabel={(value: Types.IOption) => (value ? value.label : '')}
        value={getValue() ?? ''}
        classes={{ popper: 'z-1600' }}
        autoComplete
        includeInputInList
        disabled={disabled === true}
        onChange={updateFieldValue}
        disableClearable={disableClearable}
        renderInput={params => (
          <TextFieldComponent
            {...params}
            color={color}
            error={touched[field.name] && errors[field.name] !== undefined}
            label={label}
            margin='none'
          />
        )}
        {...props}
        {...otherProps}
      />
      {hasErrors(form, field.name) &&
        <FieldError
          color={color}
          errors={get(errors, field.name)}
          touched={touched}
        />
      }

    </div>
  );
};

Autocomplete.defaultProps = {
  freeSolo: false,
  multiple: false,
};
export default Autocomplete;
