// React libs
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Service
import MapService from '../Services/MapService';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
import * as MapTypes from '../Models/Map.type';
// Common
import CoreCommon from '../../../../Core/Resources/Common';

export interface IGetExternalTerritoriesHook
  extends CoreTypes.IHook<MapTypes.IExternalsTerritoriesData> {}

const useExternalTerritories = (): IGetExternalTerritoriesHook => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);

  // State
  const [data, setData]: [
    MapTypes.IExternalsTerritoriesData | null,
    Function
  ] = useState(null);
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);

  // Effects
  useEffect(() => {
    const getConfig = () => {
      // Map config
      setIsLoading(true);
      MapService.getExternalTerritories()
        .then((data: MapTypes.IExternalsTerritoriesData) => {
          setData(data);
          setIsLoading(false);
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
          setIsLoading(false);
        });
    };
    getConfig();
  }, [enqueueSnackbar, t]);

  return { data, isLoading };
};

export default useExternalTerritories;
