// React libs
import React, { FC } from 'react';

interface IProps {
  className?: string;
  color?: string;
  icon?: string;
  otherIconClass?: string;
}

const OtherPoiMarker: FC<any> = ({
  className,
  color,
  icon,
  otherIconClass,
}: IProps) => (
  <svg
    width='33'
    height='52'
    viewBox='0 0 32 52'
    version='1.1'
    className={className}
  >
    <path
      d='M16,1 C7.7146,1 1,7.65636364 1,15.8648485 C1,24.0760606 16,51 16,51 C16,51 31,24.0760606 31,15.8648485 C31,7.65636364 24.2815,1 16,1 L16,1 Z'
      fill={color}
    ></path>
    <foreignObject className='node' x='0' y='0' width='100%' height='100%'>
      <i
        className={`fa fa-${icon} other-marker ${otherIconClass} icon-white`}
      ></i>
    </foreignObject>
  </svg>
);

OtherPoiMarker.defaultProps = {
  className: '',
  color: 'currentColor',
  icon: '',
  otherIconClass: '',
};

export default OtherPoiMarker;
