import { LocaleManager } from '@bryntum/gantt/gantt.umd.js'

const locale = {
  localeName: 'FR',
  localeDesc: 'Francais',

  DateHelper: {
    locale: 'fr',
    unitNames: [
      { single: 'Milliseconde', plural: 'Millisecondes', abbrev: 'ms' },
      { single: 'Seconde', plural: 'Secondes', abbrev: 's' },
      { single: 'Minute', plural: 'Minutes', abbrev: 'min' },
      { single: 'Heure', plural: 'Heures', abbrev: 'h' },
      { single: 'Jour', plural: 'Jours', abbrev: 'J' },
      { single: 'Semaine', plural: 'Semaines', abbrev: 'sem' },
      { single: 'Mois', plural: 'Mois', abbrev: 'mo' },
      { single: 'Trimestre', plural: 'Trimestres', abbrev: 'tr' },
      { single: 'Année', plural: 'Années', abbrev: 'An' },
      { single: 'Décennie', plural: 'Décennies', abbrev: 'Dec' }
    ],
    unitAbbreviations: [
      ['ms'],
      ['s', 'sec'],
      ['m', 'min'],
      ['h', 'hr'],
      ['j'],
      ['sem'],
      ['mo'],
      ['tr'],
      ['a', 'an'],
      ['dec']
    ],
  },

  PlanningToolbar: {
    zoomIn: "Zoomer",
    zoomOut: "Dézoomer",
    previousTimeSpan: "Reculer",
    nextTimeSpan: "Avancer",
    searchByProjectName: "Recherche par nom de projet",
    unCollapseAll: "Tout développer",
    filters: "Filtres",
    todo: "À faire",
    doing: "En-cours",
    done: "Fait"
  },

  GridBase: {
    noRows: 'Pas de données !',
    loadFailedMessage: 'Échec du chargement des données !',
    syncFailedMessage: 'Échec de la synchronisation des données !',
  },

  TimeAxisHeaderMenu: {
    activeDateRange: "Plage de dates",
    startText: "Date de début",
    endText: "Date de fin",
    pickZoomLevel: "Zoom",
    todayText: "Aujourd'hui"
  }
}

export default locale;

// LocaleManager.throwOnMissingLocale = true;

LocaleManager.registerLocale('FR', { desc: 'Francais', locale: locale });