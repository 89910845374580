// React libs
import React, { FC, useContext } from 'react';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
// Components
import MapConfigForm from '../../Form/MapConfig/MapConfigForm';
import BaseModale from '../../../../../Core/Components/UiKit/Modales/BaseModale/BaseModale';
// Contexts
import MapConfigContext from '../../../Data/Contexts/MapConfigContext';
// Service
import MapService from '../../../Data/Services/MapService';
// Types
import * as Types from './MapConfigModale.type';
import * as CoreTypes from '../../../../../Core/Data/Models/Core.type';
import * as FormTypes from '../../Form/MapConfig/MapConfigForm.type';
// Common
import CoreCommon from '../../../../../Core/Resources/Common';

const MapConfigModale: FC<Types.IProps> = ({ handleClose, isOpened }) => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'map']);

  // Contexts
  const { mapConfig, updateMapConfig } = useContext(MapConfigContext);

  // Render
  const renderForm = () => {
    const configData = Object.assign({}, mapConfig);
    const defaultValues: FormTypes.IFormValues = {
      mapConfigBoundaries: configData?.mapConfigBoundaries || '',
      mapConfigCenter: configData?.mapConfigCenter || '',
      mapConfigGeocodageCountry:
        configData?.mapConfigGeocodageCountry?.split(',') || [],
      mapConfigLayerToken: configData?.mapConfigLayerToken || '',
      mapConfigMaxZoom: configData?.mapConfigMaxZoom || 10,
      mapConfigMinZoom: configData?.mapConfigMinZoom || 1,
      mapConfigUpdateInterval: configData?.mapConfigUpdateInterval || 5,
      mapConfigZoom: configData?.mapConfigZoom || 8,
    };
    const validationSchema = Yup.object({
      mapConfigBoundaries: Yup.array(),
      mapConfigCenter: Yup.string().required(
        t('map:modale.updateConfig.geospatial.form.center.errors.required')
      ),
      mapConfigGeocodageCountry: Yup.string(),
      mapConfigLayerToken: Yup.string().required(
        t('map:modale.updateConfig.geospatial.form.mapboxKey.errors.required')
      ),
      mapConfigMaxZoom: Yup.number()
        .required(
          t('map:modale.updateConfig.geospatial.form.zoom.max.errors.required')
        )
        .integer(
          t('map:modale.updateConfig.geospatial.form.zoom.max.errors.integer')
        )
        .min(
          Yup.ref('mapConfigMinZoom'),
          t('map:modale.updateConfig.geospatial.form.zoom.max.errors.min')
        )
        .max(
          18,
          t('map:modale.updateConfig.geospatial.form.zoom.max.errors.max')
        ),
      mapConfigMinZoom: Yup.number()
        .required(
          t('map:modale.updateConfig.geospatial.form.zoom.min.errors.required')
        )
        .integer(
          t('map:modale.updateConfig.geospatial.form.zoom.min.errors.integer')
        )
        .min(
          0,
          t('map:modale.updateConfig.geospatial.form.zoom.min.errors.min')
        )
        .max(
          Yup.ref('mapConfigMaxZoom'),
          t('map:modale.updateConfig.geospatial.form.zoom.min.errors.max')
        ),
      mapConfigUpdateInterval: Yup.number()
        .integer(t('map:modale.updateConfig.misc.form.refresh.errors.integer'))
        .min(0, t('map:modale.updateConfig.misc.form.refresh.errors.min'))
        .max(120, t('map:modale.updateConfig.misc.form.refresh.errors.max')),
      mapConfigZoom: Yup.number()
        .required(
          t(
            'map:modale.updateConfig.geospatial.form.zoom.default.errors.required'
          )
        )
        .integer(
          t(
            'map:modale.updateConfig.geospatial.form.zoom.default.errors.integer'
          )
        )
        .min(
          Yup.ref('mapConfigMinZoom'),
          t('map:modale.updateConfig.geospatial.form.zoom.default.errors.min')
        )
        .max(
          Yup.ref('mapConfigMaxZoom'),
          t('map:modale.updateConfig.geospatial.form.zoom.default.errors.max')
        ),
    });
    const handleSubmit = (
      values: FormTypes.IFormValues,
      { setSubmitting }: FormikHelpers<FormTypes.IFormValues>
    ) => {
      setSubmitting(true);
      const formData: any = Object.assign({}, values);
      formData.mapConfigGeocodageCountry = values.mapConfigGeocodageCountry.join(
        ','
      );
      MapService.updateMapConfig(formData)
        .then(() => {
          updateMapConfig(formData);
          enqueueSnackbar(t('map:modale.updateConfig.success'), {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'success',
          });
          handleClose();
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
          setSubmitting(false);
        });
    };
    return (
      <MapConfigForm
        defaultValues={defaultValues}
        onFormSubmit={handleSubmit}
        validationSchema={validationSchema}
        miscFunctions={{ cancel: handleClose }}
      />
    );
  };

  return (
    <Modal
      open={isOpened}
      onClose={handleClose}
      aria-labelledby='update-map-config'
      aria-describedby='update-map-config'
      className='z-1600'
    >
      <div data-testid='map-config-modale'>
        <BaseModale
          content={renderForm()}
          title={t('map:modale.updateConfig.title')}
          size='large'
        />
      </div>
    </Modal>
  );
};

export default MapConfigModale;
