// Hooks
import LinkMetrics from '../../Data/Hooks/LinkMetrics';
import ProjectEvolutionMetrics from '../../Data/Hooks/ProjectEvolutionMetrics';
import ProjectMetrics from '../../Data/Hooks/ProjectMetrics';
import ProjectMetricsBudget from '../../Data/Hooks/ProjectMetricsBudget';
import ProjectTasksMetrics from '../../Data/Hooks/ProjectTasksMetrics';
import ResourceEvolutionMetrics from '../../Data/Hooks/ResourceEvolutionMetrics';
import ResourceLinksEvolutionMetrics from '../../Data/Hooks/ResourceLinksEvolutionMetrics';
import ResourceMetrics from '../../Data/Hooks/ResourceMetrics';

export default {
  LinkMetrics,
  ProjectEvolutionMetrics,
  ProjectMetrics,
  ProjectMetricsBudget,
  ProjectTasksMetrics,
  ResourceEvolutionMetrics,
  ResourceLinksEvolutionMetrics,
  ResourceMetrics
}
