// React libs
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../../../UiKit/Button/Button';
import FaIcon from '../../../UiKit/Icon/FaIcon/FaIcon';
// Type
import * as Types from './EditButton.type';

const EditButton: FC<Types.IProps> = ({ className, onClick, isEditionMode, size }) => {
  // Variables
  const { t } = useTranslation(['common'])

  return <span data-testid='edit-button'>
    <Button
      onClick={onClick}
      variant="outlined"
      startIcon={<FaIcon name='pencil' />}
      className={className}
      disabled={isEditionMode}
      size={size}
    >
      {isEditionMode ? t("common:forms.buttons.editing") : t('common:forms.buttons.edit')}
    </Button>
  </span>
}

export default EditButton