import bikeParking from '../pictograms/services/bike-parking.png';
import city from '../pictograms/services/city.png';
import restrooms from '../pictograms/services/restrooms.png';
import toilet from '../pictograms/services/toilet.png';
import tribune from '../pictograms/services/tribune.png';
import trolley from '../pictograms/services/trolley.png';
import coffeeCup from '../pictograms/services/coffee-cup.png';

export const servicesPictograms = [
  {
    slug: 'bike-parking',
    icon: bikeParking,
  },
  {
    slug: 'city',
    icon: city,
  },
  {
    slug: 'restrooms',
    icon: restrooms,
  },
  {
    slug: 'toilet',
    icon: toilet,
  },
  {
    slug: 'tribune',
    icon: tribune,
  },
  {
    slug: 'trolley',
    icon: trolley,
  },
  {
    slug: 'coffee-cup',
    icon: coffeeCup,
  },
];
