// React libs
import React, { FC, useState } from 'react';
import { Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../../../../../../Core/Components/UiKit/Button/Button';
import FaIcon from '../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import FormHeader from '../../../../../../Core/Components/Form/FormHeader/FormHeader';
import * as FormField from '../../../../../../Core/Components/Form/FormFields/FormFields'
// Type
import * as Types from './AddressProfilForm.type';
// Utils
import {
  getFormPropTypes, isDisabled,
} from '../../../../../../Core/Utils/FormUtils';

const UserAddressForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
  >
    {(formikProps: FormikProps<any>) => (
      <UserAddressFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}

  </Formik>
);

UserAddressForm.propTypes = getFormPropTypes();

export default UserAddressForm;


export const UserAddressFormikForm = ({
  errors,
  isSubmitting,
  touched,
  miscData,
  values
}: FormikProps<any> & any) => {
  const { t } = useTranslation(['common', 'profile']);
  const isNested = miscData?.nested ?? false
  const [edit, setEdit] = useState<boolean>(isNested);
  const fieldContainerClasses = 'flex w-full items-center mx-2';

  const handleReset = () => {
    setTimeout(() => {
      setEdit(false)
    }, 100);
  }

  return (
    <>
      <FormHeader
        title={t("profile:address.title")}
        isEditionMode={edit}
        toggleEditionMode={() => setEdit(edit => !edit)}
      />
      <Form data-testid='form-address-profils' className='flex w-full'>
        <div className='flex flex-col w-11/12'>
          <FormField.FormAddress
            name='fkaddress'
            selectedAddressId={values.fkaddress}
            disabled={!edit}
          />
          {edit && !isNested &&
            <div className={`${fieldContainerClasses} justify-center`} >
              <Button
                type='reset'
                variant='text'
                size='large'
                className='mx-2'
                onClick={handleReset}
              >
                {t("profile:common.cancel")}
              </Button>
              <Button
                type='submit'
                variant='text'
                size='large'
                className='mx-2'
                onClick={handleReset}
                disabled={isDisabled(errors, isSubmitting, touched)}
                startIcon={
                  isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : null
                }
              >
                {t("profile:common.save")}
              </Button>
            </div>
          }
        </div>
      </Form>
    </>
  );
};